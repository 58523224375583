<template>
  <div>
    <div v-for="(msg, index) in messages" :key="index">
      <b-alert
        :variant="msg.type"
        show
        fade
        :key="index"
        v-if="msg.allow_module ? msg.allow_module == currentModule : true"
        >{{ msg.message }}</b-alert
      >
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "alertSection",
  props: ["currentModule"],
  computed: {
    ...mapGetters({
      messages: "messages",
    }),
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
