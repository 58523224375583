module.exports = {
  label: {
    title: "Staff Members",
    addMemberText: 'Add New Staff Member',
    selectMemberText:"Select Staff Member",
    editMemberText:'Edit Member',
    deleteMember: 'Are you sure your want to delete this member?',
    confirmTitle: 'Are you sure?',
    confirmMsg: 'You want to delete this!',
    ValidationTitle: 'Warning!',
    form: {      
      label: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        contactNumber: 'Contact Number',
        role: 'Role',
      },
      validation: {
        firstName: 'first name',
        lastName: 'last name',
        email: 'email',
        contactNumber: 'contact number',
        role: 'role',
      },
      placeholder: {
        enter: 'Enter',
        email: 'example@mail.com',
        enterNumber: 'Enter Number',
        select: 'Select',
        searchContact: 'Search Contact',
        contactNumber: 'contact number',
        searchStaffMember:"Search Staff Member"
      }
    },
    tableHeader: {
      firstName: "Member Name",
      role: "Role",
      email: "Email ID",
      contactNumber: 'Contact',
      dateAdded: 'Date Added',
      status: "Status",
      actions: 'Actions'
    },
    buttons: {
      addMember: 'Add Staff Member',
      addNew:"Add New",
      inviteAdd: 'Invite and Add',
      editText:'Save Member',
      yes: 'Yes',
      no: 'No',
      ok:'Ok',
      selected:"Selected",
      select:"Select"
    },
    tooltip:{
      edit : 'Edit Member',
      delete : 'Delete Member',
    }
  }
};