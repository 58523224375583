<template>
  <div class="property-amenity">
    <div class="amenity-box">
      <div class="row">
        <template v-for="(amenityAdd, indexA) in selectedAmenities">
          <div
            class="col-4 col-md-3 col-lg-2 text-center"
            :key="indexA"
            v-if="(limit != 0 && indexA < limit) || (limit == 0)"
          >
            <div class="btn">
              <b-media
                class="flex-column justify-content-center align-items-center text-center"
              >
                <template #aside>
                  <img
                    :src="getAmenityIcon(amenityAdd.image)"
                    :alt="amenityAdd.amenity"
                  />
                </template>
                <p class="amenity-text mb-0">{{ amenityAdd.amenity }}</p>
              </b-media>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import jsondata from "@/assets/json/data.json";
import { mapGetters } from "vuex";
export default {
  props: ["selectedAmenities","limit"],
  components: {},
  computed: {
    ...mapGetters({
      ImageUrl: "ImageUrl",
    }),
  },
  data() {
    return {
      lang: this.$lang.property_details,
      data: jsondata
    };
  },

  methods: {
    getAmenityIcon(amenity) {
      return this.ImageUrl + amenity;
    },
  },
};
</script>
