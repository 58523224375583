<!--Bank Table -->
<template>
    <!-- table section -->
    <div class="table-section">
      <div class="table-card table-border-none with-pagination-table">
        <b-card no-body class="custom-card reports-table">
          <b-card-body class="p-0 ">
            <v-client-table
              :data="tableData"
              :columns="columns"
              :options="options"
            >
            <template slot="address" slot-scope="props" v-if="props.row.address != ''">
              <span>{{ props.row.address }}</span>,<span>{{ props.row.country }}</span>,<span>{{ props.row.state }}</span>,<span>{{ props.row.city }}</span>,<span>{{ props.row.zipCode }}</span>
            </template>
            </v-client-table>
          </b-card-body>
        </b-card>
      </div>
    </div>
</template>
<script>
import Vue from "vue";
import { ClientTable } from "vue-tables-2";
Vue.use(ClientTable, {}, false, "bootstrap4");
export default {
    name:'BankList',
    props:['tableData'],
    data(){
      return{
        lang: this.$lang.bank_information,
        columns: ['name', 'bankName', 'bankAccountNumber', 'code', 'address'],
        options: {
            headings: {
                name: this.$lang.bank_information.label.tableHeader.holderName,
                bankName: this.$lang.bank_information.label.tableHeader.bankName,
                bankAccountNumber: this.$lang.bank_information.label.tableHeader.bankAccountNumber,
                code: this.$lang.bank_information.label.tableHeader.code,
                address: this.$lang.bank_information.label.tableHeader.address,
            },
            skin: "table",
            perPageValues: [5,10,15],
            perPage: 5,
            sortable: ['name', 'bankName', 'bankAccountNumber', 'code', 'address'],
            columnsClasses: {
              // address:'address-width',
            },
            pagination: { chunk: 5 },
            texts: {
              noResults: this.$lang.root.label.noRecord,
              filter: this.$lang.root.label.filter,
              filterPlaceholder: this.$lang.root.label.filterPlaceholder,
              limit: this.$lang.root.label.limit,
              count: this.$lang.root.label.count,
            },
        },
      }
    }
}
</script>