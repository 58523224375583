module.exports = {
  label: {
    verifyEmialAlert: "Verifique su correo electrónico para obtener acceso completo a Miradoor, es posible que no pueda añadir contactos o propiedades.",
    monthlyIncome: 'Ingresos Mensuales',
    monthlyExpenses: 'Gastos Mensuales',
    rentedUnits: 'Unidades Alquiladas',
    propertiesAvailable: 'Propiedades Disponibles',
    other: 'Lista De Contactos',
    otherTable: 'Otros',
    selectMonth: 'Seleccionar Mes',
    confirmTitle: '¿Estas Seguro?',
    confirmMsg: '¿Quieres borrar esto?',
    editContact: "Editar Contacto",
    expense: 'Gastos',
    income: 'Ingresos',
    tableHeader: {
      name: "Nombre De Contacto",
      role: "Función",
      email: "Correo Electrónico",
      dateAdded: "Fecha Que Se Agrego",
      actions: "Acciones",
    },
    buttons: {
      sendCode: 'Enviar Código De Verificación',
      yes: 'Sí',
      no: 'No',
    },
    tooltip:{
      editContact : 'Editar Contacto',
      deleteContact : 'Borrar Contacto',
    }
  }
};