<!-- content from properties.js -->
<template>
  <!-- table section -->
  <div class="table-section vertical-top">
    <div class="table-card table-border-none with-pagination-table">
      <b-card no-body class="custom-card reports-table">
        <b-card-body class="p-0">
          <v-client-table
            :data="tableData"
            :columns="columns"
            :options="options"
            v-if="isTableVisible"
          >
            <template slot="address" slot-scope="props">
              <p class="mb-0">
              {{ props.row.property_name }}
              </p>
              <div class="mt-1">
              <router-link :to="{ name: propertyViewRoute, params:{ id:props.row.property_id}}  ">
                {{ props.row.address }}
              </router-link>
              </div>
            </template>

            <template slot="name" slot-scope="props">
              <p class="mb-0">
                {{ props.row.name }}
              </p>
              <div class="mt-1">
                <span class="sm-font mt-1 d-block"  v-if="props.row.contact || props.row.email"
                  >{{ props.row.contact }} | {{ props.row.email }}</span
                >
              </div>
            </template>

            <template slot="leaseDuration" slot-scope="props">
              <p class="mb-0">
                {{
                  leaseDuration(
                    props.row.lease_period_start_date,
                    props.row.lease_period_end_date
                  )
                }}
              </p>
            </template>

            <template slot="type" slot-scope="props">
              <p class="mb-0">{{ props.row.propertyType }}</p>
            </template>

            <template slot="paidDateTime" slot-scope="props">
              <p class="mb-0">{{ getPaidDate(props.row.date) }}</p>
            </template>

            <template slot="date" slot-scope="props">
              <p class="mb-0">{{ getPaidDateTime(props.row.date) }}</p>
            </template>
          
            <template slot="billingType" slot-scope="props">
              <p class="mb-0">{{ paymentFrequencyOption[props.row.payment_frequency_id || 0] }}</p>
            </template>

            <template slot="amount" slot-scope="props">
              <p class="mb-0">
                <span v-if="props.row.type_id == 1">+</span>
                <span v-if="props.row.type_id == 2">-</span>
                {{ "$" + props.row.amount }}
              </p>
              <div class="mt-1">
                <span
                  class="sm-font mt-1 d-block"
                  :class="
                    props.row.type_name == 'Expense'
                      ? 'text-danger'
                      : props.row.type_name == 'Income'
                      ? 'text-success'
                      : 'text-dark'
                  "
                  >{{ amountTypeOption[props.row.type_id] }}</span
                >
              </div>
            </template>
            <template slot="miscellaneous" slot-scope="props">
              <el-tooltip
                :content="lang.label.tooltip.miscellaneous"
                placement="bottom"
                v-if="props.row.miscellaneous"
              >
                <a :href="ImageUrl + props.row.miscellaneous" target="_blank">
                  <b-btn variant="link" class="p-1">
                    <span class="material-icons">visibility</span>
                  </b-btn></a
                >
              </el-tooltip>
              <p class="mb-0" v-else>N/A</p>
            </template>

            <template slot="actions" slot-scope="props">
              <template v-if="props.row.type_name == 'Expense'">
                <!-- view expense confirmation message -->
                <b-btn
                  variant="link"
                  class="p-1"
                  v-b-tooltip.hover
                  :title="lang.label.tooltip.expenseView"
                  v-if="props.row.proofs.length > 0"
                >
                  <span class="material-icons" @click="viewExpensePopup(props.row.id)"
                    >visibility</span
                  >
                </b-btn>

                <b-btn
                  variant="link"
                  class="p-1"
                  v-b-tooltip.hover
                  :title="lang.label.tooltip.expenseDelete"
                >
                  <span class="material-icons" @click="deleteExpensePopup(props.row.id)"
                    >delete</span
                  >
                </b-btn>
              </template>
            </template>
          </v-client-table>
        </b-card-body>
      </b-card>

      <!-- Hidden in UI,usage(for downloading pdf) -->
      <div id="tenant" class="d-none">
        <v-client-table
          :data="tableData"
          :columns="columns"
          :options="optionsPrint"
          v-if="isTableVisible"
          id="TableData"
        >
          <template slot="address" slot-scope="props">
            <p class="mb-0">
              {{ props.row.address }}
            </p>
          </template>

          <template slot="name" slot-scope="props">
            <p class="mb-0">
              {{ props.row.name }}
            </p>
            <div class="mt-1" v-if="props.row.contact || props.row.email">
              <span class="sm-font mt-1 d-block"
                >{{ props.row.contact }} | {{ props.row.email }}</span
              >
            </div>
          </template>

          <template slot="leaseDuration" slot-scope="props">
            <p class="mb-0">
              {{
                leaseDuration(
                  props.row.lease_period_start_date,
                  props.row.lease_period_end_date
                )
              }}
            </p>
          </template>

          <template slot="type" slot-scope="props">
            <p class="mb-0">{{ props.row.propertyType }}</p>
          </template>

          <template slot="paidDateTime" slot-scope="props">
            <p class="mb-0">{{ getPaidDate(props.row.date) }}</p>
          </template>

          <template slot="date" slot-scope="props">
            <p class="mb-0">{{ getPaidDateTime(props.row.date) }}</p>
          </template>

          <template slot="billingType" slot-scope="props">
            <p class="mb-0">{{ paymentFrequencyOption[props.row.payment_frequency_id || 0] }}</p>
          </template>

          <template slot="amount" slot-scope="props">
            <p class="mb-0">
              <span v-if="props.row.type_id == 1">+</span>
              <span v-if="props.row.type_id == 2">-</span>
              {{ "$" + props.row.amount }}
            </p>
            <div class="mt-1">
              <span
                class="sm-font mt-1 d-block"
                :class="
                  props.row.type_name == 'Expense'
                    ? 'text-danger'
                    : props.row.type_name == 'Income'
                    ? 'text-success'
                    : 'text-dark'
                "
                >{{ amountTypeOption[props.row.type_id] }}</span
              >
            </div>
          </template>
          <template slot="miscellaneous" slot-scope="props">
            <el-tooltip
              :content="lang.label.tooltip.miscellaneous"
              placement="bottom"
              v-if="props.row.miscellaneous"
            >
              <a :href="ImageUrl + props.row.miscellaneous" target="_blank">
                <b-btn variant="link" class="p-1">
                  <span class="material-icons">visibility</span>
                </b-btn></a
              >
            </el-tooltip>
            <p class="mb-0" v-else>N/A</p>
          </template>

          <template slot="actions" slot-scope="props">
            <template v-if="props.row.type_name == 'Expense'">
              <!-- view expense confirmation message -->
              <b-btn
                variant="link"
                class="p-1"
                v-b-tooltip.hover
                :title="lang.label.tooltip.expenseView"
                v-if="props.row.proofs.length > 0"
              >
                <span class="material-icons" @click="viewExpensePopup(props.row.id)"
                  >visibility</span
                >
              </b-btn>

              <b-btn
                variant="link"
                class="p-1"
                v-b-tooltip.hover
                :title="lang.label.tooltip.expenseDelete"
              >
                <span class="material-icons" @click="deleteExpensePopup(props.row.id)"
                  >delete</span
                >
              </b-btn>
            </template>
          </template>
        </v-client-table>
      </div>

      <b-modal
        ref="viewExpensePopup"
        id="viewExpense"
        hide-footer
        centered
        size="md"
        modal-class=" with-header"
      >
        <template #modal-header="{ close }">
          <h4 class="modal-title">{{ lang.label.expenseDetails }}</h4>
          <b-button variant="link" class="close-btn" @click="close()">
            <img :src="getIconUrl('close.svg')" alt="close" />
          </b-button>
        </template>
        <div>
          <div class="mb-2">
            <div class="">
              <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline">{{
                lang.label.uploadProof
              }}</label>
              <div class="mt-2">
                <div
                  class="d-inline-block"
                  v-for="(image, i) in expenseImages"
                  v-bind:key="i"
                >
                  <div class="uploaded-proof-img">
                    <img :src="ImageUrl + image" alt="placehoder" />
                    <div class="img-download">
                      <b-btn
                        variant="primary"
                        size="sm"
                        @click="downloadImage(ImageUrl + image)"
                      >
                        <span class="material-icons">file_download</span>
                      </b-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import jsondata from "@/assets/json/data.json";
import { ClientTable } from "vue-tables-2";
import Swal from "sweetalert2";
Vue.use(ClientTable, {}, false, "bootstrap4");
import html2pdf from "html2pdf.js";
import { mapGetters } from "vuex";
import mixin from "@/mixins/mixin";
import property_mixin from "@/mixins/property_mixin";

export default {
  name: "PropertiesReports",
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    fileFormat: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default() {
        return [
          "address",
          "name",
          "leaseDuration",
          "paidDateTime",
          "billingType",
          "amount",
          "expenseName",
          "actions",
        ];
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    optionsPrint: {
      type: Object,
      default() {
        return {};
      },
    },
    propertyViewRoute: {
      type: String,
      default : "properties-details",
    }
  },
  mixins: [mixin, property_mixin],
  computed: {
    ...mapGetters({
      ImageUrl: "ImageUrl",
      language:"language"
    }),
  },
  data() {
    return {
      lang: this.$lang.properties,
      data: jsondata,
      // table
      fields: [
        {
          key: "address",
          lable: "Property Address",
        },
        {
          key: "name",
          label: "Tenant Name",
        },
        {
          key: "leaseDuration",
          label: "Lease Duration",
        },
        {
          key: "date",
          label: "Paid Date & Time",
        },
        {
          key: "billingType",
          label: "Billing Type",
        },
        {
          key: "expenseName",
          label: "Expense Name",
        },
        {
          key: "amount",
          label: "Amount",
        },
      ],
      showImageModal: false,
      expenseImages: [],
      isTableVisible: true,
    };
  },
  watch: {
    columns() {
      this.isTableVisible = false;
      setTimeout(() => {
        this.isTableVisible = true;
      }, 100);
    },
  },
  mounted() {
    this.actionsNone();
  },
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getImgUpload(uploaded) {
      return require("@/assets/styles/img/properties/" + uploaded);
    },
    /**
     * Popup for deletion of expense
     * @param {number} id
     */
    deleteExpensePopup(id) {
      let self = this;
      Swal.fire({
        title: this.lang.label.expenseDelete,
        text: self.lang.label.deleteConfirmMsg,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        focusConfirm: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          /* function for bank deletion */
          self.deleteExpense(id);
        }
      });
    },
    /**
     * Delete the expense
     * @param {number} id
     */
    deleteExpense(id) {
      let self = this;
      self.$store.dispatch("deleteExpense", id).then((response) => {
        if (response.status == 200) {
          this.$bvModal.hide("list");
          // for api call
          self.$emit("handleApiCallTenant", true);
          self.$store.commit("setMessages", {
            type: "success",
            message: response.data.message,
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    viewExpense(id) {
      let self = this;
      self.$bvModal.show("view" + id);
    },
    actionsNone() {
      const index = this.columns.indexOf("actions");
      if (this.$route.name == "reports") {
        if (index > -1) {
          this.columns.splice(index, 1);
        }
      }
    },
    /**
     * Calculation for billing in table column -> Billing Type
     */
    paymentMode(id) {
      if (id == 0 || !id) {
        return "N/A";
      } else if (id == 1) {
        return "Monthly";
      } else if (id == 2) {
        return "Quarterly";
      } else if (id == 3) {
        return "Half yearly";
      } else if (id == 4) {
        return "Yearly";
      }
    },
    /**
     * Calculation for lease duration calculation -> Lease Duration in table
     */
    leaseDuration(startDate, endDate) {
      if ((startDate == 0 && endDate == 0) || (!startDate && !endDate)) {
        return "N/A";
      } else if (startDate != 0 && endDate != 0) {
        return (
          this.formatDateStringToDateSlash(startDate) +
          " - " +
          this.formatDateStringToDateSlash(endDate)
        );
      } else if (startDate != 0 && endDate == 0) {
        return this.formatDateStringToDateSlash(startDate) + " - ";
      }
    },
    /**
     * download csv/pdf file
     */
    downloadFile(downloadFormat) {
      let self = this;
      if (downloadFormat.format == "pdf") {
        if (self.tableData.length > 0) {
          self.downloadPdf();
        }
      } else if (downloadFormat.format == "csv") {
        if (self.tableData.length > 0) {
          self.convertToCSV("Payment-Report.csv", self.tableData);
        }
      }
    },
    /**
     * Downloads pdf file
     */
    downloadPdf() {
      let tenant = document.getElementById("TableData");
      tenant.classList.remove("d-none");
      this.$store.commit("setLoading", true);
      const opt = {
        filename: "Payment-Report.pdf",
        margin: [5, 5],
        top: 0,
        jsPDF: { unit: "mm", format: "A2", orientation: "P", compress: true },
      };
      html2pdf().from(tenant).set(opt).save();
      setTimeout(() => {
        tenant.classList.add("d-none");
      });
      this.$store.commit("setLoading", false);
    },
    /**
     * Downloads CSV file
     */
    convertToCSV(filename, arrayOfJson) {
      // convert JSON to CSV

      let self=this;
      var spanishArrayOfJson="";
      if(self.language=="es"){ 
        spanishArrayOfJson=arrayOfJson.map(({ address, name,contact,email,lease_period_start_date,lease_period_end_date,date,amount,type_name,propertyType,expenseName,miscellaneous,property_name }) => ({ 'dirección': address, 'nombre': name,'contacto':contact,'identificación de correo':email,"período de arrendamiento fecha de inicio":lease_period_start_date,"período de arrendamiento fecha final":lease_period_end_date,"fecha":date,"cantidad":amount,"nombre del gasto" :type_name,"tipo de propiedad":propertyType,"nombre del gasto":expenseName,"misceláneos":miscellaneous,"propiedad número de":property_name}));
      }
      const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
      let newArrayJson = _.omit(self.language=="en"?arrayOfJson[0]:spanishArrayOfJson[0], ["id", "actions", "property_id", "type_id", "payment_frequency_id"]);

      const header = Object.keys(newArrayJson);
      let csv='';
      
      if(self.language=="es"){
        csv = spanishArrayOfJson.map((row) =>
        header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(",")
      );
      }else{
        csv = arrayOfJson.map((row) =>
        header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(",")
       )
      }

      csv.unshift(header.join(","));
      csv = csv.join("\r\n");

      // Create link and download
      let link = document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
      );
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    /**
     * View expense details popup
     */
    viewExpensePopup(id) {
      let self = this;
      let find = self.tableData.find((data) => data.id == id);
      self.$refs["viewExpensePopup"].show();
      self.expenseImages = find.proofs.map((data) => data.image);
    },
    /**
     * Image download
     */
    downloadImage(url) {
      fetch(url)
        .then((resp) => resp.blob())
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "proof";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          console.log("Download image error");
        });
    },
    /**
     * returns date in dd/mm/yyyy format in table
     */
    getPaidDate(date) {
      return date ? this.formatDateStringToDateSlash(date) : "N/A";
    },
    /**
     * returns date in dd/mm/yyyy hh:mm  format in table
     */
    getPaidDateTime(date) {
      return date ? this.formatDateStringToDateTimeSlash(date) : "N/A";
    },
  },
};
</script>
