<!-- content from staff_management.js -->
<template>
    <div class="body-content">
        <div class="top-sticky">
            <div class="top-bar-radius"></div>
        </div>
        <div class="starts-section" v-loading="loading">
            <!-- body content header  -->
            <alert-section></alert-section>
            <div class="page-breadcrumb">
                <div class="d-md-flex justify-content-between align-items-center">
                    <div class="pg-bread">
                        <b-breadcrumb class="bg-transparent p-0 mb-0">
                            <b-breadcrumb-item active>
                                {{ lang.label.title }}
                            </b-breadcrumb-item>
                            <b-breadcrumb-item active>
                                <span class="material-icons align-middle">supervisor_account</span>
                                <span class="align-middle ml-1">{{ tableData.length > 0 ? tableData.length : "" }}</span>
                            </b-breadcrumb-item>
                        </b-breadcrumb>
                    </div>
                    <div class="pg-right">
                        <div class="d-flex align-items-center">
                            <b-btn variant="primary" size="sm" class=" btn-mw w-sm-100" @click="addNewMember()">+ {{ lang.label.buttons.addMember }}</b-btn>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pb-3">                
                <member-list @editMember="editMember" :tableData="tableData"></member-list>
            </div>


            <!-- add new member -->
            <b-sidebar footer-class="sidebar-footer" sidebar-class="sidebar-view"
                text-variant="none" backdrop right no-close-on-esc width="462px" :visible="isVisible" @change="updateSidebar">
                <template #header="{ hide }">
                    <div class="d-flex justify-content-between w-100">
                        <div class="d-flex align-items-center">
                            <b-btn variant="link" size="sm" class="p-0" @click="hide">
                                <img :src="getIconUrl('close-sm.svg')" alt="close" />
                            </b-btn>
                            <h4 v-if="!isEdit" class="sidebar-header-title mb-0">
                                {{ lang.label.addMemberText }}
                            </h4>
                            <h4 v-else class="sidebar-header-title mb-0">
                                {{ lang.label.editMemberText }}
                            </h4>
                        </div>
                    </div>
                </template>
                <div class="amenity-body">
                    <template>
                        <add-member :userId="userId" :isEdit="isEdit" @updateSidebar="updateSidebar" @handleMember="handleMember" :clearData="clearData" ></add-member>
                    </template>
                </div>
            </b-sidebar>
        </div>
    </div>
</template>
<script>

import MemberList from "./Staff/List.vue";
import AddMember from "./Staff/AddMember.vue";
import alertSection from "@/views/shared/AlertSection.vue";
import { mapGetters } from "vuex";
import mixin from "@/mixins/mixin";
import subscriptionValidation_mixin from '@/mixins/subscriptionValidation_mixin'

export default {    
    // props: ["editData"],
    computed: {
        ...mapGetters({
            loading: "loading",
            isUserVerified: "isUserVerified",
        }),
    },
    mixins: [mixin,subscriptionValidation_mixin],
    components: {
        "member-list": MemberList,
        "add-member": AddMember,
        'alert-section' :alertSection,
    },
    data() {
        return {
            lang: this.$lang.staff_management,
            isVisible: false,
            userId : '',
            isEdit:false,
            tableData: [
            ],
            clearData:false
        };
    },
    mounted() {
        this.getStaffList()
    },
    methods: {
        getIconUrl(icon) {
            return require("@/assets/styles/img/icons/" + icon);
        },
        addNewMember(){
            let self = this;
            if (self.isUserVerified) {
                self.$store.commit("setVerificationAlert", true);
            } else {
                let payloadData = {
                    type : 3
                }
                self.checkSubscriptionValidation(payloadData).then((response) => {
                    if(response){
                        self.isEdit = false
                        self.isVisible = true
                        self.clearData = true
                    }
                })
            }
        },
        editMember(id){
            let self = this
            self.isEdit = true
            self.isVisible = true
            self.clearData = true
            self.userId = id
        },
        getStaffList(){
            let self = this
            self.$store.dispatch('listStaffMembers').then((response)=>{
            if(response.status == 200){
                self.tableData = []
                response.data.forEach((v,i) =>{
                    self.tableData.push({
                        id: v.id,
                        firstName: v.first_name,
                        lastName: v.last_name,
                        role: v.role ? v.role.name : '',
                        email: v.username,
                        contactNumber: v.contact,
                        dateAdded: self.formatDateToShow(v.added_date),
                        status: v.status,
                        user_id : v.user_id
                    })
                })
            }else{
                let message = response.data.message || response.data.detail;
                if (message) {
                    self.$store.commit("setMessages", {
                    type: "danger",
                    message: message,
                    });
                }
            }
        })
        },
        handleMember(){
            this.getStaffList()
        },
        updateSidebar(val){
            this.isVisible = val;
            if (!this.isVisible) {
                this.isEdit = false
                this.userId = ''
                this.clearData = false
            }
            const body = document.getElementsByTagName('body')[0];
            if(val){
                body.classList.add("overflow-hidden")
            } else {
                body.classList.remove("overflow-hidden")
            }
        }
    },
}
</script>

