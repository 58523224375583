module.exports = {
    label: {
      addExpenseTitle: "Agregar Gastos",
      form: {
        label: {
          expenseName: 'Nombre Del Gasto',
          expenseAmount: 'Monto Del Gasto',
          expenseDate: 'Fecha De Gasto',
          uploadProof: 'Subir Prueba Del Gasto'
        },
        placeholder: {
          expenseName: 'I.e. Painting',
          expenseAmount: '0.00',
          selectDate: 'Seleccionar Fecha',
          attach: 'Adjuntar Aquí'
        },
        validation: {
          expenseName: 'nombre del gasto',
          expenseAmount: 'monto del gasto',
          expenseDate: 'fecha de gasto',
          uploadProof: 'subir prueba del gasto'
        },
      },
      buttons: {
        submit: 'Enviar',
        add: 'Agregar',
      },
      errorMessages: {
        fileSizeExceeded: 'El Tamaño Del Archivo No Debe Ser Mayor A 10MB',
      }
    }
  };