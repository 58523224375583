<!-- content from rented_properties.js -->
<template>
    <div class="property-amenity">
        <div class="amenity-box">
            <div class="row">
                <template v-for="(amenityAdd, indexA) in data.amenities">
                <div class="col-4 col-md-3 col-lg-2 text-center" :key="indexA" v-if="indexA < limit_by">
                    <div class="btn">
                        <b-media class="flex-column justify-content-center align-items-center text-center">
                            <template #aside>
                                <img :src="getAmenityIcon(amenityAdd.icon)" :alt="amenityAdd.name" />
                            </template>
                            <p class="amenity-text mb-0">{{ amenityAdd.name }}</p>
                        </b-media>
                    </div>
                </div>
            </template>
            </div>
        </div>
</div>
</template>
<script>
import jsondata from "@/assets/json/data.json";
export default {
    components: {

    },
    data() {
        return {
            lang: this.$lang.rented_properties,
            data: jsondata,
            limit_by: 8,
        };
    },

    methods: {
        getAmenityIcon(amenity) {
            return require("@/assets/styles/img/amenities/" + amenity);
        },
    },
}
</script>