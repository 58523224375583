module.exports = {
  label: {
    title: "Messages",
    search: 'Search',
    form: {
      validation: {
        currentPassword: 'current password',
        newPassword: 'new password',
        reEnterPassword: 're-enter new password',
      },
      placeholder: {
        currentPassword: 'Enter Current Password',
        newPassword: 'Enter New Password',
        reEnterPassword: 'Re-enter New Password',
      }
    },
    writeHere: 'Write Here...',
    buttons: {
      update: 'Update Password',
      ok : 'Ok'
    },
    extensionValidation:'Only .jpeg, .jpg, .png and .pdf extensions are allowed',
    documentSizeValidation:'You cannot upload document greater then 10 MB is size',
    unreadMessages: "Unread Messages"
  }
};