module.exports = {
    label: {
      dateAdded: "Fecha De Inicio",
      addProperties: 'Añadir Propiedad',
      readMore: 'Leer Más',
      amenities: 'Comodidades',
      viewAll: 'Ver Todo',
      propertyReport: 'Reporte De La Propiedad',
      maintenanceFee: 'Cuota De Mantenimiento',
      leaseAmount: 'Monto Del Alquiler',
      advanceAmount: 'Cantidad De Adelanto',
      monthly: 'Mensual',
      brokerDetails: 'Datos Del Agente Inmobiliario',
      ownerDetails: 'Propietario Details',
      tenantDetails: 'Detalles Del Inquilino',
      leasedTo: 'Alquilado A',
      leasedPeriod: 'Plazo De Alquiler',
      memberDetails: 'Detalles Del Administrador De La Propiedad',
      startDate: 'Fecha De Inicio',
      endDate: 'Fecha Final',
      leaseAmountText: 'Monto Del Alquiler',
      depositAmount: 'Monto Del Depósito',
      rentDueDate: 'Próxima Fecha de Pago',
      maintenanceFeeText: 'Cuota De Mantenimiento',
      amountDueFirstMonth: 'Monto Adeudado Del Primer Plazo',
      rentDue: 'Próximo Pago Del Alquiler ',
      leaseAgreement: 'Contrato De Alquiler (PDF, JPEG, JPG, PNG)',
      areYouSureDelete: '¿Está Seguro Que Desea Eliminar Esta Propiedad?',
      areYouSureDeleteLease: "¿Está Seguro Que Desea Eliminar El Documento De Alquiler?",
      noRevertBackProperty: '*No Podrá Revertir Después De Eliminar Esta Propiedad.',
      photosVideos: 'Fotos Y Videos',
      noLeaseDocMsg: 'No se ha proporcionado ningún documento',
      documentSizeValidation:'No puede cargar un documento de tamaño mayor a 10MB',
      contactOwner: 'Contactar Propietario',
      copyLinkText:'El Enlace Se Ha Copiado En Su Portapapeles',
      idDoc : 'Documento De Identidad',
      notIDDoc:'No Se Ha Cargado',
      rentDetailsTitle: 'Detalles De Alquiler',
      noMemberAssigned: "Ningún Miembro Asignado",
      noBrokerAssigned: "No Hay Agente Asignado",
      noOwnerAssigned: "No Propietario Assigned",
      noTenantAssigned: "No Hay Inquilino Asignado",
      noTenantAssigned_1:"Sin inquilino asignado, por favor asigne el inquilino primero",
      rentDetails: {
        title: 'Resumen De Detalles Del Alquiler',
        tenant: 'Inquilino',
        leasePeriod: 'Período De Arrendamiento',
        leaseAmount: 'Monto Del Alquiler',
        maintenanceFees: 'Cuota De Mantenimiento',
        depositAmount: 'Monto Del Depósito',
        amountDueFirstMonth: 'Monto Adeudado Del Primer Plazo',
      },
      realtor: 'Agente Inmobiliario',
      owner: 'Propietario',
      message: 'Mensaje',
      paymentFrequency: {
        monthly: 'Mensual',
        quarterly: 'Trimestral',
        haldYearly: 'Mitad Anual',
        yearly: 'Anualmente',
      },
      amountType: {
        income: 'Ingresos',
        expense: 'Gastos',
      },
      propertyFacilities: {
        livableSpace: 'Espacio Habitable En',
        area: 'Área',
        construction: 'Construcción',
        bedrooms: 'Dormitorios',
        bathrooms: 'Baños',
        parkings: 'Estacionamiento',
      },
      tableReportHeader: {
        number: "Nº Serial",
        name: "Nombre Del Propietario",
        paymentPeriod: "Tipo De Propiedad",
        amount: "Monto De Alquiler",
        date: "Fecha Y Hora Pagada",
        paymentCard: "Tarjeta de Pago",
        actions: "",
      },
      placeholder: {
        uploadDocument: 'Documento De Carga'
      },
      brokerCommission: {
        title: 'Tarifas De La Comisión De Broker',
        closingCostComFirst: 'Comisión De Gastos De Cierre (sólo primer plazo)',
        recurringRentalFee: 'Cuota Recurrente De Administración Del Alquiler',
        recurringRentalFeeBroker: 'Comisión De Administración Del Alquiler Recurrente Para El Agente',
      },
      rentalAmountOwner: {
        title: 'Cantidad De Alquiler Al Propietario',
        firstMonthOwner: 'Primer Plazo Al Propietario',
        recurringRentalAmountDueOwner: 'Monto Del Alquiler Adeudado Al Propietario'
      },
      form: {      
        label: {
          firstName: 'Nombre',
          lastName: 'Apellido',
          email: 'Correo Electrónico',
          contactNumber: 'Número De Teléfono',
          message: 'Mensaje',
        },
        validation: {
          firstName: 'nombre',
          lastName: 'apellido',
          email: 'correo electrónico',
          contactNumber: 'número de teléfono',
          message: 'mensaje',
        },
        placeholder: {
          enter: 'Enter',
          email: 'example@mail.com',
          enterNumber: 'Ingrese El Número',
          contactNumber: 'Número De Teléfono',
          message: 'Ingrese Su Mensaje',
        }
      },
      buttons: {
        yes: 'Sí',
        no: 'No',
        shareLink: 'Compartir Enlace',
        editProperty: 'Editar Propiedad',
        deleteProperty: 'Eliminar Propiedad',
        change: 'Cambiar',
        upload: 'Subir',
        remove: 'Eliminar',
        payRent: 'Pagar Alquiler',
        send: 'Enviar',
        signAgreement: 'Firmar Acuerdo',
        viewSign: 'Ver Firma',
        download: 'Descargar',
        undo: "Undo",
        save: "Guardar",
        submit: "Enviar",
      },
      tooltip:{
        view : 'Ver Documento',
      }
    }
  };