module.exports = {
    label: {
      tabs: {
        owner: 'Propietario',
        broker: 'Inmobiliario',
        savedCard: 'Mi Método De Pago',
        bankDetails:'Datos Bancarios'
      },
      noRecord: 'Ningún Registro Disponible',
      accountNumber: 'Cuenta Bancaria',
      swiftCode: 'Swift Del Banco',
      resendLink: 'Reenviar Enlace',
      deleteBroker: 'Borrar',
      expiry: 'Vencimiento',
      credit: 'Crédito',
      debit: 'Débito',
      addBankDetails: "Agregar Datos Bancarios",
      editBankDetails: 'Editar Datos Bancarios',
      addNewCard: "Añadir Nueva Tarjeta",
      bankInformation:'Seleccionar Pago Por Defecto',
      cardInformation:'Seleccione La Tarjeta Por Defecto',
      deleteConfirmMsg: "¿Quieres borrar esto?",
      savedCardTitle: 'Tarjeta Guardada',
      payment: "Pago",
      warning:'Advertencia',
      addCradValidation:'No Puede Agregar Una Tarjeta En El Período De Prueba',
      defAcDelete:'No puede eliminar la cuenta de pago predeterminada, seleccione otra cuenta como predeterminada',
      tableHeader:{
        holderName:'Nombre Del Titular',
        bankName:'Nombre Del Banco',
        bankAccountNumber:'Cuenta Bancaria',
        code:'Swift Del Banco',
        address:'Dirección',
        state:'Provincia',
        city:'Ciudad',
        zipCode:'Código Postal'
      },
      modal: {
        bankDetailDelete: "¿Está seguro de que desea eliminar estos datos bancarios?",
        brokerDelete: "¿Está seguro de que desea eliminar este agente inmobiliario?",
        cardDelete: "¿Está seguro de que desea eliminar esta tarjeta?",
        bankDelete:"¿Estas Seguro?"
      },
      form: {      
        label: {
          firstName: "Nombre",
          lastName: 'Apellido',
          email: 'Correo Electrónico',
          contactNumber: 'Número De Teléfono',
          cardNumber: 'Número De Tarjeta',
          nameOnCard: 'Nombre En La Tarjeta',
          expiryDate: 'Fecha De Vencimiento',
          cvv: 'CVV',
        },
        validation: {
          firstName: "nombre",
          lastName: 'apellido',
          email: 'correo electrónico',
          contactNumber: 'número de teléfono',
          cardNumber: 'número de tarjeta',
          nameOnCard: 'nombre en la tarjeta',
          expiryDate: 'fecha de vencimiento',
          cvv: 'CVV',
        },
        placeholder: {
          firstName: "Nombre",
          lastName: 'Apellido',
          email: 'example@mail.com',
          contactNumber: 'Ingrese el número',
          cardNumber: 'XXXX XXXX XXXX XXXX ',
          nameOnCard: 'Ingrese',
          expiryDate: 'MM/YY',
          cvv: 'Ingrese',
        }
      },
      buttons: {
        yes: 'Sí',
        no: 'No',
        ok:'Aceptar',
        add: 'Añadir Tarjeta De Crédito',
        payNow: 'Pagar Ahora',
        addButton: 'Agregar',
    },
      tooltip:{
        edit: 'Editar Detalles Bancarios',
        delete: 'Eliminar Detalles Bancarios',
        deleteCard: 'Eliminar Tarjeta'
      }
    }
  };