<!-- content from bank_details_request.js -->
<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ passes }">
      <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
        <b-container fluid class="px-0 pb-fix-btn">
          <alert-section currentModule="requestBankDetails"></alert-section>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <label class="form-label">{{ lang.label.form.label.firstName }}<span class="text-danger">*</span></label>
                <ValidationProvider
                  :name="lang.label.form.validation.firstName"
                  rules="required|max:50"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-form-input
                      v-model="models.firstName"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.enter"
                      class="contact-selected"
                      :disabled="isViewOnly"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label class="form-label">{{ lang.label.form.label.lastName }}<span class="text-danger">*</span></label>
                <ValidationProvider
                  :name="lang.label.form.validation.lastName"
                  rules="required|max:50"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-form-input
                      v-model="models.lastName"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.enter"
                      class="contact-selected"
                      :disabled="isViewOnly"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label class="form-label">{{ lang.label.form.label.email }}<span class="text-danger">*</span></label>
                <ValidationProvider
                  :name="lang.label.form.validation.email"
                  rules="required|email"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-form-input
                      v-model="models.email"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.email"
                      class="text-lowercase contact-selected"
                      :disabled="isViewOnly"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label class="form-label">{{
                  lang.label.form.label.contactNumber
                }}<span class="text-danger">*</span></label>
                <ValidationProvider
                  :name="lang.label.form.validation.contactNumber"
                  rules="required|numeric|min:6|max:8"
                  v-slot="validationContext"
                >
                  <div class="position-relative prepend-icon prepend-text country-code">
                    <b-form-input
                      v-model="models.contactNumber"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.enterNumber"
                      class="contact-selected"
                      :disabled="isViewOnly"
                    ></b-form-input>
                    <div class="icon">
                      <div class="pre-app-text primary-text bf-size">+507</div>
                    </div>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
        <div class="add-contact-button">
          <b-btn variant="primary" block type="submit">{{
            lang.label.buttons.sendRequest
          }}</b-btn>
        </div>
      </b-form>
    </ValidationObserver>

    <!-- send email modal -->
    <b-modal ref="sendMail" hide-footer centered size="md" modal-class="close-btn-right">
      <template #modal-header="{ close }">
        <b-button variant="link" class="close-btn" @click="close()">
          <img :src="getIconUrl('close.svg')" alt="close" />
        </b-button>
      </template>
      <div class="d-block text-center">
        <div class="mail-icon">
          <span class="material-icons">email</span>
        </div>
        <h3 class="body-modal-heading">{{ lang.label.sendMail }}</h3>
      </div>
    </b-modal>
  </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import alertSection from "@/views/shared/AlertSection.vue";
export default {
  props: ["selectedContact", "isViewOnly"],
  components: {
    "alert-section": alertSection,
  },
  data() {
    return {
      lang: this.$lang.bank_details_request,
      data: jsondata,
      models: {
        firstName: "",
        lastName: "",
        email: "",
        contactNumber: "",
      },
    };
  },
  mounted() {
    this.handleDefaultVal();
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    onSubmit() {
      let self = this;
      let data = {
        email: self.models.email.toLocaleLowerCase(),
        first_name: self.models.firstName,
        last_name: self.models.lastName,
        contact: self.models.contactNumber,
        contact_id: self.selectedContact.id,
      };
      self.$store.dispatch("requestBankDetails", data).then((response) => {
        if (response.status == 200) {
          this.$refs["sendMail"].show();
          this.$emit("handleBankSubmit", response.data.bank_id);
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "requestBankDetails",
            });
          }
        }
      });
    },
    handleDefaultVal() {
      console.log('selectedContact', this.selectedContact);
      this.models = {
        firstName: this.selectedContact.first_name,
        lastName: this.selectedContact.last_name,
        email: this.selectedContact.email,
        contactNumber: this.selectedContact.contact,
      };
    }
  },
};
</script>
