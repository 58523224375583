module.exports = {
    label: {
      title: "Gestión De Personal",
      addMemberText: 'Agregar Miembro Del Equipo',
      selectMemberText:"Seleccione El Miembro Del Equipo",
      editMemberText:'Editar Miembro',
      deleteMember: '¿Está seguro de que desea eliminar a este miembro?',
      confirmTitle: '¿Estas Seguro?',
      confirmMsg: '¿Quieres borrar esto?',
      ValidationTitle: 'Advertencia',
      form: {      
        label: {
          firstName: 'Nombre',
          lastName: 'Apellido',
          email: 'Electrónico',
          contactNumber: 'Número De Teléfono',
          role: 'Rol',
        },
        validation: {
          firstName: 'nombre',
          lastName: 'apellido',
          email: 'electrónico',
          contactNumber: 'número de teléfono',
          role: 'rol',
        },
        placeholder: {
          enter: 'Entrar',
          email: 'example@mail.com',
          enterNumber: 'Ingrese El Número',
          select: 'Seleccionar',
          searchContact: 'Buscar Contacto',
          contactNumber: 'Número De Teléfono',
          searchStaffMember:"Buscar Miembro Del Equipo"
        }
      },
      tableHeader: {
        firstName: 'Nombre',
        lastName: 'Apellido',
        email: 'Correo Electrónico',
        contactNumber: 'Teléfono',
        role: 'Función',
        status:'Estado',
        dateAdded:'dateAdded',
        actions:'Acciones'
      },
      buttons: {
        addMember: 'Añadir Miembro Del Personal',
        addNew:"Añadir Nuevo",
        editText:'Guardar Miembro',
        inviteAdd: 'Invitar y Añadir',
        yes: 'Sí',
        no: 'No',
        ok:'Aceptar',
        selected:"Seleccionado",
        select:"Seleccionar"
      },
      tooltip:{
        edit : 'Editar Miembro',
        delete : 'Eliminar Miembro',
      }
    }
  };
  