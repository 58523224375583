module.exports = {
  label: {
    oneRecord: "One Record",
    showing: "Showing ",
    to: " to ",
    of: " of ",
    records: " records",
    rentedProperties: 'Rented Properties',
    nextDueDate: 'Next Due date',
    addedDate: 'Added Date',
    search: 'Search',
    noRecord: 'No matching records',
    buttons: {
      payRent: 'Pay Rent'
    }
  }
};