<!-- content from lease_agreement_configuration.js -->
<template>
  <div>
    <ValidationObserver ref="leaseObserver" v-slot="{ passes }">
      <!-- alert component -->
      <alert-section currentModule="LeaseConfiguration"></alert-section>
      <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
        <b-container fluid class="px-0 pb-fix-btn">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label class="form-label">{{ lang.label.form.label.assignTenant }}<span class="text-danger">*</span></label>
                <ValidationProvider
                  :name="lang.label.form.validation.assignTenant"
                  rules="required"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-input-group>
                      <b-form-input
                        v-model="models.assignTenant"
                        :placeholder="lang.label.form.placeholder.select"
                        :state="getValidationState(validationContext)"
                        :class="{ 'is-invalid': validationContext.errors[0] }"
                        class="contact-selected"
                        disabled
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button
                          variant="primary"
                          class="rounded-right"
                          v-b-toggle.tenantSelectSideBar
                          :style="
                            isRented ? 'pointer-events: none; cursor: default;' : ''
                          "
                          >{{ lang.label.buttons.selectFromContacts }}</b-button
                        >
                      </b-input-group-append>
                      <b-form-invalid-feedback
                        >{{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label class="form-label">{{ lang.label.form.label.rentAmount }}<span class="text-danger">*</span></label>
                <ValidationProvider
                  :name="lang.label.form.validation.rentAmount"
                  rules="required|double:2"
                  v-slot="validationContext"
                >
                  <div class="position-relative prepend-icon prepend-text">
                    <b-form-input
                      v-model="models.rentAmount"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.enter"
                      class="contact-selected"
                      :disabled="isRented"
                    ></b-form-input>
                    <div class="icon">
                      <div class="pre-app-text primary-text bf-size">$</div>
                    </div>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label class="form-label">{{
                  lang.label.form.label.paymentFrequency
                }}<span class="text-danger">*</span></label>
                <ValidationProvider
                  :name="lang.label.form.validation.paymentFrequency"
                  rules="required"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <el-select
                      :state="getValidationState(validationContext)"
                      v-model="models.paymentFrequency"
                      :placeholder="lang.label.form.placeholder.select"
                      :class="{ 'is-invalid': validationContext.errors[0] }"
                      class="contact-selected"
                      :disabled="isRented"
                    >
                      <el-option
                        v-for="item in paymentFrequencyList"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group>
                <label class="form-label">{{ lang.label.form.label.startDate }}<span class="text-danger">*</span></label>
                <ValidationProvider
                  :name="lang.label.form.validation.startDate"
                  rules="required"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <el-date-picker
                      v-model="models.startDate"
                      format="dd/MM/yyyy"
                      value-format="dd-MM-yyyy"
                      @change="dateCheck(models.startDate, true)"
                      :state="getValidationState(validationContext)"
                      type="date"
                      :placeholder="lang.label.form.placeholder.selectDate"
                      :class="{ 'is-invalid': validationContext.errors[0] }"
                      size="lg"
                      :disabled="isStartDateDisabled"
                    >
                    </el-date-picker>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label class="form-label">{{ lang.label.form.label.endDate }}<span class="text-danger">*</span></label>
                <ValidationProvider
                  :name="lang.label.form.validation.endDate"
                  rules="required"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <el-date-picker
                      v-model="models.endDate"
                      :state="getValidationState(validationContext)"
                      :picker-options="pickerOptions"
                      format="dd/MM/yyyy"
                      value-format="dd-MM-yyyy"
                      @focus="dateCheck(models.startDate, false)"
                      type="date"
                      :placeholder="lang.label.form.placeholder.selectDate"
                      :class="{ 'is-invalid': validationContext.errors[0] }"
                      size="lg"
                    >
                    </el-date-picker>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label class="form-label">{{
                    lang.label.form.label.depositAmount
                  }}<span class="text-danger">*</span></label>
                  <div class="d-flex align-items-center form-label bf-size">
                    <!-- <span class="material-icons secondary-text sf-size mr-2">info</span> -->
                    <span>{{ lang.label.info.firstMonthOnly }}</span>
                  </div>
                </div>
                <ValidationProvider
                  :name="lang.label.form.validation.depositAmount"
                  rules="required|double:2"
                  v-slot="validationContext"
                >
                  <div class="position-relative prepend-icon prepend-text">
                    <b-form-input
                      v-model="models.depositAmount"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.enter"
                      class="contact-selected"
                      :disabled="isRented"
                    ></b-form-input>
                    <div class="icon">
                      <div class="pre-app-text primary-text bf-size">$</div>
                    </div>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <template v-if="isBrokerAvailable">
              <b-col cols="12">
                <h5 class="sf400 mb-4">{{ lang.label.brokerPaymentConfiguration }}</h5>
              </b-col>

              <b-col cols="12">
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label class="form-label">{{
                      lang.label.form.label.closingCostCommission
                    }}</label>
                    <div class="d-flex align-items-center form-label bf-size">
                      <!-- <span class="material-icons secondary-text sf-size mr-2">info</span> -->
                      <span>{{ lang.label.info.firstMonthOnly }}</span>
                    </div>
                  </div>
                  <ValidationProvider
                    :name="lang.label.form.validation.closingCostCommission"
                    :rules="{
                      required: true,
                      double: 2,
                      max_value:
                        models.closingCostCurrency == 1 ? models.rentAmount : 100,
                    }"
                    v-slot="validationContext"
                  >
                    <div class="position-relative">
                      <b-input-group class="group-select-text">
                        <b-form-input
                          v-model="models.closingCostCommission"
                          :placeholder="lang.label.form.placeholder.enter"
                          :state="getValidationState(validationContext)"
                          class="contact-selected"
                          :disabled="isRented"
                        ></b-form-input>
                        <b-input-group-append>
                          <el-select
                            v-model="models.closingCostCurrency"
                            class="contact-selected"
                            :disabled="isRented"
                          >
                            <el-option
                              v-for="item in currencyList"
                              :key="item.value"
                              :label="item.text"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </b-input-group-append>
                      </b-input-group>
                      <span class="error" v-if="validationContext.errors[0]"
                        >{{validationContext.errors[0]}}</span
                      >
                      <div class="d-flex justify-content-start mt-2">
                        <div class="d-flex align-items-center">
                          <span class="material-icons secondary-text sf-size mr-2"
                            >info</span
                          >
                          <span class="sm-font">{{
                            lang.label.info.feeChargedBrokerMonth
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label class="form-label">{{
                      lang.label.form.label.brokerRecurringRentalFee
                    }}</label>
                    <div class="d-flex align-items-center form-label bf-size">
                      <!-- <span class="material-icons secondary-text sf-size mr-2">info</span> -->
                      <span>{{ lang.label.info.monthlyFees }}</span>
                    </div>
                  </div>
                  <ValidationProvider
                    :name="lang.label.form.validation.brokerRecurringRentalFee"
                    :rules="{
                      required: true,
                      double: 2,
                      max_value:
                        models.brokerRecurringCurrency == 1 ? models.rentAmount : 100,
                    }"
                    v-slot="validationContext"
                  >
                    <div class="position-relative">
                      <b-input-group class="group-select-text">
                        <b-form-input
                          v-model="models.brokerRecurringRentalFee"
                          :placeholder="lang.label.form.placeholder.enter"
                          :state="getValidationState(validationContext)"
                          class="contact-selected"
                          :disabled="isRented"
                        ></b-form-input>
                        <b-input-group-append>
                          <el-select
                            v-model="models.brokerRecurringCurrency"
                            class="contact-selected"
                            :disabled="isRented"
                          >
                            <el-option
                              v-for="item in currencyList"
                              :key="item.value"
                              :label="item.text"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </b-input-group-append>
                      </b-input-group>
                      <span class="error" v-if="validationContext.errors[0]"
                        >{{validationContext.errors[0]}}</span
                      >
                      <div class="d-flex justify-content-start mt-2">
                        <div class="d-flex align-items-center">
                          <span class="material-icons secondary-text sf-size mr-2"
                            >info</span
                          >
                          <span class="sm-font">{{
                            lang.label.info.feeChargedBrokerBasis
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </template>
            <b-col cols="12">
              <b-form-group>
                <label class="form-label">{{
                  lang.label.form.label.rentAmountDepositAccount
                }}<span class="text-danger">*</span></label>
                <ValidationProvider
                  :name="lang.label.form.validation.rentAmountDepositAccount"
                  rules="required"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <el-select
                      :state="getValidationState(validationContext)"
                      v-model="models.rentAmountDepositAccount"
                      :placeholder="lang.label.form.placeholder.select"
                      :class="{ 'is-invalid': validationContext.errors[0] }"
                      popper-class="contact-user-list"
                      :popper-append-to-body="false"
                      class="contact-selected"
                      :disabled="isRented"
                    >
                      <el-option
                        v-for="item in depositAccountOwnerList"
                        :key="'Owner' + item.id"
                        :label="
                          lang.label.propertyOwner +
                          ' - ' +
                          item.name +
                          (item.bankName ? ' - ' + item.bankName : '') +
                          (item.account ? ' - ' + item.account : '')
                        "
                        :value="item.id"
                        :disabled="
                          item.brokerDetails || item.alertSection || item.selfAccount
                        "
                      >
                        <b-btn
                          variant="link"
                          size="sm"
                          class="broker-btn"
                          @click="addBrokerDetails(2)"
                          v-if="item.brokerDetails"
                          ><span class="bf700 sf-size align-middle">+</span>
                          <span class="align-middle">{{
                            " " + lang.label.addOwnerDetails
                          }}</span></b-btn
                        >
                        <b-btn
                          variant="link"
                          size="sm"
                          class="broker-btn"
                          @click="updateBankSidebar(true, 2)"
                          v-if="item.selfAccount"
                          ><span class="bf700 sf-size align-middle">+</span>
                          <span class="align-middle">{{
                            " " + lang.label.addBankDetails
                          }}</span></b-btn
                        >
                        <b-btn
                          variant="link"
                          size="sm"
                          class="broker-btn"
                          v-if="item.alertSection"
                        >
                          <span class="align-middle">{{
                            lang.label.contactSelectionAlert
                          }}</span></b-btn
                        >
                        <b-media
                          no-body
                          class="select-user-media"
                          v-if="
                            !item.brokerDetails && !item.alertSection && !item.selfAccount
                          "
                        >
                          <b-media-aside vertical-align="center">
                            <img :src="getImgUser(item.pic)" :alt="item.name" v-if="item.pic" />
                            <span class="material-icons" v-else>account_circle</span>
                          </b-media-aside>
                          <b-media-body>
                            <p class="mb-0">
                              {{
                                item.name +
                                (item.bankName ? " - " + item.bankName : "") +
                                (item.account ? " - " + item.account : "")
                              }}
                            </p>
                          </b-media-body>
                        </b-media>
                      </el-option>
                    </el-select>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <template v-if="isBrokerAvailable">
              <b-col cols="12">
                <b-form-group>
                  <label class="form-label">{{
                    lang.label.form.label.commissionDepositAccount
                  }}</label>
                  <ValidationProvider
                    :name="lang.label.form.validation.commissionDepositAccount"
                    :rules="getCommissionDepositAccountValidation ? '' : 'required'"
                    v-slot="validationContext"
                  >
                    <div class="position-relative">
                      <el-select
                        :state="getValidationState(validationContext)"
                        v-model="models.commissionDepositAccount"
                        :placeholder="lang.label.form.placeholder.select"
                        :class="{ 'is-invalid': validationContext.errors[0] }"
                        popper-class="contact-user-list"
                        :popper-append-to-body="false"
                        class="contact-selected"
                        :disabled="isRented"
                      >
                        <el-option
                          v-for="item in depositAccountBrokerList"
                          :key="'Broker' + item.id"
                          :label="
                            lang.label.myAccountBroker +
                            ' - ' +
                            item.name +
                            (item.bankName ? ' - ' + item.bankName : '') +
                            (item.account ? ' - ' + item.account : '')
                          "
                          :value="item.id"
                          :disabled="
                            item.brokerDetails || item.alertSection || item.selfAccount
                          "
                        >
                          <b-btn
                            variant="link"
                            size="sm"
                            class="broker-btn"
                            @click="addBrokerDetails(1)"
                            v-if="item.brokerDetails"
                            ><span class="bf700 sf-size align-middle">+</span>
                            <span class="align-middle">{{
                              " " + lang.label.addBrokerDetails
                            }}</span></b-btn
                          >
                          <b-btn
                            variant="link"
                            size="sm"
                            class="broker-btn"
                            @click="updateBankSidebar(true, 1)"
                            v-if="item.selfAccount"
                            ><span class="bf700 sf-size align-middle">+</span>
                            <span class="align-middle">{{
                              " " + lang.label.addBankDetails
                            }}</span></b-btn
                          >
                          <b-btn
                            variant="link"
                            size="sm"
                            class="broker-btn"
                            v-if="item.alertSection"
                          >
                            <span class="align-middle">{{
                              lang.label.contactSelectionAlert
                            }}</span></b-btn
                          >
                          <b-media
                            no-body
                            class="select-user-media"
                            v-if="
                              !item.brokerDetails &&
                              !item.alertSection &&
                              !item.selfAccount
                            "
                          >
                            <b-media-aside vertical-align="center">
                              <img :src="getImgUser(item.pic)" :alt="item.name" v-if="item.pic" />
                              <span class="material-icons" v-else>account_circle</span>
                            </b-media-aside>
                            <b-media-body>
                              <p class="mb-0">
                                {{
                                  item.name +
                                  (item.bankName ? " - " + item.bankName : "") +
                                  (item.account ? " - " + item.account : "")
                                }}
                              </p>
                            </b-media-body>
                          </b-media>
                        </el-option>
                      </el-select>
                      <b-form-invalid-feedback
                        >{{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </template>
            <!-- details summary -->
            <b-col cols="12">
              <!-- rent details summary -->
              <div class="detail-summary">
                <h5 class="summary-heading">{{ lang.label.rentDetails.title }}</h5>
                <div class="d-sm-flex justify-content-between summary-div">
                  <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                    >{{ lang.label.rentDetails.tenant }}:</label
                  >
                  <span>{{ getTenant }}</span>
                </div>
                <div class="d-sm-flex justify-content-between summary-div">
                  <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                    >{{ lang.label.rentDetails.leasePeriod }}:</label
                  >
                  <span>{{ getLeasePeriod }}</span>
                </div>
                <div class="d-sm-flex justify-content-between summary-div">
                  <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                    >{{ lang.label.rentDetails.leaseAmount }}:</label
                  >
                  <span>{{ getLeaseAmount }}</span>
                </div>
                <div class="d-sm-flex justify-content-between summary-div">
                  <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                    >{{ lang.label.rentDetails.maintenanceFees }}:</label
                  >
                  <span>{{ getMaintenanceAmount }}</span>
                </div>
                <div class="d-sm-flex justify-content-between summary-div">
                  <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                    >{{ lang.label.rentDetails.depositAmount }}:</label
                  >
                  <span>{{ getDepositAmount }}</span>
                </div>
              </div>

              <!-- tenant rental details summary -->
              <div class="detail-summary">
                <h5 class="summary-heading">
                  {{ lang.label.rentDetails.rentalSummaryTitle }}
                </h5>
                <div class="d-sm-flex justify-content-between summary-div">
                  <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                    >{{ lang.label.rentDetails.amountDueFirstMonth }}:</label
                  >
                  <span>{{ getFirstMonthAmountDue }}</span>
                </div>
                <div class="d-sm-flex justify-content-between summary-div">
                  <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                    >{{ lang.label.rentDetails.recurringDueAmount }}:</label
                  >
                  <span>{{ getRecurringRentalAmountByTenant }}</span>
                </div>
              </div>

              <!-- broker commission fees details summary -->
              <div class="detail-summary" v-if="isBrokerAvailable">
                <h5 class="summary-heading">{{ lang.label.brokerCommission.title }}</h5>
                <div class="d-sm-flex justify-content-between summary-div">
                  <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                    >{{ lang.label.brokerCommission.closingCostComFirst }}:</label
                  >
                  <span>{{ getClosingCostCommissionToBroker }}</span>
                </div>
                <div class="d-sm-flex justify-content-between summary-div">
                  <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                    >{{ lang.label.brokerCommission.recurringRentalFee }}:</label
                  >
                  <span>{{ getRecurringRentalFeeToBroker }}</span>
                </div>
              </div>

              <!-- rental amount to owner details summary -->
              <div class="detail-summary">
                <h5 class="summary-heading">{{ lang.label.rentalAmountOwner.title }}</h5>
                <div class="d-sm-flex justify-content-between summary-div">
                  <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                    >{{ lang.label.rentalAmountOwner.firstMonthOwner }}:</label
                  >
                  <span>{{ getFirstMonthToOwner }}</span>
                </div>
                <div class="d-sm-flex justify-content-between summary-div">
                  <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                    >{{
                      lang.label.rentalAmountOwner.recurringRentalAmountDueOwner
                    }}:</label
                  >
                  <span>{{ getRecurringRentalAmountDueToOwner }}</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <div class="add-contact-button">
          <b-btn variant="primary" block type="submit" @click="scrollToError">{{
            lang.label.buttons.save
          }}</b-btn>
        </div>
      </b-form>
    </ValidationObserver>

    <!-- bank details request -->
    <b-sidebar
      id="bankDetailsRequest"
      footer-class="sidebar-footer"
      sidebar-class="sidebar-view"
      text-variant="none"
      backdrop
      right
      width="462px"
      @change="updateBankDetailsSidebar"
      :visible="showSidebar"
      z-index="3002"
    >
      <template #header="{ hide }">
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex align-items-center">
            <b-btn variant="link" size="sm" class="p-0" @click="hide">
              <img :src="getIconUrl('close-sm.svg')" alt="close" />
            </b-btn>
            <h4 class="sidebar-header-title mb-0">
              {{ lang.label.bankDetailsRequest }}
            </h4>
          </div>
        </div>
      </template>
      <div class="amenity-body">
        <bank-details-request
          v-if="showSidebar"
          :selectedContact="requestBankUserType == 1 ? selectedBroker : selectedOwner"
          :isViewOnly="true"
          @handleBankSubmit="handleBankSubmit"
        ></bank-details-request>
      </div>
    </b-sidebar>
    <b-sidebar
      id="tenantSelectSideBar"
      footer-class="sidebar-footer"
      sidebar-class="sidebar-view"
      text-variant="none"
      backdrop
      right
      width="462px"
      @change="updateContactSidebar"
      :visible="isContactVisible"
    >
      <template #header="{ hide }">
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex align-items-center">
            <b-btn variant="link" size="sm" class="p-0" @click="hide">
              <img :src="getIconUrl('close-sm.svg')" alt="close" />
            </b-btn>
            <h4 class="sidebar-header-title mb-0">
              <template v-if="!contactListVisible">
                {{ lang.label.addNewContact }}
              </template>
              <template v-if="contactListVisible">
                {{ lang.label.selectContactLabel }}
              </template>
            </h4>
          </div>
          <b-btn variant="primary" @click="contactList()">
            <template v-if="!contactListVisible">
              {{ lang.label.buttons.selectContact }}
            </template>
            <template v-if="contactListVisible">
              {{ lang.label.buttons.addNewContactBtn }}
            </template>
          </b-btn>
        </div>
      </template>
      <div class="amenity-body">
        <!-- contact add component -->
        <template v-if="!contactListVisible">
          <add-contact
            v-if="isContactVisible"
            type="tenant"
            @handleContatctValidate="handleContatctValidate"
          ></add-contact>
        </template>

        <!-- contact list component -->
        <template v-if="contactListVisible">
          <contact-list
            type="tenant"
            :selectedContact="selectedContact"
            :selectedBroker="selectedBroker"
            :selectedOwner="selectedOwner"
            @handleContactSelect="handleContactSelect"
            v-if="isContactVisible"
          ></contact-list>
        </template>
      </div>
    </b-sidebar>
    <!-- add bank details -->
    <b-sidebar
      id="addBankDetailsBroker"
      footer-class="sidebar-footer"
      sidebar-class="sidebar-view"
      text-variant="none"
      backdrop
      right
      width="462px"
      @change="updateBankSidebar"
      :visible="isBankVisible"
      z-index="3002"
    >
      <template #header="{ hide }">
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex align-items-center">
            <b-btn variant="link" size="sm" class="p-0" @click="hide">
              <img :src="getIconUrl('close-sm.svg')" alt="close" />
            </b-btn>
            <h4 class="sidebar-header-title mb-0">
              {{ lang.label.addBankDetails }}
            </h4>
          </div>
        </div>
      </template>
      <div class="amenity-body">
        <add-bank-details v-if="isBankVisible" @handleUpdate="handleUpdate">
        </add-bank-details>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import BankDetailsRequest from "@/components/bank/DetailsRequest.vue";
import ContactList from "@/components/contacts/ContactList.vue";
import AddContactForm from "@/components/contacts/AddForm.vue";
import moment from "moment";
import mixin from "@/mixins/mixin";
import property_mixin from "@/mixins/property_mixin";
import AddBankDetails from "@/components/bank/AddDetails.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import alertSection from "@/views/shared/AlertSection.vue";
export default {
  props: [
    "isRented",
    "rentAmount",
    "securityDepositAmount",
    "maintenanceFee",
    "paymentPeriod",
    "selectedBroker",
    "selectedOwner",
    "selectedUserType",
    "leaseAgreementDetails",
    "isAddProperty",
    "isSelfBroker",
    "propertyStatus",
  ],
  components: {
    "bank-details-request": BankDetailsRequest,
    "contact-list": ContactList,
    "add-contact": AddContactForm,
    "add-bank-details": AddBankDetails,
    "alert-section": alertSection,
  },
  mixins: [mixin, property_mixin],
  computed: {
    ...mapGetters({
      ImageUrl: "ImageUrl",
      language: "language",
    }),
    /**
     * @returns Whether broker is selected or not
     */
    isBrokerAvailable() {
      return (this.selectedBroker && this.selectedBroker.id) || this.isSelfBroker ? true : false;
    },
    isStartDateDisabled() {
      return this.$route.name == "edit-properties" && this.models.startDate !== "" && this.propertyStatus !== "1";
    },
  },
  data() {
    return {
      lang: this.$lang.lease_agreement_configuration,
      data: jsondata,
      showSidebar: false,
      models: {
        assignTenant: "",
        rentAmount: "",
        paymentFrequency: "",
        startDate: "",
        endDate: "",
        depositAmount: "",
        closingCostCommission: "",
        brokerRecurringRentalFee: "",
        rentAmountDepositAccount: "",
        commissionDepositAccount: "",
        closingCostCurrency: "1",
        brokerRecurringCurrency: "1",
      },
      paymentFrequencyList: [
        { text: this.$lang.property_details.label.paymentFrequency.monthly, value: "1" },
        { text: this.$lang.property_details.label.paymentFrequency.quarterly, value: "2" },
        { text: this.$lang.property_details.label.paymentFrequency.haldYearly, value: "3" },
        { text: this.$lang.property_details.label.paymentFrequency.yearly, value: "4" },
      ],
      // paymentFrequencyVal: [1, 3, 6, 12],
      currencyList: [
        { text: "$", value: "1" },
        { text: "%", value: "2" },
      ],
      isContactVisible: false,
      selectedContact: {},
      fromDate: null,
      selectableEndDate: null,
      pickerOptions: {
        disabledDate: this.disabledDate,
        onPick: this.pick,
      },
      depositAccountOwnerList: [],
      depositAccountBrokerList: [],
      accountStaticElem: {
        id: 0,
        pic: "",
        name: "Request Broker Details",
        brokerDetails: true,
      },
      selfAccountStaticElem: {
        id: 0,
        pic: "",
        name: "Add Bank Details",
        selfAccount: true,
      },
      accountSelectAlertElem: {
        id: 0,
        pic: "",
        name: "Please select contact first",
        alertSection: true,
      },
      isBankVisible: false,
      contactListVisible: false,
      requestBankUserType: 1,
      isAutoDateChange: true,
    };
  },
  watch: {
    /**
     * Handles rent change
     */
    rentAmount() {
      this.models.rentAmount = this.rentAmount;
    },
    /**
     * Handles payment period change
     */
    paymentPeriod() {
      this.models.paymentFrequency = this.paymentPeriod;
    },
    /**
     * Handles security deposit amount change
     */
    securityDepositAmount() {
      this.models.depositAmount = this.securityDepositAmount;
    },
    /**
     * Handles listing owner account on selected owner change
     */
    selectedOwner() {
      this.listBankDetails(
        "depositAccountOwnerList",
        2,
        this.selectedOwner.id,
        null,
        true
      );
    },
    /**
     * Handles listing broker account on selected broker change
     */
    selectedBroker() {
      this.listBankDetails(
        "depositAccountBrokerList",
        1,
        this.selectedBroker.id,
        null,
        true
      );
    },
    /**
     * Handles listing accounts on selected user change
     */
    selectedUserType(val) {
      this.listBankDetails(
        "depositAccountBrokerList",
        1,
        this.selectedBroker.id,
        null,
        true
      );
      this.listBankDetails(
        "depositAccountOwnerList",
        2,
        this.selectedOwner.id,
        null,
        true
      );
    },
    /**
     * Handles prefilling lease data
     */
    leaseAgreementDetails: {
      handler(val) {
        if (Object.keys(val).length > 0) {
          this.models = {
            assignTenant: val.assignTenant ? val.assignTenant : this.models.assignTenant,
            rentAmount: val.extra.rentAmount,
            paymentFrequency: val.payment_frequency,
            startDate: val.start_date,
            endDate: val.end_date,
            depositAmount: val.extra.depositAmount,
            closingCostCommission: val.realtor_closing_commission,
            brokerRecurringRentalFee: val.realtor_rental_fee,
            rentAmountDepositAccount: val.rent_deposit_amount_account,
            commissionDepositAccount: val.commission_deposit_amount_account,
            closingCostCurrency: JSON.stringify(val.realtor_closing_commission_type),
            brokerRecurringCurrency: JSON.stringify(val.realtor_rental_fee_type),
          };
          this.selectedContact = val.selectedContact ? val.selectedContact : {};
          setTimeout(() => {
            this.onSubmit(true);
            this.isAutoDateChange = false;
          }, 0);
        }
      },
      deep: true,
    },
    /**
     *
     */
    "models.paymentFrequency"() {
      this.handleFrequancyToDateValidation();
    },
    /**
     * Handles End date change in edit mode to get user confirmation
     * @param {Date} newVal New val
     * @param {Date} oldVal OldVal
     */
    "models.endDate"(newVal, oldVal) {
      if (this.isStartDateDisabled && !this.isAutoDateChange) {
        Swal.fire({
          title: this.lang.label.confirmEndDateChangeTitle,
          text: this.lang.label.confirmEndDateChangeMsg,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: this.lang.label.buttons.yes,
          cancelButtonText: this.lang.label.buttons.no,
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        }).then((result) => {
          if (!result.isConfirmed) {
            this.models.endDate = oldVal;
            this.isAutoDateChange = true;
            setTimeout(() => {
              this.isAutoDateChange = false;
            }, 500);
          }
        });
      }
    },
  },
  created() {
    moment.updateLocale(this.language, {});
  },
  mounted() {
    this.handlePrefillInitial();
    if (this.isAddProperty) {
      this.listBankDetails("depositAccountBrokerList", 1);
      this.listBankDetails("depositAccountOwnerList", 2);
    }
  },
  methods: {
    getImgUser(img) {
      if (img.length > 0) {
        return this.ImageUrl + img;
      }
      return require("@/assets/styles/img/users/" + img);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getValidationState({ dirty, validated, valid = null }) {
      if (!valid) {
        this.$emit("handleInvalidLeaseInfo");
      }
      return dirty || validated ? valid : null;
    },
    contactList() {
      this.contactListVisible = !this.contactListVisible;
    },
    onSubmit(isNotManual) {
      let self = this;
      let data = {
        tenant: self.selectedContact.id,
        payment_frequency: self.models.paymentFrequency,
        start_date: self.formatDateReverse(self.models.startDate),
        end_date: self.formatDateReverse(self.models.endDate),
        realtor_closing_commission: self.models.closingCostCommission
          ? self.models.closingCostCommission
          : null,
        realtor_closing_commission_type: self.models.closingCostCurrency,
        realtor_rental_fee: self.models.brokerRecurringRentalFee
          ? self.models.brokerRecurringRentalFee
          : null,
        realtor_rental_fee_type: self.models.brokerRecurringCurrency,
        rent_deposit_amount_account: self.models.rentAmountDepositAccount,
        commission_deposit_amount_account: self.models.commissionDepositAccount,
        extra: {
          rentAmount: self.models.rentAmount,
          depositAmount: self.models.depositAmount,
          tenant: self.selectedContact,
        },
      };
      if (isNotManual) {
        data['isNotManual'] = isNotManual;
      }
      self.$emit("handleLeaseDetailSubmit", data);
    },
    onSubmitBroker() {},
    /**
     * Handles click event on request broker or owner bank detail
     * @param {number} type Bank account type
     */
    addBrokerDetails(type) {
      this.requestBankUserType = type;
      this.updateBankDetailsSidebar(true);
    },
    /**
     * Handles request account details submit
     * @param {number} requestedAccId Requested account id
     */
    handleBankSubmit(requestedAccId) {
      // this.$emit("addBroker", false);
      this.showBankRequestSuccessMessage();
      this.updateBankDetailsSidebar(false);
      this.listBankDetails(
        "depositAccountBrokerList",
        1,
        this.selectedBroker.id,
        requestedAccId
      );
      this.listBankDetails(
        "depositAccountOwnerList",
        2,
        this.selectedOwner.id,
        requestedAccId
      );
    },
    showBankRequestSuccessMessage() {
      let self = this;
      Swal.fire({
        icon: 'success',
        title: self.lang.label.success,
        text: self.lang.label.bankRequestSuccessMessage,
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: self.lang.label.buttons.ok,
        focusConfirm: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      })
    },
    /**
     * Handles visibility of bank details sidebar form
     * @param {boolean} val Sidebar visibility value
     */
    updateBankDetailsSidebar(val) {
      this.showSidebar = val;
    },
    /**
     * Handles visibility of broker contact sidebar form
     * @param {boolean} val Sidebar visibility value
     */
    updateContactSidebar(val) {
      this.isContactVisible = val;
      const body = document.getElementsByTagName("body")[0];
      if (val) {
        body.classList.add("overflow-hidden");
      } else {
        body.classList.remove("overflow-hidden");
      }
    },
    /**
     * Handles selected contact from sidebar
     * @param {object} contact Selected contact
     */
    handleContactSelect(contact) {
      this.selectedContact = contact;
      this.models.assignTenant = this.selectedContact.name;
      this.updateContactSidebar(false);
    },
    /**
     * Sets default date that determines if other dates should be disabled
     * @param {Date} date used as provided date
     * @param {boolean} startChanged used to update selected end date when start date changes
     */
    dateCheck(date, startChanged) {
      this.fromDate = new Date(
        moment(date, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")
      );

      if (startChanged) {
        this.handleFrequancyToDateValidation();
        this.handleSelectedEndDate(this.fromDate);
      }
    },
    /**
     * Handles disselection of seleced end date based on selected start date
     * @param {Date} selectableDate User selectable end date based on selected start date
     */
    handleSelectedEndDate(selectableDate) {
      let cDate = new Date(
        moment(this.models.endDate, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")
      );
      this.models.endDate = cDate >= selectableDate ? this.models.endDate : "";
    },
    /**
     * Handles prefilling values related to property lease amount
     */
    handlePrefillInitial() {
      this.models.rentAmount = this.rentAmount;
      this.models.paymentFrequency = this.paymentPeriod;
      this.models.depositAmount = this.securityDepositAmount;
    },
    /**
     * Provides whether date is disabled based on start date and payment frequancy provided
     * @param {Date} date used as provided date
     * @returns boolean
     */
    disabledDate(date) {            
      if (this.fromDate && this.selectableEndDate) {
        return this.fromDate > date || this.selectableEndDate > date;
      } else if (this.fromDate) {
        return this.fromDate > date;
      }
      return false;
    },
    handleFrequancyToDateValidation() {
      setTimeout(() => {
        if (this.models.paymentFrequency && this.models.startDate) {
          let start = moment(this.models.startDate, "DD-MM-YYYY HH:mm:ss");
          let end = null;
          switch (this.models.paymentFrequency) {
            case '1':
              end = start.add(1, "months").subtract(1, "days");
              break;
            case '2':
              end = start.add(3, "months").subtract(1, "days");
              break;
            case '3':
              end = start.add(6, "months").subtract(1, "days");
              break;
            case '4':
              end = start.add(12, "months").subtract(1, "days");
              break;
          }
  
          let cDate = new Date(
            moment(this.models.endDate, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")
          );
          let endDate = new Date(end.format("YYYY-MM-DD HH:mm:ss"));
          this.selectableEndDate = this.isStartDateDisabled && endDate < cDate ? cDate : endDate;  // if edit then can not select less than end date
          this.handleSelectedEndDate(this.selectableEndDate);
        }
      }, 0);
    },
    /**
     * Handles adding static element to dropdown array
     * @param {String} param Used to update provided bank object
     * @param {object} value Provided value object
     */
    handleAddElem(param, value) {
      let self = this;
      self[param] = [];
      self[param].push(value);
    },
    /**
     * Handles fetching list of bank details for dropdown
     * @param {String} param Used to update provided bank object
     * @param {number} type Bank account type
     * @param {number} contact Selected contact id
     * @param {number} requestedAccId Requested account id
     * @param {Boolean} hasUserChanged to identify user type change event
     */
    async listBankDetails(param, type, contact, requestedAccId, hasUserChanged) {
      let self = this;
      await new Promise((resolve, reject) => {
        let data = {
          contact: contact ? contact : null,
        };
        self.handleAddElem(param, self.accountStaticElem);
        if (hasUserChanged) {
          self.models.commissionDepositAccount =
            type == 1 ? null : self.models.commissionDepositAccount;
          self.models.rentAmountDepositAccount =
            type == 2 ? null : self.models.rentAmountDepositAccount;
        }
        if (
          (self.selectedUserType == 1 && type == 2 && !contact) ||
          (self.selectedUserType == 2 && type == 1 && !contact)
        ) {
          self.handleAddElem(param, self.accountSelectAlertElem);
          return;
        }

        self.$store.dispatch("listBankDetails", data).then((response) => {
          if (response.status == 200) {
            if (!contact) {
              self.handleAddElem(param, self.selfAccountStaticElem);
            }
            response.data.forEach((acc, index) => {
              self[param].push({
                id: acc.id,
                name: acc.first_name + " " + acc.last_name,
                contactTypeId: acc.contact_type_id,
                contactTypeName: acc.contact_type_name,
                contact: acc.contact,
                account: acc.account_number ? "XXX" + acc.account_number.slice(-4) : "",
                bankName: acc.bank_name,
                pic: acc.profile_image ? acc.profile_image : "",
              });
              if (index == response.data.length - 1) {
                setTimeout(() => {
                  self.handlePreselectAcc(requestedAccId);
                }, 0);
              }
            });
            resolve();
          } else {
            let message = response.data.message || response.data.detail;
            if (message) {
              self.$store.commit("setMessages", {
                type: "danger",
                message: message,
              });
            }
          }
        });
      });
    },
    /**
     * Handles preselection of account based on requested account type
     * @param {number} requestedAccId Requested account id
     */
    handlePreselectAcc(requestedAccId) {
      let self = this;
      if (requestedAccId) {
        if (self.requestBankUserType == 1) {
          self.models.commissionDepositAccount = requestedAccId;
        } else if (self.requestBankUserType == 2) {
          self.models.rentAmountDepositAccount = requestedAccId;
        }
      }
    },
    /**
     * Handles updating visibility of sidebar
     * @param {Boolean} val Visibility value of sidebar
     * @param {number} type Bank account type
     */
    updateBankSidebar(val, type) {
      this.requestBankUserType = type || this.requestBankUserType;
      this.isBankVisible = val;
    },
    /**
     * Handles add bank submit event and updates bank dropdown lists
     * @param {number} requestedAccId Requested account id
     */
    handleUpdate(requestedAccId) {
      this.updateBankSidebar(false);
      this.listBankDetails(
        "depositAccountBrokerList",
        1,
        this.selectedBroker.id,
        requestedAccId
      );
      this.listBankDetails(
        "depositAccountOwnerList",
        2,
        this.selectedOwner.id,
        requestedAccId
      );
    },

    /**
     * Handles add contact validation flow
     */
    handleContatctValidate(data) {
      let self = this;
      if ((self.selectedBroker && self.selectedBroker.email && self.selectedBroker.email == data.email) || (self.selectedOwner && self.selectedOwner.email && self.selectedOwner.email == data.email)) {
        Swal.fire({
          title: self.$lang.add_properties.label.warning,
          text: self.$lang.add_properties.label.sameUserAlert,
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: self.$lang.add_properties.label.buttons.ok,
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        });
      } else {
        this.updateContactSidebar(false);
        if (data.isStaff) {
          Swal.fire({
            title: self.lang.label.warning,
            text: self.lang.label.staffWarningMsg,
            showCloseButton: true,
            showCancelButton: false,
            confirmButtonText: self.lang.label.buttons.ok,
            focusConfirm: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger",
            },
          })
        } else if (data.showPopUp) {
          Swal.fire({
            title: self.lang.label.confirmTitle,
            text: self.lang.label.confirmContactMsg,
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: self.lang.label.buttons.yes,
            cancelButtonText: self.lang.label.buttons.no,
            focusConfirm: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              self.addContact(data);
            }
          });
        } else {
          self.addContact(data);
        }
      }
    },
    /**
     * Handles contact add operation
     * @param {object} data Contact data to be added
     */
    addContact(data) {
      let self = this;
      var notesApiCall=false;
      var noteContent="";
      if(data.noteApiCall){
        notesApiCall=true;
        noteContent=data.noteContent
        delete data.noteApiCall
        delete data.noteContent
      }
      delete data.showPopUp;
      self.$store.dispatch("addContact", data).then((response) => {
        if (response.status == 200) {
          if (response.data.message) {
            self.$store.commit("setMessages", {
              type: "success",
              message: response.data.message,
              allow_module: "LeaseConfiguration",
            });
            let contact = {
              id: response.data.contact.id,
              name:
                response.data.contact.first_name + " " + response.data.contact.last_name,
              email: response.data.contact.email,
              pic: "",
            };
            self.handleContactSelect(contact);
            //notes api call if notes have been added
            if(notesApiCall){
              let data={
                contact:response.data.contact.id,
                note:noteContent
              }
              self.notesApiCall(data);
            }
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "LeaseConfiguration",
            });
          }
        }
      });
    },
    /**
     * Handles notes add operation
     * @param {object} data Notes to be added while adding contact
     */
    notesApiCall(data){
      let self=this;
      self.$store.dispatch('addContactNotes',data).then((response) =>{
        if(response.status == 200){
          return true;
        }else{
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      })
    },
  },
};
</script>
