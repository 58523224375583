<!-- content from bank_information.js -->
<template>
  <b-container fluid class="px-0">
    <b-row>
      <b-col lg="12">
        <el-tabs v-model="activeTab" class="my-account-tab">
          <el-tab-pane :label="lang.label.tabs.bankDetails" name="bankDetails" v-if="!isUserOnlyTenant">
            <bank :isAddBank="isAddBank" @initializedAdd="handleInitializedAdd"></bank>
          </el-tab-pane>
          <!-- for tenant,realtor and staff -->
          <template v-if="(login.user_type_id==1 || login.user_type_id==2) || (login.user_type_id==3 && staffpermissions.length>0 && staffpermissions.includes('view_card'))">
            <el-tab-pane :label="lang.label.tabs.savedCard" name="savedCard">
              <saved-card :isAddCard="isAddCard" @initializedAdd="handleInitializedCardAdd"></saved-card>
            </el-tab-pane>
          </template>
        </el-tabs>
      </b-col>
    </b-row>
    <div class="bank-add">
      <b-btn
        variant="primary"
        size="sm"
        class="btn-mw mr-0"
        @click="addBankDetailsBroker"
        v-show="activeTab == 'bankDetails'"
        :hidden="login.user_type_id == 3"
        >+ {{lang.label.buttons.addButton}}</b-btn
      >
      <b-btn
        variant="primary"
        size="sm"
        class="btn-mw mr-0"
        @click="addCard"
        v-show="activeTab == 'savedCard'"
        :hidden="login.user_type_id == 3"
        >+ {{lang.label.buttons.addButton}}</b-btn
      >
    </div>
  </b-container>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import Bank from "./bankinfo/BankInfo.vue";
import SavedCard from "./bankinfo/SavedCard.vue";
import { mapGetters } from "vuex";
import Swal from 'sweetalert2';

export default {
  components: {
    bank: Bank,
    "saved-card": SavedCard,
  },
  data() {
    return {
      lang: this.$lang.bank_information,
      data: jsondata,
      activeTab: "bankDetails",
      isAddBank: false,
      isAddCard: false,
    };
  },
  computed: {
    ...mapGetters({
      login: "login",
      staffpermissions:"staffpermissions",
      isUserVerified: "isUserVerified",
      isUserOnlyTenant: "isUserOnlyTenant",
    }),
  },
  mounted() {
    if (this.isUserOnlyTenant) { 
      this.activeTab = "savedCard";
    }
    this.$emit("activeTab", this.activeTab);
  },

  watch: {
    activeTab() {
      this.$emit("activeTab", this.activeTab);
    },
  },
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    /**
     * Handles add bank details sidebar initialized event
     */
    handleInitializedAdd() {
      this.isAddBank = false;
    },
    /**
     * Handles add bank details event
     */
    addBankDetailsBroker() {
      if ((this.login.user_type_id == 1 && this.login.subscription_end_date == "") || (this.login.user_type_id == 3 && this.login.is_subscription_expired)) {
        this.$store.commit("setSubscriptionAlert", true);
      } else if (this.isUserVerified) {
        this.$store.commit("setVerificationAlert", true);
      } else {
        this.isAddBank = true;
      }
    },
    /**
     * Handles add card details event
     */
    addCard() {
      let self = this;
      if (self.isUserVerified) {
        self.$store.commit("setVerificationAlert", true);
      } else {
        self.isAddCard = true;
      }
    },
    handleInitializedCardAdd() {
      this.isAddCard = false;
    }
  },
};
</script>

