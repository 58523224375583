<!-- content from signup.js -->
<template>
  <div class="type-selection">
    <div class="form-header with-back-arrow">
      <div class="back-arrow">
        <b-btn variant="link p-0" @click="back()"
          ><img :src="getIconUrl('arrow_back.svg')" alt="back"
        /></b-btn>
      </div>
      <h1 class="form-heading mb-2">{{ lang.label.register }}</h1>
      <!-- <p class="sf400">{{ lang.label.selectUserType }}</p> -->
    </div>
    <div class="register-as section-space">
      <b-form-group>
        <div class="row d-flex btn-group">
          <div
            class="col-6 btn btn-secondary"
            @click="userTypeClick(user.id)"
            v-for="(user, index) in data.userType"
            :key="index"
          >
            <div class="user-type-box h-100">
              <div class="box-img">
                <img :src="getImgUrl(user.icon)" :alt="user.text" />
              </div>
              <div class="type-text">
                <p class="mb-0 sf400">{{ lang.label.onBoard }}</p>
                <h6 class="mb-0 sf400">{{ language == 'en' ? user.text_english : user.text }}</h6>
              </div>
            </div>
          </div>
        </div>

        <!-- <b-form-radio-group class="row d-flex" v-model="selected" name="userType" buttons>
                        <b-form-radio class="col-6" :value="type.id" v-for="(type, index) in data.userType" :key="index">
                            <div class="user-type-box">
                                <div class="box-img">
                                    <img :src="getImgUrl(type.icon)" :alt="type.text" />
                                </div>
                                <div class="type-text">
                                    <p class="mb-0 sf400">{{ lang.label.onBoard }}</p>
                                    <h6 class="mb-0 sf400">{{ type.text }}</h6>
                                </div>
                            </div>
                        </b-form-radio>
                    </b-form-radio-group> -->
      </b-form-group>
    </div>
  </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      language: "language",
    }),
  },
  data() {
    return {
      lang: this.$lang.signup,
      data: jsondata,
      selected: "",
    };
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/styles/img/" + img);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    userTypeClick(id) {
      this.$emit("signUpView", id);
    },
    back() {
      this.$router.go(-1);
    },
    // goBack() {
    //     this.$emit('registerAs', true)
    // }
  },
};
</script>
