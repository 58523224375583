<!-- content from error.js -->
<template>
  <div class="error-page">
    <b-container>
      <b-row class="justify-content-center">
        <b-col lg="7">
          <b-card no-body class="custom-card">
            <b-card-body class="py-5">
              <div class="w-100">
                <div class="error-image mb-3">
                  <img :src="getImgUrl('earthquake.png')" alt="" />
                </div>
                <h3 class="found-title mb-0">{{ lang.label.title }}</h3>
                <h2 class="sub-title mb-2"> {{ lang.label.oops }} {{ lang.label.notFound }}</h2>
                <p class="title sf-size">{{ lang.label.para }}</p>
                <div class="mt-4">
                  <router-link :to="{ path: '/' }" class="btn btn-primary w-50">{{ lang.label.home }}</router-link>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import alertSection from "@/views/shared/AlertSection.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    "alert-section": alertSection,
  },
  computed: {
    ...mapGetters({
      loading: "loading",
    }),
  },
  data() {
    return {
      lang: this.$lang.error,
      data: jsondata,

    };
  },
  mounted() { },
  methods: {
    getImgUrl(icon) {
      return require("@/assets/styles/img/" + icon);
    },
  },
};
</script>
