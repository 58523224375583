module.exports = {
  label: {
    rolesPermissions: "Roles & Permissions",
    confirmTitle: 'Are you sure?',
    confirmMsg: 'You want to delete this!',
    addRolesPermission: 'Add Roles & Permissions',
    editRolesPermission: 'Edit Roles & Permissions',
    tableHeader: {
      roleName: "Role Name",
      permissions: "Permissions",
      actions: "Actions",
    },
    buttons: {
      add: 'Add',
      update: 'Update',
      delete: 'Delete',
      edit: 'Edit',
      yes: 'Yes',
      no: 'No',
      assign: 'Assign',
      save: 'Save',
      cancel: 'Cancel',
      addRole: 'Add Role',
      select: 'Select',
      saveRole: 'Save Role'
    },
    form: {      
      label: {
        name: 'Name',
        permissions: 'Permissions',
      },
      validation: {
        name: 'name',
        permissions: 'permissions',
      },
      placeholder: {
        enter: 'Enter role name',
        enterName: 'Enter Name',
        select: 'Select permissions',
      }
    },
    tooltip:{
      edit : 'Edit Role',
      delete : 'Delete Role',
    }
  }
}