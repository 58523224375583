module.exports = {
  label: {
    search: "Search", 
    signOut: 'Sign Out',
    confirmTitle: 'Are you sure?',
    confirmMsg: 'You want to logout!',
    login:'Login',
    viewMore: 'View More',
    buttons: {
      yes: 'Yes',
      no: 'No',
    },
    tooltip:{
      Messages : 'Messages',
      Notifications : 'Notifications',
      Support : 'Support',
    }
  }
};