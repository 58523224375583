<!-- content from login.js -->
<template>
    <div class="auth-right-section">
        <div class="auth-logo d-lg-none">
            <img :src="getImgUrl('logo_white.svg')" alt="miradoor" />
        </div>
        <div class="content-wrapper">
            <h1 class="auth-right-heading">{{ lang.label.slogan }}</h1>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            lang: this.$lang.login,
        };
    },
    methods: {
        getImgUrl(img) {
            return require("@/assets/styles/img/" + img);
        }
    },
}
</script>
