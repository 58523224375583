module.exports = {
    label: {
      verifyEmialAlert: "Verifique Su Correo Electrónico Para Obtener Acceso Completo A Miradoor, Es Posible Que No Pueda Añadir Contactos O Propiedades.",
      emailAlert: "Ya Que No Proporcionó Su Correo Electrónico, No Se Puede Realizar Esta Acción. Proporcione Su Correo Electrónico Desde Mi Perfil",
      yourTrialPeriod: 'Su Periodo De Prueba Expirará En',
      suscriptionCancelled: 'Su Suscripción Ha Sido Cancelada. Puede Utilizar El Sistema Para ',
      days: 'Días',
      warning: 'Advertencia',
      expiredTrial: 'Su Periodo De Prueba Ha Expirado',
      uploadDocument:'Verifique Su Documento De Identificación.',
      verifyEmailAndIdentityAlert:'Verifique Su Correo Electrónico y Documento De Identificación Para Obtener Acceso Completo A Miradoor, Es Posible Que No Pueda Agregar Contactos O Propiedades.',
      documentSizeValidation:'No puede cargar un documento de tamaño mayor a 10MB',
      rejectionReason:'¿Razón De Rechazo?',
      rejectionText:'Se Ha Rechazado Su Documento.',
      form:{
        placeholder:{
          email:'example@mail.com'
        },
        validation:{
          email : 'Correo Electrónico'
        },
        label : {
          email : 'Correo Electrónico'
        }
      },
      extensionValidation:'Sólo se permiten las extensiones .jpeg, .jpg, .png .pdf',
      buttons: {
        sendCode: 'Enviar Código De Verificación',
        enrollNow: 'Suscríbase Ahora',
        uploadDocument:'Subir Documento De Identificación',
        submit:'Guardar',
        ok : 'Aceptar',
      }
    }
  };