module.exports = {
  label: {
    dateAdded: "Added Date",
    addProperties: 'Add Property',
    readMore: 'Read More',
    amenities: 'Amenities',
    viewAll: 'View All',
    propertyReport: 'Property Report',
    maintenanceFee: 'Maintenance Fee',
    leaseAmount: 'Lease Amount',
    advanceAmount: 'Advance Amount',
    monthly: 'Monthly',      
    rentDetailsTitle: 'Rent Details',
    leasedTo: 'Leased To',
    leasedPeriod: 'Leased Period',
    startDate: 'Start Date',
    endDate: 'End Date',
    leaseAmountText: 'Lease Amount',
    depositAmount: 'Deposit Amount',
    rentDue: 'Rent Due Date',
    maintenanceFeeText: 'Maintenance Fee',
    amountDueFirstMonth: 'Amount Due on First Installment',
    leaseAgreementTitle: 'Lease Agreement',
    sign: 'Signature',
    leaseAgreement: 'Lease Agreement (PDF, JPEG, JPG, PNG)',
    photosVideos: 'Photos & Videos',
    dateFilter: 'Date Filter',
    details: 'Details',
    connectTo: 'Connect to',
    from: 'From',
    to: 'To',
    paymentHistory: 'Payment History',
    form: {
      validation: {
        message: 'message'
      },
      placeholder: {
        message: 'Enter Message'
      }
    },
    buttons: {
      payRent: 'Pay Rent',
      paymentHistory: 'Payment History',
      signAgreement: 'Sign Agreement',
      viewSign: 'View Sign',
      download: 'Download',
      submit: 'Submit',
      filter: 'Apply Filter',
      undo: "Undo",
      save: "Save"
    }
  }
};