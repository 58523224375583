var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body-content"},[_vm._m(0),_c('div',{staticClass:"starts-section"},[_c('div',{staticClass:"page-breadcrumb"},[_c('div',{staticClass:"d-md-flex justify-content-between align-items-center"},[_c('div',{staticClass:"pg-bread"},[_c('b-breadcrumb',{staticClass:"bg-transparent p-0 mb-0"},[_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" "+_vm._s(_vm.lang.label.title)+" ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_c('span',{staticClass:"material-icons align-middle"},[_vm._v(" people ")]),_c('span',{staticClass:"align-middle ml-1"},[_vm._v(_vm._s(_vm.messageCount))])])],1)],1)])]),_c('div',{style:({'height': `calc(100vh - ${_vm.midTopHeight}px)`})},[_c('b-container',{staticClass:"px-0 message-module h-100",attrs:{"fluid":""}},[_c('b-row',{staticClass:"h-100"},[_c('b-col',{staticClass:"user-col h-100",attrs:{"md":"5","lg":"4"}},[_c('user-list',{on:{"messageView":_vm.messageView,"setValues":_vm.setValues,"messageCountTotal":_vm.messageCountTotal,"roomId":_vm.roomId,"unreadCount":_vm.unreadCount}})],1),_c('b-col',{staticClass:"message-col h-100",class:_vm.messageBoxSection == true ? 'message-col-show' : '',attrs:{"md":"7","lg":"8"}},[_c('message-box',{ref:"messageBox",attrs:{"defaultId":_vm.userId,"hideTypingBar":(_vm.login.user_type_id == 3 &&
                  _vm.staffpermissions.length > 0 &&
                  !_vm.staffpermissions.includes('reply_to_messages')) ||
                ((_vm.getTrialDays <= 0 && !_vm.login.subscription_end_date) && (!_vm.isUserOnlyTenant && !_vm.isInvitedUser))
                  ? true
                  : false,"clearText":_vm.cleartext,"roomId":_vm.roomid,"clearMessages":_vm.clearMessages,"unreadCountMessage":_vm.unreadCountMessage},on:{"backToUsersEvent":_vm.backToUsersEvent,"textChange":_vm.textChange,"messageData":_vm.messageData}})],1)],1)],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-sticky"},[_c('div',{staticClass:"top-bar-radius"})])
}]

export { render, staticRenderFns }