<!-- content from reports.js -->
<template>
  <div>
    <div class="reports-filter">
      <div class="d-md-flex align-items-center flex-wrap justify-content-xl-end">
        <b-form-group class="pay-tracker" v-if="isThisFilterVisible('incometype')">
          <b-form-radio-group
            v-model="selected"
            :options="PayTracker"
            name="radio-options"
          ></b-form-radio-group>
        </b-form-group>
        <div class="input-select-box">
          <div class="d-md-flex">
            <b-form-group v-if="propertySelection && isThisFilterVisible('property')">
              <div class="select-dropdown">
                <el-select
                  v-model="selectedProperty"
                  :placeholder="lang.label.selectProperty"
                  size="small"
                >
                  <el-option
                    v-for="item in propertyOptions"
                    :key="'options - ' + item.id"
                    :label="item.property_name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </b-form-group>
            <b-form-group v-if="isThisFilterVisible('billType')">
              <div class="select-dropdown">
                <el-select
                  v-model="billingStatus"
                  :placeholder="lang.label.selectBillingType"
                  size="small"
                >
                  <el-option
                    v-for="item in bilingType"
                    :key="item.id"
                    :label="item.text"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </b-form-group>
            <b-form-group v-if="isThisFilterVisible('date')">
              <div class="d-flex align-items-center">
                <label class="mr-2 mb-0 text-nowrap">{{ lang.label.dateFilter }}</label>
                <div class="date-range">
                  
                  <el-date-picker
                    v-model="dateRangeSelected"
                    type="daterange"
                    range-separator="-"
                    :start-placeholder="lang.label.from"
                    :end-placeholder="lang.label.to"
                    size="sm"
                    :append-to-body="false"
                    align="right"
                    :picker-options="{ disabledDate: (timeStart) => disabledEndDate(timeStart, new Date()) }"
                  >
                  </el-date-picker>
                </div>
              </div>
            </b-form-group>
          </div>
        </div>

        <b-form-group>
          <div class="d-flex flex-wrap align-items-center filter-buttons">
            <div class="form-buttons">
              <b-btn variant="primary" size="sm" @click="applyFilter()">{{ lang.label.buttons.filter }}</b-btn>
              <b-btn variant="primary" size="sm" @click="emailReport()" v-if="isThisFilterVisible('emailReport')">{{
                lang.label.buttons.emailReport
              }}</b-btn>
              <b-btn variant="primary" size="sm" @click="resetFilters()">{{ lang.label.buttons.clearFilter }}</b-btn>
              <b-dropdown
                id="dropdown-1"
                variant="primary"
                size="sm"
                :text="lang.label.buttons.download"
                right
                no-caret
                :disabled="login.user_type_id==3 && !staffpermissions.includes('download_payment_report')?true:false"
              >
                <template v-for='(option) in availableDownloadOptions' >
                  <b-dropdown-item    
                      :key="option.value" 
                      :value="option.value"
                      @click="downloadPaymetReport(option.value)"                    
                    >
                    {{option.text}}
                  </b-dropdown-item>
                </template>
              </b-dropdown>
              
                
            </div>
            <div class="add-expense-btn" v-if="isThisFilterVisible('addExpense')">
              <b-btn variant="primary" size="sm" @click="addExpenseClick()">{{
                lang.label.buttons.addExpense
              }}</b-btn>
            </div>
          </div>
        </b-form-group>
      </div>
    </div>
    <b-sidebar
      id="addExpense"
      footer-class="sidebar-footer"
      sidebar-class="sidebar-view for-top-submit"
      text-variant="none"
      backdrop
      right
      width="462px"
      :visible="expenseShow"
      no-header
      no-close-on-backdrop
      no-close-on-esc
      @change="updateSidebar"
    >
      <add-expense @hideSidebar="hideSidebar" @handleApiCall="handleApiCall" :selectedProperty="selectedProperty" v-if="expenseShow"></add-expense>
    </b-sidebar>
  </div>
</template>

<script>
import addExpense from "./AddExpense.vue";
import { mapGetters } from "vuex";
import mixin from "@/mixins/mixin";
import Swal from "sweetalert2";


export default {
  components: {
    "add-expense": addExpense,
  },
  props: {
    activeTab: {
      type: String,
      default: '',
    },
  },
  mixins: [mixin],
  data() {
    return {
      lang: this.$lang.reports,
      selected: "",
      selectedProperty: null,
      PayTracker: [
        { text: this.$lang.reports.label.expense, value: 2 },
        { text: this.$lang.reports.label.income, value: 1 },
      ],
      statusSelected: "",
      propertyOptions: [],
      dateRangeSelected: "",
      billingStatus: "",
      bilingType: [
        { text: "Monthly", value: "monthly",id:1 },
        { text: "Quarterly", value: "quarterly",id:2 },
        { text: "Half yearly", value: "hafly",id:3 },
        { text: "Yearly", value: "yearly",id:4 },
      ],
      expenseShow: false,
      propertySelection: false,
      availableDownloadOptions: [
        { text: "As CSV", value: "csv" },
        { text: "As PDF", value: "pdf" },
      ],
      filterNames: {
        ownerBroker: ['incometype', 'property', 'date', 'addExpense', 'emailReport'],
        tenant: ['property', 'date', 'emailReport'],
        tenantPropertyDetail: ['date']
      },
      emailMethodName: {
        ownerBroker: "emailOwnerBrokerReport",
        tenant: "emailTenantReport",
      },
      userTypeEnum: {
        ownerBroker: 1,
        tenant: 2,
        staff: 3,
      },
      filterData: {},
    };
  },
  watch: {
    activeTab() {
      this.selectProperty();
      this.resetFilters();
    }
  },
  computed: {
    ...mapGetters({
      login:"login",
      staffpermissions:"staffpermissions",
      filterpayloads:"filterpayloads",
      isUserVerified: "isUserVerified",
    }),
  },
  mounted() {
    this.hidePropertyData();
    this.updateSidebar();
    this.selectProperty();
  },
  methods: {
    isThisFilterVisible(name) {
      if (this.activeTab) {
        return this.filterNames[this.activeTab].includes(name);
      } else {
        return true;
      }
    }, 
    addExpenseClick() {
      if (this.isUserVerified) {
        this.$store.commit("setVerificationAlert", true);
      } else if (!this.selectedProperty && this.activeTab) {
        Swal.fire({
          title: this.lang.label.warning,
          text: this.lang.label.propertySelectionAlert,
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: this.lang.label.buttons.ok,
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        })
      } else {
        this.expenseShow = true;
      }
    },
    hideSidebar() {
      let self = this;
      self.expenseShow = false;
    },
    hidePropertyData() {
      this.propertySelection = this.$route.name == "properties-details" ? false : true;
    },
    updateSidebar(val) {
      this.expenseShow = val;
      const body = document.getElementsByTagName("body")[0];
      if (val) {
        body.classList.add("overflow-hidden");
      } else {
        body.classList.remove("overflow-hidden");
      }
    },
    resetFilters() {
      if (this.isUserVerified) {
        this.$store.commit("setVerificationAlert", true);
      } else {
        this.selected = '';
        this.billingStatus = '';
        this.dateRangeSelected = '';
        this.selectedProperty = null;
        this.applyFilter();
      }
    },
    /**
     * Mutated saved filters 
     */
    applyFilter(){
      let self=this;
      if (self.isUserVerified) {
        self.$store.commit("setVerificationAlert", true);
      } else {
        self.filterData = {
          'type':self.selected?self.selected:null,
          'property': self.selectedProperty,
          'frequency_type':self.billingStatus?self.billingStatus:null,
          'start_date':self.dateRangeSelected?self.formatDateStringToDate(self.dateRangeSelected[0]):null,
          'end_date':self.dateRangeSelected?self.formatDateStringToDate(self.dateRangeSelected[1]):null,
          'search':''   
        }
        self.$store.commit("setFilterPayload", this.filterData)
        self.handleApiCall(true, this.filterData)
      }
    },
    /**
     * Event for tenant details API Add Expense api call->Filter form->propertyDetails
     */
    handleApiCall(val){
      let self=this
      self.updateSidebar(false);
      // if(val){
        self.$emit('handleApiCallTenant', this.filterData)
      // }
    },
    /**
     * Event for downloading pdf -> Flow (Event emitted here will be received in PropertyDetails page )
     */
    downloadPaymetReport(val){
      let self=this;  
      if (self.isUserVerified) {
        self.$store.commit("setVerificationAlert", true);
      } else {
        self.$emit('download',{'format':val})
      }
    },
    selectProperty() {
      let self = this;
      if (self.isThisFilterVisible('property') && this.activeTab) {
        let data = {
          type: self.userTypeEnum[this.activeTab],
        }
        self.$store.dispatch('selectProperty', data).then((response) => {
          if (response.status == 200) {
            self.propertyOptions = [];
            self.propertyOptions = response.data;
          } else {
            let message = response.data.message || response.data.detail;
            if (message) {
              self.$store.commit("setMessages", {
                type: "danger",
                message: message,
              });
            }
          }
        });
      }
    },
    /**
     * Email report
     */
    emailReport(){
      let self = this;
      if (self.isUserVerified) {
        self.$store.commit("setVerificationAlert", true);
      } else {
        let data='';
        if(self.filterpayloads==null){
          data = {
            ID: self.$route.params.id,
            size: 100000000000,
            page: 1,
            payload: {
              search: null,
              start_date: null,
              end_date: null,
              type:null,
              frequency_type:null
            },
          };
        } else{
          data = {
            ID: self.$route.params.id,
            size: 100000000000,
            page: 1,
            payload: {
              search: self.search?self.search:'',
              start_date: self.filterpayloads.start_date,
              end_date: self.filterpayloads.end_date,
              type:self.filterpayloads.type,
              frequency_type:self.filterpayloads.frequency_type
            },
          };
        }
        let method = 'emailTenantHistory';
        if (this.activeTab) {
          method = this.emailMethodName[this.activeTab];
          data = {
            property: self.filterData.property,
            start_date: self.filterData.start_date ? self.formatDateReverse(self.filterData.start_date) : null,
            end_date: self.filterData.end_date ? self.formatDateReverse(self.filterData.end_date) : null,
            type: self.filterData.type,
            search: self.filterData.search,
          }
        }
        self.$store.dispatch(method, data).then((response) => {
          if (response.status == 200) {
            self.$store.commit("setMessages", {
                type: "success",
                message: response.data.message,
              });
          } else {
            let message = response.data.message || response.data.detail;
            if (message) {
              self.$store.commit("setMessages", {
                type: "danger",
                message: message,
              });
            }
          }
        });
      }
    }
  } 
};
</script>
