<!-- content from add_bank_details.js -->
<template>
  <div>
    <!-- alert component -->
    <alert-section currentModule="add-bank-details"></alert-section>
    <ValidationObserver ref="observer" v-slot="{ passes }">
      <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
        <b-container fluid class="px-0">
          <b-form-group v-if="noTitle">
            <h5 class="mb-0">{{ lang.label.addBankDetails }}</h5>
          </b-form-group>
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label class="form-label"
                  >{{ lang.label.form.label.name
                  }}<span class="text-danger">{{ lang.label.required }}</span></label
                >
                <ValidationProvider
                  :name="lang.label.form.validation.name"
                  rules="required|max:50"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-form-input
                      v-model="models.name"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.name"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label class="form-label"
                  >{{ lang.label.form.label.bankName
                  }}<span class="text-danger">{{ lang.label.required }}</span></label
                >
                <ValidationProvider
                  :name="lang.label.form.validation.bankName"
                  rules="required|max:70"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-form-input
                      v-model="models.bankName"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.bankName"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label class="form-label"
                  >{{ lang.label.form.label.bankAccountNumber
                  }}<span class="text-danger">{{ lang.label.required }}</span></label
                >
                <ValidationProvider
                  :name="lang.label.form.validation.bankAccountNumber"
                  rules="required|numeric"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-form-input
                      v-model="models.bankAccountNumber"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.bankAccountNumber"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label class="form-label"
                  >{{ lang.label.form.label.bankSwiftCode
                  }}</label
                >
                <ValidationProvider
                  :name="lang.label.form.validation.bankSwiftCode"
                  rules="min:8|max:11"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-form-input
                      v-model="models.bankSwiftCode"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.bankSwiftCode"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label class="form-label"
                  >{{ lang.label.form.label.address
                  }}<span class="text-danger">{{ lang.label.required }}</span></label
                >
                <ValidationProvider
                  :name="lang.label.form.validation.address"
                  rules="required|max:200"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-form-input
                      v-model="models.address"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.address"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col cols="6" class="align-self-end">
              <b-form-group>
                <label class="form-label"
                  >{{ lang.label.form.label.country
                  }}<span class="text-danger">{{ lang.label.required }}</span></label
                >
                <ValidationProvider
                  :name="lang.label.form.validation.country"
                  rules="required"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <el-select
                      v-model="models.country"
                      @change="stateList"
                      :class="{ 'is-invalid': validationContext.errors[0] }"
                    >
                      <el-option
                        v-for="item in countries"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col cols="6" class="align-self-end">
              <b-form-group>
                <label class="form-label"
                  >{{ lang.label.form.label.state
                  }}<span class="text-danger">{{ lang.label.required }}</span></label
                >
                <ValidationProvider
                  :name="lang.label.form.validation.state"
                  rules="required"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <el-select
                      v-model="models.state"
                      :class="{ 'is-invalid': validationContext.errors[0] }"
                    >
                      <el-option
                        v-for="item in states"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col cols="6" class="align-self-end">
              <b-form-group>
                <label class="form-label"
                  >{{ lang.label.form.label.city
                  }}<span class="text-danger">{{ lang.label.required }}</span></label
                >
                <ValidationProvider
                  :name="lang.label.form.validation.city"
                  rules="required|max:50"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-form-input
                      v-model="models.city"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.city"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col cols="6" class="align-self-end">
              <b-form-group>
                <label class="form-label">{{ lang.label.form.label.zipCode }}</label>
                <ValidationProvider
                  :name="lang.label.form.validation.zipCode"
                  rules="numeric|min:4|max:6"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-form-input
                      v-model="models.zipCode"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.zipCode"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
        <div>
          <b-btn variant="primary" block type="submit" @click="scrollToError" v-if="!isEdit">{{
            lang.label.buttons.add
          }}</b-btn>
          <b-btn variant="primary" block type="submit" @click="scrollToError" v-else>{{
            lang.label.buttons.save
          }}</b-btn>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import alertSection from "@/views/shared/AlertSection.vue";
import mixin from "@/mixins/mixin";
export default {

  components: {
    "alert-section": alertSection,
  },
  mixins: [mixin],
  props: ["isEdit", "roleId","request"],
  data() {
    return {
      lang: this.$lang.add_bank_details,
      data: jsondata,
      noTitle: false,
      models: {
        name: "",
        bankName: "",
        bankAccountNumber: "",
        bankSwiftCode: "",
        address: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
        countryId: "",
      },
      countries: [],
      states: [],
    };
  },
  watch: {
    'models.bankAccountNumber'(val) {
      if (val) {
        this.$store.commit("setMessages", null);
      }
    }
  },
  created() {},
  mounted() {
    if (this.$route.name == "my-account") {
      this.noTitle = false;
    } else {
      this.noTitle = true;
    }
    this.viewBankDetails();
    this.countryList();
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    /**
     * Add/Edit bank ac
     */
    onSubmit() {
      let self = this;
      let method = "addBankDetailsOwner";
      if (self.isEdit) {
        method = "editBankDetailsOwner";
      }
      let data = {
        id: self.roleId,
        data: {
          holder_name: self.models.name,
          bank_name: self.models.bankName,
          account: self.models.bankAccountNumber,
          code: self.models.bankSwiftCode,
          address: self.models.address,
          city: self.models.city,
          state: self.models.state,
          country: self.models.country,
          zip_code: self.models.zipCode,
        },
      };
      if(self.request){
        delete data["id"];
        data.data['bank_request_id'] = self.$route.params.token
        console.log(data)
        self.$store.dispatch('requestAddBankDetails', data).then((response) => {
          if (response.status == 200) {
            self.$router.push({name : 'dashboard'})
            self.$store.commit("setMessages", {
              type: "success",
              message: response.data.message,
              allow_module: "bank-details",
            });
          } else {
            let message = response.data.message || response.data.detail;
            if (message) {
              self.$store.commit("setMessages", {
                type: "danger",
                message: message,
                allow_module: "add-bank-details",
              });
            }
          }
      });
      }else{
        self.$store.dispatch(method, data).then((response) => {
        if (response.status == 200) {
          self.$emit("handleUpdate", response.data.bank_id);
          self.$store.commit("setMessages", {
            type: "success",
            message: response.data.message,
            allow_module: "bank-details",
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "add-bank-details",
              disable_timer:method=='addBankDetailsOwner'?true:false
            });
          }
        }
      });
      }
      },

    /**
     * Loads the bank details when popup is opened in edited mode
     */
    viewBankDetails() {
      let self = this;
      if (self.isEdit) {
        self.$store.dispatch("viewBankDetailsOwner", self.roleId).then((response) => {
          if (response.status == 200) {
            self.models = {
              name: response.data.holder_name,
              bankName: response.data.bank_name,
              bankAccountNumber: response.data.account,
              bankSwiftCode: response.data.code,
              address: response.data.address,
              city: response.data.city,
              state: response.data.state_id,
              country: response.data.country_id,
              zipCode: response.data.zip_code,
            };
            // loads the state list in edit mode
            self.countryChange(response.data.country_id);
          } else {
            let message = response.data.message || response.data.detail;
            if (message) {
              self.$store.commit("setMessages", {
                type: "danger",
                message: message,
                allow_module: "add-bank-details",
              });
            }
          }
        });
      }
    },
    /**
     * Loads the country dropdown
     */
    countryList() {
      let self = this;
      self.$store.dispatch("listOfCountries").then(async (response) => {
        if (response.status == 200) {
          self.countries = await response.data;
          if (self.countries.length > 0) {
            self.models.country = self.countries[0].id;
            self.stateList(self.models.country);
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "add-bank-details",
            });
          }
        }
      });
    },
    /**
     * Event to load states when changed country from dropdown and nullify states dropdown
     * @param {number} id
     */
    stateList(id) {
      let self = this;
      self.models.state = "";
      self.countryChange(id);
    },
    /**
     * Event to load states when changed country from dropdown
     * @param {number} id
     */
    countryChange(id) {
      let self = this;
      let countryId = id;
      self.$store.dispatch("listOfStates", countryId).then(async (response) => {
        if (response.status == 200) {
          self.states = await response.data;
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "add-bank-details",
            });
          }
        }
      });
    },
  },
};
</script>
