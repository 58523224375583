<!-- content from contacts.js -->
<template>
  <ValidationObserver ref="observer" v-slot="{ passes }">
    <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
      <b-container fluid class="px-0 pb-fix-btn">
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <label class="form-label">{{ lang.label.form.label.name }}<span class="text-danger">*</span></label>
              <ValidationProvider
                :name="lang.label.form.validation.name"
                rules="required"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <b-form-input
                    v-model="models.name"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="lang.label.form.placeholder.enter"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label class="form-label">{{ lang.label.form.label.permissions }}<span class="text-danger">*</span></label>
              <ValidationProvider
                :name="lang.label.form.validation.permissions"
                rules="required"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <el-select
                    v-model="models.permissions"
                    :placeholder="lang.label.form.placeholder.select"
                    :class="{
                      'is-invalid': validationContext.errors[0],
                    }"
                    :state="getValidationState(validationContext)"
                    multiple
                  >
                    <el-option
                      v-for="item in permissionsOption"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
      <div class="add-contact-button">
        <b-btn variant="primary" block type="submit" v-if="!isEdit">{{
          lang.label.buttons.addRole
        }}</b-btn>
        <b-btn variant="primary" block type="submit" v-else>{{
          lang.label.buttons.saveRole
        }}</b-btn>
      </div>
    </b-form>
  </ValidationObserver>
</template>
<script>
export default {
  props: ["isEdit", "roleId"],
  data() {
    return {
      lang: this.$lang.roles_permissions,
      models: {
        id: null,
        name: "",
        permissions: [],
      },
      permissionsOption: [],
    };
  },
  mounted() {
    this.viewRole();
    this.listPermissions();
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    /**
     * Get permission list to be selected
     */
    listPermissions() {
      let self = this;
      self.$store.dispatch("listPermissions").then((response) => {
        if (response.status == 200) {
          self.permissionsOption = response.data;
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Get role details
     */
    viewRole() {
      let self = this;
      if (self.isEdit) {
        self.$store.dispatch("viewRole", self.roleId).then((response) => {
          if (response.status == 200) {
            let permissions = [];
            response.data.permissions.forEach((el) => {
              permissions.push(el.id);
            });
            self.models = {
              id: response.data.id,
              name: response.data.name,
              permissions: permissions,
            };
          } else {
            let message = response.data.message || response.data.detail;
            if (message) {
              self.$store.commit("setMessages", {
                type: "danger",
                message: message,
              });
            }
          }
        });
      }
    },
    /**
     * Submits role data
     */
    onSubmit() {
      let self = this;
      let method = "addRole";
      if (self.isEdit) {
        method = "editRole";
      }
      let data = {
        id: self.roleId,
        data: {
          name: self.models.name,
          permissions: self.models.permissions,
        },
      };
      self.$store.dispatch(method, data).then((response) => {
        self.$emit('handleUpdate');
        if (response.status == 200) {
          self.$store.commit("setMessages", {
            type: "success",
            message: response.data.message,
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
  },
};
</script>
