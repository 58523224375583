<!-- content from forgot_password.js -->
<template>
  <div class="auth" v-loading="loading">
    <b-container fluid class="bv-example-row">
      <b-row>
        <b-col lg="6" xl="3" class="order-2 order-lg-1 form-view">
          <div class="auth-left-section">
            <div class="auth-logo d-lg-block d-none">
              <img :src="getImgUrl('logo.svg')" alt="miradoor" />
            </div>
            <div class="form-section other-auth">
              <div class="form-inner">
                <!-- alert component -->
                <alert-section></alert-section>
                <div class="form-header">
                  <div class="back-arrow with-back-arrow">
                    <b-btn variant="link p-0" @click="back()"
                      ><img :src="getIconUrl('arrow_back.svg')" alt="back"
                    /></b-btn>
                  </div>
                  <h1 class="form-heading">{{ lang.label.setPassword }}</h1>
                </div>
                <ValidationObserver ref="observer" v-slot="{ passes }">
                  <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
                    <b-form-group>
                      <ValidationProvider
                        :name="lang.label.form.validation.password"
                        :rules="{
                          required: true,
                          regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$!#%*?&]{8,}$/,
                        }"
                        v-slot="validationContext"
                        vid="password"
                      >
                        <div class="position-relative">
                          <b-form-input
                            v-model="models.password"
                            type="password"
                            :state="getValidationState(validationContext)"
                            autocomplete="new-password"
                            :placeholder="lang.label.form.placeholder.password"
                          ></b-form-input>
                          <small class="sm-font text-grey">{{
                            lang.label.form.placeholder.passwordFormateNote
                          }}</small>
                          <b-form-invalid-feedback
                            >{{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group>
                      <ValidationProvider
                        :name="lang.label.form.validation.confirmPassword"
                        rules="required|confirmed:password"
                        v-slot="validationContext"
                      >
                        <div class="position-relative">
                          <b-form-input
                            v-model="models.confirmPassword"
                            type="password"
                            :state="getValidationState(validationContext)"
                            autocomplete="confirm-password"
                            :placeholder="lang.label.form.placeholder.confirmPassword"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            >{{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="pt-2">
                      <b-button block variant="primary" type="submit" class="w-100"
                        >{{ lang.label.buttons.continue }}
                      </b-button>
                    </b-form-group>
                    <b-form-group class="pt-0 pt-lg-2 text-center text-lg-left">
                      <span class="text-grey"
                        >{{ lang.label.alreadyAccount }}
                        <router-link to="/" class="bf600">{{
                          lang.label.signIn
                        }}</router-link></span
                      >
                    </b-form-group>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </b-col>
        <b-col lg="6" xl="9" class="order-1 order-lg-2 slogan-view">
          <auth-banner></auth-banner>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import AuthBanner from "@/components/AuthBanner.vue";
import alertSection from "@/views/shared/AlertSection.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    "auth-banner": AuthBanner,
    "alert-section": alertSection,
  },
  computed: {
    ...mapGetters({
      loading: "loading",
    }),
  },
  data() {
    return {
      lang: this.$lang.invite_user,
      models: {
        password: "",
        confirmPassword: "",
      },
    };
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/styles/img/" + img);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    passwordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    onSubmit() {
      let self = this;
      let data = {
        contact_id: self.$route.params.id,
        password: self.models.password,
        confirm_password: self.models.confirmPassword,
      };
      self.$store.dispatch("inviteUser", data).then((response) => {
        if (response.status == 200) {
          self.$store.commit("setMessages", {
            type: "success",
            message: response.data.message,
            allow_route: self.$route.meta.listRoute,
          });
          self.$router.push({ name: "login" });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    back() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
