module.exports = {
  label: {
    form: {      
      label: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        contactNumber: 'Contact Number',
        contactType: 'Contact Type',
        noRecord: 'No record available',
        notes:'Notes'
      },
      contactType: {
        realtor: "Realtor",
        owner: "Owner",
        tenant: "Tenant",
        other: "Other",
      },
      validation: {
        firstName: 'first name',
        lastName: 'last name',
        email: 'email',
        contactNumber: 'contact number',
        contactType: 'contact type',
        notes:'Notes'
      },
      placeholder: {
        enter: 'Enter',
        email: 'example@mail.com',
        enterNumber: 'Enter Number',
        select: 'Select',
        searchContact: 'Search Contact',
        contactNumber: 'contact number'
      }
    },
    buttons: {
      addContact: 'Add Contact',
      select: 'Select',
      selected: 'Selected',
      saveContact: 'Save Contact'
    }
  }
};