<!-- content from my_contacts.js -->
<template>
    <div class="table-section">
        <div class="table-card table-border-none with-pagination-table">
            <b-card no-body class="custom-card">
                <b-card-body class="p-0">
                    <v-client-table :data="tableData" :columns="columns" :options="options">
                        <template slot="actions">
                            <!-- slot-scope="props" -->
                            <b-btn variant="link" class="p-1" @click="viewContact">
                                <span class="material-icons">visibility</span>
                            </b-btn>
                            <b-btn variant="link" class="p-1">
                                <span class="material-icons">description</span>
                            </b-btn>
                            <b-btn variant="link" class="p-1">
                                <span class="material-icons">home</span>
                            </b-btn>
                            <b-btn variant="link" class="p-1">
                                <span class="material-icons">edit</span>
                            </b-btn>
                            <b-btn variant="link" class="p-1" @click="deleteRow()">
                                <span class="material-icons">delete</span>
                            </b-btn>
                        </template>
                    </v-client-table>
                </b-card-body>
            </b-card>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import jsondata from "@/assets/json/data.json";
import { ClientTable } from "vue-tables-2";
Vue.use(ClientTable, {}, false, "bootstrap4");
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
    data() {
        return {
            lang: this.$lang.my_contacts,
            data: jsondata,
            // table
            columns: ['name', 'role', 'emailID', 'contact', 'dateAdded', 'actions'],
            tableData: [
                {
                    id: 1,
                    name: "John Doe",
                    role: "Tenant",
                    emailID: 'john@mail.com',
                    contact: '+507 254-1085',
                    dateAdded: "09/21/2022",
                    actions: ""
                },
                {
                    id: 2,
                    name: "Carlson Downing",
                    role: "Realtor",
                    emailID: 'john@mail.com',
                    contact: '+507 254-1085',
                    dateAdded: "09/21/2022",
                    actions: ""
                }
            ],
            options: {
                headings: {
                    name: "Contact Name",
                    role: "Role",
                    emailID: "Email ID",
                    contact: "Contact",
                    dateAdded: "Date Added",
                    actions: "Actions"
                },
                filterable: false,
                skin: "table table-hover",
                perPage: 10,
                perPageValues: [],
                sortable: ['name', 'role', 'emailID', 'contact', 'dateAdded'],
                columnsClasses: {
                    name: 'name-width',
                    actions: "action-width",
                    role: 'd-none d-md-table-cell ',
                    emailID: 'd-none d-md-table-cell ',
                    contact: 'd-none d-md-table-cell ',
                    dateAdded: 'd-none d-md-table-cell ',
                },
                pagination: { chunk: 5 },
                texts: {
                    noResults: this.$lang.root.label.noRecord,
                    filter: this.$lang.root.label.filter,
                    filterPlaceholder: this.$lang.root.label.filterPlaceholder,
                    limit: this.$lang.root.label.limit,
                    count: this.$lang.root.label.count,
                },
            },
        };
    },
    mounted() {

    },
    methods: {
        viewContact() {
            this.$router.push({ name: 'contacts-detail' })
        },
        deleteRow() {
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to delete this!",              
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                focusConfirm: false,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-danger'
                },
                }).then((result) => {                
                    if (result.isConfirmed) {
                        this.tableData.splice(result, 1);
                        Swal.fire(this.$lang.root.label.deleted, '', 'success')
                    } 
                })
        },
    },
}
</script>

