<!-- content from login.js -->
<template>
  <div class="auth" v-loading="loading">
    <b-container fluid class="bv-example-row">
      <b-row>
        <b-col lg="6" xl="3" class="order-2 order-lg-1 form-view">
          <div class="auth-left-section">
            <div class="auth-logo d-lg-block d-none">
              <img :src="getImgUrl('logo.svg')" alt="miradoor" />
            </div>

            <div class="form-section">
              <!-- Language Selection Section -->
              <language-selection></language-selection>

              <div class="form-inner">
                <!-- alert component -->
                <alert-section></alert-section>
                <div class="form-header">
                  <h1 class="form-heading">{{ lang.label.login }}</h1>
                </div>
                <ValidationObserver ref="observer" v-slot="{ passes }">
                  <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
                    <b-form-group>
                      <ValidationProvider
                        :name="lang.label.form.validation.plan"
                        rules="required"
                        v-slot="validationContext"
                      >
                        <div class="position-relative">
                          <el-select
                            :state="getValidationState(validationContext)"
                            v-model="models.selectedPlan"
                            :placeholder="lang.label.form.placeholder.selectPlan"
                            :class="{ 'is-invalid': validationContext.errors[0] }"
                          >
                            <el-option
                              v-for="item in planList"
                              :key="item.id"
                              :label="item.title"
                              :value="item.id"
                            >
                            </el-option>
                          </el-select>
                          <b-form-invalid-feedback
                            >{{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <!-- Social SignIn Section -->
                    <!-- <social-sign-in type="login"></social-sign-in>
                    <b-form-group>
                      <span class="d-block text-center or-text">{{ lang.label.or }}</span>
                    </b-form-group> -->
                    <b-form-group>
                      <ValidationProvider
                        :name="lang.label.form.validation.email"
                        rules="required|email"
                        v-slot="validationContext"
                      >
                        <div class="position-relative">
                          <b-form-input
                            v-model="models.email"
                            v-lowercase
                            type="text"
                            :state="getValidationState(validationContext)"
                            autocomplete="new-email"
                            :placeholder="lang.label.form.placeholder.email"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            >{{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="mb-3">
                      <ValidationProvider
                        :name="lang.label.form.validation.password"
                        :rules="{
                          required: true,
                          regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$!#%*?&]{8,}$/,
                        }"
                        v-slot="validationContext"
                      >
                        <div class="position-relative append-icon">
                          <b-form-input
                            v-model="models.password"
                            :type="passwordFieldType"
                            :state="getValidationState(validationContext)"
                            :placeholder="lang.label.form.placeholder.password"
                          ></b-form-input>
                          <div class="icon" @click="passwordVisibility">
                            <div class="pre-app-text cursor-pointer">
                              <img
                                :src="
                                  passwordFieldType == 'password'
                                    ? getIconUrl('visibility_visible.svg')
                                    : getIconUrl('visibility_off.svg')
                                "
                                alt=""
                              />
                            </div>
                          </div>
                          <b-form-invalid-feedback
                            >{{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group>
                      <router-link to="/forgot-password" class="bf600">{{
                        lang.label.forgotPassword
                      }}</router-link>
                    </b-form-group>
                    <b-form-group class="pt-2">
                      <b-btn block variant="primary" type="submit"
                        >{{ lang.label.buttons.continue }}
                      </b-btn>
                    </b-form-group>
                    <b-form-group class="pt-0 pt-lg-2 text-center text-lg-left">
                      <span class="text-grey"
                        >{{ lang.label.dontHaveAccount }}
                        <router-link to="/sign-up" class="bf600">{{
                          lang.label.signUp
                        }}</router-link></span
                      >
                    </b-form-group>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </b-col>
        <b-col lg="6" xl="9" class="order-1 order-lg-2 slogan-view">
          <auth-banner></auth-banner>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import AuthBanner from "@/components/AuthBanner.vue";
import alertSection from "@/views/shared/AlertSection.vue";
import languageSelection from "@/views/shared/LanguageSelection.vue";
import SocialSignIn from "@/views/shared/SocialSignIn.vue";
import { mapGetters } from "vuex";
export default {
  name: "loginPage",
  components: {
    "auth-banner": AuthBanner,
    "alert-section": alertSection,
    "language-selection": languageSelection,
    "social-sign-in": SocialSignIn,
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      selectedPlan: "selectedPlan",
      login:"login"
    }),
  },
  data() {
    return {
      lang: this.$lang.login,
      models: {
        email: "",
        password: "",
        selectedPlan: "",
      },
      passwordFieldType: "password",
      planList: [],
    };
  },
  watch: {
    'models.selectedPlan'(val) {
      this.$store.commit('setSelectedPlan', val);
    }
  },
  mounted() {
    this.listPlan();
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/styles/img/" + img);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    passwordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    listPlan() {
      let self = this;
      self.$store.dispatch("listPlan").then((response) => {
        if (response.status == 200) {
          self.planList = response.data;
          self.models.selectedPlan = self.planList[0] ? self.planList[0].id : "";
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    onSubmit() {
      let self = this;
      let data = {
        email: self.models.email.toLocaleLowerCase(),
        password: self.models.password,
      };
      self.$store.dispatch("userLogin", data).then((response) => {
        if (response.status == 200) {
          self.$store.commit("setLogin", response.data);
          if(response.data.user_has_tenant_contact_type && response.data.subscription_purchased_count == 0) {
            self.$router.push({ name: "properties" });
          } else {
            self.$router.push({ name: "dashboard" });
          }
          if(self.login.user_type_id==3 && self.login.permissions && (self.login.permissions.length>0 || self.login.permissions!==null)){
              let staffPermissions=[]
              response.data.permissions.forEach(element => {
                  staffPermissions.push(element.code)
              });
              self.$store.commit("setStaffPermissions",staffPermissions)
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
  },
};
</script>
