<!-- content from add_expense.js -->
<template>
  <div class="amenity-body h-100">
    <!-- alert component -->
    <alert-section></alert-section>
    <ValidationObserver ref="observer" v-slot="{ passes }" class="h-100">
      <b-form
        @submit.stop.prevent="passes(onSubmit)"
        autocomplete="off"
        class="h-100"
      >
        <b-card no-body class="bg-transparent border-0 p-0 h-100">
          <template #header>
            <div class="d-flex justify-content-between w-100">
              <div class="d-flex align-items-center">
                <b-btn variant="link" size="sm" class="p-0" @click="hide()">
                  <img :src="getIconUrl('close-sm.svg')" alt="close" />
                </b-btn>
                <h4 class="sidebar-header-title mb-0">
                  {{ lang.label.addExpenseTitle }}
                </h4>
              </div>
              <b-btn variant="primary" type="submit">
                {{ lang.label.buttons.submit }}
              </b-btn>
            </div>
          </template>
          <b-card-body>
            <b-form-group>
              <label class="form-label">{{
                lang.label.form.label.expenseName
              }}<span class="text-danger">*</span></label>
              <ValidationProvider
                :name="lang.label.form.validation.expenseName"
                rules="required"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <b-form-input
                    v-model="models.expenseName"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="lang.label.form.placeholder.expenseName"
                  ></b-form-input>

                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
            <b-form-group>
              <label class="form-label">{{
                lang.label.form.label.expenseAmount
              }}<span class="text-danger">*</span></label>
              <ValidationProvider
                :name="lang.label.form.validation.expenseAmount"
                :rules="{required:true,regex:/^[0-9]+(\.[0-9]{1,2})?$/}"
                v-slot="validationContext"
              >
                <div class="position-relative prepend-icon prepend-text">
                  <b-form-input
                    v-model="models.expenseAmount"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="lang.label.form.placeholder.expenseAmount"
                  ></b-form-input>
                  <div class="icon">
                    <div class="pre-app-text primary-text bf-size">$</div>
                  </div>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
            <b-form-group>
              <label class="form-label">{{
                lang.label.form.label.expenseDate
              }}<span class="text-danger">*</span></label>
              <ValidationProvider
                :name="lang.label.form.validation.expenseDate"
                rules="required"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <el-date-picker
                    v-model="models.expenseDate"
                    :state="getValidationState(validationContext)"
                    type="date"
                    :placeholder="lang.label.form.placeholder.selectDate"
                    :class="{ 'is-invalid': validationContext.errors[0] }"
                    size="lg"
                    :picker-options="{
                      disabledDate: (time) => disabledEndDate(time, new Date()),
                    }"
                  >
                  </el-date-picker>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>

            <!-- <b-form-group>
                            <label class="form-label">{{ lang.label.form.label.uploadProof }}</label>
                            <ValidationProvider :name="lang.label.form.validation.uploadProof" rules="required"
                                v-slot="validationContext">
                                <div class="position-relative file-control-dropzone dropzone-flex">
                                    <vue-dropzone ref="myVueDropzone" 
                                        id="dropzone" 
                                        :options="dropzoneOptions"
                                        @vdropzone-max-files-exceeded="filesExceeded"
                                        @vdropzone-error="fileError"
                                        @vdropzone-file-added="filesAdded"
                                        @vdropzone-success="fileSuccess"
                                        @vdropzone-removed-file="filesRemoved"
                                        :useCustomSlot="true" 
                                        :state="getValidationState(validationContext)" 
                                        :class="{ 'is-invalid': validationContext.errors[0] }">
                                        <div class="dropzone-custom-content d-flex justify-content-between w-100">
                                            <p class="dropzone-custom-title">{{ lang.label.form.placeholder.attach }}</p>
                                            <div class="uplaod-icon">
                                                <span class="material-icons">publish</span>
                                            </div>
                                        </div>
                                    </vue-dropzone>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </div>
                            </ValidationProvider>
                        </b-form-group> -->

            <b-form-group>
              <label class="form-label">{{
                lang.label.form.label.uploadProof
              }}</label>
              <div
                class="position-relative file-control-dropzone dropzone-flex"
              >
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  :useCustomSlot="true"
                  @vdropzone-thumbnail="thumbnail"
                  @vdropzone-max-files-exceeded="filesExceeded"
                  @vdropzone-error="fileError"
                  @vdropzone-file-added="filesAdded"
                  @vdropzone-success="fileSuccess"
                  @vdropzone-removed-file="filesRemoved"
                >
                  <div
                    class="dropzone-custom-content d-flex justify-content-between w-100"
                  >
                    <p class="dropzone-custom-title">
                      {{ lang.label.form.placeholder.attach }}
                    </p>
                    <div class="uplaod-icon">
                      <span class="material-icons">publish</span>
                    </div>
                  </div>
                </vue-dropzone>
              </div>
              <span
                class="invalid-feedback"
                style="display: block !important"
                v-if="documentSizeError"
                >{{ lang.label.errorMessages.fileSizeExceeded }}</span
              >
              <span
                class="invalid-feedback"
                style="display: block !important"
                v-else-if="documentErrorMessage"
                >{{ documentErrorMessage }}</span
              >
              <ValidationObserver ref="mediaObserver">
                <ValidationProvider
                  :name="lang.label.form.validation.uploadProof"
                  rules=""
                  v-slot="validationContext"
                >
                  <b-form-file
                    v-model="models.uploadProof"
                    accept=".jpeg,.png,.jpg"
                    type="text"
                    :class="{ 'is-invalid': validationContext.errors[0] }"
                    :state="getValidationState(validationContext)"
                    disabled
                    multiple
                    v-show="false"
                  >
                    <template slot="file-name" slot-scope="{ names }">{{
                      getDocNameShort(names[0])
                    }}</template>
                  </b-form-file>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </ValidationObserver>
            </b-form-group>
          </b-card-body>
        </b-card>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import alertSection from "@/views/shared/AlertSection.vue";
import mixin from "@/mixins/mixin";
export default {
  components: {
    vueDropzone: vue2Dropzone,
    "alert-section": alertSection,
  },
  props: ['selectedProperty'],
  mixins: [mixin],
  data() {
    return {
      lang: this.$lang.add_expense,
      models: {
        expenseName: "",
        expenseAmount: "",
        expenseDate: "",
        uploadProof: [],
      },
      dropzoneOptions: {
        maxFilesize: 10,
        addRemoveLinks: true,
        maxFiles: 2,
        acceptedFiles: ".png,.jpg",
        url: " ",
        addRemoveLinks: true,
        paramName: "asdasd",
        autoProcessQueue: false,
        previewTemplate: this.template(),
      },
      documentError: false,
      documentErrorMessage: "",
      documentSizeError: false,
      uploading: false,
    };
  },
  mounted() {},
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    /**
     * Add expense api call
     */
    onSubmit() {
      let self = this;
      let time = self.formatDateStringToDate(self.models.expenseDate);

      let formData = new FormData();
      formData.append("name", self.models.expenseName);
      formData.append("amount", self.models.expenseAmount);
      formData.append("date", time);
      if (self.models.uploadProof[0]) {
        formData.append("file1", self.models.uploadProof[0]);
      } else {
        formData.append("file1", "");
      }

      if (self.models.uploadProof[1]) {
        formData.append("file2", self.models.uploadProof[1]);
      } else {
        formData.append("file2", "");
      }

      let data = {
        id: self.selectedProperty ? self.selectedProperty : self.$route.params.id,
        formData: formData,
      };
      self.$store.dispatch("addExpense", data).then((response) => {
        if (response.status == 200) {
          self.models = [];
          if (self.$refs.observer) {
            self.$refs.observer.reset();
          }
          if (self.$refs.myVueDropzone) {
            self.$refs.myVueDropzone.removeAllFiles();
          }
          if (response.data.message) {
            self.$store.commit("setMessages", {
              type: "success",
              message: response.data.message,
            });
          }
          self.$emit("handleApiCall", true);
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    hide() {
      let self = this;
      self.models = [];
      self.$refs.observer.reset();
      self.$refs.myVueDropzone.removeAllFiles();
      self.$emit("hideSidebar", false);
    },
    /**
     * Handles event for file added on file upload
     * @param {File} file Selected file
     */
    filesAdded(file) {
      this.validateSelectedMedia(file);
      this.models.uploadProof = this.$refs.myVueDropzone.dropzone.files;
      this.validatedMediaObserver();
      this.$emit("handleFileChange", this.models.uploadProof);
    },
    validateSelectedMedia(file) {
      this.documentErrorMessage = "";
      let extArr = ["jpg","png"];
      let isImage = extArr.includes(
        file.name.split(".")[file.name.split(".").length - 1]
      );
      if (
        file.size > (this.dropzoneOptions.maxFilesize *1000 *1000)
      ) {
        this.documentSizeError = true;
        this.$refs.myVueDropzone.removeAllFiles();
        this.models.uploadProof=[];        
      } else {
        this.documentSizeError = false;
        if (!isImage) {
          let thumbnail = this.getIconUrl("media-file.svg");
          this.$refs.myVueDropzone.dropzone.emit("thumbnail", file, thumbnail);
          this.$refs.myVueDropzone.dropzone.emit("complete", file);
        }
      }
    },
    /**
     * Handles validation for selected media
     */
    validatedMediaObserver() {
      if (this.$refs.mediaObserver.flags.validated) {
        this.$refs.mediaObserver.validate();
      }
    },
    /**
     * Handles event for success on file upload
     * @param {File} file Uploaded file
     */
    fileSuccess: function (file) {
      this.uploading = false;
    },
    /**
     * Handles event for error on file upload
     * @param {File} file Uploaded file
     * @param {Error} error Error initiated by user
     */
    filesRemoved: function (file, error) {
      this.models.uploadProof = this.$refs.myVueDropzone.dropzone.files;
      this.validatedMediaObserver();
      this.uploading = false;
    },
    /**
     * Handles event for file size exceeded error on file upload
     * @param {File} file Uploaded file
     */
    filesExceeded(file) {
      this.$refs.myVueDropzone.removeFile(file);
    },
    /**
     * Handles event for file not acceptable error on file upload
     * @param {File} file Uploaded file
     * @param {String} message Error message
     * @param {object} xhr xhr request
     */
    fileError(file, message, xhr) {
      this.documentErrorMessage = message;
      this.$refs.myVueDropzone.removeFile(file);
      // this.$refs.myVueDropzone.removeAllFiles();
    },
    /**
     * Handles uploaded thumbnail preview section
     * @returns HTML template for file preview
     */
    template: function () {
      // <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
      return `<div class="dz-preview dz-file-preview">
                            <div class="dz-image">
                                <div class="h-100" data-dz-thumbnail-bg></div>
                            </div>
                            <div class="dz-details">
                                <div class="dz-size"><span data-dz-size></span></div>
                                <div class="dz-filename"><span data-dz-name></span></div>
                            </div>
                            <div class="dz-error-message"><span data-dz-errormessage></span></div>
                            <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                            <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                        </div>
                `;
    },
    /**
     * Handles thumbnail image for uploaded file
     * @param {File} file uploaded file
     * @param {String} dataUrl Thumbnail image as base64
     */
    thumbnail: function (file, dataUrl) {
      let j, len, ref, thumbnailElement;
      if (file.previewElement) {
        file.previewElement.classList.remove("dz-file-preview");
        ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
        for (j = 0, len = ref.length; j < len; j++) {
          thumbnailElement = ref[j];
          thumbnailElement.alt = file.name;
          let img = document.createElement("img");
          img.src = dataUrl;
          thumbnailElement.appendChild(img);
        }
        return setTimeout(
          (function (_this) {
            return function () {
              return file.previewElement.classList.add("dz-image-preview");
            };
          })(this),
          1
        );
      }
    },
  },
};
</script>