<!-- content from contacts.js -->
<template>
  <ValidationObserver ref="observer" v-slot="{ passes }">
    <!-- alert component -->
    <alert-section currentModule="UpdateContact"></alert-section>
    <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
      <b-container fluid class="px-0 pb-fix-btn">
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <label class="form-label">{{ lang.label.form.label.firstName }}<span class="text-danger">*</span></label>
              <ValidationProvider
                :name="lang.label.form.validation.firstName"
                rules="required|max:50"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <b-form-input
                    v-model="models.firstName"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="lang.label.form.placeholder.enter"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label class="form-label">{{ lang.label.form.label.lastName }}<span class="text-danger">*</span></label>
              <ValidationProvider
                :name="lang.label.form.validation.lastName"
                rules="required|max:50"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <b-form-input
                    v-model="models.lastName"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="lang.label.form.placeholder.enter"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label class="form-label">{{ lang.label.form.label.email }}<span class="text-danger">*</span></label>
              <ValidationProvider
                :name="lang.label.form.validation.email"
                rules="required|email"
                v-slot="validationContext"
                >
                <div class="position-relative">
                  <b-form-input
                    v-model="models.email"
                    type="text"
                    class="text-lowercase"
                    :state="getValidationState(validationContext)"
                    :placeholder="lang.label.form.placeholder.email"
                    :disabled="isEdit?true:false"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label class="form-label">{{ lang.label.form.label.contactNumber }}<span class="text-danger">*</span></label>
              <ValidationProvider
                :name="lang.label.form.validation.contactNumber"
                rules="required|numeric|min:6|max:8"
                v-slot="validationContext"
              >
                <div class="position-relative prepend-icon prepend-text country-code">
                  <b-form-input
                    v-model="models.contactNumber"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="lang.label.form.placeholder.contactNumber"
                  ></b-form-input>
                  <div class="icon">
                    <div class="pre-app-text primary-text bf-size">+507</div>
                  </div>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label class="form-label">{{ lang.label.form.label.contactType }}<span class="text-danger">*</span></label>
              <ValidationProvider
                :name="lang.label.form.validation.contactType"
                rules="required"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <el-select
                    v-model="models.contactType"
                    :placeholder="lang.label.form.placeholder.select"
                    :disabled ="isEdit || isTypeDisabled ? true : false"
                    :class="{
                      'is-invalid': validationContext.errors[0],
                    }"
                    :state="getValidationState(validationContext)"
                  >
                    <el-option
                      v-for="item in contactTypeOption"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>

            <b-col cols="12" v-if="!isEdit">
              <b-form-group>
                <label class="form-label">{{ lang.label.form.label.notes }}</label>
                  <ValidationProvider
                      :name="lang.label.form.validation.notes"
                      rules="max:200"
                      v-slot="validationContext">
                      <div class="position-relative">
                        <b-form-textarea
                          v-model="models.notes"
                          type="text"
                          :state="getValidationState(validationContext)" 
                          rows="5"></b-form-textarea>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                  </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
      </b-container>

      <div class="add-contact-button">
        <b-btn variant="primary" block type="submit" v-if="!saveBTn">{{
          lang.label.buttons.addContact
        }}</b-btn>
        <b-btn variant="primary" block type="submit" v-else>{{
          lang.label.buttons.saveContact
        }}</b-btn>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import alertSection from "@/views/shared/AlertSection.vue";
export default {
  components: {
    "alert-section": alertSection,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    contactData: {
      default() {
        return {};
      },
    },
    isEdit: {
      default: false,
    },
  },
  data() {
    return {
      lang: this.$lang.contacts,
      data: jsondata,
      saveBTn: false,
      models: {
        firstName: "",
        lastName: "",
        email: "",
        contactNumber: "",
        contactType: "",
        notes:""
      },
      contactTypeOption: [
        { text: this.$lang.contacts.label.form.contactType.realtor, value: 1 },
        { text: this.$lang.contacts.label.form.contactType.owner, value: 2 },
        { text: this.$lang.contacts.label.form.contactType.tenant, value: 3},
        { text: this.$lang.contacts.label.form.contactType.other, value: 4 },
      ],
      contactTypes: {
        broker: 1,
        owner: 2,
        tenant: 3,
        other: 4,
      },
      isTypeDisabled: false,
    };
  },
  mounted() {
    this.handleDefaultSelection();
    this.setContactData();

  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    /**
     * Submits contact data
     */
    onSubmit() {
      let self = this;
      let notesLength='';
      let data = {
        type: self.models.contactType,
        first_name: self.models.firstName,
        last_name: self.models.lastName,
        email: self.models.email.toLocaleLowerCase(),
        contact: self.models.contactNumber,
      };
      if(self.isEdit){
        self.$emit('updateContact',data)
        return;
      }
      self.$store.dispatch('addContactValidate', data).then((response) => {
        if (response.status == 200) {
          data["showPopUp"] = response.data.show_pop_up;
          data["isStaff"] = response.data.is_staff;
          notesLength=self.models.notes.length;
          if(notesLength>0){
            data["noteApiCall"]=true;
            data["noteContent"]=self.models.notes;
          }
          self.$emit("handleContatctValidate", data);
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Handles default selected contact type
     */
    handleDefaultSelection() {
      if (this.type) {
        this.models.contactType = this.contactTypes[this.type];
        this.isTypeDisabled = true;
      }
    },
    /**
     * fill the contact details in form modal 
     */
    setContactData(){
      let self = this;
      if (self.isEdit) {
        self.models = {
          firstName: self.contactData.first_name,
          lastName: self.contactData.last_name,
          email: self.contactData.email,
          contactNumber: self.contactData.contact,
          contactType: self.contactData.contact_type,
        };
        self.saveBTn = true;
      }
    }
  },
};
</script>
