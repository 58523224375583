<!-- content from messages.js -->
<template>
  <div class="body-content">
    <div class="top-sticky">
      <div class="top-bar-radius"></div>
    </div>
    <div class="starts-section">
      <!-- body content header  -->
      <div class="page-breadcrumb">
        <div class="d-md-flex justify-content-between align-items-center">
          <div class="pg-bread">
            <b-breadcrumb class="bg-transparent p-0 mb-0">
              <b-breadcrumb-item active>
                {{ lang.label.title }}
              </b-breadcrumb-item>
              <b-breadcrumb-item active>
                <span class="material-icons align-middle">
                  people
                </span>
                <span class="align-middle ml-1">{{ messageCount }}</span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </div>
      </div>
      <div :style="{'height': `calc(100vh - ${midTopHeight}px)`}">
        <!-- class="mid-screen-height-full" -->
        <b-container fluid class="px-0 message-module h-100">
          <b-row class="h-100">
            <b-col md="5" lg="4" class="user-col h-100">
              <user-list
                @messageView="messageView"
                @setValues="setValues"
                @messageCountTotal="messageCountTotal"
                @roomId="roomId"
                @unreadCount="unreadCount"
              ></user-list>
            </b-col>
            <b-col
              md="7"
              lg="8"
              class="message-col h-100"
              :class="messageBoxSection == true ? 'message-col-show' : ''"
            >
              <message-box
                @backToUsersEvent="backToUsersEvent"
                :defaultId="userId"
                :hideTypingBar="
                  (login.user_type_id == 3 &&
                    staffpermissions.length > 0 &&
                    !staffpermissions.includes('reply_to_messages')) ||
                  ((getTrialDays <= 0 && !login.subscription_end_date) && (!isUserOnlyTenant && !isInvitedUser))
                    ? true
                    : false
                "
                :clearText="cleartext"
                :roomId="roomid"
                @textChange="textChange"
                :clearMessages="clearMessages"
                @messageData="messageData"
                :unreadCountMessage="unreadCountMessage"
                ref="messageBox"
              ></message-box>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import UserList from "./message/UserList.vue";
import MessageBox from "./message/MessageBox.vue";
import { mapGetters } from "vuex";

export default {
  name: "messagePage",
  components: {
    "user-list": UserList,
    "message-box": MessageBox,
  },
  data() {
    return {
      lang: this.$lang.messages,
      data: jsondata,
      searchUser: "",
      index: 0,
      activeClass: 0,
      messageBoxSection: false,
      userId: null,
      cleartext: false,
      clearMessages: false,
      subscriptionIsActive: false,
      messageCount: 0,
      roomid: null,
      unreadCountMessage: 0,
      midTopHeight: 0
    };
  },
  created() {
    window.addEventListener("resize", this.getMidTopHeight);
  },
  computed: {
    ...mapGetters({
      login: "login",
      staffpermissions: "staffpermissions",
      loading: "loading",
      isUserOnlyTenant: "isUserOnlyTenant",
      isInvitedUser:"isInvitedUser"
    }),
    getTrialDays() {
      if (this.login.subscription_end_date && this.login.is_trial_active) {
        let date_1 = new Date(this.login.subscription_end_date);
        let date_2 = new Date();

        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        if (date_1.setHours(0, 0, 0, 0) == date_2.setHours(0, 0, 0, 0)) {
          TotalDays = 1;
        }
        return TotalDays;
      } else {
        return 0;
      }
    },
  },
  mounted() {
    let self = this;
    self.checkSubscription();  
    self.getMidTopHeight()
  },
  
  methods: {
    getMidTopHeight(){
      let self = this
        setTimeout(function() {
          let headerSection = document.querySelector('.header');
          let trialPeriod = document.querySelector('.trial-period');
          let pageBreadcrumb = document.querySelector('.page-breadcrumb');
          if(headerSection && trialPeriod && pageBreadcrumb){
            self.midTopHeight = headerSection.offsetHeight + trialPeriod.offsetHeight + pageBreadcrumb.offsetHeight + 16 + 20*3;
          }
        }, 1000);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getImgUser(img) {
      return require("@/assets/styles/img/users/" + img);
    },
    
    /**
     * Loads the user chat with his/her unique id
     */
    messageView(id) {
      // console.log(this.data.chatUserList[index])
      let self = this;
      self.messageBoxSection = true;
      self.userId = id;
    },
     /**
     * Loads the room id
     */
    roomId(id){
      // console.log("r id",id)
      let self=this;
      self.roomid=id;
    },
    /**
     * Calculate total messages on top
     */
    messageCountTotal(number) {
      let self = this;
      self.messageCount = number;
    },
    /**
     * Sets the page number to 1 and clears the text in text field
     */
    setValues() {
      let self = this;
      self.cleartext = true;
      self.clearMessages = true;
    },
    /**
     * Sets cleartext to init value
     */
    textChange() {
      let self = this;
      self.cleartext = false;
    },
    messageData() {
      let self = this;
      self.clearMessages = false;
    },
    backToUsersEvent() {
      this.messageBoxSection = false;
    },
    /**
     * Check whether the user has subscritopn or not - for future usage -need to pass in props
     */
    checkSubscription() {
      let self = this;
      if (self.login.subscription_end_date == "") {
        self.subscriptionIsActive = false;
      } else if (self.login.subscription_end_date != "") {
        let subscriptionEndDate = new Date(self.login.subscription_end_date);
        let currentDate = new Date();
        if (currentDate.getTime() < subscriptionEndDate.getTime()) {
          self.subscriptionIsActive = true;
        }
      }
    },
    /**
     * Use to display the unread messages if cancelled subscription plan
     */
    unreadCount(number) {
      let self = this;
      self.unreadCountMessage = number;
    },
  },
};
</script>

