module.exports = {
  label: {
    verifyEmialAlert: "Verify your email to get full access to Miradoor, you may not be able to add a contact or properties.",
    monthlyIncome: 'Monthly Income',
    monthlyExpenses: 'Monthly Expenses',
    rentedUnits: 'Rented Units',
    propertiesAvailable: 'Properties Available',
    other: 'Contact List',
    otherTable: 'Other',
    selectMonth: 'Select Month',
    confirmTitle: 'Are you sure?',
    confirmMsg: 'You want to delete this!',
    editContact: "Edit Contact",
    expense: 'Expense',
    income: 'Income',
    tableHeader: {
      name: "Contact Name",
      role: "Role",
      email: "Email Id",
      dateAdded: "Date Added",
      actions: "Actions",
    },
    buttons: {
      sendCode: 'Send Verification Code',
      yes: 'Yes',
      no: 'No',
    },
    tooltip:{
      editContact : 'Edit Contact',
      deleteContact : 'Delete Contact',
    }
  }
};