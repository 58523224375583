module.exports = {
    label: {
      forgotPassword: "¿Has Olvidado Tu Contraseña?",
      alreadyAccount: "Ingresar A Mi Cuenta?",
      signIn: "Iniciar Sesión",
      slogan: "Tu única Plataforma Para El Manejo De Propiedades",
      sentLink: "Le Hemos Enviado El Enlace Para Restablecer La Contraseña A Su Correo Electrónico.",
      form: {
        email: 'Correo Electrónico',
        validation: {
          email: 'correo electrónico',
        },
        placeholder: {
          email: 'Correo Electrónico',
        }
      },
      buttons: {
        continue: 'Continuar',
      }
    }
  };