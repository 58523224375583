<!-- content from bank_information.js -->
<template>
  <div class="bank-info-card">
    <p class="showing-record w-100 text-center" style="color:#707070 !important" v-if="bankDetails && bankDetails.length == 0">{{ lang.label.noRecord }}</p> 
    <b-row>
      <b-col md="6" lg="4" v-for="(bank, index) in bankDetails" :key="index">
        <b-card no-body class="card-with-border h-100">
          <b-card-body>
            <div class="info">
              <div class="d-flex justify-content-between align-items-center">
                <span class="mr-3 word-break">{{ bank.holder_name }}</span>
                <div>
                  <div class="check-radio">
                    <b-form-radio
                      v-model="bank.is_default"
                      name="bank"
                      :value="true"
                      @change="defaultAccount(bank.id)"
                      :disabled="login.user_type_id == 3 && !staffpermissions.includes('update_card')"
                      >{{ lang.label.bankInformation }}</b-form-radio
                    >
                  </div>
                </div>
              </div>
              <div class="bank-details">
                <h6 class="bf-size mt-3 pt-1">{{ bank.bank_name }}</h6>
                <b-row>
                  <b-col cols="6">
                    <label class="sm-font text-grey d-block mb-1">{{
                      lang.label.accountNumber
                    }}</label>
                    <span class="d-block">{{ bank.account }}</span>
                  </b-col>
                  <b-col cols="6">
                    <label class="sm-font text-grey d-block mb-1">{{
                      lang.label.swiftCode
                    }}</label>
                    <span class="d-block" v-if="bank.code">{{ bank.code }}</span>
                    <span class="d-block" v-else>-</span>
                  </b-col>
                </b-row>
                <address class="bf-size mt-3 pt-1 mb-0">
                  {{ bank.address }}, {{ bank.city }}, {{ bank.state }}, {{ bank.country }}<span v-if="bank.zip_code">, {{ bank.zip_code }}
                  </span>  
                </address>
                <div class="text-right mt-3">
                  <el-tooltip :content="lang.label.tooltip.edit" placement="top">
                  <b-btn variant="link" class="p-0" @click="editBank(bank.id)" :hidden="login.user_type_id==3 && staffpermissions.length>0 && !staffpermissions.includes('update_card')?true:false"
                    ><span class="material-icons">edit</span></b-btn
                  >
                </el-tooltip>
                <el-tooltip :content="lang.label.tooltip.delete" placement="top">
                  <b-btn
                    variant="link"
                    class="p-0"
                    @click="deleteBankPopup(bank.id)"
                    :hidden="login.user_type_id == 3 && staffpermissions.length > 0 && !staffpermissions.includes('delete_card')">
                    <span class="material-icons">delete</span>
                    </b-btn>
                </el-tooltip>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- add bank details -->
    <b-sidebar
      id="addBankDetailsBroker"
      footer-class="sidebar-footer"
      sidebar-class="sidebar-view"
      text-variant="none"
      backdrop
      right
      width="462px"
      @change="updateSidebar"
      :visible="isVisible"
    >
      <template #header="{ hide }">
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex align-items-center">
            <b-btn variant="link" size="sm" class="p-0" @click="hide">
              <img :src="getIconUrl('close-sm.svg')" alt="close" />
            </b-btn>
            <h4 class="sidebar-header-title mb-0">
              {{
                isEdit ? lang.label.editBankDetails : lang.label.addBankDetails
              }}
            </h4>
          </div>
        </div>
      </template>
      <div class="amenity-body">
        <add-bank-details
          v-if="isVisible"
          :isEdit="isEdit"
          :roleId="roleId"
          @handleUpdate="handleUpdate"
        >
        </add-bank-details>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import AddBankDetails from "@/components/bank/AddDetails.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  components: {
    "add-bank-details": AddBankDetails,
  },
  computed: {
    ...mapGetters({
      messages: "messages",
      staffpermissions:"staffpermissions",
      login:"login"
    }),
  },
  props: ["addBank", "isAddBank"],
  data() {
    return {
      lang: this.$lang.bank_information,
      data: jsondata,
      bankDetails: [],
      isVisible: false,
      isEdit: false,
      roleId: null,
    };
  },
  watch: {
    isAddBank(val) {
      if (val) {
        this.updateSidebar(true);
        this.$emit('initializedAdd');
      }
    },
  },
  mounted() {
    this.updateSidebar();
    this.fetchBankDetails();
  },
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    updateSidebar(val) {
      this.isVisible = val;
      if (!this.isVisible) {
        this.isEdit = false;
        this.roleId = null;
      }
      const body = document.getElementsByTagName("body")[0];
      if (val) {
        body.classList.add("overflow-hidden");
      } else {
        body.classList.remove("overflow-hidden");
      }
    },
    /**
     * Fetch the details of bank ac's
     */
    fetchBankDetails() {
      let self = this;
      self.bankDetails = [];
      self.$store.dispatch("listBankDetailsOwner").then((response) => {
        if (response.status == 200) {
          self.bankDetails = response.data;
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
     /**
     * Edit the details of bank ac
     * @param {number} id
     */
    editBank(id) {
      let self = this;
      if ((this.login.user_type_id == 1 && this.login.subscription_end_date == "") || (this.login.user_type_id == 3 && this.login.is_subscription_expired)) {
        this.$store.commit("setSubscriptionAlert", true);
      } else {
        self.isEdit = true;
        self.roleId = id;
        self.isVisible = true;
      }
    },
     /**
     * Popup for deletion of bank ac
     * @param {number} id
     */
    deleteBankPopup(id) {
      let self = this;
      if ((this.login.user_type_id == 1 && this.login.subscription_end_date == "") || (this.login.user_type_id == 3 && this.login.is_subscription_expired)) {
        this.$store.commit("setSubscriptionAlert", true);
      } else {
        Swal.fire({
          title: this.lang.label.modal.bankDelete,
          text: self.lang.label.deleteConfirmMsg,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            /* function for bank deletion */
            self.deleteBank(id);
          }
        });
      }
    },
     /**
     * Delete the details of bank ac
     * @param {number} id
     */
    deleteBank(id) {
      let self = this;
      let checkPrimaryAc = self.bankDetails.find((data)=>data.id==id);
      if (checkPrimaryAc.is_default) {
        Swal.fire({
          title: "",
          text: self.lang.label.defAcDelete,
          icon: "warning",
          showCloseButton: true,
          confirmButtonText: "Ok",
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } else {
        self.$store
          .dispatch("deleteBankDetailsOwner", id)
          .then((response) => {
            if (response.status == 200) {
              self.$bvModal.hide("bankModal");
              self.fetchBankDetails();
              self.$store.commit("setMessages", {
                type: "success",
                message: response.data.message,
              });
            } else {
              let message = response.data.message || response.data.detail;
              if (message) {
                self.$store.commit("setMessages", {
                  type: "danger",
                  message: message,
                });
              }
            }
          });
      }
    },
    /**
     * Handles event for bank update
     */
    handleUpdate() {
      let self = this;
      self.updateSidebar(false);
      self.fetchBankDetails();
    },
    /**
     * Sets default payment account
     * @param {number} id
     */
    defaultAccount(id) {
      let self = this;
      self.$store.dispatch("defaultBankDetailsOwner", id).then((response) => {
        if (response.status == 200) {
          self.$store.commit("setMessages", {
            type: "success",
            message: response.data.message,
          });
          self.fetchBankDetails();
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
  },
};
</script>

