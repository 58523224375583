<!-- content from change_password.js -->
<template>
  <div class="body-content">
    <div class="top-sticky">
      <div class="top-bar-radius"></div>
    </div>
    <div class="starts-section" v-loading="loading">
      <!-- alert component -->
      <alert-section></alert-section>

      <!-- body content header  -->
      <div class="page-breadcrumb">
        <div class="d-md-flex justify-content-between align-items-center">
          <div class="pg-bread">
            <b-breadcrumb class="bg-transparent p-0 mb-0">
              <b-breadcrumb-item active>
                {{ lang.label.changePassword }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </div>
      </div>
      <div class="pb-3">
        <b-container class="px-0">
          <b-row class="justify-content-center">
            <b-col md="7" lg="5">
              <b-card no-body class="custom-card">
                <b-card-body>
                  <ValidationObserver ref="observer" v-slot="{ passes }">
                    <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
                      <b-form-group>
                        <ValidationProvider
                          :name="lang.label.form.validation.currentPassword"
                          :rules="{
                            required: true,
                            regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$!#%*?&]{8,}$/,
                          }"
                          v-slot="validationContext"
                        >
                          <div class="position-relative">
                            <b-form-input
                              v-model="models.currentPassword"
                              type="password"
                              :state="getValidationState(validationContext)"
                              autocomplete="current-password"
                              :placeholder="lang.label.form.placeholder.currentPassword"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group>
                        <ValidationProvider
                          :name="lang.label.form.validation.newPassword"
                          :rules="{
                            required: true,
                            regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$!#%*?&]{8,}$/,
                          }"
                          v-slot="validationContext"
                          vid="newPassword"
                        >
                          <div class="position-relative">
                            <b-form-input
                              v-model="models.newPassword"
                              type="password"
                              :state="getValidationState(validationContext)"
                              autocomplete="new-password"
                              :placeholder="lang.label.form.placeholder.newPassword"
                            ></b-form-input>
                            <small class="sm-font text-grey d-inline-block mt-2">{{ lang.label.form.placeholder.passwordFormateNote }}</small>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group>
                        <ValidationProvider
                          :name="lang.label.form.validation.reEnterPassword"
                          rules="required|confirmed:newPassword"
                          v-slot="validationContext"
                        >
                          <div class="position-relative">
                            <b-form-input
                              v-model="models.reEnterPassword"
                              type="password"
                              :state="getValidationState(validationContext)"
                              autocomplete="confirm-password"
                              :placeholder="lang.label.form.placeholder.reEnterPassword"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group class="mb-0">
                        <b-button block variant="primary" type="submit" class="w-100"
                          >{{ lang.label.buttons.update }}
                        </b-button>
                      </b-form-group>
                    </b-form>
                  </ValidationObserver>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import alertSection from "@/views/shared/AlertSection.vue"
import { mapGetters } from "vuex";

export default {
  components: {
    "alert-section": alertSection,
  },
  computed: {
    ...mapGetters({
      loading: "loading",
    }),
  },
  data() {
    return {
      lang: this.$lang.change_password,
      data: jsondata,
      models: {
        currentPassword: "",
        newPassword: "",
        reEnterPassword: "",
      },
    };
  },
  mounted() {},
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      let self = this;
      let data = {
        old_password: self.models.currentPassword,
        new_password: self.models.newPassword,
        confirm_password: self.models.reEnterPassword,
      };
      self.$store.dispatch("changePassword", data).then((response) => {
        if (response.status == 200) {
          self.$store.commit("setMessages", {
            type: "success",
            message: response.data.message,
            allow_route: self.$route.meta.listRoute,
          });
          self.$store.commit('clearLogin');
          self.$router.push({name: "login"});
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
  },
};
</script>
