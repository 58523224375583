<!-- content from staff_management.js -->
<template>
    <!-- table section -->
    <div class="table-section vertical-top">
        <div class="table-card table-border-none with-pagination-table">
            <b-card no-body class="custom-card reports-table">
                <b-card-body class="p-0">
                    <v-client-table :data="tableData" :columns="columns" :options="options">
                        <template slot="firstName" slot-scope="props">
                            <span>{{ props.row.firstName }}</span> <span>{{ props.row.lastName }}</span>
                        </template>
                        <template slot="status" slot-scope="props">
                            <el-select size="small" v-model="props.row.status" @change="updateStatus(props.row.user_id,props.row.status)">
                                <el-option v-for="item in statusType" :key="item.value" :label="item.text" :value="item.value">
                                </el-option>
                            </el-select>
                        </template>
                        <template slot="actions" slot-scope="props">
                            <!-- slot-scope="props" -->
                            <el-tooltip :content="lang.label.tooltip.edit" placement="top">
                                <b-btn variant="link" class="p-1">
                                    <span class="material-icons" @click="edit(props.row.id)">edit</span>
                                </b-btn>
                            </el-tooltip>
                            <el-tooltip :content="lang.label.tooltip.delete" placement="top">
                                <b-btn variant="link" class="p-1">
                                    <span class="material-icons" @click="deleteConfirmation(props.row.user_id)">delete</span>
                                </b-btn>
                            </el-tooltip>
                            <!-- delete member confirmation message -->
                            <b-modal ref="deleteMember" :id="'modal' + props.index" hide-footer centered size="md" modal-class="close-btn-right">
                                <template #modal-header="{ close }">
                                    <b-button variant="link" class="close-btn" @click="close()">
                                        <img :src="getIconUrl('close.svg')" alt="close" />
                                    </b-button>
                                </template>
                                <div class="d-block text-center">
                                    <h3 class="body-modal-heading">{{ lang.label.deleteMember }}</h3>
                                </div>
                                <!-- <div class="modal-buttons text-center modal-btn-responsive">
                                    <b-button class="btn-mw" variant="primary" @click="yes(props.index,props.row.user_id)">{{ lang.label.buttons.yes }}</b-button>
                                    <b-button class="btn-mw" variant="primary" @click="hideModal()">{{ lang.label.buttons.no }}</b-button>
                                </div> -->
                            </b-modal>
                        </template>
                    </v-client-table>
                </b-card-body>
            </b-card>
        </div>

        
    </div>
</template>

<script>
import Vue from 'vue'
import jsondata from "@/assets/json/data.json";
import { ClientTable } from "vue-tables-2";
import Swal from "sweetalert2";

Vue.use(ClientTable, {}, false, "bootstrap4");

export default {
    name: "PropertiesTable",
    props: ['tableData'],
    data() {
        return {
            lang: this.$lang.staff_management,
            data: jsondata,
            // table
            columns: ['firstName', 'role', 'email', 'contactNumber', 'dateAdded', 'status', 'actions'],
            options: {
                headings: {
                    firstName: this.$lang.staff_management.label.tableHeader.firstName,
                    role: this.$lang.staff_management.label.tableHeader.role,
                    email: this.$lang.staff_management.label.tableHeader.email,
                    contactNumber: this.$lang.staff_management.label.tableHeader.contactNumber,
                    dateAdded: this.$lang.staff_management.label.tableHeader.dateAdded,
                    status: this.$lang.staff_management.label.tableHeader.status,
                    actions: this.$lang.staff_management.label.tableHeader.actions,
                },
                skin: "table",
                perPageValues: [5,10,15],
                perPage: 5,
                sortable: ['firstName', 'role', 'email', 'contactNumber', 'dateAdded', 'status'],
                columnsClasses: {
                    firstName: 'address-width',
                    role: 'd-none d-md-table-cell ',
                    email: 'd-none d-md-table-cell ',
                    contactNumber: 'd-none d-md-table-cell ',
                    dateAdded: 'd-none d-md-table-cell ',  
                    status: "action-width d-none d-md-table-cell ",
                    actions:'action-width d-none d-md-table-cell '
                           
                },
                pagination: { chunk: 5 },
                texts: {
                    noResults: this.$lang.root.label.noRecord,
                    filter: this.$lang.root.label.filter,
                    filterPlaceholder: this.$lang.root.label.filterPlaceholder,
                    limit: this.$lang.root.label.limit,
                    count: this.$lang.root.label.count,
                },
            },
            statusModel: [],
            statusType: [
                { text: 'Active', value: true },
                { text: 'Blocked', value: false },
            ]

        };
    },
    mounted() {
    },
    // computed: {
    //     tableListData(){
    //         return JSON.parse(JSON.stringify(this.tableData));
    //     }
    // },
    // watch: {
    //     tableData(val) {
    //         this.statusModel = val.map(v => v.status ? this.statusType[0].value : this.statusType[1].value)
    //     },
    // },
    methods: {
        getIconUrl(icon) {
            return require("@/assets/styles/img/icons/" + icon);
        },
        edit(id){
            this.$emit('editMember', id);
        },
        deleteConfirmation(id){
            let self = this
            Swal.fire({
                title: self.lang.label.confirmTitle,
                text: self.lang.label.confirmMsg,
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: self.lang.label.buttons.yes,
                cancelButtonText: self.lang.label.buttons.no,
                focusConfirm: false,
                buttonsStyling: false,
                customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-danger",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    self.$store.dispatch('deleteStaffMember',id).then((response) => {
                    if (response.status == 200) {
                    Swal.fire(this.$lang.root.label.deleted, response.data.message, "success");
                    const index = self.tableData.findIndex(product => product.user_id == id);
                    self.$bvModal.hide('modal' + id);
                    self.tableData.splice(index, 1)
                } else {
                    let message = response.data.message || response.data.detail;
                    if (message) {
                        self.$store.commit("setMessages", {
                        type: "danger",
                        message: message,
                        });
                    }
                    }
                });
                }
            });
        },
        updateStatus(user_id,status){
            let self = this
            const index = self.tableData.find(product => product.user_id == user_id);
            self.$store.dispatch('updateStaffStatus',user_id).then((response) =>{
                if(response.status == 200){
                    index.status = status
                    self.$store.commit("setMessages", {
                    type: "success",
                    message: response.data.message,
                    });
                }else{
                    let message = response.data.message || response.data.detail;
                    if (message) {
                        self.$store.commit("setMessages", {
                        type: "danger",
                        message: message,
                    });
                    }
                }
            })
        }
    },
}
</script>

