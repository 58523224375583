module.exports = {
  label: {
    setPassword: "Configurar La Clave",
    alreadyAccount: "Ingresar A Mi Cuenta?",
    signIn: "Iniciar Sesión",
    slogan: "Tu única Plataforma Para El Manejo De Propiedades",
    sentLink: "Le Hemos Enviado El Enlace Para Restablecer La Contraseña A Su Correo Electrónico.",
    form: {
      email: 'Correo Electrónico',
      validation: {
        email: 'correo electrónico',
        password: 'contraseña',
        confirmPassword: 'confirmar contraseña',
      },
      placeholder: {
        email: 'Correo Electrónico',
        password: 'Contraseña',
        confirmPassword: 'Confirmar Contraseña',
        passwordFormateNote: '*Longitud De 8 O Más, Incluir Al Menos Una Mayúscula, Minúscula, Caracteres Especiales Y Un Número. Ej: Miradoor123*',
      }
    },
    buttons: {
      continue: 'Continuar',
    }
  }
};