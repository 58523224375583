module.exports = {
  label: {
    rolesPermissions: "Roles y Permisos",
    confirmTitle: '¿Estas Seguro?',
    confirmMsg: '¿Quieres borrar esto?',
    addRolesPermission: 'Agregar Roles y Permisos',
    editRolesPermission: 'Editar Roles y Permisos',
    tableHeader: {
      roleName: "Nombre Del Rol",
      permissions: "Permisos",
      actions: "Acciones",
    },
    buttons: {
      add: 'Agregar',
      update: 'Update',
      delete: 'Borrar',
      edit: 'Editar',
      yes: 'Sí',
      no: 'No',
      assign: 'Asignar',
      save: 'Guardar',
      cancel: 'Cancelar',
      addRole: 'Agregar Rol',
      select: 'selección',
      saveRole: 'Guardar Rol'
    },
    form: {      
      label: {
        name: 'Nombre',
        permissions: 'Permisos',
      },
      validation: {
        name: 'nombre',
        permissions: 'permisos',
      },
      placeholder: {
        enter: 'Escribir Nombre Del Rol',
        enterName: 'Ingrese Su Nombre',
        select: 'Seleccionar Permisos',
      }
    },
    tooltip:{
      edit : 'Editar Rol',
      delete : 'Eliminar Rol',
    }
  }
}