<!-- content from signup.js -->
<template>
  <div>
    <b-form-group>
      <b-btn block variant="default" class="sign-google" @click="loginWithGoogle">
        <img :src="getImgUrl('google.svg')" alt="Sign in With Google" />
        {{ lang.label.signGoogle }}
      </b-btn>
    </b-form-group>
    <!-- <b-form-group>
      <b-btn block variant="default" class="sign-facebook" @click="loginWithFacebook">
        <img :src="getImgUrl('facebook.svg')" alt="Sign in With Facebook" />
        {{ lang.label.signFacebook }}
      </b-btn>
    </b-form-group> -->
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { mapGetters } from "vuex";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";

export default {
  computed: {
    ...mapGetters({
      selectedPlan: "selectedPlan",
    })
  },
  props: ["type"],
  data() {
    return {
      lang: this.type == "login" ? this.$lang.login : this.$lang.signup,
      auth: null,
      googleProvider: null,
      facebookProvider: null,
    };
  },
  mounted() {
    this.initializeFirebase();
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/styles/img/" + img);
    },
    /**
     * Initializes Firebase sdk and auth configs
     */
    initializeFirebase() {
      // Your web app's Firebase configuration
      // For Firebase JS SDK v7.20.0 and later, measurementId is optional
      const firebaseConfig = {
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: "miradoor-maps.firebaseapp.com",
        projectId: "miradoor-maps",
        storageBucket: "miradoor-maps.appspot.com",
        messagingSenderId: "911251160285",
        appId: "1:911251160285:web:9625b2d8cce326fae8ef34",
        measurementId: "G-N46PEWPXRQ",
      };

      // Initialize Firebase
      const app = initializeApp(firebaseConfig);

      // Initialize Firebase Authentication and get a reference to the service
      this.auth = getAuth(app);
      this.auth.languageCode = "es";

      // Initialize Firebase Google provider
      this.googleProvider = new GoogleAuthProvider();
      this.googleProvider.addScope("https://www.googleapis.com/auth/contacts.readonly");

      // Initialize Firebase Facebook provider
      // this.facebookProvider = new FacebookAuthProvider();
      // this.facebookProvider.addScope("email");
      // this.facebookProvider.addScope("user_birthday");
    },
    /**
     * Handles social login with Google using Firebase
     */
    loginWithGoogle() {
      signInWithPopup(this.auth, this.googleProvider)
        .then((result) => {
          // This gives a Google Access Token. Use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          // The signed-in user info.
          const user = result.user;
          // IdP data available using getAdditionalUserInfo(result)

          this.handleSocialLogin(user, credential);
        })
        .catch((error) => {
          console.log("error", error);
          // Handle Errors here.
          // const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          // const email = error.customData.email;
          // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
          this.$store.commit("setMessages", {
            type: "danger",
            message: errorMessage,
          });
        });
    },
    /**
     * Handles social login with Facebook using Firebase
     */
    loginWithFacebook() {
      signInWithPopup(this.auth, this.facebookProvider)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          const credential = FacebookAuthProvider.credentialFromResult(result);
          // IdP data available using getAdditionalUserInfo(result)

          this.handleSocialLogin(user, credential);
        })
        .catch((error) => {
          console.log("error", error);
          // Handle Errors here.
          const errorMessage = error.message;
          // The email of the user's account used.
          // const email = error.customData.email;
          // The AuthCredential type that was used.
          // const credential = FacebookAuthProvider.credentialFromError(error);
          // ...
          this.$store.commit("setMessages", {
            type: "danger",
            message: errorMessage,
          });
        });
    },
    /**
     * Handles user social login using user data from firebase
     * @param {object} user Object that contains user data
     * @param {object} credential Onject that contains user credentials
     */
    handleSocialLogin(user, credential) {
      let self = this;
      let data = {
        username: user.uid,
        email: user.email.toLocaleLowerCase(),
        first_name: user.displayName.split(' ')[0],
        last_name: user.displayName.split(' ')[1] || '',
        registered_type: 2,
        plan: self.selectedPlan,
      };
      self.$store.dispatch("socialLogin", data).then((response) => {
        if (response.status == 200) {
          self.$store.commit("setLogin", response.data);
          if(response.data.is_new_user && !response.data.email){
            self.$store.commit("setNewUser", true);
          }
          self.$router.push({ name: "dashboard" });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
  },
};
</script>
