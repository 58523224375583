module.exports = {
    label: {
      login: "Iniciar Sesión",
      dontHaveAccount: "Crear Cuenta?",
      signUp: "Registrarse",
      slogan: "Tu única Plataforma Para El Manejo De Propiedades",
      forgotPassword: '¿Has Olvidado Tu Contraseña?',
      or: 'O',
      signGoogle: 'Iniciar Sesión Con Google',
      signFacebook: 'Iniciar Sesión Con Facebook',
      form: {
        email: 'Correo Electrónico',
        password: 'Contraseña',
        validation: {
          email: 'correo electrónico',
          password: 'contraseña',
          plan: "plan",
        },
        placeholder: {
          email: 'Correo Electrónico',
          password: 'Contraseña',
          selectPlan: "Seleccionar Plan"
        }
      },
      buttons: {
        continue: 'Continuar',
      }
    }
  };