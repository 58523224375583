export default {
    setEditExpense(state, payload) {
        state.editExpense = payload;
    },
	setLoading(state, payload) {
		state.loading = payload;
	},
	setMessages(state, payload) {
        state.alertTimeouts.forEach(t => {
            clearTimeout(t);
        });
        let arr = [];
        if (payload) {
            arr.push(payload);
        }
        state.messages = arr.length > 0 ? arr : [];
	},
	setLogin(state, payload) {
		localStorage.setItem('login', JSON.stringify(payload));
		state.login = payload;
	},
	clearLogin(state) {
		localStorage.removeItem('login');
		state.login = {
			token: null
		};
	},
	setUnauthorized(state, payload) {
		state.unauthorized = payload;
	},
    setLanguage(state, payload) {
        localStorage.setItem('language', payload);
        state.language = payload;
    },
	setStaffPermissions(state,payload){
		localStorage.setItem('staffpermissions', JSON.stringify(payload));
        state.staffPermissions = payload;
	},
	setFilterPayload(state,payload){
		state.payloadFilters = payload;
	},
	setSubscriptionAlert(state,payload){
		state.subscriptionAlert = payload;
	},
	setVerificationAlert(state,payload){
		state.verificationAlert = payload;
	},
	setNewUser(state,payload){
		state.isNewUser = payload;
	},
	setLoadingRelative(state, payload) {
		state.loadingRelative = payload;
	},
	setCardDetails(state, payload) {
		state.cardDetails = payload;
	},
	setSelectedPlan(state, payload) {
		state.selectedPlan = payload;
	},
}