<!-- content from verification_code.js -->
<template>
  <div
    class="top-sticky"
    id="VerificationSection"
    :class="[
      !login.is_verified ? 'verification-sticky' : '',
      expired ? 'trial-period' : '',
    ]"
    ref="infoBox"
    v-if="!showVerification.includes($route.name)"
  >
    <div class="top-bar-radius" v-if="login.is_verified && !expired"></div>
    <template v-if="!login.is_verified">
      <b-alert variant="success" show class="verification-email-message">
        <div class="d-flex align-items-center flex-column flex-md-row">
          <span v-if="login.id_doc_status_id == 1 || login.id_doc_status_id == 3">{{
            lang.label.verifyEmailAndIdentityAlert
          }}</span>
          <span v-else>{{ lang.label.verifyEmialAlert }}</span>

          <b-btn
            size="sm"
            variant="primary"
            class="ml-0 ml-xl-4 mt-3 mt-xl-0"
            @click="getVerifyEmailOrOtp()"
            >{{ lang.label.buttons.sendCode }}</b-btn
          >
          <!-- v-b-modal.otpVerification -->

          <!-- Upload document button -->
          <template v-if="login.id_doc_status_id == 1 || login.id_doc_status_id == 3">
            <b-btn
              size="sm"
              variant="primary"
              class="ml-0 ml-xl-4 mt-3 mt-xl-0"
              @click="loadDocument()"
              :disabled="login.id_doc !== '' && login.id_doc_status_id == 1"
              >{{ lang.label.buttons.uploadDocument }}</b-btn
            >
          </template>
          <template v-if="login.id_doc_reject_reason && login.id_doc_reject_reason != ''">
            <div class="rejected-docs">
              <span class="md-font align-middle">{{ lang.label.rejectionText }}</span>
              <b-btn
                size="sm"
                class="mt-0 text-danger p-0 mh-auto md-font"
                variant="link"
                v-b-modal.documentRejectionReason
                >{{ lang.label.rejectionReason }}</b-btn
              >
            </div>
          </template>
        </div>
      </b-alert>

      <!-- otp verification -->
      <b-modal
        ref="deleteMember"
        id="otpVerification"
        hide-footer
        centered
        size="md"
        modal-class="close-btn-right"
      >
        <template #modal-header="{ close }">
          <b-button variant="link" class="close-btn" @click="close()">
            <img :src="getIconUrl('close.svg')" alt="close" />
          </b-button>
        </template>
        <div class="d-block text-center">
          <!-- alert component -->
          <alert-section currentModule="OTP-Verification"></alert-section>
          <otp-verification
            @resendOTP="resendOTP"
            @verifyOTP="verifyOTP"
            :canBeResent="canBeResent"
            @handleTimerDone="handleTimerDone"
          ></otp-verification>
        </div>
      </b-modal>

      <!-- Get Email Field -->
      <b-modal
        ref="deleteMember"
        id="getEmailField"
        hide-footer
        centered
        size="md"
        modal-class="close-btn-right"
        @hidden="resetEmailModal"
      >
        <template #modal-header="{ close }">
          <b-button variant="link" class="close-btn" @click="close()">
            <img :src="getIconUrl('close.svg')" alt="close" />
          </b-button>
        </template>
        <div class="d-block">
          <ValidationObserver ref="observer" v-slot="{ passes }">
            <b-form @submit.stop.prevent="passes(submitEmail)" autocomplete="off">
              <b-form-group>
                <ValidationProvider
                  :name="lang.label.form.validation.email"
                  rules="required|email"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <label class="form-label">{{ lang.label.form.label.email }}</label>
                    <b-form-input
                      v-model="models.email"
                      type="text"
                      class="text-lowercase"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.email"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <b-form-group class="pt-3 mb-0">
                <b-btn block variant="primary" type="submit"
                  >{{ lang.label.buttons.submit }}
                </b-btn>
              </b-form-group>
            </b-form>
          </ValidationObserver>
        </div>
      </b-modal>
    </template>

    <!-- Upload documents button -->
    <template
      v-if="
        login.is_verified && (login.id_doc_status_id == 1 || login.id_doc_status_id == 3)
      "
    >
      <b-alert variant="success" show class="verification-email-message">
        <div class="d-flex align-items-center flex-column flex-md-row">
          <span>{{ lang.label.uploadDocument }}</span>
          <b-btn
            size="sm"
            variant="primary"
            class="ml-0 ml-md-4 mt-3 mt-md-0"
            @click="loadDocument()"
            :disabled="login.id_doc !== '' && login.id_doc_status_id == 1"
            >{{ lang.label.buttons.uploadDocument }}</b-btn
          >
          <template v-if="login.id_doc_reject_reason && login.id_doc_reject_reason != ''">
            <div>
              <span class="md-font">{{ lang.label.rejectionText }}</span
              ><br />
              <b-btn
                size="sm"
                class="mt-3 mt-md-0 text-danger p-0 mh-auto md-font"
                variant="link"
                v-b-modal.documentRejectionReason
                >{{ lang.label.rejectionReason }}</b-btn
              >
            </div>
          </template>
        </div>
      </b-alert>
    </template>

    <div class="trial-period-section" v-if="((getTrialDays && trialMessage) || (!login.subscription_end_date && trialMessage) && login.subscription_purchased_count > 0)">
      <span>{{ trialMessage }}</span>
      <b-btn size="sm" variant="primary" class="ml-4" @click="enrollNow()">{{
        lang.label.buttons.enrollNow
      }}</b-btn>
    </div>
    <div class="trial-period-section" v-if="getRemainingDays">
      <span
        >{{ lang.label.suscriptionCancelled }} {{ getRemainingDays }} {{ lang.label.days }}</span
      >
    </div>

    <!-- upload document rejection reason -->
    <b-modal
      ref="documentRejectionReason"
      id="documentRejectionReason"
      hide-footer
      centered
      size="md"
      modal-class="close-btn-right"
    >
      <template #modal-header="{ close }">
        <b-button variant="link" class="close-btn" @click="close()">
          <img :src="getIconUrl('close.svg')" alt="close" />
        </b-button>
      </template>
      <div class="d-block text-center">
        {{ login.id_doc_reject_reason }}
      </div>
    </b-modal>
  </div>
</template>

<script>
import OtpVerification from "../views/auth/signup-steps/OtpVerification.vue";
import alertSection from "@/views/shared/AlertSection.vue";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import { mapGetters } from "vuex";

export default {
  name: "verificationCode",
  components: {
    "alert-section": alertSection,
    "otp-verification": OtpVerification,
  },
  computed: {
    ...mapGetters({
      login: "login",
      isNewUser: "isNewUser",
    }),
    getTrialDays() {
      if (this.login.subscription_end_date && this.login.is_trial_active) {
        let date_1 = new Date(this.login.subscription_end_date);
        let date_2 = new Date();

        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        if (date_1.setHours(0, 0, 0, 0) == date_2.setHours(0, 0, 0, 0)) {
          TotalDays = 1;
        }
        return TotalDays;
      } else {
        return 0;
      }
    },
    getRemainingDays() {
      if (this.login.subscription_end_date && this.login.is_subscription_cancelled) {
        let date_1 = new Date(this.login.subscription_end_date);
        let date_2 = new Date();

        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        if (date_1.setHours(0, 0, 0, 0) == date_2.setHours(0, 0, 0, 0)) {
          TotalDays = 1;
        }
        return TotalDays;
      } else {
        return 0;
      }
    },
  },
  data() {
    return {
      lang: this.$lang.verification_code,
      canBeResent: false,
      expired: true,
      expiredPlan: false,
      docFile: [],
      showVerification: ["public-properties-details"],
      models: {
        email: null,
      },
      trialMessage: '',
    };
  },
  created() {
    let self = this;
    this.getSystemMessage();
    if(!self.showVerification.includes(self.$route.name)){
        self.retrieveProfileInfo();
    }
  },
  mounted() {
    if (this.isNewUser) {
      this.getVerifyEmailOrOtp();
      this.$store.commit("setNewUser", false);
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getImgUrl(img) {
      return require("@/assets/styles/img/" + img);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getOTP(isResend) {
      let self = this;
      if (self.login.email.length > 0) {
        let data = {
          email: self.login.email,
        };
        self.$store.dispatch("getOTP", data).then((response) => {
          if (response.status == 200) {
            self.canBeResent = false;
            self.$store.commit("setMessages", {
              type: "success",
              message: response.data.message,
              allow_module: "OTP-Verification",
            });
            if (!isResend) {
              self.$bvModal.show("otpVerification");
            }
          } else {
            let message = response.data.message || response.data.detail;
            if (isResend) {
              self.$bvModal.hide("otpVerification");
            }
            if (message) {
              Swal.fire(self.lang.label.warning, response.data.message, "error");
            }
          }
        });
      } else {
        Swal.fire("Warning!", this.lang.label.emailAlert, "error").then(() => {
          if (this.$route.name !== "my-account") {
            this.$router.push({ name: "my-account", params: { id: 1 } });
          }
        });
      }
    },
    resendOTP(val) {
      this.getOTP(true);
    },
    handleTimerDone() {
      this.canBeResent = true;
    },
    verifyOTP(otp) {
      let self = this;
      let data = {
        email: self.login.email,
        otp: otp,
      };
      self.$store.dispatch("verifyOTP", data).then((response) => {
        if (response.status == 200) {
          let loginData = self.login;
          loginData.is_verified = true;
          self.$store.commit("setLogin", loginData);
          self.$bvModal.hide("otpVerification");
          Swal.fire("Verified!", response.data.message, "success");
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "OTP-Verification",
            });
          }
        }
      });
    },
    /**
     * Show Email Popup or Send OTP for verification
     */
    getVerifyEmailOrOtp() {
      let self = this;
      if (!self.login.email) {
        self.$bvModal.show("getEmailField");
      } else if (self.isNewUser) {
        self.$bvModal.show("otpVerification");
      } else {
        self.getOTP();
      }
    },
    /**
     * Submit Email
     */
    submitEmail() {
      let self = this;
      let data = {
        email: self.models.email.toLocaleLowerCase(),
      };
      self.$store.dispatch("updateUserEmail", data).then((response) => {
        if (response.status == 200) {
          self.$bvModal.hide("getEmailField");
          let loginData = self.login;
          loginData.email = self.models.email.toLocaleLowerCase();
          self.$store.commit("setLogin", loginData);
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "success",
              message: message,
            });
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "emailModal",
            });
          }
        }
      });
    },
    enrollNow() {
      if (this.$route.path !== "/my-account/2") {
        this.$router.push({ path: "/my-account/2" });
      }
    },
    /**
     * Loads the document from file picker
     */
    loadDocument() {
      let self = this;
      let input = document.createElement("input");
      input.type = "file";
      input.accept = [".jpeg", ".jpg", ".png", ".pdf"];
      input.onchange = () => {
        let allowedExtensions = ["jpeg", "jpg", "png", "pdf"];
        let files = Array.from(input.files);
        let isImageAndPdf = files[0].name.split(".").pop();
        if (!allowedExtensions.includes(isImageAndPdf)) {
          self.fireValidation("extensionValidation");
        } else {
          let fileSizeMB = files[0].size / 1000 ** 2;
          if (fileSizeMB > 10) {
            self.fireValidation();
          } else {
            self.docFile = files[0];
            self.uploadIdentificationDocument();
          }
        }
      };
      input.click();
    },
    /**
     * Fires the error if uploaded file size is greater then 10MB or extension is wrong
     */
    fireValidation(validationType = "") {
      let self = this;
      Swal.fire({
        title: "",
        text:
          validationType == "extensionValidation"
            ? self.lang.label.extensionValidation
            : self.lang.label.documentSizeValidation,
        icon: "warning",
        confirmButtonText: self.lang.label.buttons.ok,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    /**
     * For document upload.
     */
    uploadIdentificationDocument() {
      let self = this;
      let data = new FormData();
      data.append("id_doc", self.docFile);

      self.$store.dispatch("uploadIdentificationDocument", data).then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          self.retrieveProfileInfo();
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "OTP-Verification",
            });
          }
        }
      });
    },
    /**
     * Receives profile information,specifically to retrieve uploaded document status.
     */
    retrieveProfileInfo() {
      let self = this;
      self.$store.dispatch("retrieveProfile").then((response) => {
        if (response.status == 200) {
          let loginData = Object.assign(self.login, response.data);
          self.$store.commit("setLogin", loginData);
          if(self.login.user_type_id == 3 && self.login.permissions && (self.login.permissions.length > 0 || self.login.permissions !== null)){
            let staffPermissions=[]
            response.data.permissions.forEach(element => {
              staffPermissions.push(element.code)
            });
            self.$store.commit("setStaffPermissions",staffPermissions)
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "OTP-Verification",
            });
          }
        }
      });
    },
    /**
     * Get System Messages
     */
     getSystemMessage() {
      let self = this;
      if (self.login.token) {
        self.$store.dispatch("getSystemMessage").then((response) => {
          if (response.status == 200) {
            if (self.getTrialDays <=0 && !self.login.subscription_end_date) {
              self.trialMessage = self.login.subscription_purchased_count > 1 ? response.data.subscription_expired_message : response.data.trial_period_expired_message;
            } else if (self.getTrialDays > 0 && self.login.user_type_id !== 3) {
              self.trialMessage = response.data.subscription_going_to_expire_message || response.data.trial_period_going_to_expire_message;
              self.trialMessage = self.trialMessage.replace('#n', self.getTrialDays);
            }
          } else {
            let message = response.data.message || response.data.detail;
            if (message) {
              self.$store.commit("setMessages", {
                type: "danger",
                message: message,
                allow_module: "OTP-Verification",
              });
            }
          }
        });
      }
    },
    resetEmailModal() {
      this.models.email = null;
    },
  },
};
</script>
