<!-- content from properties.js -->
<template>
  <div class="body-content">
    <div class="top-sticky">
      <div class="top-bar-radius"></div>
    </div>
    <div class="starts-section" v-loading="loading">
      <!-- body content header  -->
      <alert-section currentModule="propertyList"></alert-section>
      <div class="page-breadcrumb">
        <div class="d-md-flex justify-content-between align-items-center">
          <div class="pg-bread">
            <b-breadcrumb class="bg-transparent p-0 mb-0">
              <b-breadcrumb-item active>
                {{ lang.label.propertiesPortfolio }}
              </b-breadcrumb-item>
              <b-breadcrumb-item active>
                <span class="material-icons align-middle">home</span>
                <span class="align-middle ml-1">{{
                  tableData.length > 0 ? tableData.length : ""
                }}</span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>

          <div class="pg-right">
            <div class="d-flex align-items-center">
              <!-- <b-form-input
                v-model="search"
                type="text"
                placeholder="Search"
                size="sm"
                class="data-search"
              ></b-form-input> -->
              <b-btn
                variant="primary"
                size="sm"
                class="text-nowrap btn-mw w-sm-100"
                @click="addProperties()"
                :hidden="(login.user_type_id === 3 || (isUserOnlyTenant)) ? true : false"
                >+ {{ lang.label.buttons.add }}</b-btn
              >
            </div>
          </div>
        </div>
      </div>
      <!-- table section -->
       <div class="pb-3" v-if="login.user_type_id == 3">
            <properties-table :tableData="tableData" @handleStatus="handleStatus">
            </properties-table>
      </div>
      <div class="pb-3" v-else-if="isUserOnlyTenant">
            <tenet-properties :isHidden="true" :tableData="tableData"></tenet-properties>
      </div>
      <div class="pb-3" v-else>
        <el-tabs v-model="activeName">
          <el-tab-pane :label="lang.label.tabs.owner" name="owner">
            <properties-table :tableData="tableData" @handleStatus="handleStatus">
            </properties-table>
          </el-tab-pane>
          <el-tab-pane :label="lang.label.tabs.broker" name="broker">
            <properties-table :tableData="tableData" @handleStatus="handleStatus">
            </properties-table>
          </el-tab-pane>
          <el-tab-pane :label="lang.label.tabs.tenant" name="tenant">
            <tenet-properties :isHidden="true" :tableData="tableData"></tenet-properties>
          </el-tab-pane>
        </el-tabs>
      </div>
     
      
    </div>
  </div>
</template>

<script>
import PropertiesTable from "@/components/PropertiesTable.vue";
import mixin from "@/mixins/mixin";
import alertSection from "@/views/shared/AlertSection.vue";
import { mapGetters } from "vuex";
import TenantRentedProperties from "@/views/tenant/RentedProperties";
import subscriptionValidation_mixin from '@/mixins/subscriptionValidation_mixin'
export default {
  name: "DashboardPage",
  components: {
    "tenet-properties": TenantRentedProperties,
    "properties-table": PropertiesTable,
    "alert-section": alertSection,
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      login: "login",
      staffpermissions: "staffpermissions",
      isUserVerified: "isUserVerified",
      isUserOnlyTenant: "isUserOnlyTenant",
    }),
  },
  mixins: [mixin,subscriptionValidation_mixin],
  data() {
    return {
      lang: this.$lang.properties,
      search: "",
      activeName: "owner",
      tableData: [],
      propertyType: {
        owner: 2,
        broker: 1,
        tenant: 3,
      },
      propertyTypeEnum: {
        2: 'owner',
        1: 'broker',
        3: 'tenant',
      },
      propertyStatusOption: {
        1: this.$lang.add_properties.label.form.placeholder.available,
        2: this.$lang.add_properties.label.form.placeholder.rented,
      },
      propertyTypeOption: {
        1: this.$lang.add_properties.label.form.placeholder.commercial,
        2: this.$lang.add_properties.label.form.placeholder.residential,
      },
    };
  },
  watch: {
    activeName() {
      this.getPropertiesList();
    },
  },
  created() {
    this.handleDefaultTab();
  },
  mounted() {
    this.getPropertiesList();
  },
  methods: {
    /**
     * Handles default tab based on query params
     */
    handleDefaultTab() {
      if (this.$route.query.activeTab) {
        this.activeName = this.propertyTypeEnum[this.$route.query.activeTab];
        this.$router.replace({ name: "properties" });
      }
    },
    addProperties() {
      let self = this;
      if ((this.login.user_type_id == 1 && this.login.subscription_end_date == "") || (this.login.user_type_id == 3 && this.login.is_subscription_expired)) {
        this.$store.commit("setSubscriptionAlert", true);
      } else if (this.isUserVerified) {
        this.$store.commit("setVerificationAlert", true);
      } else {
        let payloadData = {
          type : 1
        }
        self.checkSubscriptionValidation(payloadData).then((response) => {
          if(response){
            self.$router.push({ name: "add-properties" });
          }
        })
      }

    },
    /**
     * call the list of added property api
     */
    getPropertiesList() {
      let self = this;
      var type = 1;
      if(self.login.user_type_id == 3) {
        type = 1;
      } else if(self.isUserOnlyTenant) {
        type = 3;
      } else {
        type = self.propertyType[self.activeName]
      }
      let data = {
        page: 1,
        size: 100000,
        data: {
          type: type
        },
      };
      self.tableData = [];
      self.$store.dispatch("listOfProperties", data).then((response) => {
        if (response.status == 200) {
          self.tableData = [];
          self.pushIntotableData(response);
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * handle the property status when updated
     */
    handleStatus() {
      this.getPropertiesList();
    },
    /**
     * update the tableData
     * @param {object} response details of propery list
     */
    pushIntotableData(response) {
      let self = this;
      response.data.results.forEach((v) => {
        if(v.income.length == 1) {
          v.income.unshift(0);
        }
        if(v.expense.length == 1) {
          v.expense.unshift(0);
        }
        let sumOfIncome = v.income.reduce((a, v) => a + v, 0);
        let sumOfExpense = v.expense.reduce((a, v) => a + v, 0);
        self.tableData.push({
          id: v.id,
          amount: "$" + v.rent_amount,
          address: v.address,
          status: self.propertyStatusOption[v.status_id],
          status_id: v.status_id,
          img: v.property_image,
          dateAdded: self.formatDateToShow(v.created_date),
          type: self.propertyTypeOption[v.type_id],
          rentDue: v.next_due_date ? self.formatDateToShow(v.next_due_date) : 'N/A',
          created_by: v.created_by,
          series: [
            {
              name: "Income",
              data: v.income,
            },
            {
              name: "Expense",
              data: v.expense,
            },
          ],
          totalExpense: sumOfExpense,
          totalIncome: sumOfIncome,
          canPayRent : v.can_pay_rent
        });
      });
    },
  },
};
</script>
