module.exports = {
  label: {
    sendMail: 'We have sent Link to registered Email id to add bank details',
    form: {      
      label: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        contactNumber: 'Contact Number',
      },
      validation: {
        firstName: 'first name',
        lastName: 'last name',
        email: 'email',
        contactNumber: 'contact number',
      },
      placeholder: {
        enter: 'Enter',
        email: 'example@mail.com',
        enterNumber: 'Enter Number',
      }
    },
    buttons: {
      sendRequest: 'Send Request'
    }
  }
};