<!-- content from property_details.js -->
<template>
  <div>
    <b-container fluid class="property-media px-0">
      <b-row>
        <b-col lg="3" class="thumbnail-images">
          <div
            class="thumnail-image-div h-100 d-flex flex-md-wrap flex-md-column"
            :class="getMedia.length > 3 ? 'justify-content-between' : ''"
          >
            <template v-for="(thumb, indexThumb) in getMedia">
              <div
                class="thumnail-box"
                :key="indexThumb"
                v-if="indexThumb < 3"
                v-b-modal="'modal' + indexThumb"
              >
                <div class="thumb-box-inner">
                  <!-- v-b-modal="'modal' + thumb.id" -->
                  <template v-if="thumb.type == 'video'">
                    <video class="video" controls>
                      <source :src="thumb.src" type="video/mp4" />
                    </video>
                    <div class="videocam-icon">
                      <span class="material-icons">videocam</span>
                    </div>
                  </template>
                  <template v-else>
                    <b-img
                      thumbnail
                      fluid
                      :src="thumb.src"
                      :alt="thumb.type"
                    ></b-img>
                  </template>

                  <!-- <template v-if="thumb.type == 'video'">
                  <div class="videocam-icon">
                    <span class="material-icons">videocam</span>
                  </div>
                </template> -->

                  <div class="more-media" v-if="indexThumb == 2">
                    <div class="w-100">
                      <h4 class="more-file-count">
                        +{{ getMedia.length - 3 }}
                      </h4>
                      <span>{{ lang.label.photosVideos }}</span>
                    </div>
                  </div>
                </div>

                <b-modal
                  :id="'modal' + indexThumb"
                  hide-footer
                  modal-class="property-modal"
                >
                  <template #modal-header="{ close }">
                    <b-btn variant="link" @click="close()">
                      <span class="material-icons">close</span>
                    </b-btn>
                  </template>
                  <b-carousel
                    id="carousel-2"
                    :value="indexThumb"
                    :interval="0"
                    controls
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
                  >
                    <b-carousel-slide
                      v-for="(media, index) in getMedia"
                      :key="index"
                    >
                      <template v-if="media.type == 'video'">
                        <video class="video" controls>
                          <source :src="media.src" type="video/mp4" />
                        </video>
                      </template>
                      <template v-else>
                        <img
                          class="slider-image"
                          :src="media.src"
                          :alt="media.type"
                        />
                      </template>
                    </b-carousel-slide>
                  </b-carousel>
                </b-modal>
              </div>
            </template>
          </div>
        </b-col>
        <b-col lg="9" class="slider-images">
          <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="0"
            controls
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide v-for="(media, index) in getMedia" :key="index">
              <template v-if="media.type == 'video'">
                <video class="video" controls>
                  <source :src="media.src" type="video/mp4" />
                </video>
              </template>
              <template v-else>
                <img class="slider-image" :src="media.src" :alt="media.type" />
              </template>
            </b-carousel-slide>
          </b-carousel>
        </b-col>
      </b-row>
    </b-container>
    <div class="mt-4">
      <p
        class="mb-0"
        v-if="!readMore"
        v-html="description.replace(/\n/g, '<br />').slice(0, 300)"
      ></p>
      <b-btn
        variant="link"
        size="sm"
        class="p-0 text-underline mh-auto text-uppercase"
        v-if="!readMore && description.length > 300"
        
        v-b-modal.moreDescription
        >{{ lang.label.readMore }}</b-btn
      >
      <p v-if="readMore" v-html="description.replace(/\n/g, '<br />')"></p>

      <b-modal
        id="moreDescription"
        hide-header
        scrollable 
        size="lg"
        button-size="sm"
        modal-class="description-modal"
      >
      <template #modal-footer="{ close }">
          <b-button variant="danger" @click="close()" size="sm">Close</b-button>
        </template>
        <div class="d-block text-left">
          <p>{{ description }}</p>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import jsondata from "@/assets/json/data.json";
import { mapGetters } from "vuex";
export default {
  props: {
    description: {
      type: String,
      default: "",
    },
    propertyMedia: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {},
  computed: {
    ...mapGetters({
      ImageUrl: "ImageUrl",
    }),
    /**
     * @returns Property media with proper extention type
     */
    getMedia() {
      let self = this;
      let mediaArr = [];
      self.propertyMedia.forEach((media) => {
        let ext = media.file.split(".")[media.file.split(".").length - 1];
        mediaArr.push({
          id: media.id,
          src: self.ImageUrl + media.file,
          type: self.mediaExt.video.includes(ext) ? "video" : "image",
        });
      });
      return mediaArr;
    },
  },
  data() {
    return {
      lang: this.$lang.property_details,
      data: jsondata,
      productDecriprion:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      readMore: false,
      slide: 0,
      sliding: null,
      slideModal: 0,
      mediaExt: {
        video: ["mkv", "mp4"],
        image: ["jpg", "png", "jpeg"],
      },
    };
  },

  mounted() {},

  methods: {
    getMediaImg(media) {
      return require("@/assets/styles/img/properties/" + media);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    readMoreShow() {
      this.readMore = true;
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>
