<!-- content from add_properties.js -->
<template>
  <!-- amenities sidebar -->
  <b-sidebar
    id="amenitiesSelection"
    footer-class="sidebar-footer"
    sidebar-class="sidebar-view"
    backdrop
    right
    width="745px"
    @change="updateSidebar"
    :visible="isVisible"
  >
    <template #header="{ hide }">
      <div>
        <div class="d-flex align-items-center">
          <b-btn variant="link" size="sm" class="p-0" @click="hide">
            <img :src="getIconUrl('close-sm.svg')" alt="close" />
          </b-btn>
          <h4 class="sidebar-header-title mb-0">{{ lang.label.addAmenities }}</h4>
        </div>
      </div>
      <div class="amenities-search">
        <div class="position-relative">
          <b-form-input
            v-model="searchAmenities"
            type="text"
            :placeholder="lang.label.search"
          ></b-form-input>
        </div>
      </div>
    </template>
    <div class="amenity-body">
      <div class="amenity-box">
        <div class="row">
          <div
            class="col-4 col-md-3 col-lg-3 text-center"
            v-for="(amenityAdd, indexA) in amenitiesListing"
            :key="indexA"
          >
            <b-form-checkbox
              v-model="selectAmenities"
              name="check-amenity"
              :value="amenityAdd"
              button
              @click="selectedAm()"
            >
              <b-media
                class="flex-column justify-content-center align-items-center text-center"
              >
                <template #aside>
                  <img
                    :src="getAmenityIcon(amenityAdd.image)"
                    :alt="amenityAdd.amenity"
                  />
                </template>
                <p class="amenity-text mb-0">{{ amenityAdd.amenity }}</p>
              </b-media>
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </div>
    <template #footer="{}">
      <div class="d-flex w-100">
        <div class="chips">
          <b-badge
            pill
            variant="secondary"
            class="chip-items size-small"
            v-for="(amenity, index) in selectAmenities"
            :key="index"
          >
            <div class="chip-item">
              <div class="chip-inner">
                <span>{{ amenity.amenity }}</span>
              </div>
              <b-btn variant="link" class="p-0 text-grey" @click="removeChip(index)">
                <span class="material-icons">close</span>
              </b-btn>
            </div>
          </b-badge>
        </div>
        <div class="ml-auto">
          <b-button variant="primary" size="sm" class="btn-mw" @click="Add">{{
            lang.label.buttons.addAmenitiesBtn
          }}</b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import { mapGetters } from "vuex";
export default {
  props: ["selectedAmenities"],
  data() {
    return {
      lang: this.$lang.add_properties,
      data: jsondata,
      searchAmenities: "",
      selectAmenities: [],
      amenitiesData: [],
      isVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      ImageUrl: "ImageUrl",
    }),
    /**
     * Returns amenity list based on search string
     */
    amenitiesListing() {
      return this.amenitiesData.filter((card) => {
        return card.amenity.toLowerCase().includes(this.searchAmenities);
      });
    },
  },
  watch: {
    isVisible(val) {
      if (val) {
        this.listAmenities();
      }
    },
  },
  mounted() {
    this.listAmenities();
  },
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getAmenityIcon(amenity) {
      return this.ImageUrl + amenity;
    },
    removeChip(index) {
      this.selectAmenities.splice(index, 1);
    },
    /**
     * Handles add amenities event and sends selected amenities to parent
     */
    Add() {
      this.updateSidebar(false);
      this.$emit("handleAmenities", this.selectAmenities);
    },
    /**
     * Handles visibility of update sidebar form
     * @param {boolean} val Sidebar visibility value
     */
    updateSidebar(val) {
      this.isVisible = val;
      const body = document.getElementsByTagName('body')[0];
      if(val){
        body.classList.add("overflow-hidden")
      } else {
        body.classList.remove("overflow-hidden")
      }
    },
    /**
     * Fetches amenities list
     */
    listAmenities() {
      let self = this;
      self.amenitiesData = [];
      self.$store.dispatch("listAmenities").then((response) => {
        if (response.status == 200) {
          self.amenitiesData = response.data;
          /**
           * Changes selected amenities value based on current selected values
           */
          self.selectAmenities = self.selectedAmenities;
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
  },
};
</script>
