<!-- content from my_contacts.js -->
<template>
    <div class="contact-note">
        <div class="note" v-for="(note, index) in notes" :key="index">
            <div class="d-flex justify-content-between">
                <p class="time sf400">{{ note.date }} , {{ note.time }}</p>
                <div v-if="!note.show">
                    <el-tooltip :content="lang.label.tooltip.editNote" placement="bottom">
                        <b-btn variant="link" class="p-0">
                            <span class="material-icons mr-2" @click="editNote(index)">edit</span>
                        </b-btn>
                    </el-tooltip>
                    <el-tooltip :content="lang.label.tooltip.deleteNote" placement="bottom">
                        <b-btn variant="link" class="p-0" v-b-modal="'modal' + index">
                            <span class="material-icons" @click="deleteNote(index,note.id)">delete</span>
                        </b-btn>
                    </el-tooltip>
                </div>
            </div>

            <template v-if="note.show">
                <ValidationObserver ref="observer" v-slot="{ passes }">
                    <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
                        <b-form-group class="mb-0">
                            <ValidationProvider :name="lang.label.validation.note" rules="required|max:200"
                                v-slot="validationContext">
                                <div class="position-relative">
                                    <b-form-textarea v-model="noteModel" type="text"
                                        :state="getValidationState(validationContext)" placeholder="Write..."
                                        rows="5"></b-form-textarea>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </div>
                            </ValidationProvider>
                        </b-form-group>
                        <div class="text-right">
                            <b-btn variant="link" size="sm" type="submit">{{ lang.label.buttons.save }}</b-btn>
                            <b-btn variant="link" size="sm" @click="cancel(index)">{{ lang.label.buttons.cancel }}</b-btn>
                        </div>
                    </b-form>
                </ValidationObserver>
            </template>
            <template v-else>
                <p class="mb-0 sf400 w-5" style="word-wrap: break-word;">{{ note.note }}</p>
            </template>

            <!-- delete contact confirmation message -->
            <!-- <b-modal ref="deleteNote" :id="'modal' + index" hide-footer centered size="md"
                modal-class="close-btn-right">
                <template #modal-header="{ close }">
                    <b-button variant="link" class="close-btn" @click="close()">
                        <img :src="getIconUrl('close.svg')" alt="close" />
                    </b-button>
                </template>
                <div class="d-block text-center">
                    <h3 class="body-modal-heading">{{ lang.label.message.deleteNoteText }}</h3>
                </div>
                <div class="modal-buttons text-center modal-btn-responsive">
                    <b-button class="btn-mw" variant="primary" @click="deleteNote(index,note.id)">{{
                        lang.label.buttons.yes
                    }}</b-button>
                    <b-button class="btn-mw" variant="primary" @click="NoDeleteNote(index)">{{
                        lang.label.buttons.no
                    }}</b-button>
                </div>
            </b-modal> -->
        </div>
        <span v-if="notes.length == 0">{{ lang.label.noRecord }}</span>
    </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import mixin from "@/mixins/mixin";
import Swal from "sweetalert2";
import moment from "moment";
export default {
    props:['contactID','listCall'],
    data() {
        return {
            lang: this.$lang.my_contacts,
            data: jsondata,
            notes : [],
            noteModel : '',
            noteId:'',
            noteIndex: ''
        };
    },
    mixins: [mixin],
    mounted(){
        this.getListOfNotes();
    },
    methods: {
        getImgUrl(img) {
            return require("@/assets/styles/img/" + img);
        },
        getIconUrl(icon) {
            return require("@/assets/styles/img/icons/" + icon);
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        getListOfNotes(){
            let self = this;
            self.$store.dispatch('listOfContactNotes',self.contactID).then((response) =>{
                if(response.status == 200){
                    self.notes = []
                    response.data.forEach((v) =>{
                        let reverse_date = self.formatDateTimeReverse(v.updated_ts)
                        let testDateUtc = moment.utc(reverse_date);
                        let localDate = moment(testDateUtc).local();
                        localDate = localDate.format('DD/MM/YYYY HH:mm:ss').split('-').join('/');
                        let date = localDate.split(' ')[0];
                        let time = self.formatTime(localDate.split(' ')[1])
                        self.notes.push({
                            id : v.id,
                            date : date,
                            time : time,
                            note : v.note,
                            show : false
                        })
                    })
                    self.$emit('handleNotesLength',self.notes.length)
                }else{
                    self.$emit('handleNotes')
                    let message = response.data.message || response.data.detail;
                    if (message) {
                        self.$store.commit("setMessages", {
                        type: "danger",
                        message: message,
                        });
                    }
                }
            })
        },
        editNote(index) {
            let self = this;
            self.notes[index].show = true;
            self.noteModel = self.notes[index].note
            self.noteId = self.notes[index].id
            self.noteIndex = index
        },
        deleteNote(index,id) {
            let self = this
            Swal.fire({
                title: self.lang.label.confirmTitle,
                text: self.lang.label.confirmMsg,
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: self.lang.label.buttons.yes,
                cancelButtonText: self.lang.label.buttons.no,
                focusConfirm: false,
                buttonsStyling: false,
                customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-danger",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    self.notes.splice(index, 1);
                    self.$bvModal.hide('modal' + index);
                    self.$store.dispatch('deleteContactNotes',id).then((response) =>{
                        if(response.status == 200){
                            self.$emit('handleNotes')
                            self.$emit('handleNotesLength',self.notes.length)
                            let message = response.data.message
                            self.$store.commit("setMessages", {
                                type: "success",
                                message: message,
                                });
                        }else{
                            self.$emit('handleNotes')
                            let message = response.data.message || response.data.detail;
                            if (message) {
                                self.$store.commit("setMessages", {
                                type: "danger",
                                message: message,
                                });
                            }
                        }
                    })
                }
            });


        },
        NoDeleteNote(index) {
            this.$bvModal.hide('modal' + index);
        },
        onSubmit(){
            let self = this
            let data = {
                contact : self.contactID,
                note : self.noteModel
            }
            let notesDetails = {
                id : self.noteId,
                data : data
            }
            self.$store.dispatch('updateContactNotes',notesDetails).then((response) =>{
                if(response.status == 200){
                    self.getListOfNotes()
                }else{
                    self.$emit('handleNotes')
                    self.notes[self.noteIndex].show = false
                    let message = response.data.message || response.data.detail;
                    if (message) {
                        self.$store.commit("setMessages", {
                        type: "danger",
                        message: message,
                        });
                    }
                }
            })
        },
        cancel(index){
            let self = this;
            self.notes[index].show = false;
            self.noteModel = ''
            self.noteId = ''
        }
    },
    watch:{
        listCall(val){
            if(val){
                this.getListOfNotes();
                this.$emit('listCallInit',false)
            }
        }
    }
}
</script>
