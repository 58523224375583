<!-- content from support.js -->
<template>
    <div class="body-content">
        <div class="top-sticky">
            <div class="top-bar-radius"></div>
        </div>
        <div class="starts-section" v-loading="loading">
            <!-- body content header  -->
            <alert-section></alert-section>
            <div class="page-breadcrumb">
                <div class="d-md-flex justify-content-between align-items-center">
                    <div class="pg-bread">
                        <b-btn variant="link" class="p-0 mr-3 primary-text" @click="goBack()"
              ><span class="material-icons align-middle">arrow_back</span></b-btn>
                        <b-breadcrumb class="bg-transparent p-0 mb-0">
                            <b-breadcrumb-item active>
                                {{ lang.label.title }}
                            </b-breadcrumb-item>
                        </b-breadcrumb>
                    </div>
                </div>
            </div>
            <div class="pb-3">
                <b-row>
                    <b-col lg="7" xl="8" class="order-2 order-lg-1 mt-2 mt-lg-0">
                        <b-card no-body class="custom-card ">
                            <b-card-body>
                                <h5 class="sf400">{{ lang.label.faq }}</h5>
                                <div class="accordion faq-accordion" role="tablist">
                                    <b-card no-body class="bg-transparent no-shadow rounded-0 border-0"
                                        v-for="(faq, index) in faqList" :key="index">
                                        <b-card-header>
                                            <b-button block v-b-toggle="'faq' + index" variant="link">
                                                <div class="d-flex">
                                                    <span class="mr-1">{{ index + 1 }}.</span>
                                                    <span>{{ faq.question }}</span>
                                                    <div class="ml-auto">
                                                        <span class="when-open">
                                                            <span class="material-icons align-middle">arrow_drop_up</span>
                                                        </span>
                                                        <span class="when-closed">
                                                            <span class="material-icons align-middle">arrow_drop_down</span>
                                                        </span>
                                                    </div>
                                                </div>

                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'faq' + index" visible accordion="my-accordion">
                                            <b-card-body class="pt-0">
                                                <b-card-text>{{ faq.answer }}</b-card-text>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="5" xl="4" class="order-1 order-lg-2 mb-2 mb-lg-0">
                        <b-card no-body class="custom-card ">
                            <b-card-body>
                                <h5 class="sf400 text-lg-center">{{ lang.label.writeUs }}</h5>
                                <div class="post-faq">
                                    <ValidationObserver ref="observer" v-slot="{ passes }">
                                        <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
                                            <b-form-group>
                                                <ValidationProvider :name="lang.label.form.validation.queryReason"
                                                    rules="required" v-slot="validationContext">
                                                    <div class="position-relative">
                                                        <el-select v-model="models.selectReason"
                                                            :placeholder="lang.label.form.placeholder.selectQueryReason" :popper-append-to-body="false" popper-class="append-div"
                                                            :class="validationContext.errors[0] ? 'is-invalid' : ''">
                                                            <el-option v-for="item in reasonList" :key="item.id"
                                                                :label="item.reason" :value="item.id">
                                                            </el-option>
                                                        </el-select>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}
                                                        </b-form-invalid-feedback>
                                                    </div>
                                                </ValidationProvider>
                                            </b-form-group>
                                            <b-form-group>
                                                <ValidationProvider :name="lang.label.form.validation.description"
                                                    rules="required|max:500" v-slot="validationContext">
                                                    <div class="position-relative">
                                                        <b-form-textarea v-model="models.description" type="text"
                                                            :state="getValidationState(validationContext)"
                                                            :placeholder="lang.label.form.placeholder.description" rows="5"></b-form-textarea>

                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}
                                                        </b-form-invalid-feedback>
                                                    </div>
                                                </ValidationProvider>
                                            </b-form-group>
                                            <b-form-group class="mb-0">
                                                <b-btn variant="primary" block type="submit">{{ lang.label.buttons.submit }}</b-btn>
                                            </b-form-group>
                                        </b-form>
                                    </ValidationObserver>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>


            </div>


        </div>
    </div>
</template>

<script>

import jsondata from "@/assets/json/data.json";
import { mapGetters } from "vuex";
import alertSection from "@/views/shared/AlertSection.vue";

export default {
    // props: ["editData"],
    name: 'supportPage',
    computed: {
        ...mapGetters({
            loading: "loading",
        }),
    },
    components: {
        'alert-section' :alertSection,
    },
    data() {
        return {
            lang: this.$lang.support,
            models: {
                selectReason: '',
                description: ''
            },
            reasonList: [
                // { text: 'Lorem Ipsum is simply dummy text', value: 1 },
                // { text: 'Contrary to popular belief', value: 2 },
                // { text: 'Printing and typesetting industry?', value: 3 },
                // { text: 'It has roots', value: 4 },
            ],
            faqList:[]
        };
    },
    mounted() {
        this.getFAQList();
        this.getReasons()
    },
    methods: {
        getIconUrl(icon) {
            return require("@/assets/styles/img/icons/" + icon);
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onSubmit() {
          let self = this;
          let queryData = {
            reason : self.models.selectReason,
            description : self.models.description
          }
          self.$store.dispatch('addQuery',queryData).then((response) =>{
            if(response.status == 200 ){
                let message = response.data.message;
                if (message) {
                    self.$store.commit("setMessages", {
                        type: "success",
                        message: message,
                    });
                }
                self.clearForm()
            }else{
                let message = response.data.message || response.data.detail;
                if (message) {
                    self.$store.commit("setMessages", {
                    type: "danger",
                    message: message,
                    });
                }
            }
          })
        },
        getFAQList(){
            let self = this;
            self.$store.dispatch('listFAQs').then((response) =>{
                if(response.status == 200){
                    self.faqList = response.data
                }else{
                    let message = response.data.message || response.data.detail;
                    if (message) {
                        self.$store.commit("setMessages", {
                        type: "danger",
                        message: message,
                        });
                    }
                }
            })
        },
        goBack() {
            this.$router.go(-1);
        },
        getReasons(){
            let self = this
            self.$store.dispatch('getReportReason').then((response) =>{
               if(response.status == 200) {
                self.reasonList = response.data
               }else{
                let message = response.data.message || response.data.detail;
                if (message) {
                    self.$store.commit("setMessages", {
                    type: "danger",
                    message: message,
                    });
                }
               }
            });
        },
        clearForm(){
            this.models= {
                selectReason: '',
                description: ''
            }
            this.$refs['observer'].reset()
        }
    },
}
</script>

