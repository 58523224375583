<!-- content from forgot_password.js -->
<template>
  <div>
    <!-- view document modal -->
    <b-modal
      ref="viewCMS"
      id="viewCMS"
      hide-footer
      centered
      size="xl"
      modal-class="close-btn-right full-height-modal"
      scrollable
    >
      <template #modal-header="{ close }">
        <b-button variant="link" class="close-btn" @click="close()">
          <img :src="getIconUrl('close.svg')" alt="close" />
        </b-button>
      </template>
      <div class="d-block text-center h-100">
        <iframe
          :srcdoc="cmsContent1"
          width="100%"
          height="50%"
          title="viewCMSContent"
        ></iframe>
        <iframe
          :srcdoc="cmsContent2"
          width="100%"
          height="50%"
          title="viewCMSContent"
        ></iframe>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: ["cmsContent1", "cmsContent2"],
  computed: {
    ...mapGetters({}),
  },
  data() {
    return {
      lang: this.$lang.reset_password,
      models: {},
    };
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/styles/img/" + img);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
  },
};
</script>
<style scoped>
iframe {
  border-width: 0px;
}
</style>
