<!-- content from properties.js -->
<template>
  <!-- table section -->
  <div class="table-section">
    <div class="table-card table-border-none with-pagination-table">
      <b-card no-body class="custom-card reports-table">
        <b-card-body class="p-0 ">
          <v-client-table
            :data="tableData"
            :columns="columns"
            :options="options"
          >
            <template slot="address" slot-scope="props">
              <div class="property-address">
                <b-media no-body>
                  <b-media-aside vertical-align="center">
                    <div class="property-image">
                      <img :src="getPropertiesImg(props.row.img)" alt="" :class="!props.row.img ? 'placeholder-img' : ''"/>
                    </div>
                  </b-media-aside>
                  <b-media-body class="align-self-center">
                    <p class="mb-0 primary-text">{{ props.row.address }}</p>
                  </b-media-body>
                </b-media>
              </div>
            </template>
            <template slot="graph" slot-scope="props">
              <div class="line-graph">
                <apexChart
                  width="100%"
                  type="line"
                  height="100"
                  :options="chartOptions"
                  :series="props.row.series"
                ></apexChart>
                <div class="d-flex justify-content-between">
                  <div class="graph-line-one">
                    <span class="gr-line"></span>
                    <span>+{{ props.row.totalIncome }}<br/><small>{{ lang.label.income }}</small></span>
                  </div>
                  <div class="graph-line-two">
                    <span class="gr-line"></span>
                    <span>-{{ props.row.totalExpense }}<br/><small>{{ lang.label.expense }}</small></span>
                  </div>
                </div>
              </div>
            </template>
            <template slot="status" slot-scope="props">
              <div class="status-type">
                <p v-if="redableOnly || (login.user_type_id==3 && staffpermissions.length>0 && !staffpermissions.includes('update_property')) || (login.user_type_id==1 && props.row.created_by !== login.id)" class="mb-0">{{ props.row.status }}</p>
                <b-dropdown v-else
                  id="dropdown-form"
                  right
                  :ref="'dropdown' + props.row.id"
                  boundary="windows"
                  @show="onDropdownShow(props.row.status_id)"
                >
                  <template #button-content>
                    {{ props.row.status }}
                  </template>
                  <div>
                    <b-form-group class="mb-0" >
                      <b-form-radio-group
                        v-model="curentSelected"
                        :options="statusOption"
                        @change="onChange(props.row, $event)"
                        name="status"
                        buttons
                        stacked
                        class="status-list"
                      ></b-form-radio-group>
                    </b-form-group>
                  </div>
                </b-dropdown>
              </div>
            </template>
            <template slot="actions" slot-scope="props">
              <el-tooltip :content="lang.label.tooltip.view" placement="bottom">
                <b-btn
                  variant="link"
                  class="p-1"
                  @click="viewDetails(props.row.id)"
                >
                  <span class="material-icons">visibility</span>
                </b-btn>
              </el-tooltip>
              <el-tooltip :content="lang.label.tooltip.edit" placement="bottom">
                <b-btn variant="link" class="p-1" @click="onEdit(props.row.id)" v-if="(login.user_type_id==3 && staffpermissions.length>0 && staffpermissions.includes('update_property')) || (login.user_type_id==1 && props.row.created_by == login.id)">
                <span class="material-icons">edit</span>
              </b-btn>
              </el-tooltip>
              <el-tooltip :content="lang.label.tooltip.delete" placement="bottom">
                <b-btn variant="link" class="p-1" @click="onDelete(props.row.id)" v-if="(login.user_type_id==3 && staffpermissions.length>0 && staffpermissions.includes('delete_property')) || (login.user_type_id==1 && props.row.created_by == login.id)">
                <span class="material-icons">delete</span>
              </b-btn>
              </el-tooltip>
            </template>
          </v-client-table>
        </b-card-body>

        <!-- cancel property confirmation message -->
        <b-modal
          ref="deleteConfirmation"
          hide-footer
          centered
          size="md"
          modal-class="close-btn-right"
        >
          <template #modal-header="{ close }">
            <b-button variant="link" class="close-btn" @click="close()">
              <img :src="getIconUrl('close.svg')" alt="close" />
            </b-button>
          </template>
          <div class="d-block text-center">
            <h3 class="body-modal-heading">{{ lang.label.areYouSureDelete }}</h3>
            <p class="body-modal-content">
              {{ lang.label.noRevertBackProperty }}
            </p>
          </div>
          <div class="modal-buttons text-center modal-btn-responsive">
            <b-button
              class="btn-mw"
              variant="primary"
              @click="confirmDeleteProperty()"
              >{{ lang.label.buttons.yes }}</b-button
            >
            <b-button
              class="btn-mw"
              variant="danger"
              @click="noDeleteProperty()"
              >{{ lang.label.buttons.no }}</b-button
            >
          </div>
        </b-modal>
      </b-card>

    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { ClientTable } from "vue-tables-2";
import Swal from "sweetalert2";
Vue.use(ClientTable, {}, false, "bootstrap4");
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexChart", VueApexCharts);
import subscriptionValidation_mixin from '@/mixins/subscriptionValidation_mixin'


export default {
  name: "PropertiesTable",
  props: ["tableData",'redableOnly'],
  mixins: [subscriptionValidation_mixin],
  computed: {
    ...mapGetters({
      ImageUrl: "ImageUrl",
      login:"login",
      staffpermissions:"staffpermissions"
    }),
  },
  data() {
    return {
      lang: this.$lang.properties,
      curentSelected: "",
      // table
      columns: [
        "address",
        "graph",
        "amount",
        "rentDue",
        "type",
        "dateAdded",
        "status",
        "actions",
      ],
      options: {
        headings: {
          address: this.$lang.properties.label.tableHeader.address,
          graph: "",
          amount: this.$lang.properties.label.tableHeader.amount,
          rentDue: this.$lang.properties.label.tableHeader.rentDue,
          type: this.$lang.properties.label.tableHeader.type,
          dateAdded: this.$lang.properties.label.tableHeader.dateAdded,
          status: this.$lang.properties.label.tableHeader.status,
          actions: this.$lang.properties.label.tableHeader.actions,
        },
        filterable: true,
        skin: "table",
        perPage: 10,
        perPageValues: [],
        sortable: ["amount", "rentDue", "type", "dateAdded", "status"],
        columnsClasses: {
          address: "address-width",
          graph: "d-none d-md-table-cell ",
          actions: "action-width",
          amount: "d-none d-md-table-cell ",
          rentDue: "d-none d-md-table-cell ",
          type: "d-none d-md-table-cell ",
          dateAdded: "d-none d-md-table-cell ",
          status: "d-none d-md-table-cell ",
        },
        pagination: { chunk: 5 },
        texts: {
          noResults: this.$lang.root.label.noRecord,
          filter: this.$lang.root.label.filter,
          filterPlaceholder: this.$lang.root.label.filterPlaceholder,
          limit: this.$lang.root.label.limit,
          count: this.$lang.root.label.count,
        },
      },
      statusOption: [
        { text: this.$lang.add_properties.label.form.placeholder.available, value: 1 },
        { text: this.$lang.add_properties.label.form.placeholder.rented, value: 2 },
      ],
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        stroke: {
          width: [1.5, 1.5],
          curve: "straight",
          dashArray: [0, 0],
        },
        colors: ["#5734D6", "#295E73"],
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
          categories: [
            "01 Jan",
            "02 Jan",
            "03 Jan",
            "04 Jan",
            "05 Jan",
            "06 Jan",
            "07 Jan",
            "08 Jan",
            "09 Jan",
            "10 Jan",
            "11 Jan",
            "12 Jan",
          ],
          labels: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        grid: {
          show: true,
          borderColor: "#EAEAEA",
          strokeDashArray: 1,
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      id:''
    };
  },
  methods: {
    /**
     * get the property image 
     * @param {string} properties property image url
     * @returns property image url  
     */
    getPropertiesImg(properties) {
      if (!properties) {
        return require("@/assets/styles/img/properties/logo.svg");
      }
      return this.ImageUrl + properties;
    },

    /**
     * show alert mesasge while changing the property status
     * @param {object} data table row data
     * @param {number} val updated value of status
     */
    onChange(data, val) {
      let self = this;
      let statusText;
      let id = data.id;
      self.curentSelected = val;
      self.statusOption.forEach((v) => {
        if (v.value == val) {
          statusText = v.text;
        }
      });
      let alertText = self.lang.label.confirmMsg + " " + statusText;
      let alertTitle = self.lang.label.confirmTitle;
      // if status is changed to Rented
      if (val == 2) {
        alertTitle = self.lang.label.confirmWarning;
        alertText = self.lang.label.confirmAdd;
      }
      Swal.fire({
        title: alertTitle,
        text: alertText,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: self.lang.label.buttons.yes,
        cancelButtonText: self.lang.label.buttons.no,
        focusConfirm: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // if status is changed to Rented
          if (val == 2) {
            self.handleChangeStatus(id);
            return;
          }
          let statusData = {
            id: id,
            status: {
              status: val,
            },
          };
          self.updatePropertyStatus(statusData);
        } else {
          self.curentSelected = data.status_id;
        }
      });
    },
    /**
     * Handle the change property status with validation 
     * @param {number} id property Id
     */
    handleChangeStatus(id){
      let self = this;
      let payloadData = {
        type : 2
      }
      self.checkSubscriptionValidation(payloadData).then((response) => {
        if(response){
          self.$router.push({ name: "edit-properties", params: { id: id } });
        }
      })
    },
    /**
     * push to property detail page
     * @param {number} id tabledata row id
     */
    viewDetails(id) {
      this.$router.push({ name: "properties-details", params: { id: id } });
    },

    /**
     * initialize the initial status value
     * @param {number} item value of initial status
     */
    onDropdownShow(item) {
      this.curentSelected = "";
      this.curentSelected = item;
    },

    /**
     * push to edit page
     * @param {number} id row data id
     */
    onEdit(id) {
      if ((this.login.user_type_id == 1 && this.login.subscription_end_date == "") || (this.login.user_type_id == 3 && this.login.is_subscription_expired)) {
        this.$store.commit("setSubscriptionAlert", true);
      } else {
        this.$router.push({ name: "edit-properties", params: { id: id } });
      }
    },
    /**
     * @param {number} id row data id
     */
    onDelete(id){
      if ((this.login.user_type_id == 1 && this.login.subscription_end_date == "") || (this.login.user_type_id == 3 && this.login.is_subscription_expired)) {
        this.$store.commit("setSubscriptionAlert", true);
      } else {
        // delete code
        this.$refs["deleteConfirmation"].show();
        this.id=id;
      }
    },
    /**
     * call the api of update the property status
     * @param {object} statusData details of payload status data
     */
    updatePropertyStatus(statusData) {
      let self = this;
      self.$store
        .dispatch("updatePropertyStatus", statusData)
        .then((response) => {
          if (response.status == 200) {
            self.$emit("handleStatus");
            let message = response.data.message;
            self.$store.commit("setMessages", {
              type: "success",
              message: message,
            });
          } else {
            let message = response.data.message || response.data.detail;
            if (message) {
              self.$store.commit("setMessages", {
                type: "danger",
                message: message,
              });
            }
          }
        });
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    noDeleteProperty() {
      this.$refs["deleteConfirmation"].hide();
    },
    confirmDeleteProperty() {
      this.deleteProperty();
    },
    /**
     * Handles deleting property
     */
    deleteProperty() {
      let self = this;
      let id = self.id;
      self.$store.dispatch("deleteProperty", id).then((response) => {
        self.$refs["deleteConfirmation"].hide();
        if (response.status == 200) {
          self.$emit("handleStatus");
          setTimeout(() => {
            self.$store.commit("setMessages", {
              type: "success",
              message: response.data.message,
            });
          }, 0);
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    }
  },
};
</script>

