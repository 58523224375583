import Vue from 'vue'
import store from '../store/index';
import VueRouter from 'vue-router'
import Login from '../views/auth/login'
import ForgotPassword from '../views/auth/ForgotPassword'
import ResetPassword from '../views/auth/ResetPassword'
import InviteUser from '../views/auth/InviteUser'
import SignUp from '../views/auth/SignUp'
import Dashboard from '../views/Dashboard'
import MyProperties from '../views/properties/MyProperties'
import AddProperties from '../views/properties/AddProperties'
import EditProperties from '../views/properties/EditProperties'
import PropertiesDetails from '../views/properties/PropertiesDetails'
import PublicPropertiesDetails from '../views/properties/PublicPropertiesDetails'
import MyContacts from '../views/contacts/MyContacts'
import MyContactsDetail from '../views/contacts/MyContactsDetail'
import AddBankDetails from '../views/AddBankDetails'
import Reports from '../views/ReportsMain'
import StaffManagement from '../views/StaffManagement'
import Support from '../views/Support'
import MyAccount from '../views/MyAccount'
import ChangePassword from '../views/ChangePassword'
import NotFound from '../views/NotFound'
import Messages from '../views/Messages'
import TenantRentedProperties from '../views/tenant/RentedProperties'
import TenantPropertyDetailsBackup from '../views/tenant/PropertiesDetails'
import TenantPropertyDetails from '../views/properties/TenantPropertyDetails'
import RolesPermissionsList from '../views/roles/List'
import Notifications from '../views/Notifications'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null) {
        if (store.getters.isUserOnlyTenant) {
          next('/properties')
        } else{
          next('/dashboard')
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null) {
        next('/dashboard')
      } else {
        next()
      }
    }
  },
  {
    path: '/invite-user/:id',
    name: 'invite-user',
    component: InviteUser,
    meta: {
      listRoute: 'login'
    },
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null) {
        next('/dashboard')
      } else {
        next()
      }
    }
  },
  {
    path: '/reset-password/:id',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      listRoute: 'login'
    },
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null) {
        next('/dashboard')
      } else {
        next()
      }
    }
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: SignUp,
    meta: {
      listRoute: 'dashboard'
    },
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null) {
        next('/dashboard')
      } else {
        next()
      }
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null) {
        if (store.getters.isUserOnlyTenant) {
          next('/properties')
        } else {
          next()
        }
      } else {
        next('/')
      }
    }
  },
  {
    path: '/properties',
    name: 'properties',
    component: MyProperties,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null && loginObj.user_type_id == 3 && store.getters.staffpermissions && store.getters.staffpermissions.length > 0 && !store.getters.staffpermissions.includes('view_property')) {
        next('/dashboard')
      }
      else {
        if (loginObj && loginObj.token != null) {
          next()
        } else {
          next('/')
        }
      }
    }
  },
  {
    path: '/properties/add',
    name: 'add-properties',
    component: AddProperties,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && store.getters.isUserOnlyTenant) {
        next('/properties')
      }
      if (loginObj && loginObj.token != null && loginObj.user_type_id == 3) {
        next('/dashboard')
      }
      else {
        if (loginObj && loginObj.token != null) {
          if (store.getters.isUserVerified) {
            next('/dashboard')
            setTimeout(() => {
              store.commit("setVerificationAlert", true);
            }, 500);
          } else {
            next()
          }
        } else {
          next('/')
        }
      }
    }
  },
  {
    path: '/properties/edit/:id',
    name: 'edit-properties',
    component: EditProperties,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null) {
        if (store.getters.isUserVerified) {
          next('/dashboard')
          setTimeout(() => {
            store.commit("setVerificationAlert", true);
          }, 500);
        } else {
          next()
        }
      } else {
        next('/')
      }
    }
  },
  {
    path: '/properties/details/:id',
    name: 'properties-details',
    component: PropertiesDetails,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null) {
        if (store.getters.isUserOnlyTenant) {
          next('/properties')
        } else {
          next()
        }
      } else {
        next('/')
      }
    }
  },
  {
    path: '/rented-properties/details/:id',
    name: 'rented-properties-details',
    component: TenantPropertyDetails,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/public/properties/details/:id',
    name: 'public-properties-details',
    component: PublicPropertiesDetails,
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: MyContacts,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null) {
        if (store.getters.isUserOnlyTenant) {
          next('/properties')
        }
        else if ((loginObj.user_type_id == 1 && loginObj.subscription_end_date == "") || (loginObj.user_type_id == 3 && loginObj.is_subscription_expired)) {
          store.commit("setSubscriptionAlert", true);
        } else {
          next()
        }
      } else {
        next('/')
      }
    }
  },
  {
    path: '/contacts/detail/:id',
    name: 'contacts-detail',
    component: MyContactsDetail,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null) {
        if (store.getters.isUserOnlyTenant) {
          next('/properties')
        } else {
          next()
        }
      } else {
        next('/')
      }
    }
  },
  {
    path: '/add-bank-details',
    name: 'add-bank-details',
    component: AddBankDetails,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null && loginObj.user_type_id == 3 && store.getters.staffpermissions && store.getters.staffpermissions.length > 0 && !store.getters.staffpermissions.includes('view_payment_report')) {
        next('/dashboard')
      }
      else {
        if (loginObj && loginObj.token != null) {
          next()
        } else {
          next('/')
        }
      }
    }
  },
  {
    path: '/roles-permissions',
    name: 'roles-permissions',
    component: RolesPermissionsList,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (store.getters.isUserOnlyTenant) {
        next('/properties')
      }
      if (loginObj && loginObj.token != null && loginObj.user_type_id == 3) {
        next('/dashboard')
      }
      else {
        if (loginObj && loginObj.token != null) {
          if ((loginObj.user_type_id == 1 && loginObj.subscription_end_date == "") || (loginObj.user_type_id == 3 && loginObj.is_subscription_expired)) {
            store.commit("setSubscriptionAlert", true);
          } else {
            next()
          }
        } else {
          next('/')
        }
      }
    }
  },
  {
    path: '/staff-management',
    name: 'staff-management',
    component: StaffManagement,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (store.getters.isUserOnlyTenant) {
        next('/properties')
      }
      if (loginObj && loginObj.token != null && loginObj.user_type_id == 3) {
        next('/dashboard')
      }
      else {
        if (loginObj && loginObj.token != null) {
          if ((loginObj.user_type_id == 1 && loginObj.subscription_end_date == "") || (loginObj.user_type_id == 3 && loginObj.is_subscription_expired)) {
            store.commit("setSubscriptionAlert", true);
          } else {
            next()
          }
        } else {
          next('/')
        }
      }
    }
  },
  {
    path: "/request-bank-detail/:token",
    name: "request-bank-detail",
    component: AddBankDetails,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/support',
    name: 'support',
    component: Support,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/my-account/:id',
    name: 'my-account',
    component: MyAccount,
    beforeEnter: (to, from, next) => {
      let loginObj = store.getters.login;
      if (loginObj && loginObj.token != null) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePassword,
    meta: {
      listRoute: 'login'
    },
  },
  {
    path: '/messages',
    name: 'messages',
    component: Messages,
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
})

export default router
