module.exports = {
    label: {
      reportTitle: "Reporte",    
      selectProperty: 'Seleccionar Propiedad',
      dateFilter: 'Filtro De Fecha',
      from: 'Desde',
      to: 'Hasta',
      selectBillingType: 'Seleccionar El Tipo De Facturación',
      warning: 'Advertencia',
      propertySelectionAlert: 'Seleccione la propiedad primerot',
      expense: 'Gastos',
      income: 'Ingresos',
      tableHeader: {
        address: "Dirección De La Propiedad",
        name: "Nombre Del Inquilino",
        ownerName: "Propietario",
        leaseDuration: "Duración De Arrendamiento ",
        paidDateTime: "Fecha Pagada",
        billingType: "Tipo De Facturación",
        amount: "Cantidad",
        expenseName: "Nombre Del Gasto",
        miscellaneous: "Misceláneos",
        type: "Tipo De Propiedad",
        actions: "Acciones",
      },
      buttons: {
        addExpense: 'Agregar Gastos',
        filter: 'Aplicar Filtro',
        clearFilter: 'Filtro Claro',
        emailReport: 'Informe Por Correo Electrónico',
        download: 'Descargar',
        ok: 'Aceptar',
      },
      tabs: {
        all: 'Todos',
        ownerBroker: 'Propietario/Agente Inmobiliario',
        broker: 'Agente Inmobiliario',
        tenant: 'Inquilino',
        owner: "Propietario",
        other: 'Otros'
      },
    }
  };