<!-- content from add_card.js -->
<template>
  <div>
    <!-- alert component -->
    <alert-section currentModule="add-card-details"></alert-section>
    <ValidationObserver ref="observerCard" v-slot="{ passes }">
      <b-form @submit.stop.prevent="passes(onSubmitCard)" @submit="onSubmitCardBeforeaVlidation" autocomplete="off">
        <b-container fluid class="px-0">
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <label class="form-label"
                  >{{ lang.label.form.label.firstName
                  }}<span class="text-danger">{{ lang.label.required }}</span></label
                >
                <ValidationProvider
                  :name="lang.label.form.validation.firstName"
                  rules="required|max:50"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-form-input
                      v-model="models.firstName"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.firstName"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label class="form-label"
                  >{{ lang.label.form.label.lastName
                  }}<span class="text-danger">{{ lang.label.required }}</span></label
                >
                <ValidationProvider
                  :name="lang.label.form.validation.lastName"
                  rules="required|max:50"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-form-input
                      v-model="models.lastName"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.lastName"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <!-- <b-col cols="12">
                            <b-form-group>
                                <label class="form-label">{{ lang.label.form.label.email }}<span class="text-danger">{{ lang.label.required }}</span></label>
                                <ValidationProvider :name="lang.label.form.validation.email" rules="required|email"
                                    v-slot="validationContext">
                                    <div class="position-relative">
                                        <b-form-input v-model="models.email" type="text" class="text-lowercase"
                                            :state="getValidationState(validationContext)" :placeholder="lang.label.form.placeholder.email"></b-form-input>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </div>
                                </ValidationProvider>
                            </b-form-group>
                        </b-col> -->
            <!-- <b-col cols="12">
                            <b-form-group>
                                <label class="form-label">{{ lang.label.form.label.contactNumber }}</label>
                                <ValidationProvider :name="lang.label.form.validation.contactNumber" rules="required|numeric|min:6|max:8" v-slot="validationContext">
                                    <div class="position-relative  prepend-icon prepend-text country-code">
                                        <b-form-input v-model="models.contactNumber" type="text" :state="getValidationState(validationContext)" :placeholder="lang.label.form.placeholder.contactNumber"></b-form-input>
                                        <div class="icon">
                                                <div class="pre-app-text primary-text bf-size">+507</div>
                                            </div>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </div>
                                </ValidationProvider>
                            </b-form-group>
                        </b-col> -->
            <!-- <b-col cols="12">
              <b-form-group>
                <label class="form-label"
                  >{{ lang.label.form.label.nameOnCard
                  }}<span class="text-danger">{{ lang.label.required }}</span></label
                >
                <ValidationProvider
                  :name="lang.label.form.validation.nameOnCard"
                  rules="required"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-form-input
                      v-model="models.nameOnCard"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.nameOnCard"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col> -->

            <b-col cols="12">
              <b-row>
                <b-col
                  cols="12"
                  v-loading="loadingRelative"
                ></b-col>
                <div v-show="cardDetails">
                  <b-col cols="12">
                    <b-form-group>
                      <label class="form-label input-field"
                        >{{ lang.label.form.label.cardNumber
                        }}<span class="text-danger">{{
                          lang.label.required
                        }}</span></label
                      >
                      <div id="demoCcnumber"></div>
                      <!--<ValidationProvider :name="lang.label.form.validation.cardNumber" rules="required|numeric"
                                    v-slot="validationContext">
                                    <div class="position-relative append-icon">
                                        <b-form-input v-model="models.cardNumber" type="text" id="ccnumber"
                                            :state="getValidationState(validationContext)" :placeholder="lang.label.form.placeholder.cardNumber"></b-form-input>
                                            <div class="icon">
                                                <div class="pre-app-text cursor-pointer">
                                                    <img :src="getIconUrl('card.svg')" alt="">
                                                </div>
                                            </div>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </div>
                                </ValidationProvider>-->
                      <b-form-invalid-feedback style="display: block !important" v-if="nmi_error.ccnumber != ''"
                        >{{ nmi_error.ccnumber }}
                      </b-form-invalid-feedback>          
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-row>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="form-label input-field"
                            >{{ lang.label.form.label.expiryDate
                            }}<span class="text-danger">{{
                              lang.label.required
                            }}</span></label>
                          <div id="demoCcexp"></div>
                          <b-form-invalid-feedback style="display: block !important" v-if="nmi_error.ccexp != ''"
                              >{{ nmi_error.ccexp }}
                          </b-form-invalid-feedback>          
                    </b-form-group>
                          <!--<ValidationProvider :name="lang.label.form.validation.expiryDate" rules="required"
                                        v-slot="validationContext">
                                        <div class="position-relative">
                                            <b-form-input v-model="models.expiryDate" type="text"
                                                :state="getValidationState(validationContext)" :placeholder="lang.label.form.placeholder.expiryDate"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </div>
                                    </ValidationProvider>-->
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="form-label input-field"
                            >{{ lang.label.form.label.cvv
                            }}<span class="text-danger">{{
                              lang.label.required
                            }}</span></label
                          >
                          <div id="demoCvv"></div>
                          <b-form-invalid-feedback style="display: block !important" v-if="nmi_error.cvv != ''"
                              >{{ nmi_error.cvv }}
                          </b-form-invalid-feedback>
                          <!--<ValidationProvider :name="lang.label.form.validation.cvv" rules="required|numeric|digits:3"
                                        v-slot="validationContext">
                                        <div class="position-relative">
                                            <b-form-input v-model="models.cvv" type="text"
                                                :state="getValidationState(validationContext)" :placeholder="lang.label.form.placeholder.cvv"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </div>
                                    </ValidationProvider>-->
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </div>
              </b-row>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label class="form-label"
                  >{{ lang.label.form.label.address
                  }}<span class="text-danger">{{ lang.label.required }}</span></label
                >
                <ValidationProvider
                  :name="lang.label.form.validation.address"
                  rules="required|max:200"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-form-input
                      v-model="models.address"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.address"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group>
                <label class="form-label"
                  >{{ lang.label.form.label.country
                  }}<span class="text-danger">{{ lang.label.required }}</span></label
                >
                <ValidationProvider
                  :name="lang.label.form.validation.country"
                  rules="required"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <el-select
                      v-model="models.country"
                      @change="stateList"
                      :class="{ 'is-invalid': validationContext.errors[0] }"
                    >
                      <el-option
                        v-for="item in countries"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group>
                <label class="form-label"
                  >{{ lang.label.form.label.state
                  }}<span class="text-danger">{{ lang.label.required }}</span></label
                >
                <ValidationProvider
                  :name="lang.label.form.validation.state"
                  rules="required"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <el-select
                      v-model="models.state"
                      :class="{ 'is-invalid': validationContext.errors[0] }"
                    >
                      <el-option
                        v-for="item in states"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group>
                <label class="form-label"
                  >{{ lang.label.form.label.city
                  }}<span class="text-danger">{{ lang.label.required }}</span></label
                >
                <ValidationProvider
                  :name="lang.label.form.validation.city"
                  rules="required|max:50"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-form-input
                      v-model="models.city"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.city"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group>
                <label class="form-label">{{ lang.label.form.label.zipCode }}</label>
                <ValidationProvider
                  :name="lang.label.form.validation.zipCode"
                  rules="numeric|min:4|max:6"
                  v-slot="validationContext"
                >
                  <div class="position-relative">
                    <b-form-input
                      v-model="models.zipCode"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="lang.label.form.placeholder.zipCode"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >{{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
        <div>
          <b-btn variant="primary" id="PayButton1" block type="submit">{{
            lang.label.buttons.add
          }}</b-btn>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<style>
#demoCcnumber > input {
  height: 20px;
  width: 10px;
}
</style>
<script>
import jsondata from "@/assets/json/data.json";
import { mapGetters } from "vuex";
import payment_mixin from "@/mixins/payment_mixin";
import alertSection from "@/views/shared/AlertSection.vue";
export default {
  props: ["isVisible", "alertModulename"],
  components: {
    "alert-section": alertSection,
  },
  mixins: [payment_mixin],
  computed: {
    ...mapGetters({
      loadingRelative: "loadingRelative",
      cardDetails: "cardDetails",
    }),
  },
  data() {
    return {
      lang: this.$lang.add_card,
      data: jsondata,
      checkvalidate:false,
      models: {
        firstName: null,
        lastName: null,
        address: null,
        country: null,
        state: null,
        city: null,
        zipCode: null,
      },
      countries: [],
      states: [],
      nmi_error: {ccnumber: '', ccexp:'', cvv: ''},
      isValidationCallback: false,
      isValid: false
    };
  },
  watch: {
    isVisible() {
      this.resetForm();
    },
  },
  mounted() {
    this.countryList();
  },
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    /**
     * Handles resetting the form
     */
    resetForm() {
      this.models = {
        firstName: null,
        lastName: null,
        address: null,
        country: null,
        state: null,
        city: null,
        zipCode: null,
      };
      this.$refs.observerCard.reset();
    },
    onSubmitCard() {
      for (const errorfield in this.nmi_error) {
            if(this.nmi_error[errorfield] != "") {
              this.isValid = false;
              return;
            } else {
              this.isValid = true;
            }
      }
      if(this.isValid) {
          this.initializeRequestToken();
      }
    },
    onSubmitCardBeforeaVlidation() {
      this.$store.commit("setLoadingRelative", true);
      this.isValidationCallback = true;
      var frames = document.querySelectorAll("iframe.CollectJSInlineIframe")
        for (var i = 0; i < frames.length; i++) {
          if(this.isValidationCallback) {
            frames[i].focus();
            frames[i].blur();            
          }
      }
      this.$store.commit("setLoadingRelative", false);
    },
    /**
     * Handles submitting payment token and relivent info after successful payment token generation
     */
    handlePaymentToken() {
      let self = this;
      let data = {
        first_name: self.models.firstName,
        last_name: self.models.lastName,
        address: self.models.address,
        country: self.models.country,
        state: self.models.state,
        city: self.models.city,
        zip_code: self.models.zipCode,
        payment_token: self.token,
      };
      self.$store.dispatch("addCard", data).then((response) => {
        if (response.status == 200) {
          self.$emit('handleCardAdded', response.data.card_id);
          if (response.data.message) {
            self.$store.commit("setMessages", {
              type: "success",
              message: response.data.message,
              allow_module: self.alertModulename || "bank-details",
            });
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "add-card-details",
            });
          }
        }
      });
    },
    /**
     * Loads the country dropdown
     */
    countryList() {
      let self = this;
      self.$store.dispatch("listOfCountries").then(async (response) => {
        if (response.status == 200) {
          self.countries = await response.data;
          if (self.countries.length > 0) {
            self.models.country = self.countries[0].id;
            self.stateList(self.models.country);
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "add-bank-details",
            });
          }
        }
      });
    },
    /**
     * Event to load states when changed country from dropdown and nullify states dropdown
     * @param {number} id
     */
    stateList(id) {
      let self = this;
      self.models.state = "";
      self.countryChange(id);
    },
    /**
     * Event to load states when changed country from dropdown
     * @param {number} id
     */
    countryChange(id) {
      let self = this;
      let countryId = id;
      self.$store.dispatch("listOfStates", countryId).then(async (response) => {
        if (response.status == 200) {
          self.states = await response.data;
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "add-bank-details",
            });
          }
        }
      });
    },
  },
  beforeDestroy(){
    this.$store.commit("setCardDetails", false);
  }
};
</script>
