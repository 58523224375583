<!-- content from my_contacts.js -->
<template>
  <div class="body-content">
    <div class="top-sticky">
      <div class="top-bar-radius"></div>
    </div>
    <div class="starts-section" v-loading="loading">
      <!-- alert component -->
      <alert-section currentModule="ContactList"></alert-section>

      <!-- body content header  -->
      <div class="page-breadcrumb contacts-breadcrumbs">
        <div class="d-xl-flex justify-content-between align-items-center">
          <div class="pg-bread">
            <b-breadcrumb class="bg-transparent p-0 mb-0">
              <b-breadcrumb-item active>
                {{ lang.label.myContacts }}
              </b-breadcrumb-item>
              <b-breadcrumb-item active>
                <span class="material-icons align-middle">people</span>
                <span class="align-middle ml-1">{{
                  tableData.length > 0 ? tableData.length : ""
                }}</span>
              </b-breadcrumb-item>
            </b-breadcrumb>


            <!-- this button for mobile -->
            <!-- <div class="display-resp-button d-md-none">
              <b-btn
                variant="primary"
                size="sm"
                class="btn-mw"
                @click="addPropertyConfirmation()"
                >+ {{ lang.label.buttons.add }}</b-btn
              >
              <b-btn
                variant="primary"
                size="sm"
                class="btn-mw"
                @click="cancelPropertyConfirmation()"
                >{{ lang.label.buttons.cancel }}</b-btn
              >
            </div> -->


          </div>

          <div class="pg-right">
            <div class="d-flex align-items-center flex-wrap">
              <div class="text-nowrap property-status-label">
                {{ lang.label.dateFilter }}
              </div>
              <div class="date-range mr-md-2 mb-3 mb-md-0">
                <el-date-picker
                  v-model="dateRangeSelected"
                  type="daterange"
                  range-separator="-"
                  :start-placeholder="lang.label.from"
                  :end-placeholder="lang.label.to"
                  size="sm"
                  :append-to-body="false"
                >
                </el-date-picker>
              </div>
              <b-btn variant="primary" size="sm" @click="handleFilter">{{
                lang.label.buttons.filter
              }}</b-btn>
              <div class="separation-btn">
              <b-btn
                variant="primary"
                size="sm"
                class="text-nowrap btn-mw"
                @click="addNewContact"
                >+ {{ lang.label.buttons.add }}</b-btn
              >
              </div>


            </div>

            
          </div>
        </div>
      </div>
      <!-- <div class="d-md-flex justify-content-between align-items-center">
        <div></div>
        <div
          class="d-md-flex justify-content-between align-items-center flex-start filter-buttons"
        >
          <b-form-group>
            <div class="d-flex align-items-center mr-4">
              <label class="mr-2 mb-0 text-nowrap">{{ lang.label.dateFilter }}</label>
              <div class="date-range">
                <el-date-picker
                  v-model="dateRangeSelected"
                  type="daterange"
                  range-separator="-"
                  :start-placeholder="lang.label.from"
                  :end-placeholder="lang.label.to"
                  size="sm"
                  :append-to-body="false"
                  align="right"
                >
                </el-date-picker>
              </div>
            </div>
          </b-form-group>
          <b-form-group>
            <div class="d-md-flex justify-content-between">
              <b-btn variant="primary" size="sm" @click="handleFilter">{{
                lang.label.buttons.filter
              }}</b-btn>
            </div>
          </b-form-group>
        </div>
      </div> -->
      <hr class="mt-0">
      <div class="pb-3">
        <el-tabs v-model="activeName">
          <el-tab-pane :label="lang.label.tabs.all" name="all">
            <contacts-table
              :tableData="tableData"
              @handleContactDelete="handleContactDelete"
            ></contacts-table>
          </el-tab-pane>
          <el-tab-pane :label="lang.label.tabs.broker" name="broker">
            <contacts-table
              :tableData="tableData"
              @handleContactDelete="handleContactDelete"
            ></contacts-table>
          </el-tab-pane>
          <el-tab-pane :label="lang.label.tabs.tenant" name="tenant">
            <contacts-table
              :tableData="tableData"
              @handleContactDelete="handleContactDelete"
            ></contacts-table>
          </el-tab-pane>
          <el-tab-pane :label="lang.label.tabs.owner" name="owner">
            <contacts-table
              :tableData="tableData"
              @handleContactDelete="handleContactDelete"
            ></contacts-table>
          </el-tab-pane>
          <el-tab-pane :label="lang.label.tabs.other" name="other">
            <contacts-table
              :tableData="tableData"
              @handleContactDelete="handleContactDelete"
            ></contacts-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <!-- add new contact -->
    <b-sidebar
      id="addNewContact"
      footer-class="sidebar-footer"
      sidebar-class="sidebar-view"
      text-variant="none"
      backdrop
      right
      width="462px"
      @change="updateSidebar"
      :visible="isVisible"
    >
      <template #header="{ hide }">
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex align-items-center">
            <b-btn variant="link" size="sm" class="p-0" @click="hide">
              <img :src="getIconUrl('close-sm.svg')" alt="close" />
            </b-btn>
            <h4 class="sidebar-header-title mb-0">{{ lang.label.addNewContact }}</h4>
          </div>
        </div>
      </template>
      <div class="amenity-body">
        <add-contact
          v-if="isVisible"
          @handleContatctValidate="handleContatctValidate" :isEdit="false"
        ></add-contact>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import Vue from "vue";
import jsondata from "@/assets/json/data.json";
import { ClientTable } from "vue-tables-2";
Vue.use(ClientTable, {}, false, "bootstrap4");

import AddContactForm from "@/components/contacts/AddForm.vue";
import ContactsTable from "./lists/ContactsTable.vue";
import BrokerList from "./lists/BrokerList.vue";
import TenantList from "./lists/TenantList.vue";
import OtherList from "./lists/OtherList.vue";
import alertSection from "@/views/shared/AlertSection.vue";
import mixin from "@/mixins/mixin";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  components: {
    "add-contact": AddContactForm,
    "contacts-table": ContactsTable,
    broker: BrokerList,
    tenant: TenantList,
    "other": OtherList,
    "alert-section": alertSection,
  },
  mixins: [mixin],
  computed: {
    ...mapGetters({
      loading: "loading",
      isUserVerified: "isUserVerified",
    }),
  },
  data() {
    return {
      lang: this.$lang.my_contacts,
      data: jsondata,
      activeName: "all",
      dateRangeSelected: "",
      tableData: [],
      contactType: {
        all: 1,
        owner: 2,
        broker: 3,
        tenant: 4,
        other: 5,
      },
      isVisible: false,
      startDate: null,
      endDate: null,
      rollName: {
        1: this.$lang.my_contacts.label.tabs.broker,
        2: this.$lang.my_contacts.label.tabs.owner,
        3: this.$lang.my_contacts.label.tabs.tenant,
        4:this.$lang.my_contacts.label.tabs.other
      },
    };
  },
  watch: {
    activeName() {
      this.listContacts();
    },
  },
  mounted() {
    this.listContacts();
  },
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    viewContact() {
      this.$router.push({ name: "contacts-detail" });
    },
    /**
     * Handles loading tabale data based on active tab and applied date filter
     * @param {String} start_date Range start date
     * @param {String} end_date Range end date
     */
    listContacts(start_date, end_date) {
      let self = this;
      let data = {
        page: 1,
        size: 100000,
        data: {
          type: self.contactType[self.activeName],
          search: null,
          start_date: self.startDate,
          end_date: self.endDate,
        },
      };
      self.tableData = [];
      self.$store.dispatch("listContacts", data).then((response) => {
        if (response.status == 200) {
          self.tableData = [];
          response.data.results.forEach((el) => {
            self.tableData.push({
              id: el.id,
              name: el.first_name + " " + el.last_name,
              role: self.rollName[el.contact_type],
              emailID: el.email,
              contact: el.contact,
              dateAdded: self.formatDateToShow(el.created_date),
            });
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Handles date range filter for table data
     */
    handleFilter() {
      let self = this;
      self.startDate =
        self.dateRangeSelected && self.dateRangeSelected.length > 0
          ? self.formatDateReverse(self.dateRangeSelected[0])
          : null;
      self.endDate =
        self.dateRangeSelected && self.dateRangeSelected.length > 0
          ? self.formatDateReverse(self.dateRangeSelected[1])
          : null;
      self.listContacts();
    },
    /**
     * Loads new list when contact is deleted
     */
    handleContactDelete() {
      this.listContacts();
    },
    /**
     * Handles visibility of update sidebar form
     * @param {boolean} val Sidebar visibility value
     */
    updateSidebar(val) {
      this.isVisible = val;
      const body = document.getElementsByTagName('body')[0];
            if(val){
                body.classList.add("overflow-hidden")
            } else {
                body.classList.remove("overflow-hidden")
            }
    },
    /**
     * Handles add contact validation flow
     */
    handleContatctValidate(data) {
      let self = this;
      if (data.isStaff) {
        this.updateSidebar(false);
        Swal.fire({
          title: self.lang.label.warning,
          text: self.lang.label.staffWarningMsg,
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: self.lang.label.buttons.ok,
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        })
      } else if (data.showPopUp) {
        this.updateSidebar(false);
        Swal.fire({
          title: self.lang.label.confirmTitle,
          text: self.lang.label.confirmContactMsg,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: self.lang.label.buttons.yes,
          cancelButtonText: self.lang.label.buttons.no,
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            self.addContact(data);
          }
        });
      } else {
        self.addContact(data);
      }
    },
    /**
     * Handles add contact event
     */
    addNewContact() {
      if (this.isUserVerified) {
        this.$store.commit("setVerificationAlert", true);
      } else {
        this.updateSidebar(true);
      }
    },
    /**
     * Handles contact add operation
     * @param {object} data Contact data to be added
     */
    addContact(data) {
      let self = this;
      let isPopuUpShown = data.showPopUp;
      var notesApiCall=false;
      var noteContent="";
      if(data.noteApiCall){
        notesApiCall=true;
        noteContent=data.noteContent
        delete data.noteApiCall
        delete data.noteContent
      }
      delete data.showPopUp;
      self.$store.dispatch("addContact", data).then((response) => {
        if (response.status == 200) {
          self.updateSidebar(false);
          self.listContacts();
          
          if (response.data.message) {
            self.$store.commit("setMessages", {
              type: "success",
              message: response.data.message,
              allow_module: "ContactList"
            });
          }

          //notes api call if notes have been added
          if(notesApiCall){
            let data={
              contact:response.data.contact.id,
              note:noteContent
            }
            self.notesApiCall(data);
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: !isPopuUpShown ? "UpdateContact" : null,
            });
          }
        }
      });
    },
    /**
     * Handles notes add operation
     * @param {object} data Notes to be added while adding contact
     */
    notesApiCall(data){
      let self=this;
      self.$store.dispatch('addContactNotes',data).then((response) =>{
        if(response.status == 200){
          return true;
        }else{
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      })
    }
  },
};
</script>
