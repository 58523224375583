module.exports = {
  label: {
    verifyEmialAlert: "Verify your email to get full access to Miradoor, you may not be able to add a contact or properties.",
    propertiesPortfolio: 'Properties Portfolio',
    deleteMember: 'Are you sure you want to delete this expense?',
    expenseName: 'Expense Name',
    expenseAmount: 'Expense Amount',
    expenseDate: 'Expense Date',
    uploadProof: 'Upload Proof',
    confirmTitle: 'Are you sure?',
    confirmMsg: 'You want to change the status to',
    deleteConfirmMsg: "You want to delete this!",
    confirmAdd:'Please add tenant',
    confirmWarning:'Confirm?',
    expenseDelete:'Are you sure you want to delete this expense ?',
    expense:'Expense',
    income:'Income',
    ValidationTitle: 'Warning!',
    expenseDetails: "Expense Details",
    areYouSureDelete: 'Are you sure you want to delete this property?',
    noRevertBackProperty: '*You wan’t be able to revert back after deleting this property.',
    tableHeader: {
      address: "Property Address",
      amount: "Amount",
      rentDue: "Rent Due",
      type: "Property Type",
      dateAdded: "Date Added",
      status: "Status",
      actions: "Actions",
    },
    tableReportHeader: {
      name: "Tenant/Owner/Realtor",
      tenant: "Tenant",
      leaseDuration: "Lease Duration",
      paidDateTime: "Paid Date",
      billingType: "Billing Type",
      amount: "Amount",
      expenseName: "Income/Expense Name",
      actions: "Actions",
    },
    buttons: {
      sendCode: 'Send Verification Code',
      add: 'Add',
      update: 'Update',
      yes: 'Yes',
      no: 'No',
      ok:'Ok'
    },
    tabs: {
      all: 'All',
      broker: 'Realtor',
      tenant: 'Tenant',
      owner: "Owner",
      other: 'Other'
    },
    tooltip:{
      edit : 'Edit Property',
      view : 'View Property',
      delete:'Delete Property',
      expenseView:'View Expense',
      expenseDelete:'Delete Expense',
      miscellaneous: 'View Lease Document',
    }
  }
};