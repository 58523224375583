<!-- content from rented_properties.js -->
<template><!-- table section -->
    <div class="table-section vertical-top">
        <div class="table-card table-border-none with-pagination-table">
            <b-card no-body class="custom-card reports-table">
                <b-card-body class="p-0">
                    <v-client-table :data="tableData" :columns="columns" :options="options">
                        <template slot="number" slot-scope="props">
                            <span>{{ props.index }}</span>
                        </template>
                        <template slot="paymentMode" slot-scope="props">
                            <span>{{ props.row.paymentMode }}</span>
                            <span class="ml-1">Card</span>
                        </template>

                    </v-client-table>
                </b-card-body>
            </b-card>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import jsondata from "@/assets/json/data.json";
import { ClientTable } from "vue-tables-2";
Vue.use(ClientTable, {}, false, "bootstrap4");


export default {
    data() {
        return {
            lang: this.$lang.rented_properties,
            data: jsondata,
            // table
            columns: ['number', 'ownerName', 'rentBilledType', 'rentAmount', 'totalAmount', 'paidOn', 'paymentMode'],
            tableData: [
                {
                    id: 1,
                    ownerName: "Andy Robert",
                    rentBilledType: "Monthly",
                    rentAmount: "$2300",
                    totalAmount: '$2300',
                    paidOn: '06/01/2022, 12:00 pm',
                    paymentMode: "0042"
                },
                {
                    id: 2,
                    ownerName: "Andy Robert",
                    rentBilledType: "Monthly",
                    rentAmount: "$2300",
                    totalAmount: '$2300',
                    paidOn: '06/01/2022, 12:00 pm',
                    paymentMode: "0042"
                },
                {
                    id: 3,
                    ownerName: "Andy Robert",
                    rentBilledType: "Monthly",
                    rentAmount: "$2300",
                    totalAmount: '$2300',
                    paidOn: '06/01/2022, 12:00 pm',
                    paymentMode: "0042"
                },
                {
                    id: 4,
                    ownerName: "Andy Robert",
                    rentBilledType: "Monthly",
                    rentAmount: "$2300",
                    totalAmount: '$2300',
                    paidOn: '06/01/2022, 12:00 pm',
                    paymentMode: "0042"
                },
                {
                    id: 5,
                    ownerName: "Andy Robert",
                    rentBilledType: "Monthly",
                    rentAmount: "$2300",
                    totalAmount: '$2300',
                    paidOn: '06/01/2022, 12:00 pm',
                    paymentMode: "0042"
                },
                {
                    id: 6,
                    ownerName: "Andy Robert",
                    rentBilledType: "Monthly",
                    rentAmount: "$2300",
                    totalAmount: '$2300',
                    paidOn: '06/01/2022, 12:00 pm',
                    paymentMode: "0042"
                },
                {
                    id: 7,
                    ownerName: "Andy Robert",
                    rentBilledType: "Monthly",
                    rentAmount: "$2300",
                    totalAmount: '$2300',
                    paidOn: '06/01/2022, 12:00 pm',
                    paymentMode: "0042"
                },
                {
                    id: 8,
                    ownerName: "Andy Robert",
                    rentBilledType: "Monthly",
                    rentAmount: "$2300",
                    totalAmount: '$2300',
                    paidOn: '06/01/2022, 12:00 pm',
                    paymentMode: "0042"
                },
                {
                    id: 9,
                    ownerName: "Andy Robert",
                    rentBilledType: "Monthly",
                    rentAmount: "$2300",
                    totalAmount: '$2300',
                    paidOn: '06/01/2022, 12:00 pm',
                    paymentMode: "0042"
                },
                {
                    id: 10,
                    ownerName: "Andy Robert",
                    rentBilledType: "Monthly",
                    rentAmount: "$2300",
                    totalAmount: '$2300',
                    paidOn: '06/01/2022, 12:00 pm',
                    paymentMode: "0042"
                }
            ],
            options: {
                headings: {
                    number: "Sr. No.",
                    ownerName: "Owner name",
                    rentBilledType: "Rent Billed Type",
                    rentAmount: "Rent Amount/Month",
                    totalAmount: "Total Amount",
                    paidOn: "Paid On",
                    paymentMode: "Payment Mode"
                },
                skin: "table",
                perPageValues: [5, 10, 15],
                perPage: 5,
                sortable: ['paidOn'],
                columnsClasses: {
                    number: 'address-width',
                    ownerName: 'd-none d-md-table-cell ',
                    rentBilledType: 'd-none d-md-table-cell ',
                    rentAmount: 'd-none d-md-table-cell ',
                    totalAmount: 'd-none d-md-table-cell ',
                    
                    paymentMode: 'd-none d-md-table-cell ',
                },
                pagination: { chunk: 5 },
                texts: {
                    noResults: this.$lang.root.label.noRecord,
                    filter: this.$lang.root.label.filter,
                    filterPlaceholder: this.$lang.root.label.filterPlaceholder,
                    limit: this.$lang.root.label.limit,
                    count: this.$lang.root.label.count,
                },
            },
        };
    },
    mounted() {
    },
    methods: {
        getIconUrl(icon) {
            return require("@/assets/styles/img/icons/" + icon);
        },
        getImgUpload(uploaded) {
            return require("@/assets/styles/img/properties/" + uploaded);
        },
    },
}
</script>

