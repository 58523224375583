module.exports = {
  label: {
    title: "Mensajes",
    search: 'Buscar',
    form: {
      validation: {
        currentPassword: 'contraseña actual',
        newPassword: 'nueva contraseña',
        reEnterPassword: 're-ingrese nueva contraseña',
      },
      placeholder: {
        currentPassword: 'Introducir La Contraseña Actual',
        newPassword: 'Ingrese Nueva Clave',
        reEnterPassword: 'Re-ingrese Nueva Contraseña',
      }
    },
    writeHere: 'Escribe Aquí...',
    buttons: {
      update: 'Actualiza Contraseña',
      ok : 'Aceptar'
    },
    extensionValidation:'Sólo se permiten las extensiones .jpeg, .jpg, .png .pdf',
    documentSizeValidation:'No puede cargar un documento de tamaño mayor a 10MB',
    unreadMessages: "Mensajes No Leídos"
  }
};