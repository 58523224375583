<!-- content from messages.js -->
<template>
  <b-card no-body class="custom-card h-100">
    <template #header>
      <div>
        <b-form-input
          v-model="searchUser"
          type="text"
          :placeholder="lang.label.search"
        ></b-form-input>
      </div>
    </template>
    <b-card-body>
      <b-list-group class="user-list-chat">
        <b-list-group-item
          button
          class="d-flex justify-content-between align-items-center bg-transparent border-0 rounded-0"
          @click="selectedUser(user.user_id, index,user.id)"
          :active="index == activeClass"
          v-for="(user, index) in chatUsersListing"
          :key="index"
        >
          <b-media no-body>
            <b-media-aside vertical-align="center">
              <img
                :src="
                  user.profile_image != ''
                    ? ImageUrl + user.profile_image
                    : getImgUser('user.svg')
                "
                :alt="user.name"
              />
            </b-media-aside>
            <b-media-body class="align-self-center">
              <h6 class="contact-name mb-0">{{ user.name }}</h6>
            </b-media-body>
          </b-media>
            <span class="message-count" v-if="user.unread_count != 0 && index!=0">{{
              user.unread_count
            }}</span>
        </b-list-group-item>
      </b-list-group>
    </b-card-body>
  </b-card>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import { mapGetters } from "vuex";

export default {
  props: ["userMessage"],
  components: {},
  data() {
    return {
      lang: this.$lang.messages,
      data: jsondata,
      searchUser: "",
      activeClass: 0,
      chats: []    
    };
  },
  computed: {
    chatUsersListing() {
      if (this.searchUser) {
        var search = this.searchUser;
        return this.chats.filter((item, index) => {
          return  (item.name.toLowerCase().indexOf(search.toLowerCase()) != -1)
        })
      } else {
        return this.chats;
      }
    },
    ...mapGetters({
      ImageUrl: "ImageUrl",
    }),
  },
  mounted() {
    let self = this;
    self.loadChatList();
  },
  methods: {
    getImgUser(img) {
      return require("@/assets/styles/img/users/" + img);
    },
    /**
     * Selecting user from the chat list
     */
    selectedUser(id, index,roomID) {
      let self = this;
      let isCountAvailableOrNot = "";
      if (self.activeClass != index) {
        self.activeClass = index;
        self.$emit("setValues");
        self.$emit("messageView", id);
        self.$emit("roomId",roomID)
        self.$emit("unreadCount",self.chats[index].unread_count)
      }
      // disappearance of chat count if user has counts in the chat section
      isCountAvailableOrNot = self.chats[index].unread_count == 0;
      if (!isCountAvailableOrNot) {
        self.chats[index].unread_count = 0;
      }
    },
    /**
     * Loads the chat list
     */
    loadChatList() {
      let self = this;
      self.$store.dispatch("listContactsMessages").then((response) => {
        if (response.status == 200) {
          self.chats = response.data.rooms;
          if (self.chats && self.chats.length > 0) {
            // loads the messages of the first user from the chat list
            self.$emit("messageView", self.chats[0].user_id);
            self.$emit("roomId",self.chats[0].id);
            self.$emit("unreadCount",self.chats[0].unread_count)

            // message count total
            // let count=response.data.count;
            // let countSet=self.chats[0].unread_count>0?(--count):count;
            self.$emit("messageCountTotal", self.chats.length);
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
  },
};
</script>

