module.exports = {
  label: {
    title: "Support",
    faq: "FAQs",
    writeUs: 'Write to Us',
    form: {
      placeholder: {
        selectQueryReason: 'Select Query Reason',
        description: 'Description',
      },
      validation: {
        queryReason: 'query reason',
        description: 'description',
      }
    },    
    buttons: {
      submit: 'Submit'
    }
  }
};