<!-- content from subscription_plan.js -->
<template>
  <b-container fluid class="px-0 subscription-plan">
    <VueSlickCarousel v-bind="settings" v-if="planList.length">
      <b-card
        no-body
        class="card-with-border plan-card"
        :class="plan.show_high_lighted == true ? 'active-plan' : ''"
        v-for="(plan, index) in planList"
        :key="index"
      >
        <template #header>
          <div class="plan-header">
            <div
              :class="
                plan.tag_line && plan.is_selected == true
                  ? 'plan-tagline-active'
                  : '' || plan.tag_line
                  ? 'plan-tagline'
                  : '' || plan.is_selected == true
                  ? 'plan-is-selected'
                  : ''
              "
            >
              <!-- :class="plan.tag_line && plan.is_selected == true ? 'justify-content-between' : 'justify-content-center' " -->
              <div class="play-type" v-if="plan.tag_line && plan.tag_line.trim() != ''">
                <span v-html="getSpaceProcessedStr(plan.tag_line)"></span>
              </div>
              <h5 class="name text-center">{{ plan.title }}</h5>
              <div class="plan-activated" v-if="plan.is_selected == true">
                <b-badge pill variant="success">{{ lang.label.active }}</b-badge>
              </div>
            </div>
            <p class="price text-center">
              ${{ plan.amount }}<small>/{{ planOptions[plan.type] }}</small>
            </p>
            <div class="d-flex justify-content-between manage-property">
              <div class="manage-property-inner mr-2">
                <label>{{ lang.label.managedRentals }}</label>
                <span>
                  <template v-if="plan.rentals && plan.rentals > 0">
                    {{ lang.label.upto }} {{ plan.rentals }} {{ lang.label.properties }}
                  </template>
                  <template v-else>{{ lang.label.unlimited }}</template>
                </span>
              </div>
              <div class="manage-property-inner">
                <label>{{ lang.label.promotePropertyListing }}</label>

                <span>
                  <template v-if="plan.properties && plan.properties > 0">
                    {{ lang.label.upto }} {{ plan.properties }}
                    {{ lang.label.properties }}
                  </template>
                  <template v-else>{{ lang.label.unlimited }}</template>
                </span>
              </div>
            </div>
            <div class="d-flex justify-content-between manage-property">
              <div class="manage-property-inner mr-2">
                <label>{{ lang.label.managedStaff }}</label>
                <span>
                  <template v-if="plan.staff_members && plan.staff_members > 0">
                    {{ lang.label.upto }} {{ plan.staff_members }}
                    {{ lang.label.members }}
                  </template>
                  <template v-else>{{ lang.label.unlimited }}</template>
                </span>
              </div>
            </div>
          </div>
        </template>
        <b-card-body class="py-0">
          <div class="plan-description">
            <h6>{{ lang.label.description }}</h6>
            <span v-html="plan.description.replace(/\n/g, '<br />')"></span>
          </div>
        </b-card-body>
        <template #footer>
          <h6
            class="text-uppercase sm-font bf600 text-center free-trial"
            v-if="plan.trial_period_days != 0"
          >
            {{ lang.label.trial }} {{ plan.trial_period_days }} {{ lang.label.days }}
          </h6>
          <b-btn
            v-if="!plan.is_trial_active"
            :variant="plan.is_selected == true ? 'danger' : 'primary'"
            block
            @click="getEnrollOrCancel(plan)"
            >{{
              plan.is_selected == true
                ? lang.label.buttons.cancel
                : lang.label.buttons.enrollNow
            }}</b-btn
          >
          <b-btn
            v-if="plan.is_trial_active &&  plan.is_selected"
            :variant="'success'"
            block
            @click="getPayNow(plan)"
            >{{
              lang.label.buttons.payNow
            }}</b-btn
          >
        </template>
      </b-card>
    </VueSlickCarousel>
    <template v-if="paymentHistotyData.length">
      <div class="mt-5">
        <h5>{{ lang.label.paymentHistory }}</h5>
        <div class="table-section vertical-top mt-3">
          <div class="table-card table-border-none with-pagination-table">
            <b-card no-body class="custom-card reports-table">
              <b-card-body class="p-0">
                <v-client-table
                  :data="paymentHistotyData"
                  :columns="columns"
                  :options="options"
                >
                </v-client-table>
              </b-card-body>
            </b-card>
          </div>
        </div>
      </div>
    </template>

    <!-- Reason Message Modal-->
    <b-modal
      ref="deleteMember"
      id="cancelMessageModal"
      hide-footer
      centered
      size="md"
      modal-class="close-btn-right"
      @hidden="resetCancelmodal"
    >
      <template #modal-header="{ close }">
        <b-button variant="link" class="close-btn" @click="close()">
          <img :src="getIconUrl('close.svg')" alt="close" />
        </b-button>
      </template>
      <alert-section current-module="cancelSubscriptionModal"></alert-section>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <b-form @submit.stop.prevent="passes(cancelSubscriptionPlan)" autocomplete="off">
          <b-form-group>
            <div class="form-header d-block text-center">
              <h1 class="form-heading mb-2">{{ lang.label.reason }}</h1>
              <p class="sf400">{{ lang.label.reasonText }}</p>
            </div>
            <ValidationProvider
              :name="lang.label.validation.reasoneMessage"
              v-slot="validationContext"
              rules="required"
            >
              <b-form-textarea
                type="text"
                :state="getValidationState(validationContext)"
                v-model="models.reasonMessge"
                :placeholder="lang.label.placeholder.message"
              ></b-form-textarea>
              <b-form-invalid-feedback
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="pt-3 mb-0">
            <b-btn block variant="primary" type="submit"
              >{{ lang.label.buttons.submit }}
            </b-btn>
          </b-form-group>
        </b-form>
      </ValidationObserver>
    </b-modal>
    <payment-modal
      :planDetails="updatedPlanDetails"
      :isPaymentModalVisible="isPaymentModalVisible"
      @handleInitializedPaymentModal="handleInitializedPaymentModal"
      @handlePayment="handlePayment"
    ></payment-modal>
  </b-container>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import AlertSection from "../shared/AlertSection.vue";
import mixin from "@/mixins/mixin";
import Swal from "sweetalert2";
import paymentModal from "./paymentModal.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    VueSlickCarousel,
    "alert-section": AlertSection,
    "payment-modal": paymentModal,
  },
  computed: {
    ...mapGetters({
      login: "login",
      isUserVerified: "isUserVerified",
    }),
  },
  mixins: [mixin],
  data() {
    return {
      lang: this.$lang.subscription_plan,
      data: jsondata,
      updatedPlanDetails: null,
      planID: null,
      models: {
        selectedPlan: "",
        reasonMessge: "",
      },
      // table
      columns: ["userName", "planName", "amount", "startDate", "endDate", "statusText"],
      options: {
        headings: {
          userName: this.$lang.subscription_plan.label.tableHeader.userName,
          planName: this.$lang.subscription_plan.label.tableHeader.planName,
          amount: this.$lang.subscription_plan.label.tableHeader.amount,
          startDate: this.$lang.subscription_plan.label.tableHeader.startDate,
          endDate: this.$lang.subscription_plan.label.tableHeader.endDate,
          statusText: this.$lang.subscription_plan.label.tableHeader.statusText,
        },
        skin: "table",
        filterable: false,
        texts: {
          noResults: this.$lang.root.label.noRecord,
          filter: this.$lang.root.label.filter,
          filterPlaceholder: this.$lang.root.label.filterPlaceholder,
          limit: this.$lang.root.label.limit,
          count: this.$lang.root.label.count,
        },
      },
      settings: {
        arrows: true,
        dots: true,
        slidesToShow: 4,
        infinite: false,
        slidesToScroll: 1,
        initialSlide: 0,
        centerPadding: "20px",
        responsive: [
          {
            breakpoint: 1699,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1365,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      paymentHistotyData: [],
      planList: [],
      isPaymentModalVisible: false,
      planOptions: {
        1: this.$lang.subscription_plan.label.month,
        2: this.$lang.subscription_plan.label.year,
        3: this.$lang.subscription_plan.label.daily,
      },
    };
  },
  mounted() {
    this.listPlan();
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getImageUrl(imageId) {
      return `https://picsum.photos/600/400/?image=${imageId}`;
    },
    onSwiper(swiper) {
      console.log(swiper);
    },
    /**
     * Listing of plans
     */
    listPlan() {
      let self = this;
      self.$store.dispatch("selectedPlan").then((response) => {
        if (response.status == 200) {
          self.planList = response.data;
          self.paymentHistory();
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * payment history list
     */
    paymentHistory() {
      let self = this;
      self.$store.dispatch("subscriptionPaymentHistory").then((response) => {
        if (response.status == 200) {
          self.paymentHistotyData = [];
          response.data.forEach((payment, i) => {
            self.paymentHistotyData.push({
              userName: payment.name,
              planName: payment.plan_name,
              amount: payment.amount,
              startDate: payment.subscription_start_date,
              endDate: payment.subscription_end_date,
              statusText: payment.subscription_status_text,
            });
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "bank-details",
            });
          }
        }
      });
    },
    /**
     * Enroll Now
     */
    enrollNow(planId) {
      let self = this;
      if (self.isUserVerified) {
        this.$store.commit("setVerificationAlert", true);
      } else {
      let activePlan = this.planList.find((v) => v.is_selected);
      let changedPlan = this.planList.find((v) => v.id === planId);
      let data = {
        plan: planId,
      };
      self.$store.dispatch("canBuySubscription", data).then((response) => {
        if (response.status == 200) {
          if (response.data.can_buy_subscription) {
            self.updatedPlanDetails = changedPlan;
            // self.$router.push({ name: 'payment-card', params: planId })
            self.isPaymentModalVisible = true;
          }
        } else {
          if (!response.data.can_buy_subscription) {
            Swal.fire({
              title: self.lang.label.warning,
              text: response.data.message || response.data.detail,
              showCloseButton: true,
              showCancelButton: false,
              confirmButtonText: self.lang.label.buttons.ok,
              focusConfirm: false,
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-danger",
              },
            }).then((response) => {
              if (response.isConfirmed) {
                // self.$router.push({name : 'support'})
              }
            });
          }
        }
      });
      }
    },
    handleInitializedPaymentModal() {
      this.isPaymentModalVisible = false;
    },
    resetCancelmodal() {
      this.planID = null;
      this.models.reasonMessge = null;
    },
    /**
     * Handle Payment
     */
    handlePayment() {
      this.updateView(1);
      this.listPlan();
    },
    /**
     * Show Popup of cancel message
     * @param {number} planId Plan Id
     */
    cancelPopup(planId) {
      let self = this;
      Swal.fire({
        title: this.lang.label.confirmTitle,
        text: this.lang.label.confirmMsg,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: this.lang.label.buttons.yes,
        cancelButtonText: this.lang.label.buttons.no,
        focusConfirm: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.planID = planId;
          self.$bvModal.show("cancelMessageModal");
        }
      });
    },
    /**
     * Call the api for cancel the subscription
     */
    cancelSubscriptionPlan() {
      let self = this;
      let data = {
        user_subscription: self.planID,
        cancellation_reason: self.models.reasonMessge,
      };
      self.$store.dispatch("cancelSubscription", data).then((response) => {
        if (response.status == 200) {
          self.$bvModal.hide("cancelMessageModal");
          Swal.fire(response.data.message, "", "success").then((res) => {
            self.updateView(0);
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "cancelSubscriptionModal",
            });
          }
        }
      });
    },
    /**
     * Enroll the plan or cancel the selected plan
     * @param {object} plan Details of plan
     */
    getEnrollOrCancel(plan) {
      if (plan.is_selected) {
        this.cancelPopup(plan.user_subscription_id);
      } else {
        this.enrollNow(plan.id);
      }
    },
    /**
     * Enroll the current plan and pay the amount
     * @param {object} plan Details of plan
     */
    getPayNow(plan) {
        this.enrollNow(plan.id);
    },
    /**
     * Update the Page after Cancel the subscription plan
     */
    updateView(type) {
      let self = this;
      self.$store.dispatch("viewProfile").then((response) => {
        if (response.status == 200) {
          let loginData = Object.assign(self.login, response.data);
          self.$store.commit("setLogin", loginData);
          // type 1 : after buy subscription, type 0 : after cancel subscription
          if (!type) self.$router.push({ name: "dashboard" });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "cancelSubscriptionModal",
            });
          }
        }
      });
    },
  },
};
</script>
