module.exports = {
  label: {
    warning: 'Advertencia',
    subscriptionAlert: 'Comprar Plan De Suscripción Para Acceder A Este Módulo',
    parentSubscriptionAlert:'Your parent subscription plan has been expire, Purchase subscription plan to access this module',
    verificationAlert: 'Verifíque La Cuenta Para Acceder A Este Módulo',
    noRecord: 'Ningún Registro Disponible',
    filter: 'Filtrar:',
    filterPlaceholder: 'Buscar Por',
    limit: "Registros:",
    count: "Mostrando {from} Hasta {to} De {count} Registros|{count} Registros|1 Registro",
    countRolesAndPermission:"Mostrando {from} Hasta {to} De {count} Registro(s)|{count} Registro(s)|1 Registro(s)",
    deleted: "Borrado!",
    paymentValidations: {
      fieldEmpty: "Campo vacío",
      cardFormate: "El número de tarjeta debe tener entre 13 y 19 dígitos y un formato de tarjeta reconocible",
      cardExpired: "La fecha de caducidad debe ser un mes y un año presente o futuro",
      cvvValidation: "El CVV debe tener 3 o 4 dígitos",
    },
    buttons: {
      ok: 'Aceptar'
    }
  }
}