module.exports = {
    label: {
    oneRecord: "1 Propiedad",
    showing: "Mostrando ",
    to: " Hasta ",
    of: " De ",
    records: " Propiedades",
    rentedProperties: 'Propiedades Alquiladas',
      nextDueDate: 'Fecha De Vencimiento Siguiente',
      addedDate: 'Fecha Añadida',
      search: 'Buscar',
      noRecord: 'Ningún Registro Disponible',
      buttons: {
        payRent: 'Pagar Renta'
      }
    }
  };