module.exports = {
  label: {
    title: "My Account",
    addBankDetails: "Add Bank Details",
    tabs: {
      myProfile: 'My Profile',
      subscriptionPlan: 'Subscription Plan',
      bankInformation: 'Billing Information',
    }
  }
};