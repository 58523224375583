<!-- content from my_contacts.js -->
<template>
  <div>
    <div class="table-section vertical-top">
      <div class="table-card table-border-none with-pagination-table">
        <b-card no-body class="custom-card reports-table">
          <b-card-body class="p-0">
            <v-client-table :data="tableData" :columns="columns" :options="options">
              <template slot="actions" slot-scope="props">
                <!-- slot-scope="props" -->
                <el-tooltip :content="lang.label.tooltip.viewContact" placement="top">
                  <b-btn variant="link" class="p-1" @click="viewContact(props.row.id)" >
                    <span class="material-icons">visibility</span>
                  </b-btn>
                </el-tooltip>
                <el-tooltip :content="lang.label.tooltip.notes" placement="top">
                  <b-btn variant="link" class="p-1" @click="descriptionsNotes(props.row)">
                    <span class="material-icons">description</span>
                  </b-btn>
                </el-tooltip>
                <el-tooltip :content="lang.label.tooltip.assignedProperties" placement="top">
                  <b-btn variant="link" class="p-1" @click="assignContactProperties(props.row)">
                    <span class="material-icons">home</span>
                  </b-btn>
                </el-tooltip>
                <el-tooltip :content="lang.label.tooltip.bankDetails" placement="top">
                  <b-btn variant="link" class="p-1" @click="bankDetailsOfContact(props.row)">
                    <span class="material-icons">account_balance</span>
                  </b-btn>
                </el-tooltip>
                <!-- <b-btn variant="link" class="p-1">
                  <span class="material-icons">edit</span>
                </b-btn> -->
                <el-tooltip :content="lang.label.tooltip.deleteContact" placement="top">
                  <b-btn variant="link" class="p-1" @click="deleteContact(props.row.id)">
                    <span class="material-icons">delete</span>
                  </b-btn>
                </el-tooltip>
              </template>
            </v-client-table>
          </b-card-body>
        </b-card>
      </div>
    </div>

    <!-- listing notes -->
    <b-sidebar
      id="descriptionsNotes"
      footer-class="sidebar-footer"
      sidebar-class="sidebar-view"
      text-variant="none"
      backdrop
      right
      width="462px"
      :visible="isNotesVisible"
      @change="updateSidebar"
    >
      <template #header="{ hide }">
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex align-items-center">
            <b-btn variant="link" size="sm" class="p-0" @click="hide">
              <img :src="getIconUrl('close-sm.svg')" alt="close" />
            </b-btn>
            <h4 class="sidebar-header-title mb-0">
              {{ contactName }}’ {{ lang.label.notes }}
            </h4>
          </div>
          <b-btn variant="primary" @click="addNoteClick()">
            {{ lang.label.buttons.addNote }}
          </b-btn>
        </div>
      </template>
      <div class="amenity-body">
        <properties-notes :contactID="contactID"  @handleNotes="handleNotes" v-if="isNotesVisible"></properties-notes>
      </div>
    </b-sidebar>

    <!-- Add notes -->
    <ValidationObserver ref="observer" v-slot="{ passes }">
      <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
        <b-sidebar
          id="addNoteSidebar"
          footer-class="sidebar-footer"
          sidebar-class="sidebar-view"
          text-variant="none"
          backdrop
          right
          width="462px"
          :visible="isAddNoteVisible"
          @change="updateAddNoteSlider"
        >
          <template #header="{ hide }">
            <div class="d-flex justify-content-between w-100">
              <div class="d-flex align-items-center">
                <b-btn variant="link" size="sm" class="p-0" @click="hide">
                  <img :src="getIconUrl('close-sm.svg')" alt="close" />
                </b-btn>
                <h4 class="sidebar-header-title mb-0">{{ lang.label.addNoteLabel }}</h4>
              </div>
              <b-btn variant="primary" type="submit">
                {{ lang.label.buttons.save }}
              </b-btn>
            </div>
          </template>
          <div class="amenity-body">
            <b-form-group class="mb-0">
              <ValidationProvider
                :name="lang.label.validation.note"
                rules="required|max:200"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <b-form-textarea
                    v-model="noteModel"
                    type="text"
                    :state="getValidationState(validationContext)"
                    placeholder="Write..."
                    rows="5"
                  ></b-form-textarea>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </div>
        </b-sidebar>
      </b-form>
    </ValidationObserver>

    <!-- assign a Properties contact -->
    <b-sidebar id="assignHome" footer-class="sidebar-footer" sidebar-class="sidebar-view" text-variant="none" backdrop right     width="1200px" @change="updatePropertySlideBar" :visible="isPropertyList">
      <template #header="{ hide }">
          <div class="d-flex justify-content-between w-100">
              <div class="d-flex align-items-center">
                  <b-btn variant="link" size="sm" class="p-0" @click="hide">
                      <img :src="getIconUrl('close-sm.svg')" alt="close" />
                  </b-btn>
                  <h4 class="sidebar-header-title mb-0">{{ lang.label.assignHome }} {{ currentData.name }}</h4>
              </div>
          </div>
      </template>
      <div class="amenity-body" v-if="isPropertyList">
          <properties-table :tableData="assignProperties" :editable="login.user_type_id==3 && staffpermissions.length>0 && !staffpermissions.includes('update_property')?false:true" :redableOnly="true"></properties-table>
      </div>
    </b-sidebar>

    <!--Bank Details Contact-->
    <b-sidebar id="bankSlider" footer-class="sidebar-footer" sidebar-class="sidebar-view" text-variant="none" backdrop right     width="1200px" @change="updateBankSlideBar" :visible="isBankList">
      <template #header="{ hide }">
          <div class="d-flex justify-content-between w-100">
              <div class="d-flex align-items-center">
                  <b-btn variant="link" size="sm" class="p-0" @click="hide">
                      <img :src="getIconUrl('close-sm.svg')" alt="close" />
                  </b-btn>
                  <h4 class="sidebar-header-title mb-0">{{ lang.label.bankDetails }} {{ currentData.name }}</h4>
              </div>
          </div>
      </template>
      <div class="amenity-body" v-if="isBankList">
          <contact-bank :tableData="contactBankList"></contact-bank>
      </div>
    </b-sidebar>   
  </div>
</template>

<script>
import Vue from "vue";
import jsondata from "@/assets/json/data.json";
import { ClientTable } from "vue-tables-2";
Vue.use(ClientTable, {}, false, "bootstrap4");
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import PropertiesNotes from "@/components/PropertiesNotes.vue";
import PropertiesTable from "@/components/PropertiesTable.vue";
import BankList from "@/components/bank/BankList.vue"
import { mapGetters } from "vuex";
import mixin from "@/mixins/mixin";
export default {
  components: {
    "properties-notes": PropertiesNotes,
    "properties-table": PropertiesTable,
    "contact-bank" : BankList
  },
  computed:{
    ...mapGetters({
        loading: "loading",
        login:"login",
        staffpermissions:"staffpermissions"
    }),
  },
  mixins: [mixin],
  props: ["tableData"],
  data() {
    return {
      lang: this.$lang.my_contacts,
      data: jsondata,
      isNotesVisible: false,
      isAddNoteVisible: false,
      isPropertyList:false,
      isBankList : false,
      noteModel: "",
      contactID:'',
      contactName:'',
      assignProperties:[],
      contactBankList:[],
      currentData:{},
      // table
      columns: ["name", "role", "emailID", "contact", "dateAdded", "actions"],
      options: {
        headings: {
          name: this.$lang.my_contacts.label.tableHeader.contactName,
          role: this.$lang.my_contacts.label.tableHeader.role,
          emailID: this.$lang.my_contacts.label.tableHeader.emailId,
          contact: this.$lang.my_contacts.label.tableHeader.contact,
          dateAdded: this.$lang.my_contacts.label.tableHeader.dateAdded,
          actions: this.$lang.my_contacts.label.tableHeader.actions,
        },
        filterable: ["name", "emailID"],
        skin: "table",
        perPage: 10,
        perPageValues: [],
        sortable: ["name", "role", "emailID", "contact", "dateAdded"],
        columnsClasses: {
          name: "name-width",
          actions: "action-width",
          role: "d-none d-md-table-cell ",
          emailID: "d-none d-md-table-cell ",
          contact: "d-none d-md-table-cell ",
          dateAdded: "d-none d-md-table-cell ",
        },
        pagination: { chunk: 5 },
        texts: {
          noResults: this.$lang.root.label.noRecord,
          filter: this.$lang.root.label.filter,
          filterPlaceholder: this.$lang.root.label.filterPlaceholder,
          limit: this.$lang.root.label.limit,
          count: this.$lang.root.label.count,
        },
      },
    };
  },
  mounted() {
    this.updateSidebar();
  },
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    viewContact(id) {
      this.$router.push({ name: "contacts-detail" ,params: { id: id }});
    },
    descriptionsNotes(contact) {
      let self = this;
      self.isAddNoteVisible = false;
      self.isNotesVisible = true;
      self.contactID = contact.id
      self.contactName = contact.name
    },
    addNoteClick() {
      let self = this;
      self.isNotesVisible = false;
      self.isAddNoteVisible = true;
    },
    onSubmit() {
      let self = this;
      let data = {
        contact : self.contactID,
        note : self.noteModel
      }
      self.$store.dispatch('addContactNotes',data).then((response) =>{
        if(response.status == 200){
          self.isAddNoteVisible = false;
        }else{
          let message = response.data.message || response.data.detail;
          if (message) {
              self.isNotesVisible = false;
              self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              });
          }
        }
      })
    },
    deleteContact(id) {
      let self = this;
      Swal.fire({
        title: self.lang.label.confirmTitle,
        text: self.lang.label.confirmMsg,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: self.lang.label.buttons.yes,
        cancelButtonText: self.lang.label.buttons.no,
        focusConfirm: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.$store.dispatch("deleteContact", id).then((response) => {
            if (response.status == 200) {
              Swal.fire(this.$lang.root.label.deleted, response.data.message, "success");
              self.$emit('handleContactDelete');
            } else {
              let message = response.data.message || response.data.detail;
              if (message) {
                self.$store.commit("setMessages", {
                  type: "danger",
                  message: message,
                });
              }
            }
          });
        }
      });
    },
    updateAddNoteSlider(val){
      this.isAddNoteVisible = val
      if(!val){
        this.noteModel=''
        this.$refs.observer.reset()
        this.isNotesVisible = true
      }
    },
    handleNotes(){
      let self = this;
      self.isNotesVisible = false;
      self.isAddNoteVisible = false;
    },
    updateSidebar(val) { 
          this.isNotesVisible = val
            const body = document.getElementsByTagName('body')[0];
            if(val){
                body.classList.add("overflow-hidden")
            } else {
                body.classList.remove("overflow-hidden")
            }
    },
    updatePropertySlideBar(val){
      this.isPropertyList = val
    },
    /**
     * Call the list contact properies with specific contact id from the table data
     * @param {object} currentData 
     */
    assignContactProperties(currentData){
      let self =this;
      self.currentData = currentData
      self.isPropertyList = true;
      self.listOfContactProperties(currentData.id)
    },
    /**
    * Call the api of Assigned Contact Properties Details
    */
    listOfContactProperties(id){
      let self = this;
      let data = {
          id : id,
          page : 1,
          size : 1000000
      };
      self.$store.dispatch('listOfContactProperties',data).then((response) => {
          if(response.status == 200){
              self.pushIntotableData(response);
          }else{
              let message = response.data.message || response.data.detail;
              if (message) {
                  self.isNotesVisible = false;
                  self.$store.commit("setMessages", {
                  type: "danger",
                  message: message,
                  });
              }
          }
      })
    },
    /**
    * Create the details of assigned property of contact
    * @param {object} response Details of list Of Contact Properties
    */
    pushIntotableData(response) {
      let self = this;
      self.assignProperties = [];
      response.data.results.forEach((v) => {
        let sumOfIncome = v.income.reduce((a, v) => a + v, 0);
        let sumOfExpense = v.expense.reduce((a, v) => a + v, 0);
        self.assignProperties.push({
          id: v.id,
          amount: "$" + v.rent_amount,
          address: v.address,
          status: v.status_name,
          status_id: v.status_id,
          img: v.property_image,
          dateAdded: self.formatDateToShow(v.created_date),
          type: v.type_name,
          rentDue: self.formatDateToShow(v.next_due_date),
          series: [
              {
              name: "Income",
              data: v.income,
              },
              {
              name: "Expense",
              data: v.expense,
              },
          ],
          totalExpense: sumOfExpense,
          totalIncome: sumOfIncome,
        });
      })
    },
    bankDetailsOfContact(currentData){
      let self = this;
      self.currentData = currentData
      self.isBankList = true;
      self.contactBankList = []
      let searchData = {
        id : currentData.id,
        data : {
          search : ''
        }
      }
      self.$store.dispatch('listOfBankDetailsOfContact',searchData).then((response) =>{
        if(response.status == 200){
          response.data.forEach((bank) => {
            if(bank.account){
              self.contactBankList.push({
              id: bank.id,
              name: bank.holder_name,
              bankName:bank.bank_name,
              bankAccountNumber: bank.account,
              code: bank.code,
              address: bank.address,
              country: bank.country,
              state: bank.state,
              city: bank.city,
              zipCode: bank.zip_code,
            });
            }
          })
        }else{
          let message = response.data.message || response.data.detail;
            if (message) {
                self.isNotesVisible = false;
                self.$store.commit("setMessages", {
                type: "danger",
                message: message,
                });
            }
        }
      })
    },
    updateBankSlideBar(val){
      this.isBankList = val
    }
  },
};
</script>
