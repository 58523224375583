<!-- content from rented_property_details.js -->
<template>
  <div class="body-content">
    <div class="top-sticky">
      <div class="top-bar-radius"></div>
    </div>
    <div class="starts-section">
      <!-- body content header  -->
      <div class="page-breadcrumb property-breadcrumbs details-breadcrumbs">
        <div class="d-xl-flex justify-content-between align-items-center add-pro-bread">
          <div class="pg-bread">
            <b-btn variant="link" class="p-0 mr-3 primary-text" @click="goBack()"
              ><span class="material-icons align-middle">arrow_back</span></b-btn
            >
            <b-breadcrumb class="bg-transparent p-0 mb-0">
              <b-breadcrumb-item active>
                <span class="sf700">Colón Villa</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item active class="text-grey-light">
                Residential
              </b-breadcrumb-item>
            </b-breadcrumb>
            <div class="ml-4">
              <template v-for="(status, index) in data.propertyStatus">
                <b-badge
                  :variant="status.availabilityType == 1 ? 'success' : 'danger'"
                  pill
                  class="p-2 bf-size"
                  :key="index"
                  v-if="status.availabilityType == 2"
                >
                  {{ status.name }}
                </b-badge>
              </template>
            </div>

            <!-- for mobile -->
            <div class="text-nowrap text-grey-light d-md-none mt-3 w-100">{{ lang.label.dateAdded }} : 09/21/2022</div>
            <!-- this button for mobile -->
            <div class="display-resp-button d-md-none">
              <b-btn
                variant="primary"
                size="sm"
                class="btn-mw"
                >{{ lang.label.buttons.payRent }}</b-btn
              >
              <b-btn
                variant="primary"
                size="sm"
                class="btn-mw"
                >{{ lang.label.buttons.paymentHistory }}</b-btn
              >
            </div>
          </div>

          <div class="pg-right">
            <div class="d-flex align-items-center flex-wrap">

              <!-- for desktop -->
              <span class="text-nowrap text-grey-light mr-2 pr-1"
                >{{ lang.label.dateAdded }} : 09/21/2022</span
              >

              <!-- this button for desktop -->
              <b-btn
                variant="primary"
                size="sm"
                class="text-nowrap btn-mw d-none d-md-inline"
                >{{ lang.label.buttons.payRent }}</b-btn
              >
              <b-btn
                variant="primary"
                size="sm"
                class="text-nowrap btn-mw d-none d-md-inline"
                >{{ lang.label.buttons.paymentHistory }}</b-btn
              >
            </div>
          </div>
        </div>
      </div>

      <!-- properties property section -->
      <div class="property-detail-page">
        <b-container fluid class="px-0">
          <b-row>
            <!-- property details images and content section -->
            <b-col lg="6" xl="8" class="order-1 order-lg-1">
              <b-card no-body class="bg-transparent border-0 rounded-0 form-group">
                <b-card-body class="p-0">
                  <property-media></property-media>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- property address section -->
            <b-col lg="6" xl="4" class="order-2 order-lg-2">
              <b-card no-body class="custom-card form-group">
                <b-card-body>
                  <div class="property-facilities">
                    <b-row>
                      <b-col
                        cols="6"
                        lg="6"
                        xl="4"
                        v-for="(facility, fIndex) in data.propertyFacilities"
                        :key="fIndex"
                      >
                        <b-media class="facility-media">
                          <template #aside>
                            <img :src="getIconUrl(facility.icon)" :alt="facility.name" />
                          </template>
                          <p class="mb-0">{{ facility.name }}</p>
                        </b-media>
                      </b-col>
                    </b-row>
                  </div>
                  <p class="text-grey mb-2">{{ lang.label.maintenanceFee }} $800</p>
                  <b-row>
                    <b-col>
                      <label class="mb-0 d-block">{{ lang.label.leaseAmount }}</label>
                      <p class="property-amount my-2 mb-0">
                        $2300<span class="bf400">/{{ lang.label.monthly }}</span>
                      </p>
                    </b-col>
                    <b-col class="text-nowrap">
                      <label class="mb-0 d-block">{{ lang.label.advanceAmount }}</label>
                      <p class="property-amount my-2 mb-0">$4600</p>
                    </b-col>
                  </b-row>
                  <div class="address-map">
                    <div class="address-iframe">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d62988.753853870716!2d-79.92690882012938!3d9.351213884178671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1zTWFkcm9uYWwgNjM1MSwgQ29sw7NuLCBQYW5hbcOh!5e0!3m2!1sen!2sin!4v1676638843494!5m2!1sen!2sin"
                        style="border: 0"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                    <div class="mb-0 address-text">
                      <div class="d-flex align-items-center">
                        <span class="material-icons secondary-text mr-1">place</span>
                        <span>Madronal 6351, Colón, Panamá</span>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- property amenities section -->
            <b-col lg="6" xl="8" class="order-5 order-lg-3">
              <b-card no-body class="bg-transparent border-0 rounded-0 form-group">
                <b-card-body class="p-0">
                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="mb-0 sf-size bf700">{{ lang.label.amenities }}</h5>
                    <b-btn variant="link" class="p-0 text-underline mh-auto">{{
                      lang.label.viewAll
                    }}</b-btn>
                  </div>
                  <div class="mt-3">
                    <property-amenities></property-amenities>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- property broker & tenant details section -->
            <b-col lg="6" xl="4" class="order-3 order-lg-4">
              <b-card no-body class="custom-card form-group">
                <b-card-body>
                  <div class="property-manager border-0 tenant-rent-details">
                    <div class="manager-section mb-0">
                      <h5 class="mng-title sf-size bf700">
                        {{ lang.label.rentDetailsTitle }}
                      </h5>
                      <b-row>
                        <b-col md="6" lg="4">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">{{ lang.label.leasedTo }}</h6>
                            <div class="form-group">
                              <label class="mb-0">John Doe</label>
                            </div>
                          </div>
                        </b-col>
                        <b-col md="6" lg="8">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">
                              {{ lang.label.leasedPeriod }}
                            </h6>
                            <b-row>
                              <b-col cols="6">
                                <div class="mng-box">
                                  <div class="form-group">
                                    <label class="mb-0">{{ lang.label.startDate }}</label>
                                    <p class="mb-0 mt-1">01/06/2022</p>
                                  </div>
                                </div>
                              </b-col>
                              <b-col cols="6">
                                <div class="mng-box">
                                  <div class="form-group">
                                    <label class="mb-0">{{ lang.label.endDate }}</label>
                                    <p class="mb-0 mt-1">31/05/2023</p>
                                  </div>
                                </div>
                              </b-col>
                            </b-row>
                          </div>
                        </b-col>
                        <b-col md="6" lg="4">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">
                              {{ lang.label.leaseAmountText }}
                            </h6>
                            <div class="form-group">
                              <label class="mb-0">$3500.00</label>
                            </div>
                          </div>
                        </b-col>
                        <b-col md="6" lg="4">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">
                              {{ lang.label.depositAmount }}
                            </h6>
                            <div class="form-group">
                              <label class="mb-0">$3500.00</label>
                            </div>
                          </div>
                        </b-col>
                        <b-col md="6" lg="4">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">{{ lang.label.rentDue }}</h6>
                            <div class="form-group">
                              <label class="mb-0">01/07/2022</label>
                            </div>
                          </div>
                        </b-col>
                        <b-col md="6" lg="4">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">
                              {{ lang.label.maintenanceFeeText }}
                            </h6>
                            <div class="form-group">
                              <label class="mb-0">$500.00</label>
                            </div>
                          </div>
                        </b-col>
                        <b-col md="6" lg="8">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">
                              {{ lang.label.amountDueFirstMonth }}
                            </h6>
                            <div class="form-group">
                              <label class="mb-0">$7500.00</label>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>

                    <!-- lease agreement sign agreement -->
                    <div class="sign-agreement">
                      <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center">
                          <a href="" class="file-format">
                            <span class="format-name">PDF</span>
                          </a>
                          <span class="ml-3">{{ lang.label.leaseAgreementTitle }}</span>
                        </div>
                        <div class="space-top">
                          <b-btn variant="primary" size="sm" @click="viewSignature()">{{
                            lang.label.buttons.viewSign
                          }}</b-btn>
                          <b-btn variant="primary" size="sm" @click="signatureModal()">{{
                            lang.label.buttons.signAgreement
                          }}</b-btn>

                          <!-- signature agreememt modal -->
                          <b-modal
                            ref="refSignature"
                            id="signature"
                            hide-footer
                            centered
                            size="md"
                            modal-class="close-btn-right"
                          >
                            <template #modal-header="{ close }">
                              <b-button variant="link" class="close-btn" @click="close()">
                                <img :src="getIconUrl('close.svg')" alt="close" />
                              </b-button>
                            </template>
                            <div class="d-block text-center">
                              <h3 class="body-modal-heading">{{ lang.label.sign }}</h3>
                              <VueSignaturePad
                                id="signature"
                                class="signature-box"
                                ref="signaturePad"
                                :options="{
                                  onBegin: () => {
                                    $refs.signaturePad.resizeCanvas();
                                  },
                                }"
                              />
                            </div>
                            <div class="modal-buttons text-center modal-btn-responsive">
                              <b-button class="btn-mw" variant="primary" @click="undo">{{
                                lang.label.buttons.undo
                              }}</b-button>
                              <b-button class="btn-mw" variant="danger" @click="save">{{
                                lang.label.buttons.save
                              }}</b-button>
                            </div>
                          </b-modal>

                          <!-- view signature agreememt modal -->
                          <b-modal
                            ref="refSignatureView"
                            id="signatureView"
                            hide-footer
                            centered
                            size="md"
                            modal-class="close-btn-right"
                          >
                            <template #modal-header="{ close }">
                              <b-button variant="link" class="close-btn" @click="close()">
                                <img :src="getIconUrl('close.svg')" alt="close" />
                              </b-button>
                            </template>
                            <div class="d-block text-center">
                              <h3 class="body-modal-heading">&nbsp;</h3>
                              <img
                                src="https://i.fonts2u.com/bs/bs-signature-demo_4.png"
                                alt=""
                                class="img-fluid"
                              />
                            </div>
                          </b-modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>

              <!-- property lease agreement section -->
              <b-card no-body class="custom-card form-group">
                <b-card-body>
                  <div class="property-manager border-0">
                    <div class="manager-section">
                      <h5 class="mng-title sf-size bf700">
                        {{ lang.label.leaseAgreement }}
                      </h5>
                      <div class="d-flex justify-content-between flex-wrap">
                        <div>
                          <a href="" class="file-format">
                            <span class="format-name">PDF</span>
                          </a>
                        </div>
                        <div>
                          <b-btn variant="primary" size="sm">{{
                            lang.label.buttons.download
                          }}</b-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- broker & owner details -->
            <b-col lg="12" class="order-7 order-lg-7">
              <b-row class="justify-content-end">
                <b-col
                  md="6"
                  xl="4"
                  class="form-group"
                  v-for="(user, uIndex) in data.userTypeDetails"
                  :key="uIndex"
                >
                  <b-card no-body class="custom-card h-100">
                    <b-card-body>
                      <div class="d-flex justify-content-between align-items-center">
                        <h5 class="mb-0 sf-size bf700">
                          {{ user.typeName }} {{ lang.label.details }}
                        </h5>
                        <b-btn
                          variant="primary"
                          size="sm"
                          v-b-modal="'user' + user.typeName"
                          >{{ lang.label.connectTo }} {{ user.typeName }}</b-btn
                        >
                      </div>
                      <b-media no-body class="contact-details mt-3">
                        <b-media-aside>
                          <div class="usr-pic">
                            <img :src="getUserUrl(user.pic)" alt="" />
                          </div>
                        </b-media-aside>
                        <b-media-body>
                          <h5 class="mt-0 usr-name">{{ user.name }}</h5>
                          <div
                            class="d-flex justify-content-between align-items-center flex-wrap"
                          >
                            <b-media no-body class="contact-via">
                              <b-media-aside>
                                <div class="contact-icon">
                                  <span class="material-icons">email</span>
                                </div>
                              </b-media-aside>
                              <b-media-body>
                                <a
                                  :href="'mailto:' + user.email"
                                  class="primary-text text-underline"
                                  >{{ user.email }}</a
                                >
                              </b-media-body>
                            </b-media>

                            <b-media no-body class="contact-via">
                              <b-media-aside>
                                <div class="contact-icon">
                                  <span class="material-icons">phone</span>
                                </div>
                              </b-media-aside>
                              <b-media-body>
                                <a
                                  :href="'tel:' + user.phone"
                                  class="primary-text text-underline"
                                  >{{ user.phone }}</a
                                >
                              </b-media-body>
                            </b-media>

                            <b-media
                              no-body
                              class="contact-via"
                              v-if="!user.address == ''"
                            >
                              <b-media-aside>
                                <div class="contact-icon">
                                  <span class="material-icons">place</span>
                                </div>
                              </b-media-aside>
                              <b-media-body>
                                <span class="primary-text">{{ user.address }}</span>
                              </b-media-body>
                            </b-media>
                          </div>
                        </b-media-body>
                      </b-media>
                    </b-card-body>
                  </b-card>

                  <!-- enter message for broker or owner -->
                  <b-modal
                    :id="'user' + user.typeName"
                    hide-footer
                    centered
                    size="md"
                    modal-class="close-btn-right"
                  >
                    <template #modal-header="{ close }">
                      <b-button variant="link" class="close-btn" @click="close()">
                        <img :src="getIconUrl('close.svg')" alt="close" />
                      </b-button>
                    </template>
                    <ValidationObserver ref="observer" v-slot="{ passes }">
                      <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
                        <div class="d-block text-center">
                          <h3 class="body-modal-heading text-left">Message</h3>
                          <ValidationProvider
                            :name="lang.label.form.validation.message"
                            rules="required"
                            v-slot="validationContext"
                          >
                            <div class="position-relative text-left">
                              <b-form-textarea
                                v-model="models.message"
                                type="text"
                                :state="getValidationState(validationContext)"
                                :placeholder="lang.label.form.placeholder.message"
                                rows="3"
                              ></b-form-textarea>

                              <b-form-invalid-feedback
                                >{{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="modal-buttons text-center modal-btn-responsive">
                          <b-button class="btn-mw" variant="primary" type="submit">{{
                            lang.label.buttons.submit
                          }}</b-button>
                        </div>
                      </b-form>
                    </ValidationObserver>
                  </b-modal>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="12" class="order-8 order-lg-8">
              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <b-form-group>
                  <h5 class="mb-0 sf-size bf700">{{ lang.label.paymentHistory }}</h5>
                </b-form-group>
                <filter-form></filter-form>
              </div>
              <hr class="mt-0" />
              <payment-history></payment-history>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import jsondata from "@/assets/json/data.json";
import PropertyMedia from "./details/PropertyMedia.vue";
import PropertyAmenities from "./details/PropertyAmenities.vue";
import PaymentHistoryFilter from "./details/PaymentHistoryFilter.vue";
import PaymentHistory from "./details/PaymentHistory.vue";

import VueSignaturePad from "vue-signature-pad";
Vue.use(VueSignaturePad);

export default {
  name: "PropertiesDetails",
  components: {
    "property-media": PropertyMedia,
    "property-amenities": PropertyAmenities,
    "filter-form": PaymentHistoryFilter,
    "payment-history": PaymentHistory,
  },
  data() {
    return {
      lang: this.$lang.rented_property_details,
      data: jsondata,
      models: {
        message: "",
      },
      options: {
        penColor: "#c0f",
      },
    };
  },
  mounted() {
    document.body.classList.add("no-sidebar-lg");
  },
  destroyed() {
    document.body.classList.remove("no-sidebar-lg");
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/styles/img/" + img);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getAmenityIcon(amenity) {
      return require("@/assets/styles/img/amenities/" + amenity);
    },
    getUserUrl(user) {
      return require("@/assets/styles/img/users/" + user);
    },
    goBack() {
      this.$router.go(-1);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {},

    signatureModal() {
      this.$refs["refSignature"].show();
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      alert("Open DevTools see the save data.");
      console.log(isEmpty);
      console.log(data);
      this.$refs["refSignature"].hide();
    },

    viewSignature() {
      this.$refs["refSignatureView"].show();
    },
  },
};
</script>
