module.exports = {
  label: {
    register: "Register",
    alreadyAccount: "Already have a account?",
    signIn: "Sign In",
    slogan: "You’ll never need another property management platform",
    selectUserType: "Select your user type",
    onBoard: 'Onboard as',
    enterYourDetails: 'Enter your details',
    agreeTerms: "I have read and agree to the",
    termsConditions: 'Terms & Conditions',
    privacyPolicy: 'Privacy Policy',
    verification: 'Verification',
    codeSentEmail: 'A 6 digit verification code has been sent to your email inbox.',
    didNotReceive: 'Did not receive?',
    resend: 'Resend',
    and: 'and',
    emailLabel: 'Email',
    signGoogle: 'Register With Google',
    signFacebook: 'Register with Facebook',
    form: {      
      validation: {
        firstName: 'first name',
        lastName: 'last name',
        email: 'email',
        brokerId: 'broker Id',
        realtorId: 'realtor Id',
        brokerRealtorId: 'broker / realtor Id',
        password: 'password',
        confirmPassword: 'confirm password',
        termsConditionAgree: 'terms and conditions'
      },
      placeholder: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        brokerId: 'Broker ID',
        realtorId: 'Realtor ID',
        brokerRealtorId: 'Broker / Realtor ID',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        brokerIdNote: '*First part 2 letters - Second part 4 numbers, Ex: ab-1234',
        passwordFormateNote: '*Length 8 or longer, includes at least one capital, small, special character and one number. Ex: Miradoor123*',
      }
    },
    buttons: {
      continue: 'Continue',
      verify: 'Verify'
    }
  }
};