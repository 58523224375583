module.exports = {
    label: {
      trial: 'Prueba Gratuita (Nuevos Clientes)',
      days: 'Días',
      month: 'Mensual',
      year: 'Año',
      daily: 'Diario',
      managedRentals: 'Administrar Propiedades De Alquiler',
      promotePropertyListing: 'Promover Propiedad',
      managedStaff: 'Managed Miembros Del Equipo',
      upto: 'Hasta',
      properties: 'Propiedades',
      members: 'Miembros',
      description: 'Descripción',
      unlimited:'Unlimited',
      reason : 'Razón Mensaje',
      reasonText : 'Cancelar Suscripción ¿Por Qué?',
      confirmTitle: '¿Estas Seguro?',
      confirmMsg: 'Desea cancelar el plan de suscripción',
      validation : {
        reasoneMessage : 'Describa La Razón'
      },
      placeholder:{
        message : 'Mensaje...'
      },
      warning:'Advertencia',
      verificationAlert : 'No se puede degradar el plan. Por favor escriba una consulta en soporte para esto',
      paymentHistory:'Historial De Pagos',
      buttons: {
        enrollNow: 'Suscríbase Ahora',
        payNow: 'Pagar Ahora',
        cancel: 'Cancelar',
        submit : 'Enviar',
        yes:'Sí',
        no:'No',
        ok:'Aceptar'
      },
      tableHeader:{
        userName : 'Nombre',
        planName: 'Nombre De Plan',
        amount: 'Monto Pagado',
        startDate: 'Fecha De Inicio',
        endDate: 'Fecha Final',
        statusText: 'Estado',
      },
      active: 'Plan Activo',
      purchaseSubscriptionPlan: 'Comprar plan de suscripción para acceder a este módulo' 
    }
  };