module.exports = {
  label: {
    addBankDetails: 'Add Bank Details',
    required: '*',
    form: {      
      label: {
        name: "Account Holder’s Name",
        bankName: 'Bank Name',
        bankAccountNumber: 'Bank Account Number',
        bankSwiftCode: 'Bank Swift Code',
        address: 'Address',
        city: 'City',
        state: 'State',
        country: 'Country',
        zipCode: 'Zip Code'
      },
      validation: {
        name: "account holder’s name",
        bankName: 'bank name',
        bankAccountNumber: 'bank account number',
        bankSwiftCode: 'bank swift code',
        address: 'address',
        city: 'city',
        state: 'state',
        country: 'country',
        zipCode: 'zip code'
      },
      placeholder: {
        name: "Enter Account Holder’s Name",
        bankName: 'Enter Bank Name',
        bankAccountNumber: 'Enter Bank Account Number',
        bankSwiftCode: 'Enter Bank Swift Code',
        address: 'Address',
        city: 'City',
        state: 'State',
        country: 'Country',
        zipCode: 'Zip Code'
      }
    },
    buttons: {
      add: 'Add',
      save:'Save'
    }
  }
};