module.exports = {
  label: {
    changeProfilePic: 'Change Profile Picture',
    companyImage: 'Upload Company Image',
    identityDocument: 'Identity Document',
    changeDocument: 'Change ID Document',
    uploadDocument : 'Upload ID Document',
    viewDocument: 'View Document',
    companyProfile: 'Company Images',
    areYouSure: 'Are you sure you want to delete this image?',
    confirmTitle: 'Are you sure?',
    confirmMsg: 'You want to delete this!',
    documentSizeValidation:'You cannot upload document greater then 10 MB is size',
    moreThanThreeValidation:'You cannot upload more than 3 files',
    extensionValidation:'Only .jpeg and .pdf extensions are allowed',
    extensionValidationImage:'Only .jpeg .jpg and .png extensions are allowed',
    companyImageSizeValidation:'One of your file greater then 10 MB is size',
    noFileChosen: "No file chosen",
    form: {      
      label: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        contactNumber: 'Contact Number',
        contactType: 'Contact Type',
        address:'Address',
        country: 'Country',
        state: 'State',
        city: 'City',
        zipCode: 'Zip Code',
        brokerId:'Broker-id',
        companyName:'Company Name',
        companyEmail:'Company Email'
      },
      validation: {
        firstName: 'first name',
        lastName: 'last name',
        email: 'email',
        contactNumber: 'contact number',
        contactType: 'contact type',
        address: 'address',
        city: 'city',
        state: 'state',
        country: 'country',
        zipCode: 'zip code',
        brokerId:'broker-ID',
        companyName:'company name',
        companyEmail:'company email'
      },
      placeholder: {
        enterfname: 'Nombre',
        enterlname:'Apellido',        
        email: 'example@mail.com',
        enterNumber: 'Enter Number',
        select: 'Select',
        city:'City',
        searchContact: 'Search Contact',
        contactNumber: 'Contact Number',
        address: 'Address',
        zipCode: 'Zip Code',
        brokerId: 'Broker ID',
        brokerIdNote: '*First part 2 letters - Second part 4 numbers, Ex: ab-1234',
        companyName:'Company Name',
        companyEmail:'Company Email'
      }
    },
    buttons: {
      update: 'Update',
      removeImage: 'Delete Image',
      yes: 'Yes',
      no: 'No',
      ok : 'Ok'
    },
    tooltip:{
      document : 'Identity Document'
    }
  }
};