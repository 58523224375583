<!-- content from staff_management.js -->
<template>
  <ValidationObserver ref="observer" v-slot="{ passes }">
    <!-- <template v-if="fromProperty">         -->
    <!-- alert component -->
    <alert-section currentModule="staff_member"></alert-section>
    <!-- </template> -->
    <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
      <b-container fluid class="px-0 pb-fix-btn">
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <label class="form-label">{{ lang.label.form.label.firstName }}<span class="text-danger">*</span></label>
              <ValidationProvider
                :name="lang.label.form.validation.firstName"
                rules="required|max:50"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <b-form-input
                    v-model="models.firstName"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="lang.label.form.placeholder.enter"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label class="form-label">{{ lang.label.form.label.lastName }}<span class="text-danger">*</span></label>
              <ValidationProvider
                :name="lang.label.form.validation.lastName"
                rules="required|max:50"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <b-form-input
                    v-model="models.lastName"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="lang.label.form.placeholder.enter"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label class="form-label">{{ lang.label.form.label.email }}<span class="text-danger">*</span></label>
              <ValidationProvider
                :name="lang.label.form.validation.email"
                rules="required|email"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <b-form-input
                    v-model="models.email"
                    type="text"
                    class="text-lowercase"
                    :state="getValidationState(validationContext)"
                    :placeholder="lang.label.form.placeholder.email"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label class="form-label">{{ lang.label.form.label.contactNumber }}<span class="text-danger">*</span></label>
              <ValidationProvider
                :name="lang.label.form.validation.contactNumber"
                rules="required|numeric|min:6|max:8"
                v-slot="validationContext"
              >
                <div class="position-relative prepend-icon prepend-text country-code">
                  <b-form-input
                    v-model="models.contactNumber"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="lang.label.form.placeholder.contactNumber"
                  ></b-form-input>
                  <div class="icon">
                    <div class="pre-app-text primary-text bf-size">+507</div>
                  </div>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label class="form-label">{{ lang.label.form.label.role }}<span class="text-danger">*</span></label>
              <ValidationProvider
                :name="lang.label.form.validation.role"
                rules="required"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <el-select
                    v-model="models.role"
                    :placeholder="lang.label.form.placeholder.select"
                    :state="getValidationState(validationContext)"
                    :class="{ 'is-invalid': validationContext.errors[0] }"
                  >
                    <el-option
                      v-for="item in selectRole"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
      <div class="add-contact-button">
        <!-- <b-btn variant="primary" block type="submit" v-if="!saveBTn">{{ lang.label.buttons.addContact }}</b-btn> -->
        <b-btn variant="primary" block type="submit" v-if="isEdit">{{
          lang.label.buttons.editText
        }}</b-btn>
        <template v-else>
          <b-btn variant="primary" block type="submit" v-if="fromProperty">{{
            lang.label.buttons.addMember
          }}</b-btn>
          <b-btn variant="primary" block type="submit" v-else>{{
            lang.label.buttons.inviteAdd
          }}</b-btn>
        </template>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import alertSection from "@/views/shared/AlertSection.vue";

export default {
  components: {
    "alert-section": alertSection,
  },
  props: ["userId", "isEdit", "clearData", "fromProperty"],
  data() {
    return {
      lang: this.$lang.staff_management,
      data: jsondata,
      saveBTn: false,
      models: {
        firstName: "",
        lastName: "",
        email: "",
        contactNumber: "",
        role: "",
        user_id: "",
      },
      selectRole: [],
    };
  },
  mounted() {
    this.getRoles();
    this.viewStaffMember();
  },
  watch: {
    clearData(val) {
      if (val) {
        this.models = {
          firstName: "",
          lastName: "",
          email: "",
          contactNumber: "",
          role: "",
          user_id: "",
        };
        this.$refs.observer.reset();
        this.viewStaffMember();
      }
    },
  },
  methods: {
    clearForm() {
      this.models = {
        firstName: "",
        lastName: "",
        email: "",
        contactNumber: "",
        role: "",
        user_id: "",
      };
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getRoles() {
      this.$store.dispatch("listRole").then((response) => {
        if (response.status == 200) {
          response.data.forEach((v, i) => {
            this.selectRole.push({
              text: v.name,
              value: v.id,
            });
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    viewStaffMember() {
      let self = this;
      if (self.isEdit) {
        self.$store.dispatch("viewStaffMember", self.userId).then((response) => {
          if (response.status == 200) {
            let memberDetails = response.data;
            self.models = {
              id: memberDetails.id,
              firstName: memberDetails.first_name,
              lastName: memberDetails.last_name,
              role: memberDetails.role ? memberDetails.role.id : "",
              email: memberDetails.username,
              contactNumber: memberDetails.contact,
              dateAdded: memberDetails.added_date,
              status: memberDetails.status,
              user_id: memberDetails.user_id,
            };
          } else {
            let message = response.data.message || response.data.detail;
            if (message) {
              self.$store.commit("setMessages", {
                type: "danger",
                message: message,
              });
            }
          }
        });
      }
    },
    onSubmit() {
      let self = this;
      let memberDetails = {
        first_name: self.models.firstName,
        last_name: self.models.lastName,
        username: self.models.email.toLocaleLowerCase(),
        contact: self.models.contactNumber,
        role: self.models.role,
      };
      let method = "addStaffMember";
      if (self.isEdit) {
        method = "updateStaffMember";
        memberDetails["user_id"] = self.models.user_id;
      }
      self.$store.dispatch(method, memberDetails).then((response) => {
        if (!self.fromProperty) {
          self.$emit("handleMember");
        }
        if (response.status == 200) {
          if (self.fromProperty) {
            self.$emit("handleMember", {
              ...memberDetails,
              name: self.models.firstName + " " + self.models.lastName,
              email: self.models.email.toLocaleLowerCase(),
              user_id: response.data.staff.user_id
            });
            this.clearForm();
            this.$refs.observer.reset();
          }
          if (!self.fromProperty) {
            self.$emit("updateSidebar");
            self.$store.commit("setMessages", {
              type: "success",
              message: response.data.message,
            });
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "staff_member",
            });
          }
        }
      });
    },
  },
};
</script>
