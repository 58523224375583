import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            notifications: [],
            notificationsHeader: [],
            notificationDataCount: ''
        }
    },
    computed: {
        ...mapGetters({
            login: "login"
        })
    },
    methods: {
        getAllNotifications(notification, from) {
            let self = this;
            let data = {
                size: from == 'header' ? 5 : notification.size,
                page: notification.page
            }
            self.$store.dispatch("listNotification", data).then((response) => {
                if (response.status == 200) {
                    // Header variables  and list variables are different
                    if (from == 'header') {
                        self.notificationsHeader = response.data.results;
                    } else if (from == 'notification') {
                        self.notifications = response.data.results;
                    }
                    self.notificationDataCount = response.data.count;
                    let loginData = self.login;
                    loginData.unread_notification_count =
                        response.data.unread_count;
                    self.$store.commit("setLogin", loginData);

                } else {
                    let message = response.data.message || response.data.detail;
                    if (message) {
                        self.$store.commit("setMessages", {
                            type: "danger",
                            message: message,
                        });
                    }
                }
            });
        }
    },
}