module.exports = {
    label: {
      sendMail: 'Hemos Enviado Un Enlace A La Dirección De Correo Electrónico Registrada Para Añadir Los Datos Bancarios.',
      form: {      
        label: {
          firstName: 'Nombre',
          lastName: 'Apellido',
          email: 'Correo Electrónico',
          contactNumber: 'Número De Teléfono',
        },
        validation: {
          firstName: 'nombre',
          lastName: 'apellido',
          email: 'correo electrónico',
          contactNumber: 'número de teléfono',
        },
        placeholder: {
          enter: 'Ingrese',
          email: 'example@mail.com',
          enterNumber: 'Ingrese El Número',
        }
      },
      buttons: {
        sendRequest: 'Enviar Petición'
      }
    }
  };