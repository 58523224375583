var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body-content"},[_vm._m(0),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"starts-section"},[_c('alert-section'),_c('div',{staticClass:"page-breadcrumb"},[_c('div',{staticClass:"d-md-flex justify-content-between align-items-center"},[_c('div',{staticClass:"pg-bread"},[_c('b-breadcrumb',{staticClass:"bg-transparent p-0 mb-0"},[_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" "+_vm._s(_vm.lang.label.rolesPermissions)+" ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_c('span',{staticClass:"material-icons align-middle"},[_vm._v("people")]),_c('span',{staticClass:"align-middle ml-1"},[_vm._v(_vm._s(_vm.tableData.length || ''))])])],1)],1),_c('div',{staticClass:"pg-right"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-btn',{staticClass:"text-nowrap btn-mw w-sm-100",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.addNewRole}},[_vm._v("+ "+_vm._s(_vm.lang.label.buttons.add))])],1)])])]),_c('div',{staticClass:"table-section vertical-top"},[_c('div',{staticClass:"table-card table-border-none with-pagination-table"},[_c('b-card',{staticClass:"custom-card reports-table",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"p-0"},[_c('v-client-table',{attrs:{"data":_vm.tableData,"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"permissions",fn:function(props){return [_c('span',{domProps:{"innerHTML":_vm._s(
                    _vm.getProcessedStr(
                      props.row.permissions.length > 0
                        ? '• ' + props.row.permissions.join('\n• ')
                        : 'N/A'
                    )
                  )}})]}},{key:"actions",fn:function(props){return [_c('el-tooltip',{attrs:{"content":_vm.lang.label.tooltip.edit,"placement":"top"}},[_c('b-btn',{staticClass:"p-0",attrs:{"variant":"link"}},[_c('span',{staticClass:"material-icons",on:{"click":function($event){return _vm.editRole(props.row.id)}}},[_vm._v("edit")])])],1),_c('el-tooltip',{attrs:{"content":_vm.lang.label.tooltip.delete,"placement":"top"}},[(props.row.is_allow_delete)?_c('b-btn',{staticClass:"p-1",attrs:{"variant":"link"},on:{"click":function($event){return _vm.deleteRole(props.row.id)}}},[_c('span',{staticClass:"material-icons"},[_vm._v("delete")])]):_vm._e()],1)]}}])})],1)],1)],1)])],1),_c('b-sidebar',{attrs:{"id":"addNewRole","footer-class":"sidebar-footer","sidebar-class":"sidebar-view","text-variant":"none","backdrop":"","right":"","width":"462px","visible":_vm.isVisible},on:{"change":_vm.updateSidebar},scopedSlots:_vm._u([{key:"header",fn:function({ hide }){return [_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-btn',{staticClass:"p-0",attrs:{"variant":"link","size":"sm"},on:{"click":hide}},[_c('img',{attrs:{"src":_vm.getIconUrl('close-sm.svg'),"alt":"close"}})]),_c('h4',{staticClass:"sidebar-header-title mb-0"},[_vm._v(_vm._s(_vm.isEdit ? _vm.lang.label.editRolesPermission : _vm.lang.label.addRolesPermission))])],1)])]}}])},[_c('div',{staticClass:"amenity-body"},[(_vm.isVisible)?_c('update-role',{attrs:{"isEdit":_vm.isEdit,"roleId":_vm.roleId},on:{"handleUpdate":_vm.handleUpdate}}):_vm._e()],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-sticky"},[_c('div',{staticClass:"top-bar-radius"})])
}]

export { render, staticRenderFns }