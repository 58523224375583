<!-- content from signup.js -->
<template>
  <div class="signup-detail-section">
    <div class="form-header with-back-arrow">
      <div class="back-arrow">
        <b-btn variant="link p-0" @click="goBack()"
          ><img :src="getIconUrl('arrow_back.svg')" alt="back"
        /></b-btn>
      </div>
      <!-- alert component -->
      <alert-section></alert-section>
      <h1 class="form-heading mb-2">{{ lang.label.register }}</h1>
      <!-- <p class="sf400">{{ lang.label.enterYourDetails }}</p> -->
    </div>

    <ValidationObserver ref="observer" v-slot="{ passes }">
      <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
        <b-row class="signup-details section-space">
          <b-col cols="12">
            <b-form-group>
              <ValidationProvider
                :name="lang.label.form.validation.plan"
                rules="required"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <el-select
                    :state="getValidationState(validationContext)"
                    v-model="models.selectedPlan"
                    :placeholder="lang.label.form.placeholder.selectPlan"
                    :class="{ 'is-invalid': validationContext.errors[0] }"
                  >
                    <el-option
                      v-for="item in planList"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <ValidationProvider
                :name="lang.label.form.validation.firstName"
                rules="required|max:50"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <b-form-input
                    v-model="models.firstName"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="lang.label.form.placeholder.firstName"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <ValidationProvider
                :name="lang.label.form.validation.lastName"
                rules="required|max:50"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <b-form-input
                    v-model="models.lastName"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="lang.label.form.placeholder.lastName"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <ValidationProvider
                :name="lang.label.form.validation.email"
                rules="required|email"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <b-form-input
                    v-model="models.email"
                    v-lowercase
                    type="text"
                    :state="getValidationState(validationContext)"
                    autocomplete="new-email"
                    :placeholder="lang.label.form.placeholder.email"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12">
            <b-form-group>
              <ValidationProvider
                :name="lang.label.form.validation.brokerRealtorId"
                :rules="{
                  required: true,
                  regex: /^(?=.*?\b([a-zA-Z]{2})[-]([0-9]{4})\b).{7}$/,
                }"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <b-form-input
                    v-model="models.brokerId"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="lang.label.form.placeholder.brokerRealtorId"
                  ></b-form-input>
                  <small class="sm-font text-grey">{{
                    lang.label.form.placeholder.brokerIdNote
                  }}</small>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col> -->
          <b-col cols="12">
            <b-form-group>
              <ValidationProvider
                :name="lang.label.form.validation.password"
                :rules="{
                  required: true,
                  regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$!#%*?&]{8,}$/,
                }"
                v-slot="validationContext"
                vid="password"
              >
                <div class="position-relative">
                  <b-form-input
                    v-model="models.password"
                    type="password"
                    :state="getValidationState(validationContext)"
                    autocomplete="new-password"
                    :placeholder="lang.label.form.placeholder.password"
                  ></b-form-input>
                  <small class="sm-font text-grey">{{
                    lang.label.form.placeholder.passwordFormateNote
                  }}</small>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <ValidationProvider
                :name="lang.label.form.validation.confirmPassword"
                rules="required|confirmed:password"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <b-form-input
                    v-model="models.confirmPassword"
                    type="password"
                    :state="getValidationState(validationContext)"
                    autocomplete="confirm-password"
                    :placeholder="lang.label.form.placeholder.confirmPassword"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-3">
            <b-form-group>
              <ValidationProvider
                :name="lang.label.form.validation.termsConditionAgree"
                :rules="{ required: { allowFalse: false } }"
                v-slot="validationContext"
              >
                <div class="position-relative">
                  <b-form-checkbox
                    v-model="models.termsConditionAgree"
                    :class="{ 'is-invalid': validationContext.errors[0] }"
                    name="termsConditionAgree"
                    value="selected"
                    :state="getValidationState(validationContext)"
                  >
                    {{ lang.label.agreeTerms }}
                    <router-link
                      to=""
                      event=""
                      class="text-underline"
                      @click.native="showCMSContent(2)"
                      >{{ lang.label.termsConditions }}</router-link
                    >
                    {{ lang.label.and }}
                    <router-link
                      to=""
                      event=""
                      class="text-underline"
                      @click.native="showCMSContent(1)"
                      >{{ lang.label.privacyPolicy }}</router-link
                    >
                  </b-form-checkbox>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group class="pt-3">
          <b-btn block variant="primary" type="submit"
            >{{ lang.label.buttons.continue }}
          </b-btn>
        </b-form-group>
      </b-form>
    </ValidationObserver>
    <cms-section :cmsContent1="cmsContent1" :cmsContent2="cmsContent2"></cms-section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import alertSection from "@/views/shared/AlertSection.vue";
import cmsSection from "@/views/shared/CmsSection.vue";
export default {
  components: {
    "alert-section": alertSection,
    "cms-section": cmsSection,
  },
  computed: {
    ...mapGetters({
      messages: "messages",
      selectedPlan: "selectedPlan",
    }),
  },
  data() {
    return {
      lang: this.$lang.signup,
      models: {
        firstName: "",
        lastName: "",
        email: "",
        brokerId: "",
        password: "",
        confirmPassword: "",
        termsConditionAgree: "",
        selectedPlan: "",
      },
      planList: [],
      cmsContent1: "",
      cmsContent2: "",
      cmsType: [
        {
          1: 1,
          2: 2,
        },
        {
          1: 3,
          2: 4,
        },
      ],
    };
  },
  watch: {
    'models.selectedPlan'(val) {
      this.$store.commit('setSelectedPlan', val);
    }
  },
  mounted() {
    this.listPlan();
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/styles/img/" + img);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    /**
     * Submits user details for register
     */
    onSubmit() {
      let self = this;
      let data = {
        first_name: self.models.firstName,
        last_name: self.models.lastName,
        email: self.models.email.toLocaleLowerCase(),
        // broker_id: self.models.brokerId,
        password: self.models.password,
        plan: self.models.selectedPlan,
      };
      self.$store.dispatch("userRegister", data).then((response) => {
        if (response.status == 200) {
          self.$store.commit("setLogin", response.data);
          self.$store.commit("setNewUser", true);
          self.$router.push({ name: "dashboard" });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    listPlan() {
      let self = this;
      self.$store.dispatch("listPlan").then((response) => {
        if (response.status == 200) {
          self.planList = response.data;
          self.models.selectedPlan = self.planList[0] ? self.planList[0].id : "";
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    goBack() {
      // this.$emit("registerDetailsGo", false);
      this.$router.go(-1)
    },
    /**
     * Handles fetching CMS content based on user type and CMS type
     * @param {Number} type CMS type
     */
    showCMSContent(type) {
      let self = this;
      for (let i = 0; i < 2; i++) {
        self.$store.dispatch("getCMSContent", self.cmsType[i][type]).then((response) => {
          if (response.status == 200) {
            self['cmsContent' + (i+1)] = response.data.value;
            self.$bvModal.show("viewCMS");
          } else {
            let message = response.data.message || response.data.detail;
            if (message) {
              self.$store.commit("setMessages", {
                type: "danger",
                message: message,
              });
            }
          }
        });
      }
    },
  },
};
</script>
