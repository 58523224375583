module.exports = {
  label: {
    required: '*',
    form: {      
      label: {
        firstName: "First Name",
        lastName: 'Last Name',
        email: 'Email',
        contactNumber: 'Contact Number',
        cardNumber: 'Card Number',
        nameOnCard: 'Name on card',
        expiryDate: 'Expiry Date',
        cvv: 'CVV',
        address: 'Address',
        city: 'City',
        state: 'State',
        country: 'Country',
        zipCode: 'Zip Code'
      },
      validation: {
        firstName: "First Name",
        lastName: 'Last Name',
        email: 'Email',
        contactNumber: 'Contact Number',
        cardNumber: 'Card Number',
        nameOnCard: 'Name on card',
        expiryDate: 'Expiry Date',
        cvv: 'CVV',
        address: 'address',
        city: 'city',
        state: 'state',
        country: 'country',
        zipCode: 'zip code'
      },
      placeholder: {
        firstName: "Enter",
        lastName: 'Enter',
        email: 'example@mail.com',
        contactNumber: 'Enter Number',
        cardNumber: 'XXXX XXXX XXXX XXXX ',
        nameOnCard: 'Enter',
        expiryDate: 'MM/YY',
        cvv: 'Enter',
        address: 'Address',
        city: 'City',
        state: 'State',
        country: 'Country',
        zipCode: 'Zip Code'
      }
    },
    buttons: {
      add: 'Add Card'
    }
  }
};