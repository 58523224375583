<!-- content from contacts.js -->
<template>
  <div>
    <b-form-group class="search-sticky">
      <div class="position-relative">
        <b-form-input
          v-model="searchContact"
          type="text"
          :placeholder="lang.label.form.placeholder.searchContact"
        ></b-form-input>
      </div>
    </b-form-group>
    <b-list-group class="contact-user-list">
      <b-list-group-item
        class="d-flex justify-content-between align-items-center bg-transparent border-0 rounded-0 p-0"
        v-for="(user, index) in contactUsersListing"
        :class="selectedContact && selectedContact.id == user.id ? 'selected-user' : ''"
        :key="index"
      >
        <b-media>
          <template #aside>
            <img :src="getImgUser(user.pic)" :alt="user.name" v-if="user.pic"/>
            <span class="material-icons" v-else>account_circle</span>
          </template>
          <h6 class="contact-name mb-0">{{ user.name }}</h6>
          <p class="contact-email mb-0">{{ user.email }}</p>
        </b-media>
        <b-btn variant="primary" class="ml-2" @click="select(user)">{{
          selectedContact && selectedContact.id == user.id
            ? lang.label.buttons.selected
            : lang.label.buttons.select
        }}</b-btn>
      </b-list-group-item>
    </b-list-group>
    <span v-if="contactUsersListing.length == 0">{{ lang.label.form.label.noRecord }}</span>
  </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  props: ["type", "selectedContact", "selectedBroker", "selectedOwner",],
  data() {
    return {
      lang: this.$lang.contacts,
      data: jsondata,
      searchContact: "",
      contactType: {
        all: 1,
        owner: 2,
        broker: 3,
        tenant: 4,
        other: 5,
      },
      contactData: [],
    };
  },
  computed: {
    ...mapGetters({
      ImageUrl: "ImageUrl",
    }),
    contactUsersListing() {
      return this.contactData.filter((user) => {
        return user.name.toLowerCase().includes(this.searchContact);
      });
    },
  },
  mounted() {
    this.listContacts();
  },
  methods: {
    getImgUser(img) {
      if (img.length > 0) {
        return this.ImageUrl + img;
      }
      return require("@/assets/styles/img/users/user.jpeg");
    },
    /**
     * Handles loading tabale data based on active tab and applied date filter
     */
    listContacts() {
      let self = this;
      let data = {
        page: 1,
        size: 100000,
        data: {
          type: self.contactType[self.type],
        },
      };
      self.contactData = [];
      self.$store.dispatch("listContacts", data).then((response) => {
        if (response.status == 200) {
          self.contactData = [];
          response.data.results.forEach((el) => {
            self.contactData.push({
              id: el.id,
              name: el.first_name + " " + el.last_name,
              first_name: el.first_name,
              last_name: el.last_name,
              email: el.email,
              contact: el.contact,
              pic: el.profile_image,
            });
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Handles user select event and sends user data to parent
     * @param {object} user Selected user
     */
    select(user) {
      if ((this.selectedBroker && this.selectedBroker.email && this.selectedBroker.email == user.email) || (this.selectedOwner && this.selectedOwner.email && this.selectedOwner.email == user.email)) {
        Swal.fire({
          title: this.$lang.add_properties.label.warning,
          text: this.$lang.add_properties.label.sameUserAlert,
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: this.$lang.add_properties.label.buttons.ok,
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        });
      } else {
        this.$emit("handleContactSelect", user);
      }
    },
  },
};
</script>
