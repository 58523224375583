module.exports = {
    label: {
      changePassword: "Cambiar La Contraseña",
      form: {
        validation: {
          currentPassword: 'contraseña actual',
          newPassword: 'nueva contraseña',
          reEnterPassword: 're-ingrese nueva contraseña',
        },
        placeholder: {
          currentPassword: 'Introducir La Contraseña Actual',
          newPassword: 'Ingrese Nueva Contraseña',
          reEnterPassword: 'Re-ingrese Nueva Contraseña',
          passwordFormateNote: '*Longitud De 8 O Más, Incluir Al Menos Una Mayúscula, Minúscula, Caracteres Especiales Y Un Número. Ej: Miradoor123*',
        }
      },
      buttons: {
        update: 'Cambiar La Contraseña',
      }
    }
  };