import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/styles/style.scss";
import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale_en from 'element-ui/lib/locale/lang/en'
import locale_es from 'element-ui/lib/locale/lang/es'
let language = localStorage.getItem('language');
let locale = locale_es;
if (language && language == "en") {
  locale = locale_en;
} else {
  locale = locale_es;
}
Vue.use(ElementUI, { locale })
import * as rules from 'vee-validate/dist/rules';
let Lang = require('vuejs-localization');
Lang.requireAll(require.context('./lang', true, /\.js$/));
Vue.use(Lang, {
  default : language ? language : 'es',
});
import * as VeeValidate from "vee-validate";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
} from "vee-validate/dist/vee-validate.full.esm";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.use(VeeValidate, {
  // This is the default
  inject: true,
  // Important to name this something other than 'fields'
  fieldsBagName: "veeFields",
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

/**
 * Handles lowercasing value of given input
 */
Vue.directive('lowercase', {
  update(el) {
    el.value = el.value.toLowerCase()
  }
})

setTimeout(function () {
  let messages = '';
  let max_value_message = '{_field_} needs to be less than {max}';
  if (language && language == "en") {
    messages = require('vee-validate/dist/locale/en.json');
    max_value_message = '{_field_} needs to be less than {max}';
  } else {
    messages = require('vee-validate/dist/locale/es.json');
    max_value_message = '{_field_} debe ser menor que {max}';
  }
  Object.keys(rules).forEach(rule => {
    extend(rule, {
      ...rules[rule], // copies rule configuration
      message: rule == 'max_value' ? max_value_message : messages.messages[rule] // assign message
    });
  });
}, 0)