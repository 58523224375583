module.exports = {
  label: {
    warning: 'Warning',
    subscriptionAlert: 'Purchase subscription plan to access this module',
    parentSubscriptionAlert:'Your parent subscription plan has been expire, Purchase subscription plan to access this module',
    verificationAlert: 'Please verify yourself to access this module',
    noRecord: 'No matching records',
    filter: 'Filter:',
    filterPlaceholder: 'Search query',
    limit: "Records:",
    count: "Showing {from} to {to} of {count} records|{count} records|One record",
    countRolesAndPermission:"Showing {from} to {to} of {count} records|{count} records|One record",
    deleted: "Deleted!",
    paymentValidations: {
      fieldEmpty: "Field is empty",
      cardFormate: "Card number must be 13-19 digits and a recognizable card format",
      cardExpired: "Expiration date must be a present or future month and year",
      cvvValidation: "CVV must be 3 or 4 digits",
    },
    buttons: {
      ok: 'Ok'
    }
  }
}