import moment from 'moment';
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      language: "language",
    }),
  },
  created() {
    moment.updateLocale(this.language, {});
  },
  methods: {
    handleKeyBoard() {
      let self = this;
      if (!self.focused) {
        // self.$refs.ExistInput.blur();
        self.interval.push(
          setInterval(() => {
            if (document.activeElement) {
              document.activeElement.blur();
              self.interval.forEach((i) => {
                clearInterval(i);
              });
              self.interval = [];
            }
          }, 50)
        );
      } else {
        setTimeout(() => {
          self.focused = false;
        }, 500);
      }
    },
    handleEvenListener() {
      let self = this;
      window.addEventListener("scroll", self.handleKeyBoard);
      self.removeListener();
    },
    removeListener() {
      let self = this;
      self.$on("hook:beforeDestroy", () => {
        window.removeEventListener("scroll", self.handleKeyBoard);
      });
    },
    handleFocus(e) {
      this.focused = true;
      window.dispatchEvent(new KeyboardEvent("scroll"));
    },
    /**
     * Get \n processed text
     * @param {string} data To be processed
     * @returns String
     */
    getProcessedStr: function (data) {
      return data ? data.replaceAll('\n', '<br/>') : data;
    },
    /**
     * Get space processed text
     * @param {string} data To be processed
     * @returns String
     */
    getSpaceProcessedStr: function (data) {
      return data ? data.replaceAll(' ', '<br/>') : data;
    },
    /**
     * Handle formating date from yyyy-mm-dd to dd/mm/yyyy
     * @param {String} date Value that needs to be formated
     * @returns String formated date
     */
    formatDateToShow: function (date) {
      return moment(date, "YYYY-MM-DD").format('DD-MM-YYYY').split('-').join('/');
    },
    /**
     * Handle formating date from dd-mm-yyyy to yyyy-mm-dd
     * @param {String} date Value that needs to be formated
     * @returns String formated date
     */
    formatDateReverse: function (date) {
      return moment(date, "DD-MM-YYYY").format('YYYY-MM-DD');
    },
    /**
     * Handle formating date from dd-mm-yyyy to yyyy-mm-dd
     * @param {String} date Value that needs to be formated
     * @returns String formated date
     */
    formatDateTimeReverse: function (date) {
      return moment(date, "DD-MM-YYYY HH:mm:ss").format('YYYY-MM-DD HH:mm:ss');
    },
    /**
     * Handle formating date from dd-mm-yyyy to dd/mm/yyyy
     * @param {String} date Value that needs to be formated
     * @returns String formated date
     */
    formatDateToSlash: function (date) {
      return date.split('-').join('/');
    },
    /**
    * Handle formating  date string to dd-mm-yyyy
    * @param {String} date Value that needs to be formated
    * @returns String formated date
    */
    formatDateStringToDate: function (date) {
      return moment(date).format('DD-MM-YYYY');
    },
    /**
    * Handle formating date string to dd/mm/yyyy
    * @param {String} date Value that needs to be formated
    * @returns String formated date
    */
    formatDateStringToDateSlash: function (date) {
      return moment(date, 'DD-MM-YYYY').format('DD/MM/YYYY');
    },
    /**
    * Handle formating date string to dd/mm/yyyy
    * @param {String} date Value that needs to be formated
    * @returns String formated date
    */
    formatDateStringToDateTimeSlash: function (date) {
      return moment(date, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY hh:mm A');
    },
    /**
    * Handle formating  date string to dd-mm-yyyy
    * @param {String} date Value that needs to be formated
    * @returns String formated date
    */
    formatDateStringToDate2: function (date) {
      return moment(date).format('DD/MM/YYYY');
    },
    /**
    * Disables date greater than today's date
    * @param {String} date and dateLimit Value that needs to be compared
    * @returns date lesser than today's date 
    */
    disabledEndDate(date, dateLimit) {
      if (dateLimit) {
        return date.getTime() > dateLimit
      } else {
        return date.getTime() < Date.now()
      }
    },
    formatTime: function (time) {
      // Get Hours
      var h1 = Number(time[0] - '0');
      var h2 = Number(time[1] - '0');

      var hh = h1 * 10 + h2;

      let convertedTime = ''

      // Finding out the Meridien of time
      // ie. AM or PM   
      var Meridien;
      if (hh < 12) {
        Meridien = "am";
      }
      else
        Meridien = "pm";

      hh %= 12;

      // Handle 00 and 12 case separately
      if (hh == 0) {
        convertedTime += "12";
        // Printing minutes and seconds
        for (var i = 2; i < 5; ++i) {
          convertedTime += time[i]
        }
      }
      else {
        convertedTime += hh;
        // Printing minutes and seconds
        for (var i = 2; i < 5; ++i) {
          convertedTime += time[i];
        }
      }
      return convertedTime + Meridien;
    },
    /**
     * Handles document name
     * @param {string} doc Document name
     * @param {number} len Configurable document name length
     * @returns Configured document name
     */
    getDocName: function (doc, len) {
      if (doc) {
        let length = len ? len : 20
        let name = doc.split('/')[doc.split('/').length - 1];
        return name.length > length ? name.slice(0, length) + '..' : name;
      } else {
        return 'N/A';
      }
    },
    /**
     * Decrypt id
     * @param {number} id 
     * @returns Decrypted ID
     */
    decryptID: function (id) {
      var returnid;
      try {
        returnid = window.atob(id);
      }
      catch(err) {
        returnid = ""
      }
      return returnid;
    },
    /**
     * Handles scrolling to validation error on submit
     */
    scrollToError() {
      this.$nextTick(() => {
        setTimeout(() => {
          const el = this.$el.querySelector(".is-invalid") ? this.$el.querySelector(".is-invalid").parentElement : null;
          if (el) {
            el.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
            setTimeout(() => {
              el.querySelector('input').focus();
            }, 400);
          }
        }, 300);
      });
    }
  }
}