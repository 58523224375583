module.exports = {
  label: {
    verifyEmialAlert: "Verify your email to get full access to Miradoor, you may not be able to add a contact or properties.",
    myContacts: 'My Contacts',
    myContactDetail: 'My Contact Details',
    editContact: "Edit Contact",
    addNewContact: 'Add New Contact',
    assignHome: 'Assigned Properties of',
    bankDetails:'Bank Details of',
    notes: 'Notes',
    addNoteLabel: 'Add Note',
    other: 'Other',
    dateFilter: 'Date Filter',
    from: 'From',
    to: 'To',
    warning : 'Warning',
    confirmTitle: 'Are you sure?',
    confirmMsg: 'You want to delete this!',
    confirmContactMsg:'This user is already added to the system. Are you sure you want to add the user to your contact list?',
    staffWarningMsg: 'This user is already added to the staff',
    noRecord: 'No record available',
    tableHeader: {
      contactName: "Contact Name",
      role: "Role",
      emailId: "Email ID",
      contact: "Contact",
      dateAdded: "Date Added",
      actions: "Actions",
    },
    message: {
      delete: 'Are you sure your want to delete this contact?',
      deleteNoteText: 'Are you sure your want to delete this note?',
    },
    tabs: {
      all: 'All',
      broker: 'Realtor',
      tenant: 'Tenant',
      owner: "Owner",
      other: 'Other'
    },
    buttons: {
      sendCode: 'Send Verification Code',
      add: 'Add New Contact',
      update: 'Update',
      assignHome: 'Assigned Properties',
      delete: 'Delete',
      edit: 'Edit',
      yes: 'Yes',
      no: 'No',
      ok:'Ok',
      assign: 'Assign',
      save: 'Save',
      cancel: 'Cancel',
      addNote: 'Add Note',
      filter: 'Apply Filter',
    },
    validation: {
      note: 'note'
    },
    tooltip:{
      viewContact : 'View Contact',
      notes : 'View Notes',
      assignedProperties : 'Assigned Properties',
      deleteContact : 'Delete Contact',
      bankDetails:'View Bank Details',
      editNote: 'Edit Note',
      deleteNote: 'Delete Note'
    }
  }
};