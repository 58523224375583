
<template>
    <b-container class="auth d-flex align-items-center" v-loading="loading">
        <div class="bank-wrapper">
            <div class="for-bank-detail-logo">
                <img :src="getImgUrl('logo.svg')" alt="miradoor" />
            </div>
            <div class="bank-detail-box" >
                <add-bank-details  :request="request"></add-bank-details>
            </div>
            
        </div>
    </b-container>
</template>
  
<script>
import AddBankDetails from "@/components/bank/AddDetails.vue";
import { mapGetters } from "vuex";
import NotFound from './NotFound.vue';

export default {
    computed:{
    ...mapGetters({
      loading: "loading",
      isUserVerified: "isUserVerified",
    })
  },
    components: {
        "add-bank-details": AddBankDetails,
    },
    data() {
        return {
            request : false
        };
    },
    created(){
        this.checkVerify();
    },
    methods: {
        getImgUrl(img) {
            return require("@/assets/styles/img/" + img);
        },
        checkVerify(){
            let self = this;
            if (this.isUserVerified) {
                this.$store.commit("setVerificationAlert", {status: true, redirection: true});
            } else {
                let data = {
                    "bank_request_id" : self.$route.params.token
                }
                self.$store.dispatch('verifyInquiry',data).then((response) => {
                    if(response.status != 200){
                       self.request = false
                       self.$router.push({ name: "not-found" });
                    } else {
                        self.request = true
                    }
                })
            }
        }
    },
}
</script>
  