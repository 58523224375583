module.exports = {
  label: {
    dateAdded: "Added Date",
    addProperties: 'Add Property',
    readMore: 'Read More',
    amenities: 'Amenities',
    viewAll: 'View All',
    viewLess: 'View Less',
    propertyReport: 'Property Report',
    maintenanceFee: 'Maintenance Fee',
    leaseAmount: 'Lease Amount',
    advanceAmount: 'Advance Amount',
    monthly: 'Monthly',
    brokerDetails: 'Broker Details',
    ownerDetails: 'Owner Details',
    tenantDetails: 'Tenant Details',
    memberDetails: 'Property Manager Details',
    leasedTo: 'Leased To',
    leasedPeriod: 'Leased Period',
    startDate: 'Start Date',
    endDate: 'End Date',
    leaseAmountText: 'Lease Amount',
    depositAmount: 'Deposit Amount',
    rentDueDate: 'Rent Due Date',
    maintenanceFeeText: 'Maintenance Fee',
    amountDueFirstMonth: 'Amount Due on First Installment',
    rentDue: 'Next Rent Due ',
    leaseAgreement: 'Lease Agreement (PDF, JPEG, JPG, PNG)',
    areYouSureDelete: 'Are you sure you want to delete this property?',
    areYouSureDeleteLease: "Are you sure you want to delete lease document",
    noRevertBackProperty: '*You wan’t be able to revert back after deleting this property.',
    photosVideos: 'Photos & Videos',
    noLeaseDocMsg: 'No Document Provided',
    documentSizeValidation:'You cannot upload document greater then 10 MB is size',
    contactOwner: 'Contact Owner',
    copyLinkText:'Link is copied to your clipboard',
    idDoc : 'Identity Document',
    notIDDoc:'Not Uploaded',
    rentDetailsTitle: 'Rent Details',
    noMemberAssigned: "No member assigned",
    noBrokerAssigned: "No Broker assigned",
    noOwnerAssigned: "No Owner assigned",
    noTenantAssigned: "No tenant assigned",
    noTenantAssigned_1:"No tenant assigned,please assigned tenant first",
    rentDetails: {
      title: 'Rent Details Summary',
      tenant: 'Tenant',
      leasePeriod: 'Lease Period',
      leaseAmount: 'Lease Amount',
      maintenanceFees: 'Maintenance Fee',
      depositAmount: 'Deposit Amount',
      amountDueFirstMonth: 'Amount Due on First Installment',
    },
    realtor: 'Realtor',
    owner: 'Owner',
    message: 'Message',
    paymentFrequency: {
      monthly: 'Monthly',
      quarterly: 'Quarterly',
      haldYearly: 'Half Yearly',
      yearly: 'Yearly',
    },
    amountType: {
      income: 'Income',
      expense: 'Expense',
    },
    propertyFacilities: {
      livableSpace: 'Livable Space',
      area: 'Area',
      construction: 'Construction',
      bedrooms: 'Bedrooms',
      bathrooms: 'Bathrooms',
      parkings: 'Parkings',
    },
    tableReportHeader: {
      number: "Sr. No",
      name: "Owner Name",
      paymentPeriod: "Rent Billed Type",
      amount: "Rent Amount",
      date: "Payment Date and Time",
      paymentCard: "Payment Card",
      actions: "",
    },
    placeholder: {
      uploadDocument: 'Upload Document'
    },
    brokerCommission: {
      title: 'Broker Commission Fees',
      closingCostComFirst: 'Closing Cost Commission (First Installment Only)',
      recurringRentalFee: 'Recurring Rental Admin Fee',
      recurringRentalFeeBroker: 'Recurring Rental Admin Fee to Broker',
    },
    rentalAmountOwner: {
      title: 'Rental Amount to Owner',
      firstMonthOwner: 'First Installment to Owner',
      recurringRentalAmountDueOwner: 'Recurring Rental Amount Due to Owner'
    },
    form: {      
      label: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        contactNumber: 'Contact Number',
        message: 'Message',
      },
      validation: {
        firstName: 'first name',
        lastName: 'last name',
        email: 'email',
        contactNumber: 'contact number',
        message: 'message',
      },
      placeholder: {
        enter: 'Enter',
        email: 'example@mail.com',
        enterNumber: 'Enter Number',
        contactNumber: 'contact number',
        message: 'Enter Your Message',
      }
    },
    buttons: {
      yes: 'Yes',
      no: 'No',
      shareLink: 'Share Link',
      editProperty: 'Edit Property',
      deleteProperty: 'Delete Property',
      change: 'Change',
      upload: 'Upload',
      remove: 'Remove',
      payRent: 'Pay Rent',
      send: 'Send',
      signAgreement: 'Sign Agreement',
      viewSign: 'View Sign',
      download: 'Download',
      undo: "Undo",
      save: "Save",
      submit: "Submit",
    },
    tooltip:{
      view : 'View Document',
    }
  }
};