module.exports = {
  label: {
    login: "Login",
    dontHaveAccount: "Don’t have a account?",
    signUp: "Sign Up",
    slogan: "You’ll never need another property management platform",
    forgotPassword: 'Forgot Password?',
    or: 'Or',
    signGoogle: 'Sign in With Google',
    signFacebook: 'Sign in With Facebook',
    form: {
      email: 'Email',
      password: 'Password',
      validation: {
        email: 'Email',
        password: 'Password',
        plan: "Plan",
      },
      placeholder: {
        email: 'Email',
        password: 'Password',
        selectPlan: "Select plan"
      }
    },
    buttons: {
      continue: 'Continue',
    }
  }
};