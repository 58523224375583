<!-- content from signup.js -->
<template>
  <div class="signup-with-section section-space">
    <div class="form-header">
      <!-- <div class="back-arrow">
                <b-btn variant="link p-0" @click="goBack()"><img :src="getIconUrl('arrow_back.svg')" alt="back" /></b-btn>
            </div> -->
      <h1 class="form-heading mb-2">{{ lang.label.register }}</h1>
      <!-- <p class="sf400">{{ lang.label.selectUserType }}</p> -->
    </div>

    <b-form-group>
      <b-btn block variant="primary" @click="emailClick()">{{
        lang.label.emailLabel
      }}</b-btn>
    </b-form-group>
    <!-- Social SignIn Section -->
    <social-sign-in type="signup"></social-sign-in>
  </div>
</template>

<script>
import SocialSignIn from "@/views/shared/SocialSignIn.vue"
export default {
  components: {
    'social-sign-in': SocialSignIn,
  },
  data() {
    return {
      lang: this.$lang.signup,
    };
  },
  mounted() {
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/styles/img/" + img);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },

    emailClick() {
      this.$emit("registerDetails", true);
    },
    goBack() {
      this.$emit("signUpGo", true);
    },
  },
};
</script>
