<!-- content from property_details.js -->
<template>
  <div class="body-content">
    <div class="top-sticky">
      <div class="top-bar-radius"></div>
    </div>
    <div class="starts-section" v-loading="loading">
      <!-- alert component -->
      <alert-section></alert-section>

      <!-- body content header  -->
      <div class="page-breadcrumb property-breadcrumbs details-breadcrumbs">
        <div class="d-xl-flex justify-content-between align-items-center add-pro-bread">
          <div class="pg-bread">
            <b-btn variant="link" class="p-0 mr-3 primary-text" @click="goBack()"
              ><span class="material-icons align-middle">arrow_back</span></b-btn
            >
            <b-breadcrumb class="bg-transparent p-0 mb-0">
              <b-breadcrumb-item active>
                <span class="sf700">{{ propertyDetails.property_name }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item active class="text-grey-light">
                {{ propertyDetails.type_name }}
              </b-breadcrumb-item>
            </b-breadcrumb>
            <div class="ml-4">
              <b-badge
                :variant="propertyDetails.status_id == 1 ? 'success' : 'danger'"
                pill
                class="p-2 bf-size"
              >
                {{ propertyStatusOption[propertyDetails.status_id] }}
              </b-badge>
            </div>
            <!-- for mobile -->
            <div class="text-nowrap text-grey-light d-md-none mt-3 w-100">
              {{ lang.label.dateAdded }} :
              {{ getAddedDate }}
            </div>
            <!-- this button for mobile -->
            <div class="display-resp-button d-md-none">
              <b-btn variant="primary" size="sm" class="btn-mw" 
              v-if="propertyDetails.can_pay_rent" @click="addPayRent(propertyDetails.id)">{{
                lang.label.buttons.payRent
              }}</b-btn>
            </div>
          </div>

          <div class="pg-right">
            <div class="d-flex align-items-center flex-wrap">
              <!-- for desktop -->
              <span class="text-nowrap text-grey-light mr-2 pr-1 d-none d-md-inline"
                >{{ lang.label.dateAdded }} :
                {{ getAddedDate }}
              </span>

              <!-- this button for desktop -->
              <b-btn
                variant="primary"
                size="sm"
                class="text-nowrap btn-mw d-none d-md-inline" v-if="propertyDetails.can_pay_rent"
                @click="addPayRent(propertyDetails.id)"
                >{{ lang.label.buttons.payRent }}</b-btn
              >
            </div>
          </div>
        </div>
      </div>

      <!-- properties property section -->
      <div class="property-detail-page">
        <b-container fluid class="px-0">
          <b-row>
            <!-- property details images and content section -->
            <b-col lg="6" xl="8" class="order-1 order-lg-1">
              <b-card no-body class="bg-transparent border-0 rounded-0 form-group">
                <b-card-body class="p-0">
                  <property-media
                    :description="propertyDetails.description"
                    :propertyMedia="propertyDetails.property_images"
                  ></property-media>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- property address section -->
            <b-col lg="6" xl="4" class="order-2 order-lg-2">
              <b-card no-body class="custom-card form-group">
                <b-card-body>
                  <div class="property-facilities">
                    <b-row>
                      <b-col
                        cols="6"
                        lg="6"
                        xl="4"
                        v-for="(facility, fIndex) in data.propertyFacilities"
                        :key="fIndex"
                      >
                        <b-media class="facility-media">
                          <template #aside>
                            <img :src="getIconUrl(facility.icon)" :alt="facility.name" />
                          </template>
                          <p class="mb-0">
                            {{
                              (propertyFacilities[facility.id - 1] || 0)
                            }}
                          </p>
                        </b-media>
                      </b-col>
                    </b-row>
                  </div>
                  <p class="text-grey mb-2">
                    {{ lang.label.maintenanceFee + " " + getMaintenanceAmount }}
                  </p>
                  <b-row>
                    <b-col>
                      <label class="mb-0 d-block">{{ lang.label.leaseAmount }}</label>
                      <p class="property-amount my-2 mb-0">
                        {{ getLeaseAmount
                        }}<span class="bf400"
                          >/{{
                            propertyDetails.tenant_detail &&
                            propertyDetails.tenant_detail.payment_frequency
                              ? paymentFrequencyOption[propertyDetails.tenant_detail.payment_frequency_id]
                              : lang.label.monthly
                          }}</span
                        >
                      </p>
                    </b-col>
                    <b-col class="text-nowrap">
                      <label class="mb-0 d-block">{{ lang.label.advanceAmount }}</label>
                      <p class="property-amount my-2 mb-0">
                        {{ getDepositAmount }}
                      </p>
                    </b-col>
                  </b-row>
                  <div class="address-map">
                    <div class="address-iframe">
                      <!-- Google Map Section -->
                      <google-map
                        :location="propertyDetails.coordinates"
                        :isViewOnly="true"
                      ></google-map>
                    </div>
                    <div class="mb-0 address-text">
                      <div class="d-flex align-items-center">
                        <span class="material-icons secondary-text mr-1">place</span>
                        <span>{{ propertyDetails.address || "N/A" }}</span>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- property amenities section -->
            <b-col lg="6" xl="8" class="order-5 order-lg-3">
              <b-card no-body class="bg-transparent border-0 rounded-0 form-group">
                <b-card-body class="p-0">
                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="mb-0 sf-size bf700">
                      {{ lang.label.amenities }}
                    </h5>
                    <b-btn
                      variant="link"
                      class="p-0 text-underline mh-auto"
                      @click="showAllAmenities"
                      v-if="
                        propertyDetails.amenities && propertyDetails.amenities.length > 6
                      "
                    >
                      {{ amenitiesLimit != 0 ? lang.label.viewAll : lang.label.viewLess }}
                    </b-btn>
                  </div>
                  <div class="mt-3">
                    <property-amenities
                      :selectedAmenities="propertyDetails.amenities"
                      :limit="amenitiesLimit"
                    ></property-amenities>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- property broker & tenant details section -->
            <b-col lg="6" xl="4" class="order-3 order-lg-4">
              <b-card no-body class="custom-card form-group">
                <b-card-body>
                  <div class="property-manager border-0 tenant-rent-details">
                    <div class="manager-section mb-0">
                      <h5 class="mng-title sf-size bf700">
                        {{ lang.label.rentDetailsTitle }}
                      </h5>
                      <b-row>
                        <b-col md="6" lg="4">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">{{ lang.label.leasedTo }}</h6>
                            <div class="form-group">
                              <label class="mb-0">{{ getTenantName }}</label>
                            </div>
                          </div>
                        </b-col>
                        <b-col md="6" lg="8">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">
                              {{ lang.label.leasedPeriod }}
                            </h6>
                            <b-row>
                              <b-col cols="6">
                                <div class="mng-box">
                                  <div class="form-group">
                                    <label class="mb-0">{{ lang.label.startDate }}</label>
                                    <p class="mb-0 mt-1">{{ getLeaseStartDate }}</p>
                                  </div>
                                </div>
                              </b-col>
                              <b-col cols="6">
                                <div class="mng-box">
                                  <div class="form-group">
                                    <label class="mb-0">{{ lang.label.endDate }}</label>
                                    <p class="mb-0 mt-1">{{ getLeaseEndDate }}</p>
                                  </div>
                                </div>
                              </b-col>
                            </b-row>
                          </div>
                        </b-col>
                        <b-col md="6" lg="4">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">
                              {{ lang.label.leaseAmountText }}
                            </h6>
                            <div class="form-group">
                              <label class="mb-0">{{ getLeaseAmount }}</label>
                            </div>
                          </div>
                        </b-col>
                        <b-col md="6" lg="4">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">
                              {{ lang.label.depositAmount }}
                            </h6>
                            <div class="form-group">
                              <label class="mb-0">{{ getDepositAmount }}</label>
                            </div>
                          </div>
                        </b-col>
                        <b-col md="6" lg="4">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">
                              {{ lang.label.rentDueDate }}
                            </h6>
                            <div class="form-group">
                              <label class="mb-0">{{ getRentDueDate }}</label>
                            </div>
                          </div>
                        </b-col>
                        <b-col md="6" lg="4">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">
                              {{ lang.label.maintenanceFeeText }}
                            </h6>
                            <div class="form-group">
                              <label class="mb-0">{{ getMaintenanceAmount }}</label>
                            </div>
                          </div>
                        </b-col>
                        <b-col md="6" lg="8">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">
                              {{ lang.label.amountDueFirstMonth }}
                            </h6>
                            <div class="form-group">
                              <label class="mb-0">{{ getFirstMonthAmountDue }}</label>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>

                    <!-- lease agreement sign agreement -->
                    <div class="sign-agreement">
                      <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center">
                          <a
                            :href="getSignAgreement"
                            class="file-format"
                            target="_blank"
                            v-if="getSignAgreement"
                          >
                            <span class="format-name">Sign</span>
                          </a>
                        </div>
                        <div class="space-top">
                          <b-btn
                            variant="primary"
                            size="sm"
                            @click="viewSignature()"
                            v-if="getSignAgreement"
                            >{{ lang.label.buttons.viewSign }}</b-btn
                          >
                          <b-btn variant="primary" size="sm" @click="signatureModal()" v-if="!getSignAgreement">{{
                            lang.label.buttons.signAgreement
                          }}</b-btn>

                          <!-- signature agreememt modal -->
                          <b-modal
                            ref="refSignature"
                            id="signature"
                            hide-footer
                            centered
                            size="md"
                            modal-class="close-btn-right"
                          >
                            <template #modal-header="{ close }">
                              <b-button variant="link" class="close-btn" @click="close()">
                                <img :src="getIconUrl('close.svg')" alt="close" />
                              </b-button>
                            </template>
                            <div class="d-block text-center">
                              <h3 class="body-modal-heading">{{ lang.label.sign }}</h3>
                              <VueSignaturePad
                                id="signature"
                                class="signature-box"
                                ref="signaturePad"
                                :options="{
                                  onBegin: () => {
                                    $refs.signaturePad.resizeCanvas();
                                  },
                                }"
                              />
                            </div>
                            <div class="modal-buttons text-center modal-btn-responsive">
                              <b-button class="btn-mw" variant="primary" @click="undo">{{
                                lang.label.buttons.undo
                              }}</b-button>
                              <b-button class="btn-mw" variant="danger" @click="save">{{
                                lang.label.buttons.save
                              }}</b-button>
                            </div>
                          </b-modal>

                          <!-- view signature agreememt modal -->
                          <b-modal
                            ref="refSignatureView"
                            id="signatureView"
                            hide-footer
                            centered
                            size="md"
                            modal-class="close-btn-right"
                          >
                            <template #modal-header="{ close }">
                              <b-button variant="link" class="close-btn" @click="close()">
                                <img :src="getIconUrl('close.svg')" alt="close" />
                              </b-button>
                            </template>
                            <div class="d-block text-center">
                              <h3 class="body-modal-heading">&nbsp;</h3>
                              <img :src="getSignAgreement" alt="" class="img-fluid" />
                            </div>
                          </b-modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>

              <b-card no-body class="custom-card form-group">
                <b-card-body>
                  <div class="property-manager border-0">
                    <div class="manager-section">
                      <h5 class="mng-title sf-size bf700">
                        {{ lang.label.leaseAgreement }}
                      </h5>
                      <div
                        class="d-flex justify-content-between flex-wrap"
                        v-if="getLeaseDoc"
                      >
                        <div
                          v-if="
                            login.user_type_id == 1 ||
                            login.user_type_id == 2 ||
                            (login.user_type_id == 3 &&
                              staffpermissions.length > 0 &&
                              staffpermissions.includes('view_lease_agreement'))
                          "
                        >
                          <el-tooltip :content="lang.label.tooltip.view" placement="top">
                            <a :href="getLeaseDoc" class="file-format" target="_blank">
                              <span class="format-name">PDF</span>
                            </a>
                          </el-tooltip>
                        </div>
                        <div>
                          <b-btn
                            variant="primary"
                            size="sm"
                            @click="downloadLeaseAgreement"
                            >{{ lang.label.buttons.download }}</b-btn
                          >
                        </div>
                      </div>
                      <div v-else>N/A</div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- broker & owner details -->
            <b-col lg="12" class="order-7 order-lg-7">
              <b-row class="justify-content-end">
                <template v-for="(user, uIndex) in userTypeDetails">
                  <b-col md="6" xl="4" class="form-group" v-if="user.id" :key="uIndex">
                    <b-card no-body class="custom-card h-100">
                      <b-card-body>
                        <div class="d-flex justify-content-between align-items-center">
                          <h5 class="mb-0 sf-size bf700">
                            {{ user.typeName }} {{ lang.label.details }}
                          </h5>
                          <b-btn
                            variant="primary"
                            size="sm"
                            v-b-modal="'user' + user.typeName"
                            >{{ lang.label.connectTo }} {{ user.typeName }}</b-btn
                          >
                        </div>
                        <b-media no-body class="contact-details mt-3">
                          <b-media-aside>
                            <div class="usr-pic">
                              <img :src="getUserUrl(user.pic, user.image)" alt="" v-if="user.image"/>
                              <span class="material-icons" v-else>person</span>
                            </div>
                          </b-media-aside>
                          <b-media-body>
                            <h5 class="mt-0 usr-name">{{ user.name }}</h5>
                            <div
                              class="d-flex justify-content-between align-items-center flex-wrap"
                            >
                              <b-media no-body class="contact-via">
                                <b-media-aside>
                                  <div class="contact-icon">
                                    <span class="material-icons">email</span>
                                  </div>
                                </b-media-aside>
                                <b-media-body>
                                  <a
                                    :href="'mailto:' + user.email"
                                    class="primary-text text-underline"
                                    >{{ user.email }}</a
                                  >
                                </b-media-body>
                              </b-media>

                              <b-media no-body class="contact-via">
                                <b-media-aside>
                                  <div class="contact-icon">
                                    <span class="material-icons">phone</span>
                                  </div>
                                </b-media-aside>
                                <b-media-body>
                                  <a
                                    :href="'tel:' + user.phone"
                                    class="primary-text text-underline"
                                    >{{ user.phone }}</a
                                  >
                                </b-media-body>
                              </b-media>

                              <b-media
                                no-body
                                class="contact-via"
                                v-if="!user.address == ''"
                              >
                                <b-media-aside>
                                  <div class="contact-icon">
                                    <span class="material-icons">place</span>
                                  </div>
                                </b-media-aside>
                                <b-media-body>
                                  <span class="primary-text">{{ user.address }}</span>
                                </b-media-body>
                              </b-media>
                            </div>
                          </b-media-body>
                        </b-media>
                      </b-card-body>
                    </b-card>

                    <!-- enter message for broker or owner -->
                    <b-modal
                      :id="'user' + user.typeName"
                      hide-footer
                      centered
                      size="md"
                      modal-class="close-btn-right"
                      @hidden="resetMessageModal"
                    >
                      <template #modal-header="{ close }">
                        <b-button variant="link" class="close-btn" @click="close()">
                          <img :src="getIconUrl('close.svg')" alt="close" />
                        </b-button>
                      </template>
                      <ValidationObserver ref="messageObserver" v-slot="{ passes }">
                        <b-form
                          @submit.stop.prevent="passes(() => onMessageSubmit(user.type, user.typeName))"
                          autocomplete="off"
                        >
                          <div class="d-block text-center">
                            <h3 class="body-modal-heading text-left">{{ lang.label.message }}</h3>
                            <ValidationProvider
                              :name="lang.label.form.validation.message"
                              rules="required"
                              v-slot="validationContext"
                            >
                              <div class="position-relative text-left">
                                <b-form-textarea
                                  v-model="models.message"
                                  type="text"
                                  :state="getValidationState(validationContext)"
                                  :placeholder="lang.label.form.placeholder.message"
                                  rows="3"
                                ></b-form-textarea>

                                <b-form-invalid-feedback
                                  >{{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="modal-buttons text-center modal-btn-responsive">
                            <b-button class="btn-mw" variant="primary" type="submit">{{
                              lang.label.buttons.submit
                            }}</b-button>
                          </div>
                        </b-form>
                      </ValidationObserver>
                    </b-modal>
                  </b-col>
                </template>
              </b-row>
            </b-col>

            <b-col lg="12" class="order-8 order-lg-8">
              <filter-form
                activeTab="tenantPropertyDetail"
                @handleApiCallTenant="handleApiCallTenant"
                @download="download"
              ></filter-form>
              <hr class="mt-0" />
              <report-list
                :tableData="tableData"
                :columns="columnNames"
                :options="options"
                :optionsPrint="optionsPrint"
                ref="reportList"
              ></report-list>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <payment-modal :rentTotal='rentTotal' :rentDetails='rentDetails' :isPaymentModalVisible="isPaymentModalVisible" @handleInitializedPaymentModal="handleInitializedPaymentModal" :handlePayment="handlePayment"></payment-modal>  
  </div>
</template>

<script>
import Vue from "vue";
import jsondata from "@/assets/json/data.json";
import PropertyMedia from "./details/PropertyMedia.vue";
import PropertyAmenities from "./details/PropertyAmenities.vue";
import paymentModal from "../myaccount/paymentModal.vue"
import FilterForm from "../Reports/FilterForm.vue";
import ReportList from "../Reports/ReportList.vue";
import { mapGetters } from "vuex";
import alertSection from "@/views/shared/AlertSection.vue";
import mixin from "@/mixins/mixin";
import property_mixin from "@/mixins/property_mixin";
import GoogleMapSection from "@/views/shared/GoogleMapSection.vue";
import Swal from "sweetalert2";
import moment from "moment";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexChart", VueApexCharts);
export default {
  name: "PropertiesDetails",
  components: {
    "property-media": PropertyMedia,
    "property-amenities": PropertyAmenities,
    "filter-form": FilterForm,
    "report-list": ReportList,
    "alert-section": alertSection,
    "google-map": GoogleMapSection,
    'payment-modal': paymentModal
  },
  mixins: [mixin, property_mixin],
  computed: {
    ...mapGetters({
      loading: "loading",
      ImageUrl: "ImageUrl",
      filterpayloads: "filterpayloads",
      login: "login",
      staffpermissions: "staffpermissions",
      isUserVerified: "isUserVerified",
      language: "language",
    }),
    /**
     * @returns created date of property
     */
    getAddedDate() {
      return this.propertyDetails.created_date
        ? this.formatDateToShow(this.propertyDetails.created_date)
        : "N/A";
    },
    /**
     * @returns Assigned realtor's name
     */
    getRealtorName() {
      if (this.propertyDetails.realtor_details?.first_name) {
        return this.propertyDetails.realtor_details.first_name + " " + this.propertyDetails.realtor_details.last_name;
      } else if (this.propertyDetails.realtor_details?.name) {
        return this.propertyDetails.realtor_details.name;
      } else {
        return "N/A";
      }
    },
    /**
     * @returns Assigned realtor's contact with formated string
     */
    getRealtorContact() {
      let number =
      this.propertyDetails.realtor_details &&
      this.propertyDetails.realtor_details.contact
        ? this.propertyDetails.realtor_details.contact
        : null;
      return number;
    },
    /**
     * @returns Assigned realtor's email
     */
    getRealtorEmail() {
      return this.propertyDetails.realtor_details &&
        this.propertyDetails.realtor_details.email
        ? this.propertyDetails.realtor_details.email
        : "N/A";
    },
    /**
     * @returns Assigned tenant's name
     */
    getTenantName() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.first_name
        ? this.propertyDetails.tenant_detail.first_name +
            " " +
            this.propertyDetails.tenant_detail.last_name
        : "N/A";
    },
    /**
     * @returns Assigned tenant's identity document
     */
    getTenantIdDoc() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.identity_document
        ? this.ImageUrl + this.propertyDetails.tenant_detail.identity_document
        : "N/A";
    },
    /**
     * @returns Assigned tenant's lease start date
     */
    getLeaseStartDate() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.start_date
        ? this.formatDateToSlash(this.propertyDetails.tenant_detail.start_date)
        : "N/A";
    },
    /**
     * @returns Assigned tenant's lease end date
     */
    getLeaseEndDate() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.end_date
        ? this.formatDateToSlash(this.propertyDetails.tenant_detail.end_date)
        : "N/A";
    },
    /**
     * @returns Assigned tenant's rend due date
     */
    getRentDueDate() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.next_due_date
        ? this.formatDateStringToDate2(this.propertyDetails.tenant_detail.next_due_date)
        : "N/A";
    },
    /**
     * @returns Tenant lease agreement document
     */
    getLeaseDoc() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.lease_agreement
        ? this.ImageUrl + this.propertyDetails.tenant_detail.lease_agreement
        : null;
    },
    getSignAgreement() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.sign_agreement
        ? this.ImageUrl + this.propertyDetails.tenant_detail.sign_agreement
        : null;
    },
  },
  data() {
    return {
      lang: this.$lang.property_details,
      data: jsondata,
      encryptID: "",
      isPaymentModalVisible:false,
      rentTotal: 0,
      rentDetails:[],
      // line chart
      height: "150px",
      series: [
        {
          name: "Income",
          data: [],
        },
        {
          name: "Expense",
          data: [],
        },
      ],
      propertyID: "",
      chartOptions: {
        chart: {
          type: "line",
          width: "100%",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 991,
            options: {
              chart: {
                height: 230,
              },
            },
          },
          {
            breakpoint: 1550,
            options: {
              chart: {
                height: 230,
              },
            },
          },
        ],
        dataLabels: {
          enabled: false,
        },
        legend: {
          formatter: function (seriesName, opts) {
            let sum = opts.w.globals.series[opts.seriesIndex].reduce(
              (partialSum, a) => partialSum + a,
              0
            );
            return [opts.seriesIndex == 0 ? "+" : "-", sum];
          },
        },
        stroke: {
          width: [1.5, 1.5],
          curve: "straight",
          dashArray: [0, 0],
        },
        colors: ["#5734D6", "#295E73"],
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
          categories: [
            "Dec 31, 2021",
            "Jan 31, 2022",
            "Feb 28, 2022",
            "Mar 31, 2022",
            "Apr 30, 2022",
            "May 31, 2022",
            "Jun 30, 2022",
            "Jul 31, 2022",
            "Aug 31, 2022",
            "Sep 30, 2022",
            "Oct 31, 2022",
            "Nov 30, 2022",
            "Dec 31, 2022",
          ],
          labels: {
            style: {
              cssClass: "apexcharts-xaxis-label-custom",
            },
            rotateAlways: false,
            formatter: function (val) {
              return val ? moment(val, "YYYY-MM-DD").format("MMM D, YYYY") : "";
            },
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
        },
        yaxis: {
          tickAmount: 2,
        },
        grid: {
          show: true,
          borderColor: "#DDDDDD",
          strokeDashArray: 1,
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      propertyDetails: { amenities: [] },
      propertyFacilities: [],
      userType: {
        1: "realtor_details",
        2: "owner_details",
      },
      graphAvailable: true,
      selectedContact: {},
      models: {},
      contactModel: {
        firstName: "",
        lastName: "",
        contactNumber: "",
        message: "",
      },
      maintenanceFee: null,
      leaseDocument: [],
      tenantDetails: [],
      fileFormat: {},
      amenitiesLimit: 6,
      userTypeDetails: JSON.parse(JSON.stringify(jsondata.userTypeDetails)),
      userTypeEnum: {
        broker: 1,
        owner: 2,
      },
      tableData: [],
      filterData: {},
      columnNames: ["number", "name", "paymentPeriod", "amount", "date", "paymentCard"],
      options: {
        headings: this.$lang.property_details.label.tableReportHeader,
        skin: "table",
        perPageValues: [8, 15, 25],
        perPage: 8,
        sortable: [],
        filterable: ["name", "address", "leaseDuration"],
        columnsClasses: {
          address: "address-width",
          tenant: "d-none d-md-table-cell ",
          leaseDuration: "d-none d-md-table-cell ",
          paidDateTime: "d-none d-md-table-cell ",
          billingType: "d-none d-md-table-cell ",
          actions: "expense-buttons ",
        },
        pagination: { chunk: 8 },
        texts: {
          noResults: this.$lang.root.label.noRecord,
          filter: this.$lang.root.label.filter,
          filterPlaceholder: this.$lang.root.label.filterPlaceholder,
          limit: this.$lang.root.label.limit,
          count: this.$lang.root.label.count,
        },
        customSorting: {
          paidDateTime: function (ascending) {
            return function (a, b) {
              let dateA = new Date(a.date);
              let dateB = new Date(b.date);
              if (ascending) {
                return dateA >= dateB ? 1 : -1;
              } else {
                return dateA < dateB ? 1 : -1;
              }
            };
          },
        },
      },
      optionsPrint: {
        headings: this.$lang.property_details.label.tableReportHeader,
        skin: "table",
        perPageValues: [],
        perPage: 8000000000,
        sortable: [],
        filterable: false,
        columnsClasses: {
          address: "address-width",
          tenant: "d-none d-md-table-cell ",
          leaseDuration: "d-none d-md-table-cell ",
          paidDateTime: "d-none d-md-table-cell ",
          billingType: "d-none d-md-table-cell ",
          actions: "expense-buttons ",
        },
        pagination: { chunk: 8 },
        texts: {
          noResults: this.$lang.root.label.noRecord,
          filter: this.$lang.root.label.filter,
          filterPlaceholder: this.$lang.root.label.filterPlaceholder,
          limit: this.$lang.root.label.limit,
          count: this.$lang.root.label.count,
        },
        customSorting: {
          paidDateTime: function (ascending) {
            return function (a, b) {
              let dateA = new Date(a.date);
              let dateB = new Date(b.date);
              if (ascending) {
                return dateA >= dateB ? 1 : -1;
              } else {
                return dateA < dateB ? 1 : -1;
              }
            };
          },
        },
      },
    };
  },
  created() {
    moment.updateLocale(this.language, {});
    this.propertyID = this.$route.params.id;
    this.encryptID = window.btoa(this.propertyID);
    this.viewProperty();
    this.getPaymentReport();
  },
  destroyed() {
    this.$store.commit("setFilterPayload", null);
  },
  methods: {
    showAllAmenities() {
      if (this.amenitiesLimit == 0) {
        this.amenitiesLimit = 6;
      } else {
        this.amenitiesLimit = 0;
      }
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    goBack() {
      this.$router.push({ name: "properties" });
    },
    /**
     * Handles fetching property details via API
     */
    viewProperty() {
      let self = this;
      let id = self.propertyID;
      self.$store.dispatch("viewTenantProperty", id).then((response) => {
        if (response.status == 200) {
          self.propertyDetails = response.data;
          self.handlePropertyFacilities();
          self.handleTenantDetails();
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
            self.$router.push({ name: "not-found" });
          }
        }
      });
    },
    /**
     * Handles property facilities data
     */
    handlePropertyFacilities() {
      let self = this;
      self.propertyFacilities = [];
      self.propertyFacilities.push(self.lang.label.propertyFacilities.livableSpace + " " + self.propertyDetails.approx + " mt2");
      self.propertyFacilities.push(self.lang.label.propertyFacilities.area + " " + self.propertyDetails.area + " mt2");
      self.propertyFacilities.push(self.lang.label.propertyFacilities.construction + " " + self.propertyDetails.year);
      self.propertyFacilities.push(self.propertyDetails.bedrooms + " " + self.lang.label.propertyFacilities.bedrooms);
      self.propertyFacilities.push(
        self.propertyDetails.bathrooms + " " + self.lang.label.propertyFacilities.bathrooms
      );
      self.propertyFacilities.push(self.propertyDetails.parking + " " + self.lang.label.propertyFacilities.parkings);    },
    /**
     * Handles storing tenant data for computing lease details
     */
    handleTenantDetails() {
      let self = this;
      self.maintenanceFee = self.propertyDetails.maintenance_fee;
      self.selectedContact = {
        name:
          self.propertyDetails.tenant_detail &&
          self.propertyDetails.tenant_detail.first_name
            ? self.propertyDetails.tenant_detail.first_name +
              " " +
              self.propertyDetails.tenant_detail.last_name
            : "N/A",
        email:
          self.propertyDetails.tenant_detail && self.propertyDetails.tenant_detail.email
            ? self.propertyDetails.tenant_detail.email
            : "N/A",
        id: self.propertyDetails.tenant_detail && self.propertyDetails.tenant_detail.id,
      };
      if (
        self.propertyDetails.tenant_detail &&
        Object.keys(self.propertyDetails.tenant_detail).length > 0
      ) {
        self.models = {
          startDate: self.propertyDetails.tenant_detail.start_date,
          endDate: self.propertyDetails.tenant_detail.end_date,
          paymentFrequency: self.propertyDetails.tenant_detail.payment_frequency_id,
          rentAmount: self.propertyDetails.tenant_detail.rent_amount,
          depositAmount: self.propertyDetails.tenant_detail.deposit_amount,
          brokerRecurringCurrency:
            self.propertyDetails.tenant_detail.realtor_rental_fee_type_id,
          brokerRecurringRentalFee: self.propertyDetails.tenant_detail.realtor_rental_fee,
          closingCostCurrency:
            self.propertyDetails.tenant_detail.realtor_closing_commission_type_id,
          closingCostCommission:
            self.propertyDetails.tenant_detail.realtor_closing_commission,
        };
      }
      self.handleContactDetail(0, self.propertyDetails.realtor_details);
      self.handleContactDetail(
        1,
        self.propertyDetails.owner_details
          ? self.propertyDetails.owner_details
          : {}
      );
    },
    /**
     * Handles contact details
     * @param {number} param Contact type index
     * @param {object} data Contact data
     */
    handleContactDetail(param, data) {
      let self = this;
      let firstName = data.first_name ? data.first_name + " " + data.last_name : "N/A";
      self.userTypeDetails[param].name = data.name ? data.name : firstName;
      self.userTypeDetails[param].email = data.email || "N/A";
      self.userTypeDetails[param].phone = data.contact || "N/A";
      self.userTypeDetails[param].id = data.id;
      self.userTypeDetails[param].typeName = param == 0 ? self.lang.label.realtor : self.lang.label.owner;
      self.userTypeDetails[param].image = data.profile_image ? self.ImageUrl + data.profile_image : null;
      self.userTypeDetails[param].address =
        data.address || data.city
          ? data.address + " " + data.city + ", " + data.state + ", " + data.country
          : "N/A";
    },
    /**
     * Event for tenant details API ,flow :-
     * 1) api call in AddExpense page - > Event(flag) passed to parent component FilterForm and then here
     * 2) api call from Reports listing page
     */
    handleApiCallTenant(data) {
      let self = this;
      self.filterData = data;
      this.getPaymentReport();
    },
    /**
     * Report listing downloadFile function will be called by using refs and file will be downloaded
     */
    download(obj) {
      let self = this;
      self.fileFormat = obj;
      self.$refs.reportList.downloadFile(self.fileFormat);
    },
    signatureModal() {
      this.$refs["refSignature"].show();
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        this.$refs["refSignature"].hide();
        this.updateTenantSignAgreement(data);
      }
    },
    /**
     * Handles updating tenant sign agreement
     * @param {string} baseUrl Sign base64 url
     */
    updateTenantSignAgreement(baseUrl) {
      let self = this;
      let id = self.propertyDetails.tenant_detail.id;
      self.dataURLtoFile(baseUrl, "sign_agreement.png", "image/png").then((file) => {
        let formData = new FormData();
        formData.append("sign_agreement", file);
        let data = {
          id: id,
          data: formData,
        };
        self.$store.dispatch("updateTenantSignAgreement", data).then((response) => {
          if (response.status == 200) {
            self.viewProperty();
          } else {
            let message = response.data.message || response.data.detail;
            if (message) {
              self.$store.commit("setMessages", {
                type: "danger",
                message: message,
              });
            }
          }
        });
      });
    },
    /**
     * Handles converting Base64 stringto file object
     * @param {string} url Base64 url of file
     * @param {string} filename File name
     * @param {string} mimeType Mime type of file
     */
    dataURLtoFile(url, filename, mimeType) {
      return new Promise((resolve, reject) => {
        fetch(url)
          .then(function (res) {
            return res.arrayBuffer();
          })
          .then(function (buf) {
            resolve(new File([buf], filename, { type: mimeType }));
          });
      });
    },
    /**
     * Handles downloading lease agreement file
     */
    downloadLeaseAgreement() {
      let url =
        "data:image/*;base64," +
        this.propertyDetails.tenant_detail.lease_agreement_base_url;
      if (this.getLeaseDoc && url) {
        let link = document.createElement("a");
        link.download = this.getLeaseDoc.split("/")[
          this.getLeaseDoc.split("/").length - 1
        ];
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    viewSignature() {
      this.$refs["refSignatureView"].show();
    },
    /**
     * Handles submitting message to selected contact for inquiry
     * @param {string} user Contact user type
     * @param {string} modalName Modal name
     */
    onMessageSubmit(user, modalName) {
      let self = this;
      let data = {
        data: {
          message: self.models.message,
          type: self.userTypeEnum[user],
        },
        id: self.propertyID,
      };

      self.$store.dispatch("tenantPropertyInquiry", data).then((response) => {
        if (response.status == 200) {
          self.$bvModal.hide('user' + modalName);
          self.$store.commit("setMessages", {
            type: "success",
            message: response.data.message,
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Handles reseting message modal inputs
     */
    resetMessageModal() {
      this.models.message = null;
    },
    getUserUrl(user, image) {
      return image ? image : require("@/assets/styles/img/users/" + user);
    },
    /**
     * Handles fetching payment report list
     */
    getPaymentReport() {
      let self = this;
      let data = {
        id: self.propertyID,
        start_date: self.filterData.start_date
          ? self.formatDateReverse(self.filterData.start_date)
          : null,
        end_date: self.filterData.end_date
          ? self.formatDateReverse(self.filterData.end_date)
          : null,
      };
      self.tableData = [];
      self.$store.dispatch("tenantPaymentHistory", data).then((response) => {
        if (response.status == 200) {
          response.data.forEach((el, index) => {
            self.tableData.push({
              number: index + 1,
              name: el.owner_name,
              paymentPeriod: el.payment_frequency_text,
              amount: el.amount,
              date: el.created_ts,
              paymentCard: el.payment_card,
            });
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    handleInitializedPaymentModal(){
      this.isPaymentModalVisible = false;
    },
    /**
    * handle payment in tenant property
    */
    handlePayment(){
      this.isPaymentModalVisible = false;
      this.viewProperty();
    },
    /**
    * payment initialize with summary
    * @param {number} id Property id
    */
    addPayRent(id){
      let self = this;
      let data = {
        property : id
      }
      self.rentDetails = []
      self.$store.dispatch('payRentSummary',data).then((response) => {
        if (response.status == 200 ) {
          let rents = response.data.rent_summary;
          self.rentTotal = response.data.total_amount;
          self.rentDetails['summary'] = []
          self.rentDetails['property'] = id;
          rents.forEach(rent => {
              self.rentDetails['summary'].push({
                  startDate : self.formatDateToShow(rent.rent_start_date),
                  endDate : self.formatDateToShow(rent.rent_end_date),
                  rentAmount : rent.rent_amount,
                  maintenanceFee :rent.maintenance_fee,
                  depositeAmount : rent.deposit_amount,
                  lateRentAmount : rent.late_rent_amount,
                  total : rent.total
                });
            });
          self.isPaymentModalVisible = true;
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      })
    }
  },
};
</script>
