export default {
    editExpense: false,
    loading: false,
	messages: [],
    alertTimeouts: [],
	login: {
        token: null,
    },
	unauthorized: false,
	errorTimeOut: 10000,
    language: 'es',
    staffPermissions:[],
    payloadFilters:null,
    subscriptionAlert: false,
    verificationAlert: false,
    isNewUser : false,
    loadingRelative: false,
    cardDetails: false,
    selectedPlan: null,
}