var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body-content"},[_vm._m(0),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"starts-section"},[_c('alert-section'),_c('div',{staticClass:"page-breadcrumb"},[_c('div',{staticClass:"d-md-flex justify-content-between align-items-center"},[_c('div',{staticClass:"pg-bread"},[_c('b-breadcrumb',{staticClass:"bg-transparent p-0 mb-0"},[_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" "+_vm._s(_vm.lang.label.changePassword)+" ")])],1)],1)])]),_c('div',{staticClass:"pb-3"},[_c('b-container',{staticClass:"px-0"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"md":"7","lg":"5"}},[_c('b-card',{staticClass:"custom-card",attrs:{"no-body":""}},[_c('b-card-body',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ passes }){return [_c('b-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return passes(_vm.onSubmit)}}},[_c('b-form-group',[_c('ValidationProvider',{attrs:{"name":_vm.lang.label.form.validation.currentPassword,"rules":{
                          required: true,
                          regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$!#%*?&]{8,}$/,
                        }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('div',{staticClass:"position-relative"},[_c('b-form-input',{attrs:{"type":"password","state":_vm.getValidationState(validationContext),"autocomplete":"current-password","placeholder":_vm.lang.label.form.placeholder.currentPassword},model:{value:(_vm.models.currentPassword),callback:function ($$v) {_vm.$set(_vm.models, "currentPassword", $$v)},expression:"models.currentPassword"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-form-group',[_c('ValidationProvider',{attrs:{"name":_vm.lang.label.form.validation.newPassword,"rules":{
                          required: true,
                          regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$!#%*?&]{8,}$/,
                        },"vid":"newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('div',{staticClass:"position-relative"},[_c('b-form-input',{attrs:{"type":"password","state":_vm.getValidationState(validationContext),"autocomplete":"new-password","placeholder":_vm.lang.label.form.placeholder.newPassword},model:{value:(_vm.models.newPassword),callback:function ($$v) {_vm.$set(_vm.models, "newPassword", $$v)},expression:"models.newPassword"}}),_c('small',{staticClass:"sm-font text-grey d-inline-block mt-2"},[_vm._v(_vm._s(_vm.lang.label.form.placeholder.passwordFormateNote))]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-form-group',[_c('ValidationProvider',{attrs:{"name":_vm.lang.label.form.validation.reEnterPassword,"rules":"required|confirmed:newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('div',{staticClass:"position-relative"},[_c('b-form-input',{attrs:{"type":"password","state":_vm.getValidationState(validationContext),"autocomplete":"confirm-password","placeholder":_vm.lang.label.form.placeholder.reEnterPassword},model:{value:(_vm.models.reEnterPassword),callback:function ($$v) {_vm.$set(_vm.models, "reEnterPassword", $$v)},expression:"models.reEnterPassword"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-form-group',{staticClass:"mb-0"},[_c('b-button',{staticClass:"w-100",attrs:{"block":"","variant":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.lang.label.buttons.update)+" ")])],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-sticky"},[_c('div',{staticClass:"top-bar-radius"})])
}]

export { render, staticRenderFns }