module.exports = {
    label: {
      title: "Apoyo",
      faq: "Preguntas Frecuentes",
      writeUs: 'Escríbenos',
      form: {
        placeholder: {
          selectQueryReason: 'Seleccionar Motivo De Consulta',
          description: 'Ingrese La Descripción',
        },
        validation: {
          queryReason: 'motivo de consulta',
          description: 'descripción',
        }
      },    
      buttons: {
        submit: 'Enviar'
      }
    }
  };