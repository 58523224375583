import axios from 'axios';
import store from '../store'

let url = process.env.VUE_APP_URL_ENDPOINT;
const HTTP = axios.create({
  baseURL: `${url}`,
  headers: {
    "Content-Type": "application/json",
  }
});
const HTTPFormData = axios.create({
  baseURL: `${url}`,
  headers: {
    "Content-Type": "multipart/form-data",
  }
});
// Interceptor
let controller = new AbortController();


configureCommonInterceptor(HTTP);
configureCommonInterceptor(HTTPFormData);

// common interceptor function
function configureCommonInterceptor(instance) {
  instance.interceptors.request.use(async function (config) {
    config.headers['accept-language'] = store.getters.language ? store.getters.language : 'es';
    if (config.headers.Authorization) {
      config['signal'] = controller.signal;
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  });
  
  instance.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    return Promise.reject(error);
  })
}


/**
 * List Plan while register user
 * @returns Api response
 */
export const listPlan = ({ commit, getters }) => {
  commit('setLoading', true);
  return HTTP.get('select/plan/', {
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * User Registration
 * @param {Object} data User data to be submitted
 * @returns Api response
 */
export const userRegister = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('user/register/', data, {
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * User Login
 * @param {Object} data User data to be submitted
 * @returns Api response
 */
export const userLogin = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('login/', data, {
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * User Social Login
 * @param {Object} data User data to be submitted
 * @returns Api response
 */
export const socialLogin = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('social/register/', data, {
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Forgot Password
 * @param {Object} data User data to be submitted
 * @returns Api response
 */
export const forgotPassword = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('forgot/password/', data, {
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};


/**
 * Reset Password
 * @param {Object} data User data to be submitted
 * @returns Api response
 */
export const resetPassword = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('reset/password/', data, {
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Invite User
 * @param {Object} data User data to be submitted
 * @returns Api response
 */
export const inviteUser = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('invite/user/', data, {
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * User Logout
 * @returns Api response
 */
export const logout = ({ commit, getters }) => {
  commit('setLoading', true);
  return HTTP.get('logout/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      controller.abort('Logout abort');
      controller = new AbortController();
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setUnauthorized', true);
      commit('setMessages', { type: "danger", message: err.response.data.detail });
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Change Password
 * @param {Object} data User data to be submitted
 * @returns Api response
 */
export const changePassword = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.put('change/password/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Get OTP
 * @param {Object} data User data to be submitted
 * @returns Api response
 */
export const getOTP = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('user/sent/otp/', data, {
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Verify OTP
 * @param {Object} data User data to be submitted
 * @returns Api response
 */
export const verifyOTP = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('user/verify/otp/', data, {
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Get CMS Pages
 * @param {Number} id CMS type to be submitted
 * @returns Api response
 */
export const getCMSContent = ({ commit, getters }, id) => {
  commit('setLoading', true);
  return HTTP.get('select/cms/' + id + '/', {
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Upadte User Email 
 * @param {object} data Updated Email
 * @returns api response
 */
export const updateUserEmail = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('social/email/update/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/*------------------------- Roles & Permissions -------------------------*/
/**
 * List Permissions
 * @returns Api response
 */
export const listPermissions = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.get('select/list/permission/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * List Role
 * @returns Api response
 */
export const listRole = ({ commit, getters }) => {
  commit('setLoading', true);
  return HTTP.get('list/role/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * View Role
 * @param {number} id Role id to be viewed
 * @returns Api response
 */
export const viewRole = ({ commit, getters }, id) => {
  commit('setLoading', true);
  return HTTP.get('view/role/' + id + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Add Role
 * @param {object} data Role data to be added
 * @returns Api response
 */
export const addRole = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('add/role/', data.data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Edit Role
 * @param {object} data Role data to be edited
 * @returns Api response
 */
export const editRole = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('edit/role/' + data.id + '/', data.data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Delete Role
 * @param {number} id Role id to be deleted
 * @returns Api response
 */
export const deleteRole = ({ commit, getters }, id) => {
  commit('setLoading', true);
  return HTTP.delete('delete/role/' + id + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/*------------------------- My Contacts -------------------------*/
/**
 * List Contacts
 * @param {object} data Payload for filter and pagination
 * @returns Api response
 */
export const listContacts = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('list/contacts/' + data.size + '/' + '?page=' + data.page, data.data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * view the specific contact details
 * @param {number} id Contact id
 * @returns Api response
 */
export const viewContact = ({ commit, getters }, id) => {
  commit('setLoading', true);
  return HTTP.get('contact/details/' + id + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Update the Contact
 * @param {number} data Contact Details
 * @returns Api response
 */
export const updateContact = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.put('update/contact/' + data.id + '/', data.data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}
/**
 * Delete Contacts
 * @param {number} id Contact id to be deleted
 * @returns Api response
 */
export const deleteContact = ({ commit, getters }, id) => {
  commit('setLoading', true);
  return HTTP.delete('delete/contact/' + id + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Add Contact
 * @param {object} data Contact data to be added
 * @returns Api response
 */
export const addContact = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('add/contact/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};


/**
 * Add Contact Validation
 * @param {object} data Contact data to be added
 * @returns Api response
 */
export const addContactValidate = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('add/contact/validation/popup/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * List of Notes for specific contact
 * @param {number} id Conatact id
 * @returns Api Response
 */
export const listOfContactNotes = ({ commit, getters }, id) => {
  commit('setLoading', true);
  return HTTP.get('list/contact/notes/' + id + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/**
 * Add notes in contact modal
 * @param {object} data Contact details
 * @returns Api response
 */
export const addContactNotes = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('add/notes/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/**
 * Update Notes 
 * @param {object} data of updated contact details with their id
 * @returns Api respomse
 */
export const updateContactNotes = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.put('update/notes/' + data.id + '/', data.data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/**
 * Delete notes
 * @param {number} id of the note
 * @returns Api response
 */
export const deleteContactNotes = ({ commit, getters }, id) => {
  commit('setLoading', true);
  return HTTP.delete('delete/notes/' + id + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/**
 * List of Assigned properties of contact
 * @param {object} data contact id and pagination details
 * @returns Api response
 */
export const listOfContactProperties = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.get('list/contact/properties/' + data.id + '/' + data.size + '/?page=' + data.page, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/**
 * List of Bank Details of Contact
 * @param {object} data api payload with contact id
 * @returns api response
 */
export const listOfBankDetailsOfContact = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('list/contact/bank/details/' + data.id + '/', data.data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}
/*------------- Staff Management ------------------------*/
/**
 * list of staff members
 * @returns Api Response
 */
export const listStaffMembers = ({ commit, getters }) => {
  commit('setLoading', true);
  return HTTP.get('staff/list/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status == 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/**
 * Add Staff Member
 * @param {object} data payload of staff member details
 * @returns Api Response
 */
export const addStaffMember = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('staff/add/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}
/**
 *  View Staff Member details
 * @param {number} id Staff Member id 
 * @returns Api response
 */
export const viewStaffMember = ({ commit, getters }, id) => {
  commit('setLoading', true);
  return HTTP.get('staff/view/' + id + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/**
 * Delete staff member 
 * @param {number} user_id 
 * @returns Api response
 */
export const deleteStaffMember = ({ commit, getters }, user_id) => {
  commit('setLoading', true);
  return HTTP.delete('staff/delete/' + user_id + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}
/**
 * Update the Status of Staff Member
 * @param {number} user_id 
 * @returns Api Response
 */
export const updateStaffStatus = ({ commit, getters }, user_id) => {
  commit('setLoading', true);
  return HTTP.get('staff/status/' + user_id + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}
/**
 * Update Details of Staff Member
 * @param {object} data 
 * @returns 
 */
export const updateStaffMember = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('staff/edit/' + data.user_id + '/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/*------------------------- Amenities -------------------------*/
/**
 * List Amenities
 * @returns Api response
 */
export const listAmenities = ({ commit, getters }) => {
  commit('setLoading', true);
  return HTTP.get('select/amenities/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/*------------------------- Properties -------------------------*/
/**
 * Add Properties
 * @param {object} data Property data to be added
 * @returns Api response
 */
export const addProperty = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('add/property/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Add Properties
 * @param {object} data Property data to be added
 * @returns Api response
 */
export const editProperty = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.put('update/property/' + data.id + '/', data.data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * View Properties
 * @param {number} ID Property id to be viewed
 * @returns Api response
 */
export const viewProperty = ({ commit, getters }, ID) => {
  commit('setLoading', true);
  return HTTP.get('get/property/details/' + ID + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * View Tenant Property Details
 * @param {number} ID Property id to be viewed
 * @returns Api response
 */
export const viewTenantProperty = ({ commit, getters }, ID) => {
  commit('setLoading', true);
  return HTTP.get('get/property/details/tenant/' + ID + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Update Tenant Sign Agreement
 * @param {number} ID Property id to be viewed
 * @returns Api response
 */
export const updateTenantSignAgreement = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTPFormData.put('update/tenant/sign/agreement/' + data.id + '/', data.data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Tenant Property Detail Contact Inquiry
 * @param {object} data Property data to be inquired
 * @returns Api response
 */
export const tenantPropertyInquiry = ({ commit, getters }, data) => {
  commit('setLoading', true);
  console.log('data', data);
  return HTTP.post('tenant/property/inquiry/' + data.id + '/', data.data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Tenant Payment History
 * @returns Api response
 */
export const tenantPaymentHistory = ({ commit, getters }, data) => {
  // commit('setLoading', true);
  return HTTP.post('tenant/payment/history/' + data.id + '/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      // commit('setLoading', false);
      return response;
    }
  }, (err) => {
    // commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};
/**
 * View Properties Graph Update
 * @param {number} ID Property id to be viewed
 * @returns Api response
 */
export const viewPropertyGraph = ({ commit, getters }, ID) => {
  commit('setLoading', true);
  return HTTP.get('/get/property/reportByIncomeExpense/' + ID + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * View Properties public
 * @param {number} ID Property id to be viewed
 * @returns Api response
 */
export const viewPropertyPublic = ({ commit, getters }, ID) => {
  commit('setLoading', true);
  return HTTP.get('get/property/details/public/' + ID + '/', {
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Delete Property
 * @param {object} data Property to be deleted
 * @returns Api response
 */
export const deleteProperty = ({ commit, getters }, ID) => {
  commit('setLoading', true);
  return HTTP.delete('delete/property/' + ID + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * List Tenant history against property
 * @param {number} ID Property id to be viewed
 * @returns Api response
 */
export const listTenantHistory = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('list/tenant/history/details/property/' + data.ID + '/' + data.size + '/?page=' + data.page, data.payload, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Upload Property Media
 * @param {object} data Property media to be uploaded
 * @returns Api response
 */
export const uploadPropertyMedia = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTPFormData.post('upload/property/images/' + data.id + '/', data.formData, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Delete Property Media
 * @param {object} data Property media to be uploaded
 * @returns Api response
 */
export const deletePropertyMedia = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('delete/property/images/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};


/**
 * Add Tanent
 * @param {object} data Lease detatils to be added
 * @returns Api response
 */
export const addtenant = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('add/tenant/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Update Tanent
 * @param {object} data Lease detatils to be updated
 * @returns Api response
 */
export const updateTenant = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.put('update/tenant/' + data.id + '/', data.data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Upload Lease Document
 * @param {object} data Lease media to be uploaded
 * @returns Api response
 */
export const uploadLeaseMedia = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTPFormData.put('upload/lease/agreement/' + data.id + '/', data.formData, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Delete Lease Document
 * @param {object} data Lease media to be deleted
 * @returns Api response
 */
export const deletedLeaseMedia = ({ commit, getters }, ID) => {
  commit('setLoading', true);
  return HTTP.delete('delete/lease/agreement/' + ID + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * List of added Property
 * @param {object} data details of user type
 * @returns Api Response
 */
export const listOfProperties = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('list/property/' + data.size + '/?page=' + data.page, data.data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/**
 * Update the property status
 * @param {number} id 
 */
export const updatePropertyStatus = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.put('change/property/status/' + data.id + '/', data.status, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/**
 * Property inquiry 
 * @param {object} data Contact details 
 * @returns api respone
 */
export const propertyInquiry = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('property/inquiry/', data, {
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}


/*------------------------- Bank Details -------------------------*/
/**
 * List Bank Details
 * @param {object} data filter data to be sent
 * @returns Api response
 */
export const listBankDetails = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('list/bank/details/dropdown/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};
/**
 * Request Bank Details
 * @param {object} data User data to be sent
 * @returns Api response
 */
export const requestBankDetails = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('request/bank/detail/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};


export const verifyInquiry = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('verify/request/add/bank/details/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}
/**
 * Add bank Details on request 
 * @param {object} data bank Details
 * @returns Api response
 */
export const requestAddBankDetails = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('request/add/bank/details/', data.data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};


/*------------- Support ------------------------*/
/**
 * List of FAQs
 * @returns Api Response 
 */
export const listFAQs = ({ commit, getters }) => {
  commit('setLoading', true);
  return HTTP.get('select/faq/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/**
 * List Of Report Reason  
 * @returns Api Response
 */
export const getReportReason = ({ commit, getters }) => {
  commit('setLoading', true);
  return HTTP.get('select/report/reason/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/**
 * Add Support Query 
 * @param {object} data Query details  
 * @returns Api Response
 */
export const addQuery = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('add/query/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/*------------------------- Owner Bank Details  -------------------------*/

/**
 * Request Bank Details
 * @param {object} data User data to be sent
 * @returns Api response
 */
export const listBankDetailsOwner = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.get('list/bank/details/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * View Bank Details
 * @param {object} data User data to be sent
 * @returns Api response
 */

export const viewBankDetailsOwner = ({ commit, getters }, id) => {
  commit('setLoading', true);
  return HTTP.get('view/bank/details/' + id + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Add Bank Details
 * @param {object} data User data to be sent
 * @returns Api response
 */
export const addBankDetailsOwner = ({ commit, getters }, data) => {
  let payload = data.data;
  commit('setLoading', true);
  return HTTP.post('add/bank/details/', payload, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Edit Bank Details
 * @param {object} data User data to be sent
 * @returns Api response
 */
export const editBankDetailsOwner = ({ commit, getters }, data) => {
  let id = data.id
  let payload = data.data;
  delete data.id, data.data;
  commit('setLoading', true);
  return HTTP.post('edit/bank/details/' + id + '/', payload, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};


/**
 * Delete Bank Details
 * @param {object} data User data to be sent
 * @returns Api response
 */
export const deleteBankDetailsOwner = ({ commit, getters }, id) => {
  commit('setLoading', true);
  return HTTP.delete('delete/bank/details/' + id + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Default Bank Details
 * @param {object} data User data to be sent
 * @returns Api response
 */
export const defaultBankDetailsOwner = ({ commit, getters }, id) => {
  commit('setLoading', true);
  return HTTP.post('default/bank/details/' + id + '/', {}, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Country
 * @returns Api response
 */

export const listOfCountries = ({ commit, getters }) => {
  commit('setLoading', true);
  return HTTP.get('select/country/', {}, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * States
 * @returns Api response
 */

export const listOfStates = ({ commit, getters }, id) => {
  // commit('setLoading', true);
  return HTTP.get('select/state/' + id + '/', {}, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      // commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};


/**
 * Upload Identification Document
 * @param {object} data Lease media to be uploaded
 * @returns Api response
 */
export const uploadIdentificationDocument = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTPFormData.post('upload/id/document/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Retrieves Updated Profile Of User 
 * @param {object} data Lease media to be uploaded
 * @returns Api response
 */
export const retrieveProfile = ({ commit, getters }, data) => {
  // commit('setLoading', true);
  return HTTP.get('get/my/profile/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      // commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    // commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Retrieves chats for all users 
 */
export const listContactsMessages = ({ commit, getters }) => {
  commit('setLoading', true);
  return HTTP.get('list/chat/rooms/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Retrieves chats for all users 
 */
export const loadUserChat = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('list/message/chat/rooms/' + data.id + '/' + data.size + '/?page=' + data.page, {}, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};


/**
 * Add expense 
 * @param {object} data Lease media to be uploaded
 * @returns Api response
 */

export const addExpense = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTPFormData.post('property/expense/add/' + data.id + '/', data.formData, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};


/**
 * Delete expense
 * @param {object} data Lease media to be uploaded
 * @returns Api response
 */

export const deleteExpense = ({ commit, getters }, id) => {
  commit('setLoading', true);
  return HTTP.delete('property/expense/delete/' + id + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};


/**
 * Email Tenant history against property
 * @param {number} ID Property id to be viewed
 * @returns Api response
 */
export const emailTenantHistory = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('email/list/tenant/history/details/property/' + data.ID + '/' + data.size + '/?page=' + data.page, data.payload, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};
/*------------- Profile ------------------------*/

/**
 * Profile Details
 * @returns api response
 */
export const viewProfile = ({ commit, getters }) => {
  commit('setLoading', true);
  return HTTP.get('get/my/profile/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/**
 * Update the profile view
 * @param {object} data Details of profile fields
 * @returns api response
 */
export const updateProfile = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.put('update/my/profile/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/**
 * Update the profile Image 
 * @param {object} data formdata of profile image
 * @returns api response
 */
export const updateProfileImage = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTPFormData.put('update/profile/image/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/**
 * Update the Identity Document
 * @returns api response
 */
export const updateProfileDocument = ({ commit, getters }) => {
  commit('setLoading', true);
  return HTTPFormData.put('update/profile/image/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}
/**
 * Update the Company Images
 * @param {object} data Formdata of Images
 * @returns api response
 */
export const companyImages = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTPFormData.post('upload/company/images/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}
/**
 * Delete the Company image
 * @param {number} id 
 * @returns Api response
 */
export const deleteCompanyImage = ({ commit, getters }, id) => {
  commit('setLoading', true);
  return HTTP.delete('delete/company/image/' + id + '/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/*------------------------ Subscription ------------------------*/

/**
 * Subscription plan list
 * @returns Api response
 */
export const selectedPlan = ({ commit, getters }) => {
  commit('setLoading', true);
  return HTTP.get('list/plan/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};


/**
 * Send Messages to users
 * @returns Api response
 */
export const sendMessage = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTPFormData.post('send/message/', data.formData, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};
/**
 * Buy Subscription
 * @param {object} data plan details
 * @returns api response 
 */
export const buySubscription = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('buy/subscription/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/**
 * Cancel Subscription
 * @param {object} data details of subscription plan
 * @returns api respone
 */
export const cancelSubscription = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('cancel/subscription/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/**
 * Check the features for subscription 
 * @param {object} data type of feature
 * @returns api response
 */
export const checkSubscriptionFeature = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('check/subscription/feature/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}
/**
 * Payment History in subscription plan
 * @returns api response
 */
export const subscriptionPaymentHistory = ({ commit, getters }) => {
  commit('setLoading', true);
  return HTTP.get('user/subscription/history/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/*------------------------ Dashboard ------------------------*/

/**
 * dashboard Stats
 * @returns Api response
 */
export const dashboardStats = ({ commit, getters }) => {
  commit('setLoading', true);
  return HTTP.get('dashboard/stats/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};
/**
 * Monthly Income Graph
 * @param {object} data Filter data
 * @returns api response
 */
export const monthlyIncomeGraph = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('dashboard/graph/monthlyIncomes/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}
/**
 * Monthly Expense Graph
 * @param {object} data Filter data
 * @returns api response
 */
export const monthlyExpenseGraph = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('dashboard/graph/monthlyExpenses/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}
/**
 * Monthly Rented Graph
 * @param {object} data Filter data
 * @returns api response
 */
export const monthlyRentedGraph = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('dashboard/graph/rentedProperties/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}
/**
 * Monthly Available Graph
 * @param {object} data Filter data
 * @returns api response
 */
export const monthlyAvailableGraph = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('dashboard/graph/availableProperties/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    commit('setLoading', false);
    commit('setUnauthorized', false);
    return response
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}



/**
 * List notification
 * @returns Api response
 */
export const listNotification = ({ commit, getters }, data) => {
  // commit('setLoading', true);
  return HTTP.post('list/notification/' + data.size + '/?page=' + data.page, {}, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      // commit('setLoading', false);
      return response;
    }
  }, (err) => {
    // commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};


/**
 * Read notification
 * @returns Api response
 */
export const readNotification = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('read/notification/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/*------------------------- Reports -------------------------*/
/**
 * Owner Realtor Payment Report
 * @returns Api response
 */
export const ownerRealtorPaymentReport = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('property/owner/realtor/payment/report/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};
/**
 * Tenant Payment Report
 * @returns Api response
 */
export const tenantPaymentReport = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('property/tenant/payment/report/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};
/**
 * get Property Selection List
 * @returns Api response
 */
export const selectProperty = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('select/property/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};
/**
 * Email Property Owner/Broker Payment Report
 * @returns Api response
 */
export const emailOwnerBrokerReport = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('email/property/owner/realtor/payment/report/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};
/**
 * Email Property Tenant Payment Report
 * @returns Api response
 */
export const emailTenantReport = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('email/property/tenant/payment/report/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};


/*------------------------- Payment & Cards -------------------------*/
/**
 * Add Card
 * @returns Api response
 */
export const addCard = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('add/card/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * List of Cards
 * @returns api respones
 */
export const listOfCards = ({ commit, getters }) => {
  commit('setLoading', true);
  return HTTP.get('list/card/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};


/**
 * Delete Card
 * @param {number} id Card id
 * @returns api response
 */
export const deleteCard = ({commit, getters},id) => {
  commit('setLoading', true);
  return HTTP.delete('delete/card/'+id+'/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
}

/*------------------------- Pay Rent  -------------------------*/
/**
 * Rent Summary Details 
 * @param {object} data Details property 
 * @returns api response
 */
export const payRentSummary = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('rent/payment/summary', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};


/**
 * Rent payment 
 * @param {object} data details of card and property 
 * @returns api response 
 */
export const payRent = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('pay/rent/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Get System Messages
 * @returns api response 
 */
export const getSystemMessage = ({ commit, getters }) => {
  // commit('setLoading', true);
  return HTTP.get('get/system/global/settings/', {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      // commit('setLoading', false);
      return response;
    }
  }, (err) => {
    // commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', err.response.data.detail);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};


/**
 * Default Card Details
 * @param {object} data User data to be sent
 * @returns Api response
 */
export const defaultCard = ({ commit, getters }, id) => {
  commit('setLoading', true);
  return HTTP.post('default/card/' + id + '/', {}, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Change Language
 * @returns Api response
 */
export const changeLanguage = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.put('change/language/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};

/**
 * Can Buy Subscription
 * @returns Api response
 */
export const canBuySubscription = ({ commit, getters }, data) => {
  commit('setLoading', true);
  return HTTP.post('can/buy/subscription/', data, {
    headers: {
      Authorization: `Token ` + getters.login.token
    }
  }).then((response) => {
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setUnauthorized', false);
      return response;
    }
  }, (err) => {
    commit('setLoading', false);
    if (err.response.status == 401) {
      commit('setMessages', { type: "danger", message: err.response.data.detail });
      commit('setUnauthorized', true);
    }
    return err.response;
  }).catch(reason => {
    return reason;
  });
};
