module.exports = {
  label: {
    addExpenseTitle: "Add Expense",
    form: {
      label: {
        expenseName: 'Expense Name',
        expenseAmount: 'Expense Amount',
        expenseDate: 'Expense Date',
        uploadProof: 'Upload Proof'
      },
      placeholder: {
        expenseName: 'I.e. Painting',
        expenseAmount: '0.00',
        selectDate: 'Select Date',
        attach: 'Attach here'
      },
      validation: {
        expenseName: 'expense name',
        expenseAmount: 'expense amount',
        expenseDate: 'expense date',
        uploadProof: 'upload proof'
      },
    },
    buttons: {
      submit: 'Submit',
      add: 'Add',
    },
    errorMessages: {
      fileSizeExceeded: 'The file size should not be greater than 10MB',
    }
  },
};