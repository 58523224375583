module.exports = {
    label: {
      dateAdded: "Fecha Añadida",
      addProperties: 'Agregar Propiedad',
      readMore: 'Leer Más',
      amenities: 'Comodidades',
      viewAll: 'Ver Todo',
      propertyReport: 'Informe De Propiedad',
      maintenanceFee: 'Tarifa De Mantenimiento',
      leaseAmount: 'Monto Del Arrendamiento',
      advanceAmount: 'Cantidad De Avance',
      monthly: 'Mensual',      
      rentDetailsTitle: 'Detalles Del Alquiler',
      leasedTo: 'Arrendado A',
      leasedPeriod: 'Período Arrendado',
      startDate: 'Fecha De Inicio',
      endDate: 'Fecha Final',
      leaseAmountText: 'Monto Del Arrendamiento',
      depositAmount: 'Cantidad Del Depósito',
      rentDue: 'Fecha De Vencimiento Del Alquiler',
      maintenanceFeeText: 'Tarifa De Mantenimiento',
      amountDueFirstMonth: 'Importe Adeudado En El Primer Mes',
      leaseAgreementTitle: 'Contrato De Arrendamiento',
      sign: 'Firma',
      leaseAgreement: 'Contrato De Alquiler (PDF, JPEG, JPG, PNG)',
      photosVideos: 'Fotos Y Videos',
      dateFilter: 'Filtro De Fecha',
      details: 'Detalles',
      connectTo: 'Conectar A',
      from: 'De',
      to: 'A',
      paymentHistory: 'Historial De Pagos',
      form: {
        validation: {
          message: 'Mensaje'
        },
        placeholder: {
          message: 'Ingresar Mensaje'
        }
      },
      buttons: {
        payRent: 'Pagar Renta',
        paymentHistory: 'Historial De Pagos',
        signAgreement: 'Firmar Acuerdo',
        viewSign: 'Ver Señal',
        download: 'Descargar',
        submit: 'Entregar',
        filter: 'Aplicar Filtro',
        undo: "Deshacer",
        save: "Ahorrar"
      }
    }
  };