module.exports = {
    label: {
      addBankDetails: 'Agregar Datos Bancarios',
      required: '*',
      form: {      
        label: {
          name: "Ingrese El Nombre Del Titular De La Cuenta",
          bankName: 'Nombre Del Banco',
          bankAccountNumber: 'Número De Cuenta Bancaria',
          bankSwiftCode: 'Código Swift Del Banco (Opcional)',
          address: 'Dirección',
          city: 'Ciudad',
          state: 'Provincia',
          country: 'País',
          zipCode: 'Código Postal (Opcional)'
        },
        validation: {
          name: "nombre del titular de la cuenta",
          bankName: 'nombre del banco',
          bankAccountNumber: 'número de cuenta bancaria',
          bankSwiftCode: 'código swift del banco',
          address: 'dirección',
          city: 'ciudad',
          state: 'provincia',
          country: 'país',
          zipCode: 'código postal'
        },
        placeholder: {
          name: "Ingrese El Nombre Del Titular De La Cuenta",
          bankName: 'Ingrese El Nombre Del Banco',
          bankAccountNumber: 'Ingrese Número De Cuenta Bancaria',
          bankSwiftCode: 'Ingrese El Código Swift Del Banco',
          address: 'Dirección',
          city: 'Ciudad',
          state: 'Provincia',
          country: 'País',
          zipCode: 'Código Postal'
        }
      },
      buttons: {
        add: 'Agregar',
        save:'Guardar'
      }
    }
  };