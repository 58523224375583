<!-- content from my_account.js -->
<template>
  <div class="body-content">
    <div class="top-sticky">
      <div class="top-bar-radius"></div>
    </div>
    <div class="starts-section" v-loading="loading">
      <!-- alert component -->
      <alert-section currentModule="bank-details"></alert-section>
      <!-- body content header  -->
      <div class="page-breadcrumb">
        <div class="d-md-flex justify-content-between align-items-center">
          <div class="pg-bread">
            <b-breadcrumb class="bg-transparent p-0 mb-0">
              <b-breadcrumb-item active>
                {{ lang.label.title }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </div>
      </div>
      <div class="pb-3">
        <b-card no-body class="custom-card">
          <b-card-body>
            <el-tabs
              v-model="activeName"
              class="my-account-tab"
              :class="activeName == 'bankInformation' ? 'tab-pr' : ''"
            >
              <el-tab-pane :label="lang.label.tabs.myProfile" name="myProfile">
                <my-profile></my-profile>
              </el-tab-pane>
              <el-tab-pane v-if="login.user_type_id != 3"
                :label="lang.label.tabs.subscriptionPlan"
                name="subscriptionPlan"
              >
                <subscription-plan></subscription-plan>
              </el-tab-pane>
              <el-tab-pane
                :label="lang.label.tabs.bankInformation"
                name="bankInformation"
                v-if="(login.user_type_id==1 || login.user_type_id==2) || (login.user_type_id==3 && staffpermissions.length>0 && staffpermissions.includes('view_card'))"
              >
                <bank-information @activeTab="handleActiveBankTab"></bank-information>
              </el-tab-pane>
            </el-tabs>
            <!-- <div class="bank-add" v-if="activeName == 'bankInformation'">
                            <b-btn variant="primary" size="sm" class="btn-mw" v-b-toggle.addBankDetailsOwner v-if="activeTab == 'owner'">+ Add</b-btn>
                            <b-btn variant="primary" size="sm" class="btn-mw" v-b-toggle.addBankDetailsBroker v-if="activeTab == 'broker'">+ Add</b-btn>
                            <b-btn variant="primary" size="sm" class="btn-mw" v-b-toggle.addCard v-if="activeTab == 'savedCard'">+ Add</b-btn>
                        </div> -->
          </b-card-body>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import MyProfile from "./myaccount/MyProfile.vue";
import SubscriptionPlan from "./myaccount/SubscriptionPlan.vue";
import BankInformation from "./myaccount/BankInformation.vue";
import alertSection from "@/views/shared/AlertSection.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    "my-profile": MyProfile,
    "subscription-plan": SubscriptionPlan,
    "bank-information": BankInformation,
    "alert-section": alertSection,
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      login: "login",
      staffpermissions:"staffpermissions",
    }),
  },
  data() {
    return {
      lang: this.$lang.my_account,
      data: jsondata,
      activeName: "myProfile",
      activeBankTab: "",
      tabNames: {
        myProfile: 1,
        subscriptionPlan: 2,
        bankInformation: 3,
      },
      tabNamesArr: ["myProfile", "subscriptionPlan", "bankInformation"],
    };
  },
  watch: {
    activeName() {
      let selectedTab = this.tabNames[this.activeName];
      if (parseInt(this.$route.params.id) !== selectedTab) {
        this.$router.replace({
          name: "my-account",
          params: { id: selectedTab },
        });
      }
    },
    $route() {
      this.handleActiveTab();
    },
  },
  mounted() {
    this.handleActiveTab();
  },
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    addBank() {
      console.log(this.$emit("showSide", 15));
    },
    detailRequestSidebar(e) {
      this.showSidebar = e.showSidebar;
    },
    handleActiveBankTab(val) {
      this.activeBankTab = val;
    },
    handleActiveTab() {
      let self = this;
      // to set the my profile to default if user does not have permission to view bank/credit card details
      self.activeName = self.login.user_type_id==3 && self.staffpermissions.length>0 && !self.staffpermissions.includes('view_card')?self.tabNamesArr[0]:self.tabNamesArr[self.$route.params.id - 1];
    },
  },
};
</script>
