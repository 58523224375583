<template>
  <div class="">
    <h6>Select Language</h6>
    <b-form-group>
      <b-form-radio
        v-model="selectedLanguage"
        name="languageSelection"
        value="es"
        class="d-inline mr-3"
      >
        <p :style="isBackgroundDark ? 'color: #fff;' : ''">Española</p>
      </b-form-radio>
      <b-form-radio
        v-model="selectedLanguage"
        name="languageSelection"
        value="en"
        class="d-inline"
      >
        <p :style="isBackgroundDark ? 'color: #fff;' : ''">English</p>
      </b-form-radio>
    </b-form-group>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "languageSelection",
  props: ['isBackgroundDark'],
  computed: {
    ...mapGetters({
      language: "language",
      login: "login",
    }),
    languageChange() {
      return this.language;
    },
  },
  data() {
    return {
      selectedLanguage: this.language,
    };
  },
  watch: {
    selectedLanguage() {
      this.changeLanguage();
    },
    languageChange() {
      this.selectedLanguage = this.languageChange;
    },
  },
  mounted() {
    this.selectedLanguage = this.language;
  },
  methods: {
    /**
     * Sets Default Language
     */
     changeLanguage() {
      let self = this;
      if (self.login.token) {
        let data = {
          language: this.selectedLanguage == 'en' ? 1 : 2,
        }
        self.$store.dispatch("changeLanguage", data).then((response) => {
          if (response.status == 200) {
            self.$store.commit("setLanguage", this.selectedLanguage);
          } else {
            let message = response.data.message || response.data.detail;
            if (message) {
              self.$store.commit("setMessages", {
                type: "danger",
                message: message,
              });
            }
          }
        });
      } else {
        self.$store.commit("setLanguage", this.selectedLanguage);
      }
    },
  },
};
</script>
