module.exports = {
  label: {
    trial: 'Free Trial (New Customers Only)',
    days: 'Days',
    month: 'month',
    year: 'year',
    daily: 'daily',
    managedRentals: 'Managed Rentals',
    promotePropertyListing: 'Promote Property Listing',
    managedStaff: 'Managed Staff Members',
    upto: 'Upto',
    properties: 'Properties',
    members: 'Members',
    description: 'Description',
    unlimited:'Unlimited',
    reason : 'Reason Message',
    reasonText : 'Cancel Subscription! Why?',
    validation : {
      reasoneMessage : 'Reason Message'
    },
    placeholder:{
      message : 'Message...'
    },
    warning:'Warning',
    confirmTitle: 'Are you sure?',
    confirmMsg: 'You want to cancel the subscription plan',
    verificationAlert : 'You cannot downgrade the plan. Please write a query in support for this',
    paymentHistory:'Payment History',
    buttons: {
      enrollNow: 'Enroll Now',
      cancel: 'Cancel',
      payNow: 'Pay Now',
      submit : 'Submit',
      yes:'Yes',
      no:'No',
      ok:'Ok'
    },
    tableHeader:{
      userName : 'Name',
      planName: 'Plan Name',
      amount: 'Amount',
      startDate: 'Start Date',
      endDate: 'End Date',
      statusText: 'Status',
    },
    active: 'Active Plan',
    purchaseSubscriptionPlan: 'Purchase subscription plan to access this module' 
  }
};