module.exports = {
  label: {
    verifyEmialAlert: "Verifique su correo electrónico para obtener acceso completo a Miradoor, es posible que no pueda añadir contactos o propiedades.",
    success: 'Éxito',
    leaseSubmitlert: 'Los detalles de su contrato de arrendamiento se han guardado, cargue el documento del contrato de arrendamiento ahora. Tu siguiente paso es',
    leaseSubmitlertEnd: 'Publicar propiedad',
    leaseSubmitlertRented: 'Los detalles de su contrato de arrendamiento se han guardado.',
    confirmTitleStatusChange: '¿Estas Seguro?',
    confirmMsgStatusChange: '¿Desea cambiar el estado a?',
    editProperties: 'Editar Propiedad',
    propertyStatus: 'Estado De Propiedad',
    userType: 'Tipo De Usuario',
    mtSquare: 'Mt2',
    reviewedProperty: '¿Has revisado los detalles de tu propiedad?',
    updateAnytime: 'No se preocupe, puede actualizarlos en cualquier momento.',
    addAmenities: 'Añadir Amenidades',
    search: 'Búsqueda',
    addNewContact: 'Añadir Nuevo Contacto',
    selectContactLabel: 'Seleccionar Contacto',
    leaseAgreementConfiguration: 'Configuración Del Contrato De Arrendamiento',
    areYouSureCancel: '¿Está seguro de que desea cancelar?',
    allCleared: '*Todos los detalles no guardados se borrarán',
    errorMessages: {
      document: 'El Campo De La Categoría Miniatura Es Obligatorio',
      fileSizeExceeded: 'Se Ha Superado El Tamaño Del Archivo',
      imageSizeExceeded: 'Se Ha Superado El Tamaño De la Imagen',
    },
    confirmTitle: '¿Estas Seguro?',
    confirmMsg: '¿Quieres borrar esto?',
    ownerValidationTitle: 'Advertencia',
    ownerValidationMsg: 'Por favor, seleccione primero el contacto del propietario',
    propertyManagerAlert: "Si no selecciona ningún agente, usted se convertirá en el administrador de esta propiedad",
    confirmContactMsg:'Este usuario ya esta en el sistema. ¿estás seguro que quieres agregar a este usuario a tu lista de contactos?',
    propertyAddedAlert: 'La propiedad ha sido añadida a su lista',
    assignLeaseAgreement: 'Asignar Contrato De Alquiler',
    form:{
      uploadProperty: 'Subir Imágenes O Videos De La Propiedad',
      uploadLimit: 'Cargar Al Menos Una Imagen O Video, Máximo 20. Máximo 10mb Para Imagen Y 50 Mb Para Video.',
      asterisk:'*',
      rentAmount: 'Precio Del Alquiler',
      securityDepositAmount: 'Cantidad De Depósito De Seguridad',
      securityDeposit: 'Depósito De Seguridad',
      propertyType: 'Tipo De Propiedad',
      paymentPeriod: 'Período De Pago',
      maintenanceFee: 'Cuota De Mantenimiento',
      propertyName: 'Nombre De La Propiedad',
      aboutthisProperty: 'Acerca De Esta Propiedad',
      approxMtSquare: 'Espacio Habitable En Mt2',
      propertyArea: 'Área De La Propiedad',
      constructionYear: 'Año De Construcción',
      numberBedrooms: 'Nº Dormitorios',
      numberBathrooms: 'Nº Baños',
      parking: 'Estacionamiento(s)',
      propertyAmenities: 'Amenidades De La Propiedad',
      address: 'Dirección',
      propertyManager: 'Administrador De La Propiedad',
      ownerDetails: 'Detalles Del Propietario',
      leaseAgreement: 'Contrato De Alquiler (PDF, JPEG, JPG, PNG)',
      streetRoadName: 'Nombre De La Calle/Carretera',
      blockPropertynumber: 'Localización En El Mapa',
      city: 'Ciudad',
      state: 'Provincia',
      country: 'País',
      zoneCode: 'Código De Zona',
      placeholder: {
        zero: '0.00',
        select: 'Seleccionar',
        enterName: 'Ingrese Su Nombre',
        writeSomethingProperty: 'Escriba La Descripción De Esta Propiedad',
        enter: 'Ingrese',
        enterYear: 'Introduzca El Año',
        enterNumber: 'Ingrese El Número',
        blockPropertynumber: 'Bloque/Número De Propiedad',
        searchLocation: 'Buscar Ubicación',
        location: 'Localización En El Mapa',
        streetRoadName: 'Nombre De La Calle/Carretera',
        city: 'Ciudad',
        state: 'Provincia',
        country: 'País',
        zoneCode: 'Código De Zona',
        uploadDocument: 'Documento De Carga',
        realtor: 'Agente Inmobiliario',
        owner: 'Inquilino',
        available: 'Disponible',
        rented: 'Alquilado',
        commercial: 'Comercial',
        residential: 'Residencial',
      },
      validation: {
        propertyStatus: 'estado de propiedad',
        userType: 'tipo de usuario',
        rentAmount: 'monto de alquiler',
        securityDepositAmount: 'cantidad de depósito de seguridad',
        propertyType: 'tipo de propiedad',
        paymentPeriod: 'período de pago',
        maintenanceFee: 'tarifas de mantenimiento',
        propertyName: 'nombre de la propiedad',
        aboutthisProperty: 'acerca de esta propiedad',
        approxMtSquare: 'espacio habitable en mt2',
        propertyArea: 'area de la propiedad',
        constructionYear: 'año de construcción.',
        numberBedrooms: 'nº dormitorios',
        numberBathrooms: 'nº baños',
        parking: 'estacionamiento(s)',
        propertyAmenities: 'amenidades de la propiedad',
        propertyManager: 'administrador de la propiedad',
        ownerDetails: 'detalles del propietario',
        uploadProperty: 'imágenes o videos de la propiedad',
        blockPropertynumber: 'bloque/número de propiedad',
        searchLocation: 'buscar ubicación',
        location: 'localización en el mapa',
        streetRoadName: 'nombre de la calle/carretera',
        city: 'ciudad',
        state: 'provincia',
        country: 'país',
        zoneCode: 'código de zona',
        uploadDocument: 'documento de carga',
        assignLeaseAgreement: 'asignar contrato de alquiler',
        selectAmenities: 'por favor, seleccione las amenidades'
      }
    },
    buttons: {
      sendCode: 'Enviar Código De Verificación',
      add: 'Publicar Propiedad',
      cancel: 'Cancelar',
      addAmenities: 'Añadir Amenidades',
      selectBrokerContacts: 'Seleccionar Administrador De Propiedad',
      selectOwnerContacts: 'Seleccionar Desde Contactos',
      assignLeaseAgreement: 'Asignar Contrato De Alquiler',
      upload: 'Subir',
      yes: 'Sí',
      ok: 'Aceptar',
      no: 'No',
      addAmenitiesBtn: 'Añadir',
      selectContact: 'Seleccionar Contacto',
      addNewContactBtn: 'Añadir Nuevo',
    }
  }
};