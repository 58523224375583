<!-- content from signup.js -->
<template>
  <div class="otp-verify-section">
    <div class="form-header">
      <h1 class="form-heading mb-2">{{ lang.label.verification }}</h1>
      <p class="sf400">{{ lang.label.codeSentEmail }}</p>
    </div>
    <ValidationObserver ref="observer" v-slot="{ passes }">
      <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
        <div class="section-space">
          <b-form-group class="pt-2">
            <div class="verify-inputs">
              <b-card-group>
                <b-card class="border-0 rounded-0 bg-transparent">
                  <div class="input-control">
                    <b-form-input
                      @input="onInputEvent(1, $event)"
                      @keyup="allowNumbersOnly(1, $event)"
                      @keydown="onKeyDownEvent(1, $event)"
                      @keypress="isNumber(1, $event)"
                      @click="handleFocus"
                      class="digit-group font24"
                      ref="inp1"
                      v-model="inp1"
                      type="tel"
                      id="inp1"
                      autocomplete="off"
                      maxlength="1"
                      autofocus
                      @paste="onPaste(1, $event)"
                      placeholder="0"
                    ></b-form-input>
                  </div>
                </b-card>
                <b-card class="border-0 rounded-0 bg-transparent">
                  <div class="input-control">
                    <b-form-input
                      @input="onInputEvent(2, $event)"
                      @keyup="allowNumbersOnly(2, $event)"
                      @keydown="onKeyDownEvent(2, $event)"
                      @keypress="isNumber(2, $event)"
                      @click="handleFocus"
                      class="digit-group font24"
                      ref="inp2"
                      id="inp2"
                      v-model="inp2"
                      type="tel"
                      autocomplete="off"
                      maxlength="1"
                      @paste="onPaste(2, $event)"
                      placeholder="0"
                    ></b-form-input>
                  </div>
                </b-card>
                <b-card class="border-0 rounded-0 bg-transparent">
                  <div class="input-control">
                    <b-form-input
                      @input="onInputEvent(3, $event)"
                      @keyup="allowNumbersOnly(3, $event)"
                      @keydown="onKeyDownEvent(3, $event)"
                      @keypress="isNumber(3, $event)"
                      @click="handleFocus"
                      class="digit-group font24"
                      ref="inp3"
                      id="inp3"
                      v-model="inp3"
                      type="tel"
                      autocomplete="off"
                      maxlength="1"
                      placeholder="0"
                    ></b-form-input>
                  </div>
                </b-card>
                <b-card class="border-0 rounded-0 bg-transparent">
                  <div class="input-control">
                    <b-form-input
                      @input="onInputEvent(4, $event)"
                      @keyup="allowNumbersOnly(4, $event)"
                      @keydown="onKeyDownEvent(4, $event)"
                      @keypress="isNumber(4, $event)"
                      @click="handleFocus"
                      class="digit-group font24"
                      ref="inp4"
                      id="inp4"
                      v-model="inp4"
                      type="tel"
                      autocomplete="off"
                      maxlength="1"
                      placeholder="0"
                    ></b-form-input>
                  </div>
                </b-card>
                <b-card class="border-0 rounded-0 bg-transparent">
                  <div class="input-control">
                    <b-form-input
                      @input="onInputEvent(5, $event)"
                      @keyup="allowNumbersOnly(5, $event)"
                      @keydown="onKeyDownEvent(4, $event)"
                      @keypress="isNumber(5, $event)"
                      @click="handleFocus"
                      class="digit-group font24"
                      ref="inp5"
                      id="inp5"
                      v-model="inp5"
                      type="tel"
                      autocomplete="off"
                      maxlength="1"
                      placeholder="0"
                    ></b-form-input>
                  </div>
                </b-card>
                <b-card class="border-0 rounded-0 bg-transparent">
                  <div class="input-control">
                    <b-form-input
                      @input="onInputEvent(6, $event)"
                      @keyup="allowNumbersOnly(6, $event)"
                      @keydown="onKeyDownEvent(6, $event)"
                      @keypress="isNumber(6, $event)"
                      @click="handleFocus"
                      class="digit-group font24"
                      ref="inp6"
                      id="inp6"
                      v-model="inp6"
                      type="tel"
                      autocomplete="off"
                      maxlength="1"
                      placeholder="0"
                    ></b-form-input>
                  </div>
                </b-card>
              </b-card-group>
            </div>
          </b-form-group>
          <b-form-group class="text-center">
            <span class="text-grey" v-if="canBeResent"
              >{{ lang.label.didNotReceive }}
              <span class="bf600 cursor-pointer secondary-text" @click="resendOTP">{{
                lang.label.resend
              }}</span>
              </span >
              <count-down class="text-grey" v-if="!canBeResent" :end="timer" @timerStopped="handleTimerEnd"></count-down>
          </b-form-group>
          <b-form-group class="pt-3 mb-0">
            <b-btn block variant="primary" type="submit" :disabled="disabledVerify"
              >{{ lang.label.buttons.verify }}
            </b-btn>
          </b-form-group>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import Countdown from "@/views/shared/Countdown.vue"
import moment from "moment";
import mixin from "@/mixins/mixin.js";
import { mapGetters } from "vuex";
export default {
  components: {
    'count-down': Countdown,
  },
  props: ['canBeResent'],
  computed: {
    ...mapGetters({
      language: "language",
    }),
  },
  mixins: [mixin],
  data() {
    return {
      lang: this.$lang.signup,
      inputs: {
        inp1: false,
        inp2: false,
        inp3: false,
        inp4: false,
        inp5: false,
        inp6: false,
      },
      inp1: "",
      inp2: "",
      inp3: "",
      inp4: "",
      inp5: "",
      inp6: "",
      numerals: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
      inputType: "number",
      activeInput: 0,
      numInputs: 6,
      disabledVerify: true,
      interval: [],
      focused: false,
      prevValue: null,
      prevSelectionStart: null,
      allowBackspace: false,
      timer: null,
    };
  },
  created() {
    moment.updateLocale(this.language, {});
    this.setCouter(180);
  },
  watch: {
    canBeResent(val) {
      if (!val) {
        this.setCouter(180);
      }
    }
  },
  mounted() {
    this.handleEvenListener();
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/styles/img/" + img);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onPaste(index, event) {
      let self = this;
      event.preventDefault();
      const pastedData = event.clipboardData
        .getData("text/plain")
        .slice(0, this.numInputs - this.activeInput)
        .split("");
      if (this.inputType === "number" && !pastedData.join("").match(/^\d+$/)) {
        return "Invalid pasted data";
      } else {
        pastedData.forEach((obj, key) => {
          key = key + 1;
          if (key == 1) {
            self.inp1 = obj;
          } else if (key == 2) {
            self.inp2 = obj;
          } else if (key == 3) {
            self.inp3 = obj;
          } else if (key == 4) {
            self.inp4 = obj;
          } else if (key == 5) {
            self.inp5 = obj;
          } else if (key == 6) {
            self.inp6 = obj;
          }
          if (key < 6) {
            this.$refs["inp" + (key + 1)].focus();
          } else if (key == 6) {
            this.$refs["inp" + key].blur();
          }
        });
        if (self.inp1 && self.inp2 && self.inp3 && self.inp4 && self.inp5 && self.inp6) {
          this.disabledVerify = false;
        } else {
          this.disabledVerify = true;
        }
      }
    },
    isNumber: function (index, evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        if (this.$refs["inp" + index].value && index != 6) {
          this.$refs["inp" + (index + 1)].focus();
        }
        return true;
      }
    },
    getCodeBoxElement(index) {
      return document.getElementById("inp" + index);
    },
    onInputEvent(index, e) {
      this.handleFocus();
      this.invalidCode = false;
      this.resentCode = false;
      if (!this.numerals.includes(parseInt(e))) {
        this.inputs["inp" + index] = true;
      } else {
        this.inputs["inp" + index] = false;
      }
      let self = this;
      if (this.numerals.includes(parseInt(e))) {
        if (index < 6 && this.getCodeBoxElement(index).value.length) {
          this.getCodeBoxElement(index + 1).focus();
          this.handleFocus();
        }
        if (self.inp1 && self.inp2 && self.inp3 && self.inp4 && self.inp5 && self.inp6) {
          this.disabledVerify = false;
        } else {
          this.disabledVerify = true;
        }
      } else {
        this.disabledVerify = true;
        if (index == 1) {
          self.inp1 = "";
        } else if (index == 2) {
          self.inp2 = "";
        } else if (index == 3) {
          self.inp3 = "";
        } else if (index == 4) {
          self.inp4 = "";
        } else if (index == 5) {
          self.inp5 = "";
        } else {
          self.inp6 = "";
        }
      }
    },
    onKeyDownEvent(index, event) {
      if (
        event.code == "Backspace" &&
        !this.getCodeBoxElement(index).value.length &&
        index != 1
      ) {
        // this.getCodeBoxElement(index - 1).focus();
      }
    },
    allowNumbersOnly(index, event) {
      let self = this;
      const input = event.target;
      input.isUpdateNeeded = true;
      let value = JSON.parse(JSON.stringify(event.target.value));
      value = value.split(" ").join("");
      // Check if value is number
      let isValid = +value == +value;
      const keysAllowed = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Backspace",
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
        "Delete",
        "Tab", 
        "Control",
        "Shift",
        'a', 
        'c',
        'v', 
        'x', 
        'z',
        'A',
        'C',
        'V',
        'X',
        'Z',
      ];
      const keyPressed = event.key;
      if (keyPressed == "Backspace") {
        console.log('Backspace', index);
      }
      if (isValid && keysAllowed.includes(keyPressed)) {
        // preserve input state
        if (keyPressed == "Backspace" && self.prevValue) {
          self.allowBackspace = false;
        } else if (keyPressed == "Backspace" && !self.prevValue) {
          self.allowBackspace = true;
        }
        self.prevValue = value;
        self.prevSelectionStart = input.selectionStart;

        if (
          keyPressed == "Backspace" &&
          !self.getCodeBoxElement(index).value.length &&
          index != 1
        ) {
          if (self.allowBackspace) {
            self.getCodeBoxElement(index).value = null;
            self.getCodeBoxElement(index - 1).focus();
            console.log('index', index);
          }
        }
      } else {
        // restore previous valid input state.
        // we have to fire one more Input event in  order to reset cursor position.
        let resetEvent = new InputEvent("input");
        input.value = self.prevValue;
        input.selectionStart = self.prevSelectionStart;
        input.selectionEnd = self.prevSelectionStart;
        input.dispatchEvent(resetEvent);
      }
    },
    onSubmit() {
      let self = this;
      let userOtp = self.inp1 + self.inp2 + self.inp3 + self.inp4 + self.inp5 + self.inp6;
      console.log("userOtp", userOtp);
      self.$emit("verifyOTP", userOtp);
    },
    back() {
      this.$router.go(-1);
    },
    resendOTP() {
      this.$emit("resendOTP", true);
    },
    setCouter(sec) {
      const today = moment();
      let timer = moment(today).add(sec, "seconds");
      this.timer = timer.format("YYYY-MM-DD HH:mm:ss");
    },
    handleTimerEnd() {
      this.$emit('handleTimerDone', true);
    },
  },
};
</script>
