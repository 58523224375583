import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      language: "language",
    }),
  },
  data() {
    return {
      lang: this.$lang.root,
      doFocus: false,
      isValid: true,
      token: null,
      selectors: {
        paymentSelector: '#PayButton',
        ccnumber: '#demoCcnumber',
        ccexp: '#demoCcexp',
        cvv: '#demoCvv'
      },
      paymentValidationsName: {
        "Field is empty": "fieldEmpty",
        "Card number must be 13-19 digits and a recognizable card format": "cardFormate",
        "Expiration date must be a present or future month and year": "cardExpired",
        "CVV must be 3 or 4 digits": "cvvValidation",
      },
    }
  },
  watch: {
  },
  created() {
    this.$store.commit("setLoadingRelative", true);
    this.$store.commit("setCardDetails", true);
  },
  mounted() {
    let self = this;
    self.initializePaymentScript()
  },
  methods: {
    initializePaymentScript() {
      let self = this;
      CollectJS.configure({
        paymentSelector: self.selectors.paymentSelector,
        variant: "inline",
        styleSniffer: "false",
        googleFont: "Open Sans:400",
        customCss: {
          color: "#000000",
          height: "50px",
          border: "1px solid #C3C3C3",
          "font-size": "14px",
          padding: "12px",
          "background-color": "#ffffff",
          "border-radius": "5px",
          "text-rendering": "geometricPrecision",
        },
        invalidCss: {
          "border-color": "#dc3545",
          color: "#000000",
        },
        validCss: {
          "border-color": "#28a745",
          color: "#000000",
        },
        placeholderCss: {
          "background-color": "white",
          color: "#8D8D8D",
        },
        focusCss: {
          "border-color": "#5734D6",
          color: "#000000",
          "outline-width": "0",
        },
        fields: {
          ccnumber: {
            selector: self.selectors.ccnumber,
            title: "Card Number",
            placeholder: "0000 0000 0000 0000",
            enableCardBrandPreviews: true,
          },
          ccexp: {
            selector: self.selectors.ccexp,
            title: "Card Expiration",
            placeholder: "00 / 00",
          },
          cvv: {
            display: "required",
            selector: self.selectors.cvv,
            title: "CVV Code",
            placeholder: "***",
          },
        },
        price: "0.00",
        currency: "USD",
        country: "US",
        validationCallback: function (field, status, message) {
          if (!status) {
            self.nmi_error[field] = self.getMessageConverted(message);
          } else {
            self.nmi_error[field] = '';
          }
          // if (status) {
          //   self.isValid = true;
          // } else {
          //   self.isValid = false;
          // }
        },
        timeoutDuration: 10000,
        timeoutCallback: function () {
          self.$store.commit('setLoading', false);
        },
        fieldsAvailableCallback: function () {
          self.$store.commit("setLoadingRelative", false);
        },
        callback: function (response) {
          self.token = response.token;
          self.$store.commit('setLoading', false);
          self.handlePaymentToken();
        },
      });
    },
    initializeRequestToken() {
      this.$store.commit('setLoading', true);
      CollectJS.startPaymentRequest()
    },
    getMessageConverted(message) {
      return this.lang.label.paymentValidations[this.paymentValidationsName[message]] || message;
    },
  },
}