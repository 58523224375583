<template>
  <div id="app">
    <div class="full-section">
      <app-sidebar></app-sidebar>
      <app-header></app-header>
      <div class="body-middle">
        <router-view />
      </div>
      <!-- <app-footer></app-footer> -->
    </div>
  </div>
</template>

<script>
import Header from "@/components/layout/Header.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import { mapGetters } from "vuex";
import Vue, { set } from "vue";
import verificationCode from "@/components/VerificationCode";
// import Footer from "@/components/layout/Footer.vue";
import router from "./router";
import store from "./store";
import Swal from "sweetalert2";
export default {
  components: {
    "app-header": Header,
    "app-sidebar": Sidebar,
    // "app-footer": Footer,
    verificationCode,
  },
  computed: {
    ...mapGetters({
      unauthorized: "unauthorized",
      messages: "messages",
      errorTimeOut: "errorTimeOut",
      language: "language",
      login:'login',
      subscriptionAlert: "subscriptionAlert",
      verificationAlert: "verificationAlert",
    }),
  },
  data() {
    return {
      lang: this.$lang.root,
    }
  },
  watch: {
    $route(val) {
      if (val && val.path) {
        if (this.messages && this.messages[0]) {
          if (val.name != this.messages[0].allow_route) {
            this.$store.commit("setMessages", null);
          }
        }
      }

      // Add Verification section
      setTimeout(() => {
        this.handleVerificationSection();
      }, 0);
    },
    unauthorized(val) {
      if (val) {
        this.$store.commit("clearLogin");
        this.$store.commit("setUnauthorized", false);
        this.$router.push({ name: "login" });
      }
    },
    messages(val) {
      let self = this;
      if(val && val.length && val[0].disable_timer && val[0].disable_timer==true){
        return false;
      } else if (val && val.length) {
        setTimeout(function () {
          self.$store.commit("setMessages", null);
        }, this.errorTimeOut);
      }
    },
    language() {
      window.location.reload();
    },
    /**
     * Shows validation alert id user subscription is not available
     * @param {boolean} val whether alert should be visble
     */
    subscriptionAlert(val) {
      let self = this;
      if (val) {
        this.$store.commit("setSubscriptionAlert", false);
        Swal.fire({
          title: self.lang.label.warning,
          text: self.login.user_type_id != 3 ? self.lang.label.subscriptionAlert : self.lang.label.parentSubscriptionAlert ,
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: self.lang.label.buttons.ok,
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        }).then((result) => {
          if (!(self.$route.name == "my-account" && self.$route.params.id == 2) && self.login.user_type_id != 3) {
            self.$router.push({ name: "my-account", params: { id: 2 } });
          }
        });
      }
    },
    /**
     * Shows validation alert id user is not verified
     * @param {boolean} val whether alert should be visble
     */
    verificationAlert(val) {
      let self = this;
      if (val || val.status) {
        this.$store.commit("setVerificationAlert", false);
        Swal.fire({
          title: self.lang.label.warning,
          text: self.lang.label.verificationAlert,
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: self.lang.label.buttons.ok,
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        }).then(() => {
          if (val.redirection) {
            self.$router.push({ name: 'dashboard' })
          }
        })
      }
    }
  },
  mounted() {
    this.handleVerificationSection();
  },
  methods: {
    /**
     * Appends verification section onto body, on every page by creating new instance
     */
    handleVerificationSection() {
      let bodyElem = document.getElementsByClassName("body-content");
      let isVerifyVisible = document.getElementById("VerificationSection");
      if (bodyElem && bodyElem[0] && !isVerifyVisible) {
        let ComponentClass = Vue.extend(verificationCode);
        let instance = new ComponentClass({
          router,
          store,
        });
        instance.$mount(); // pass nothing
        bodyElem[0].prepend(instance.$el);
      }
    },
  },
};
</script>
