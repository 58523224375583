<!-- content from my_contacts.js -->
<template>
    <div class="body-content">
        <div class="top-sticky">
            <div class="top-bar-radius"></div>
        </div>
        <div class="starts-section" v-loading="loading">
            <!-- body content header  -->
            <alert-section></alert-section>
            <div class="page-breadcrumb">
                <div class="d-md-flex justify-content-between align-items-center">
                    <div class="pg-bread">
                        <b-breadcrumb class="bg-transparent p-0 mb-0">
                            <b-breadcrumb-item active>
                                <span class="back-arrow material-icons align-middle" @click="$router.push('/contacts')">arrow_back</span>
                                {{ lang.label.myContactDetail }}
                            </b-breadcrumb-item>
                            <b-breadcrumb-item active>
                                <span class="material-icons align-middle">description</span>
                                <span class="align-middle ml-1">{{ notesLength != 0 ?  notesLength : ''}}</span>
                            </b-breadcrumb-item>
                        </b-breadcrumb>
                    </div>
    
                    <div class="pg-right">
                        <div class="d-flex align-items-center right-buttons">
                            <b-btn variant="primary" size="sm" class="text-nowrap btn-mw" @click="addNoteClick">{{lang.label.addNoteLabel}}</b-btn>
                            <b-btn variant="primary" size="sm" class="text-nowrap btn-mw" v-b-toggle.assignHome>{{ lang.label.buttons.assignHome }}</b-btn>
                            <b-btn variant="primary" size="sm" class="text-nowrap btn-mw"  @click="deleteContact(contactID)">{{ lang.label.buttons.delete }}</b-btn>
                            <b-btn variant="primary" size="sm" class="text-nowrap btn-mw" @click="editNewContact">{{ lang.label.buttons.edit }}</b-btn>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="contact-card">
                <b-card no-body class="custom-card">
                    <div class="card-body">
                        <b-media no-body>
                            <b-media-aside>
                                <img v-if="models.contactImage" :src="getUserImgUrl(models.contactImage)" alt="user" />
                                <div v-else class="placeholder-user">
                                    <span class="material-icons">person</span>
                                </div>
                            </b-media-aside>
                            <b-media-body>
                                <h5 class="bf700 sf-size secondary-text">{{ models.firstName }} {{ models.lastName}}</h5>
                        <p class="mb-2">{{ models.contactType }}</p>
                        <div class="d-flex flex-wrap">
                            <div class="mr-4 my-1">
                                <span class="material-icons align-middle secondary-text mr-2">mail</span>
                                <span class="align-middle">{{ models.email }}</span>
                            </div>
                            <div class="mr-4 my-1">
                                <span class="material-icons align-middle secondary-text mr-2">phone</span>
                                <span class="align-middle">+507 {{ models.contact }}</span>                                
                            </div>
                        </div>
                            </b-media-body>
                        </b-media>


                        
                        
                    </div>
                </b-card>
            </div>
            
            <div class="mt-3" v-show="notesLength" >
                <b-card no-body class="custom-card">
                    <div class="card-body">
                        <properties-notes :contactID="contactID" :listCall='apiCall'  @handleNotesLength="handleNotesLength" @listCallInit='listCallInit' v-if="contactID!=''" ></properties-notes>
                    </div>
                </b-card>
            </div>
        </div>

        <!-- add new contact -->
        <b-sidebar id="editNewContact" footer-class="sidebar-footer" sidebar-class="sidebar-view" text-variant="none" backdrop right width="462px" @change="updateSidebar" :visible="isEdit">
            <template #header="{ hide }">
                <div class="d-flex justify-content-between w-100">
                    <div class="d-flex align-items-center">
                        <b-btn variant="link" size="sm" class="p-0" @click="hide">
                            <img :src="getIconUrl('close-sm.svg')" alt="close" />
                        </b-btn>
                        <h4 class="sidebar-header-title mb-0">{{ lang.label.editContact }}</h4>
                    </div>
                </div>
            </template>
            <div class="amenity-body" v-if="isEdit">
                <add-contact :contactData="contactDetails" @updateContact="updateContact" :isEdit="isEdit">
                </add-contact>
            </div>
        </b-sidebar>

        <!-- assign a home contact -->
        <b-sidebar id="assignHome" footer-class="sidebar-footer" sidebar-class="sidebar-view" text-variant="none" backdrop right width="1200px" @change="updatePropertySlideBar" :visible="isPropertyList">
            <template #header="{ hide }">
                <div class="d-flex justify-content-between w-100">
                    <div class="d-flex align-items-center">
                        <b-btn variant="link" size="sm" class="p-0" @click="hide">
                            <img :src="getIconUrl('close-sm.svg')" alt="close" />
                        </b-btn>
                        <h4 class="sidebar-header-title mb-0">{{ lang.label.assignHome }} {{ models.firstName }} {{ models.lastName }}</h4>
                    </div>
                </div>
            </template>
            <div class="amenity-body" v-if="isPropertyList">
                <properties-table :tableData="assignProperties" :editable="login.user_type_id==3 && staffpermissions.length>0 && !staffpermissions.includes('update_property')?false:true" :redableOnly="true"></properties-table>
            </div>
        </b-sidebar>  


        <!-- Add notes -->
        <ValidationObserver ref="observer" v-slot="{ passes }">
            <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
                <b-sidebar
                id="addNoteSidebar"
                footer-class="sidebar-footer"
                sidebar-class="sidebar-view"
                text-variant="none"
                backdrop
                right
                width="462px"
                :visible="isAddNoteVisible"
                @change="updateAddNoteSlider"
                >
                <template #header="{ hide }">
                    <div class="d-flex justify-content-between w-100">
                    <div class="d-flex align-items-center">
                        <b-btn variant="link" size="sm" class="p-0" @click="hide">
                        <img :src="getIconUrl('close-sm.svg')" alt="close" />
                        </b-btn>
                        <h4 class="sidebar-header-title mb-0">{{ lang.label.addNoteLabel }}</h4>
                    </div>
                    <b-btn variant="primary" type="submit">
                        {{ lang.label.buttons.save }}
                    </b-btn>
                    </div>
                </template>
                <div class="amenity-body">
                    <b-form-group class="mb-0">
                    <ValidationProvider
                        :name="lang.label.validation.note"
                        rules="required|max:200"
                        v-slot="validationContext"
                    >
                        <div class="position-relative">
                        <b-form-textarea
                            v-model="noteModel"
                            type="text"
                            :state="getValidationState(validationContext)"
                            placeholder="Write..."
                            rows="5"
                        ></b-form-textarea>
                        <b-form-invalid-feedback
                            >{{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                    </b-form-group>
                </div>
                </b-sidebar>
            </b-form>
        </ValidationObserver>
      
    </div>
</template>
<script>
import AddContactForm from "@/components/contacts/AddForm.vue";
import PropertiesTable from "@/components/PropertiesTable.vue";
import PropertiesNotes from "@/components/PropertiesNotes.vue";
import alertSection from "@/views/shared/AlertSection.vue";
import Swal from "sweetalert2";
import mixin from "@/mixins/mixin";
import { mapGetters } from "vuex";

export default {
    components: {
        "add-contact": AddContactForm,
        "properties-table": PropertiesTable,
        "properties-notes": PropertiesNotes,
        "alert-section": alertSection,
    },
    mixins: [mixin],
    computed: {
        ...mapGetters({
            ImageUrl :'ImageUrl',
            loading: "loading",
            login:"login",
            staffpermissions:"staffpermissions",
            isUserVerified: "isUserVerified",
        }),
    },
    data() {
        return {
            lang: this.$lang.my_contacts,
            contactID: '',
            notesLength: 0,
            contactDetails:'',
            isEdit:false,
            isPropertyList:false,
            assignProperties:[],
            models:{
                firstName:'',
                lastName:'',
                email:'',
                contact:'',
                contactType:'',
                contactImage:''
            },
            rollName: {
                1: this.$lang.my_contacts.label.tabs.broker,
                2: this.$lang.my_contacts.label.tabs.owner,
                3: this.$lang.my_contacts.label.tabs.tenant,
            },
            isNotesVisible:false,
            isAddNoteVisible:false,
            noteModel: "",
            apiCall:false,
        };
    },
    mounted() {
        this.contactID = this.$route.params.id
        this.viewContactDetails(this.contactID)
        this.updateSidebar();
        this.listOfContactProperties();
    },
    methods: {
        getIconUrl(icon) {
            return require("@/assets/styles/img/icons/" + icon);
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        getUserImgUrl(img){
            if (!img) {
                return require("@/assets/styles/img/properties/logo.svg");
            }
            return this.ImageUrl + img;
        },
        updateSidebar(val) {
            this.isEdit = val;
            const body = document.getElementsByTagName('body')[0];
            if(val){
                body.classList.add("overflow-hidden")
            } else {
                body.classList.remove("overflow-hidden")
            }
        },
        updatePropertySlideBar(val){
            this.isPropertyList = val
        },
        /**
         * handles the notes length after delete it 
         * @param {number} val value of no. of notes
         */
        handleNotesLength(val){
            this.notesLength = val;
        },
        /**
         * Show the Details of specific contact
         * @param {number} id contact id
         */
        viewContactDetails(id){
            let self = this;
            self.$store.dispatch('viewContact',id).then((response) => {
                if(response.status == 200){
                    self.contactDetails = {...response.data}
                    self.models = {
                        firstName: response.data.first_name,
                        lastName:response.data.last_name,
                        email:response.data.email,
                        contact:response.data.contact,
                        contactType: self.rollName[response.data.contact_type],
                        contactImage:response.data.profile_image                        
                    };
                }else{
                    let message = response.data.message || response.data.detail;
                    if (message) {
                        self.isNotesVisible = false;
                        self.$store.commit("setMessages", {
                        type: "danger",
                        message: message,
                        });
                        self.$router.push({ name: "not-found" });
                    }
                }
            })
        },
        /**
        * Handles contact add operation
        * @param {object} data Contact data to be added
        */
        updateContact(data) {
            let self = this;
            let updateContactdata ={
                id:self.contactID,
                data : data
            }
            self.$store.dispatch("updateContact", updateContactdata).then((response) => {
                if (response.status == 200) {
                    self.isEdit = false;
                    self.viewContactDetails(self.contactID)
                    if (response.data.message) {
                        self.$store.commit("setMessages", {
                        type: "success",
                        message: response.data.message,
                        });
                    }
                } else {
                    let message = response.data.message || response.data.detail;
                    if (message) {
                        self.$store.commit("setMessages", {
                        type: "danger",
                        message: message,
                        allow_module: "UpdateContact"
                        });
                    }
                }
            });
        },
        /**
         * Delete the contact and redirect to list contact page
         * @param {number} id Contact id
         */
        deleteContact(id){
            let self = this;
            Swal.fire({
                title: self.lang.label.confirmTitle,
                text: self.lang.label.confirmMsg,
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: self.lang.label.buttons.yes,
                cancelButtonText: self.lang.label.buttons.no,
                focusConfirm: false,
                buttonsStyling: false,
                customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-danger",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                self.$store.dispatch("deleteContact", id).then((response) => {
                    if (response.status == 200) {
                        Swal.fire(this.$lang.root.label.deleted, response.data.message, "success");
                        self.$router.push({name:'contacts'})
                    } else {
                        let message = response.data.message || response.data.detail;
                        if (message) {
                            self.$store.commit("setMessages", {
                            type: "danger",
                            message: message,
                            });
                        }
                    }
                });
                }
            });
        },
        /**
         * Call the api of Assigned Contact Properties Details
         */
        listOfContactProperties(){
            let self = this;
            let data = {
                id : self.contactID,
                page : 1,
                size : 1000000
            };
            self.$store.dispatch('listOfContactProperties',data).then((response) => {
                if(response.status == 200){
                    self.pushIntotableData(response);
                }else{
                    let message = response.data.message || response.data.detail;
                    if (message) {
                        self.isNotesVisible = false;
                        self.$store.commit("setMessages", {
                        type: "danger",
                        message: message,
                        });
                    }
                }
            })
        },
        /**
         * Handles edit contact event
         */
        editNewContact() {
            if (this.isUserVerified) {
                this.$store.commit("setVerificationAlert", true);
            } else {
                this.updateSidebar(true);
            }
        },
        /**
         * Create the details of assigned property of contact
         * @param {object} response Details of list Of Contact Properties
         */
        pushIntotableData(response) {
            let self = this;
            self.assignProperties = [];
            response.data.results.forEach((v) => {
                let sumOfIncome = v.income.reduce((a, v) => a + v, 0);
                let sumOfExpense = v.expense.reduce((a, v) => a + v, 0);
                self.assignProperties.push({
                    id: v.id,
                    amount: "$" + v.rent_amount,
                    address: v.address,
                    status: v.status_name,
                    status_id: v.status_id,
                    img: v.property_image,
                    dateAdded: self.formatDateToShow(v.created_date),
                    type: v.type_name,
                    rentDue: self.formatDateToShow(v.next_due_date),
                    series: [
                        {
                        name: "Income",
                        data: v.income,
                        },
                        {
                        name: "Expense",
                        data: v.expense,
                        },
                    ],
                    totalExpense: sumOfExpense,
                    totalIncome: sumOfIncome,
                });
                })
        },
        addNoteClick() {
            let self = this;
            self.isAddNoteVisible = true;
        },
        /**
         * Handles sidebar for adding notes
         */
        updateAddNoteSlider(val){
            let self=this;
            self.isAddNoteVisible=val
            const body = document.getElementsByTagName('body')[0];
            if(val){
                body.classList.add("overflow-hidden")
            } else if(!val){
                body.classList.remove("overflow-hidden")
                self.noteModel=''
                self.$refs.observer.reset();
            }
        },
        /**
         * Handles add contact notes
         */
        onSubmit(){
            let self=this;
            let data = {
                contact : self.contactID,
                note : self.noteModel
            }
            self.$store.dispatch('addContactNotes',data).then((response) =>{
                if(response.status == 200){
                self.isAddNoteVisible = false;
                self.apiCall=true
                }else{
                 let message = response.data.message || response.data.detail;
                    if (message) {
                        self.isNotesVisible = false;
                        self.$store.commit("setMessages", {
                        type: "danger",
                        message: message,
                        });
                    }
                }
            })
        },
        /**
         * Sets list api call to init value
         */
        listCallInit(val) {
            let self = this;
            self.apiCall =val;
        }
    }
}
</script>