
export const editExpense = state => {
    return state.editExpense;
};

export const loading = state => {
    return state.loading;
};

export const messages = state => {
    return state.messages;
};

export const login = state => {
    let state1 = state.login;
    let ls = localStorage.getItem('login');
    let localStorageLogin = ls != null ? JSON.parse(ls) : state1;
    let loginResponse = localStorageLogin != null ? localStorageLogin : state.login;
    return loginResponse;
};

export const unauthorized = state => {
    return state.unauthorized;
};

export const errorTimeOut = state => {
    return state.errorTimeOut;
};

export const language = state => {
    let state1 = state.language;
    let ls = localStorage.getItem('language');
    let localStorageMaster = ls != null ? ls : state1;
    return localStorageMaster != null ? localStorageMaster : state.language;
};

export const ImageUrl = state => {
    return process.env.VUE_APP_IMG_ENDPOINT;
};

export const staffpermissions = state => {
    let state1 = state.staffPermissions;
    let ls = localStorage.getItem('staffpermissions');
    let localStorageMaster = ls != null ? JSON.parse(ls) : state1;
    return localStorageMaster != null ? localStorageMaster : state.staffPermissions;
};

export const filterpayloads = state => {
    return state.payloadFilters;
};

export const subscriptionAlert = state => {
    return state.subscriptionAlert;
};

export const verificationAlert = state => {
    return state.verificationAlert;
};

export const isUserVerified = state => {
    let state1 = state.login;
    let ls = localStorage.getItem('login');
    let localStorageLogin = ls != null ? JSON.parse(ls) : state1;
    let loginResponse = localStorageLogin != null ? localStorageLogin : state.login;
    return  !loginResponse.is_verified && loginResponse.user_type_id !== 3;
};

export const isNewUser = state => {
    return state.isNewUser
};

export const loadingRelative = state => {
    return state.loadingRelative;
};

export const cardDetails = state => {
    return state.cardDetails;
};

export const selectedPlan = state => {
    return state.selectedPlan;
};

export const isUserOnlyTenant = state => {
    let state1 = state.login;
    let ls = localStorage.getItem('login');
    let localStorageLogin = ls != null ? JSON.parse(ls) : state1;
    let loginResponse = localStorageLogin != null ? localStorageLogin : state.login;
    return loginResponse.user_has_tenant_contact_type && loginResponse.subscription_purchased_count == 0;
};

export const isInvitedUser = state => {
    let state1 = state.login;
    let ls = localStorage.getItem('login');
    let localStorageLogin = ls != null ? JSON.parse(ls) : state1;
    let loginResponse = localStorageLogin != null ? localStorageLogin : state.login;
    return  loginResponse.subscription_purchased_count == 0;
};