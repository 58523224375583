<template>
  <div>
    <b-form-group>
      <div
        class="property-manager"
        :style="isRented || isStaff ? 'pointer-events: none; cursor: default;' : ''"
      ><div class="d-md-flex justify-content-between align-items-center">
        <label class="form-label mb-0 mr-2">{{ lang.label.form[title] }} <span v-if="type == 'owner'" class="text-danger">*</span>
          <br v-if="isNoteVisible" />
          <p
            class="text-grey align-middle"
            v-if="isNoteVisible"
            >{{ '*' + note }}</p>
        </label>
        <b-btn variant="primary" size="sm" @click="updateContactSidebar(true)">{{
          lang.label.buttons[buttonTitle]
        }}</b-btn>
        </div>

        <ValidationProvider
          :name="lang.label.form.validation[title]"
          :rules="isNotRequired ? '' : 'required'"
          v-slot="validationContext"
          class="w-100"
        >
          <el-select
            v-model="selectedContactId"
            placeholder="Select"
            size="small"
            :class="{ 'is-invalid': validationContext.errors[0] }"
            :state="getValidationState(validationContext)"
            disabled
            v-show="false"
          >
            <el-option
              v-for="item in [selectedContact]"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <b-form-invalid-feedback
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
        <div class="chips mt-2" v-if="selectedContact.name">
          <b-badge pill variant="secondary" class="chip-items">
            <div class="chip-item">
              <div class="chip-inner">
                <span>{{ selectedContact.name + " : " + selectedContact.email }}</span>
              </div>
              <b-btn
                variant="link"
                class="p-0 text-grey"
                @click="removeContactChip()"
                :disabled="isRented"
              >
                <span class="material-icons">close</span>
              </b-btn>
            </div>
          </b-badge>
        </div>
        <div class="chips mt-2" v-if="selectedMember.name">
          <b-badge pill variant="secondary" class="chip-items">
            <div class="chip-item">
              <div class="chip-inner">
                <span>{{ selectedMember.name + " : " + selectedMember.email }}</span>
              </div>
              <b-btn
                variant="link"
                class="p-0 text-grey"
                @click="removeMemberChip()"
                :disabled="isRented || isStaff"
              >
                <span class="material-icons">close</span>
              </b-btn>
            </div>
          </b-badge>
        </div>
      </div>
    </b-form-group>

    <!-- add new contact -->
    <b-sidebar
      v-if="userType == 2 || type == 'owner'"
      :id="sibarName"
      footer-class="sidebar-footer"
      sidebar-class="sidebar-view"
      text-variant="none"
      backdrop
      right
      width="462px"
      @change="updateContactSidebar"
      :visible="isContactVisible"
    >
      <template #header="{ hide }">
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex align-items-center">
            <b-btn variant="link" size="sm" class="p-0" @click="hide">
              <img :src="getIconUrl('close-sm.svg')" alt="close" />
            </b-btn>
            <h4 class="sidebar-header-title mb-0">
              <template v-if="!contactListVisible">
                {{ lang.label.addNewContact }}
              </template>
              <template v-if="contactListVisible">
                {{ lang.label.selectContactLabel }}
              </template>
            </h4>
          </div>
          <b-btn variant="primary" @click="contactList()">
            <template v-if="!contactListVisible">
              {{ lang.label.buttons.selectContact }}
            </template>
            <template v-if="contactListVisible">
              {{ lang.label.buttons.addNewContactBtn }}
            </template>
          </b-btn>
        </div>
      </template>
      <div class="amenity-body">
        <template v-if="!contactListVisible">
          <add-contact
            v-if="isContactVisible"
            :type="type"
            @handleContatctValidate="handleContatctValidate"
          ></add-contact>
        </template>

        <!-- contact list component -->
        <template v-if="contactListVisible">
          <contact-list
            :type="type"
            :selectedContact="selectedContact"
            @handleContactSelect="handleContactSelect"
            v-if="isContactVisible"
          ></contact-list>
        </template>
      </div>
    </b-sidebar>

    <!-- add new member -->
    <b-sidebar
      v-else
      :id="sibarName"
      footer-class="sidebar-footer"
      sidebar-class="sidebar-view"
      text-variant="none"
      backdrop
      right
      width="462px"
      @change="updateContactSidebar"
      :visible="isContactVisible"
    >
    <template #header="{ hide }">
        <div class="d-flex justify-content-between w-100">
            <div class="d-flex align-items-center">
                <b-btn variant="link" size="sm" class="p-0" @click="hide">
                    <img :src="getIconUrl('close-sm.svg')" alt="close" />
                </b-btn>
                <h4 class="sidebar-header-title mb-0">
                  <template v-if="memberListVisible">
                    {{ langStaff.label.addMemberText }}
                  </template>
                  <template v-if="!memberListVisible">
                    {{ langStaff.label.selectMemberText }}
                  </template>
                </h4>
            </div>
            <template v-if="memberListVisible">
              <b-btn  variant="primary" @click="memberList()">
                  {{ langStaff.label.selectMemberText }}
              </b-btn>
            </template>
            <template  v-if="!memberListVisible">
              <b-btn variant="primary" @click="addNewMember()">
                  {{ langStaff.label.buttons.addNew }}
              </b-btn>
            </template>
        </div>
    </template>
    <div class="amenity-body">
        <template v-if="memberListVisible && isContactVisible">
            <add-member
            :fromProperty="true"
            :clearData="clearData"
            @handleMember="handleMemberSelect" ></add-member>
        </template>

         <!-- contact list component -->
        <template v-if="!memberListVisible">
          <staff-list
            :type="type"
            :selectedMember="selectedMember"
            @handleMemberSelect="handleMemberSelect"
            v-if="isContactVisible"
          ></staff-list>
        </template>
    </div>
    </b-sidebar>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import AddContactForm from "@/components/contacts/AddForm.vue";
import ContactList from "@/components/contacts/ContactList.vue";
import staffList from "@/components/staff/staffList.vue";
import AddMember from "../../views/Staff/AddMember.vue"
import { mapGetters } from 'vuex';
import subscriptionValidation_mixin from '@/mixins/subscriptionValidation_mixin'

export default {
  name: "contactSelect",
  props: [
    "title",
    "buttonTitle",
    "type",
    "isNotRequired",
    "selectedContactDetail",
    "selectedMemberDetail",
    "isRented",
    "isNoteVisible",
    "note",
    "userType",
    "isStaff",
    "selectedTenant",
  ],
  components: {
    "add-contact": AddContactForm,
    "contact-list": ContactList,
    "add-member": AddMember,
    "staff-list": staffList,
  },
  computed: {
    /**
     * Returns id list of selected amenities
     */
    selectedContactId() {
      return this.selectedContact.id;
    },
    /**
     * Returns sidbar name
     */
    sibarName() {
      return this.title + "Sidebar";
    },
    ...mapGetters({
        isUserVerified: "isUserVerified",
    }),
  },
  mixins: [subscriptionValidation_mixin],
  data() {
    return {
      lang: this.$lang.add_properties,
      langStaff: this.$lang.staff_management,
      selectedContact: {},
      selectedMember:{},
      isContactVisible: false,
      contactListVisible: false,
      memberListVisible:false,
      clearData:false,
      isEdit:false,
      isVisible: false
    };
  },
  watch: {
    selectedContactDetail: {
      handler() {
        if (
          this.selectedContactDetail &&
          this.selectedContactDetail.id &&
          this.selectedContactDetail.id !== this.selectedContact.id
        ) {
          this.selectedContact = this.selectedContactDetail;
          this.selectedContact["name"] =
            this.selectedContactDetail.first_name +
            " " +
            this.selectedContactDetail.last_name;
        }
      },
      deep: true,
    },
    selectedMemberDetail: {
      handler() {
        if (
          this.selectedMemberDetail &&
          this.selectedMemberDetail.id &&
          this.selectedMemberDetail.id !== this.selectedMember.id
        ) {
          this.selectedMember = this.selectedMemberDetail;
          this.selectedMember['name'] = this.selectedMemberDetail.first_name + " " + this.selectedMemberDetail.last_name 
          this.selectedMember['email']=this.selectedMemberDetail.username
        }
      },
      deep: true,
    },
    userType() {
      this.removeContactChip();
      this.removeMemberChip();
    },
  },
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    contactList() {
      this.contactListVisible = !this.contactListVisible;
    },
    memberList() {
      this.memberListVisible = !this.memberListVisible;
    },
    /**
     * Handles selected contact from sidebar
     * @param {object} contact Selected contact
     */
    handleContactSelect(contact) {
      console.log("contact", contact);
      if (this.selectedTenant && this.selectedTenant.email && this.selectedTenant.email == contact.email) {
        Swal.fire({
          title: this.$lang.add_properties.label.warning,
          text: this.$lang.add_properties.label.sameUserAlert,
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: this.$lang.add_properties.label.buttons.ok,
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        });
      } else {
        this.selectedContact = contact;
        this.updateContactSidebar(false);
        this.$emit("handleSelected", contact);
      }
    },
    handleMemberSelect(member){
      this.selectedMember = member
      this.updateContactSidebar(false);
      this.$emit("handleMemberSelected", member);      
    },
    /**
     * Handles visibility of broker contact sidebar form
     * @param {boolean} val Sidebar visibility value
     */
    updateContactSidebar(val) {
      this.isContactVisible = val;
      const body = document.getElementsByTagName("body")[0];
      if (val) {
        body.classList.add("overflow-hidden");
      } else {
        body.classList.remove("overflow-hidden");
      }
    },
    /**
     * Removes selected contact
     */
    removeContactChip() {
      this.selectedContact = {};
      this.handleContactSelect(this.selectedContact);
    },
    /**
     * Removes selected member
     */
    removeMemberChip(){
      this.selectedMember = {}
      this.handleMemberSelect(this.selectedMember)
    },
    /**
     * Handles member add operation
     * @param {object} data member data to be added
     */
    addNewMember(){
        let self = this;
        if (self.isUserVerified) {
            self.$store.commit("setVerificationAlert", true);
        } else {
            let payloadData = {
              type : 3
            }
            self.checkSubscriptionValidation(payloadData).then((response) => {
                if(response){
                    self.isEdit = false
                    self.isVisible = true
                    self.clearData = true
                    this.memberList()
                }
            })
        }
    },
    /**
     * Handles add contact validation flow
     */
    handleContatctValidate(data) {
      let self = this;
      if (self.selectedTenant && self.selectedTenant.email && self.selectedTenant.email == data.email) {
        Swal.fire({
          title: self.$lang.add_properties.label.warning,
          text: self.$lang.add_properties.label.sameUserAlert,
          showCloseButton: self,
          showCancelButton: false,
          confirmButtonText: self.$lang.add_properties.label.buttons.ok,
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        });
      } else if (data.isStaff) {
        Swal.fire({
          title: self.lang.label.warning,
          text: self.lang.label.staffWarningMsg,
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: self.lang.label.buttons.ok,
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        })
      } else if (data.showPopUp) {
        Swal.fire({
          title: self.lang.label.confirmTitle,
          text: self.lang.label.confirmContactMsg,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: self.lang.label.buttons.yes,
          cancelButtonText: self.lang.label.buttons.no,
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            self.addContact(data);
          }
        });
      } else {
        self.addContact(data);
      }
    },
    /**
     * Handles contact add operation
     * @param {object} data Contact data to be added
     */
    addContact(data) {
      let self = this;
      var notesApiCall=false;
      var noteContent="";
      if(data.noteApiCall){
        notesApiCall=true;
        noteContent=data.noteContent
        delete data.noteApiCall
        delete data.noteContent
      }
      delete data.showPopUp;
      self.$store.dispatch("addContact", data).then((response) => {
        if (response.status == 200) {
          this.updateContactSidebar(false);
          if (response.data.message) {
            let contact = {
              id: response.data.contact.id,
              name:
                response.data.contact.first_name + " " + response.data.contact.last_name,
              first_name: response.data.contact.first_name,
              last_name: response.data.contact.last_name,
              contact: response.data.contact.contact,
              email: response.data.contact.email,
              pic: response.data.profile_image,
            };
            self.handleContactSelect(contact);
            //notes api call if notes have been added
            if(notesApiCall){
              let data={
                contact:response.data.contact.id,
                note:noteContent
              }
              self.notesApiCall(data);
            }
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Handles notes add operation
     * @param {object} data Notes to be added while adding contact
     */
    notesApiCall(data){
      let self=this;
      self.$store.dispatch('addContactNotes',data).then((response) =>{
        if(response.status == 200){
          return true;
        }else{
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      })
    },
  },
};
</script>
