module.exports = {
    label: {
      search: "Búsqueda",
      signOut: 'Cerrar Sesión',
      confirmTitle: '¿Estas Seguro?',
      confirmMsg: '¿Quieres Cerrar Sesión?',
      login:'Iniciar Sesión',
      viewMore: 'Ver Más',
      buttons: {
        yes: 'Sí',
        no: 'No',
      },
      tooltip:{
        Messages : 'Mensajes',
        Notifications : 'Notificaciones',
        Support : 'Apoyo',
      }
    }
  };