module.exports = {
  label: {
    tabs: {
      owner: 'Owner',
      broker: 'Broker',
      savedCard: 'My Payment Method',
      bankDetails:'Bank Details'
    },
    noRecord: 'No matching records',
    accountNumber: 'Account Number',
    swiftCode: 'Swift Code',
    resendLink: 'Resend Link',
    deleteBroker: 'Delete',
    expiry: 'Expiry',
    credit: 'Credit',
    debit: 'Debit',
    addBankDetails: "Add Bank Details",
    editBankDetails: 'Edit Bank Details',
    addNewCard: "Add New Card",
    bankInformation:'Select default payment',
    cardInformation:'Select default card',
    deleteConfirmMsg: "You want to delete this!",
    savedCardTitle: 'Saved Card',
    payment: "Payment",
    selectCard: 'Select',
    warning:'Warning',
    addCradValidation:'You cannot add card in trial period',
    defAcDelete:'You cannot delete default payment account, select another account as default',
    tableHeader:{
      holderName:'Holder\'s Name',
      bankName:'Bank Name',
      bankAccountNumber:'Account Number',
      code:'Swift Code',
      address:'Address',
      state:'State',
      city:'City',
      zipCode:'Zip Code'
    },
    modal: {
      bankDetailDelete: "Are you sure you want to delete this bank details?",
      brokerDelete: "Are you sure you want to delete this broker?",
      cardDelete: "Are you sure you want to delete this card?",
      bankDelete:"Are you sure ?"
    },
    form: {      
      label: {
        firstName: "First Name",
        lastName: 'Last Name',
        email: 'Email',
        contactNumber: 'Contact Number',
        cardNumber: 'Card Number',
        nameOnCard: 'Name on card',
        expiryDate: 'Expiry Date',
        cvv: 'CVV',
      },
      validation: {
        firstName: "First Name",
        lastName: 'Last Name',
        email: 'Email',
        contactNumber: 'Contact Number',
        cardNumber: 'Card Number',
        nameOnCard: 'Name on card',
        expiryDate: 'Expiry Date',
        cvv: 'CVV',
      },
      placeholder: {
        firstName: "Enter",
        lastName: 'Enter',
        email: 'example@mail.com',
        contactNumber: 'Enter Number',
        cardNumber: 'XXXX XXXX XXXX XXXX ',
        nameOnCard: 'Enter',
        expiryDate: 'MM/YY',
        cvv: 'Enter',
      }
    },
    buttons: {
      yes: 'Yes',
      no: 'No',
      ok:'Ok',
      add: 'Add Card',
      payNow: 'Pay Now',
      addButton: 'Add',
    },
    tooltip:{
      edit: 'Edit Bank Details',
      delete: 'Delete Bank Details',
      deleteCard: 'Delete Card'
    }
  }
};