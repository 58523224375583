module.exports = {
  label: {
    title: "Notificaciones",
    faq: "Preguntas Frecuentes",
    writeUs: 'Escríbenos',
    form: {
      placeholder: {
        selectQueryReason: 'Seleccionar Motivo De Consulta',
        description: 'Descripción',
      },
      validation: {
        queryReason: 'motivo de consulta',
        description: 'descripción',
      }
    },    
    buttons: {
      submit: 'Enviar'
    }
  }
};