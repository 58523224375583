<template>
  <div>
    <!-- Payment Summary-->
    <b-sidebar
      id="paymentModal"
      footer-class="sidebar-footer"
      sidebar-class="sidebar-view"
      text-variant="none"
      backdrop
      right
      width="462px"
      @change="updatePaymentSidebar"
      :visible="isPaymentVisible"
    >
      <template #header="{ hide }">
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex align-items-center">
            <b-btn variant="link" size="sm" class="p-0" @click="hide">
              <img :src="getIconUrl('close-sm.svg')" alt="close" />
            </b-btn>
            <h4 class="sidebar-header-title mb-0" v-if="planDetails">
              {{ lang.label.paymentTitle }}
            </h4>
            <h4 class="sidebar-header-title mb-0" v-else-if="rentDetails">
              {{ lang.label.paymentRentTitle }}
            </h4>
          </div>
          <b-btn variant="primary" @click="addNewCard()">
            {{ lang.label.addNewCard }}
          </b-btn>
        </div>
      </template>
      <div class="payment-body">
        <!-- alert component -->
        <alert-section currentModule="payment-modal"></alert-section>
        <div class="detail-summary" v-if="planDetails">
          <div class="d-sm-flex justify-content-between summary-div">
            <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
              >{{ lang.label.amount }}:</label
            >
            <span
              >${{ planDetails.amount }}/{{
                planDetails.type_name == "Monthly" ? lang.label.month : lang.label.year
              }}</span
            >
          </div>
        </div>
        <div
          class="detail-summary"
          v-if="rentDetails && rentDetails.summary && rentDetails.summary.length"
        >
          <div v-for="(rent, index) in rentDetails.summary" :key="index">
            <template v-if="index !== 0">
              <hr />
            </template>
            <div class="d-sm-flex justify-content-between summary-div">
              <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                >{{ lang.label.duration }}:</label
              >
              <span>{{ rent.startDate + " " + lang.label.to + " " + rent.endDate }}</span>
            </div>

            <div class="d-sm-flex justify-content-between summary-div">
              <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                >{{ lang.label.rentAmount }}:</label
              >
              <span>${{ rent.rentAmount }}</span>
            </div>

            <div
              class="d-sm-flex justify-content-between summary-div"
              v-if="rent.maintenanceFee"
            >
              <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                >{{ lang.label.maintenanceFee }}:</label
              >
              <span>${{ rent.maintenanceFee }}</span>
            </div>

            <div
              class="d-sm-flex justify-content-between summary-div"
              v-if="rent.depositeAmount"
            >
              <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                >{{ lang.label.depositeAmount }}:</label
              >
              <span>${{ rent.depositeAmount }}</span>
            </div>

            <div class="d-sm-flex justify-content-between summary-div">
              <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                >{{ lang.label.lateRentAmount }}:</label
              >
              <span>${{ rent.lateRentAmount }}</span>
            </div>

            <div class="d-sm-flex justify-content-between summary-div">
              <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                >{{ lang.label.total }}:</label
              >
              <span>${{ rent.total }}</span>
            </div>

            <template v-if="index == rentDetails.summary.length - 1">
              <hr />
              <div class="d-sm-flex justify-content-between summary-div">
                <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                  >{{ lang.label.grandTotal }}:</label
                >
                <span>${{ rentTotal }}</span>
              </div>
            </template>
          </div>
          <hr />
        </div>

        <ValidationObserver ref="cardDropDownObserver" v-slot="{ passes }">
          <b-form @submit.stop.prevent="passes(onPaymentSubmit)" autocomplete="off">
            <b-container fluid class="px-0 pb-fix-btn">
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <label class="form-label"
                      >{{ lang.label.form.label.selectedCard
                      }}<span class="text-danger">*</span></label
                    >
                    <ValidationProvider
                      :name="lang.label.form.validation.selectedCard"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <div class="position-relative">
                        <el-select
                          :state="getValidationState(validationContext)"
                          v-model="selectedCard"
                          :placeholder="lang.label.form.placeholder.select"
                          :class="{ 'is-invalid': validationContext.errors[0] }"
                          class="contact-selected"
                        >
                          <el-option
                            v-for="item in cardList"
                            :key="item.id"
                            :label="
                              item.first_name +
                              ' ' +
                              item.last_name +
                              '\u00a0\u00a0\u00a0-\u00a0\u00a0\u00a0\u00a0' +
                              item.card_number
                            "
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                        <b-form-invalid-feedback
                          >{{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </ValidationProvider>

                    <!-- add cvv-->
                    <label class="form-label mt-3"
                      >{{ lang.label.form.label.cvv
                      }}<span class="text-danger">*</span></label
                    >
                    <ValidationProvider
                      :name="lang.label.form.validation.cvv"
                      :rules="{required: true,min:3,max:4}"                      
                      v-slot="validationContext"
                    >
                      <div class="position-relative">
                         <b-form-input
                            v-model="cvv"
                            :placeholder="lang.label.form.placeholder.enter"
                            :state="getValidationState(validationContext)"
                            :class="{ 'is-invalid': validationContext.errors[0] }"
                            class="contact-selected"
                            type="password"
                            @input="onInputCvvValidate"
                            @keypress="validateKey"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            >{{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group class="mb-0">
                    <b-btn
                      block
                      variant="primary"
                      type="submit"
                      :disabled="!selectedCard ? true : false"
                      >{{ lang.label.buttons.payNow }}
                    </b-btn>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-container>
          </b-form>
        </ValidationObserver>
      </div>
    </b-sidebar>

    <!-- Add new card modal -->
    <b-sidebar
      id="addCard"
      footer-class="sidebar-footer"
      sidebar-class="sidebar-view"
      text-variant="none"
      backdrop
      right
      width="462px"
      @change="updateSidebar"
      :visible="isVisible"
    >
      <template #header="{ hide }">
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex align-items-center">
            <b-btn variant="link" size="sm" class="p-0" @click="hide">
              <img :src="getIconUrl('close-sm.svg')" alt="close" />
            </b-btn>
            <h4 class="sidebar-header-title mb-0">
              {{ lang.label.addNewCard }}
            </h4>
          </div>
        </div>
      </template>
      <div class="amenity-body" v-if="isVisible">
        <add-cardList
          :isVisible="isVisible"
          alertModulename="payment-modal"
          @handleCardAdded="handleCardAdded"
        ></add-cardList>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import AddCard from "@/components/bank/AddCard.vue";
import alertSection from "@/views/shared/AlertSection.vue";
import Swal from "sweetalert2";
import { numeric } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";
// const gateway = Gateway.create("checkout_public_fa6DbZF3H5Sb8uPfceG5v7A3B4956wkW");
// //const gateway = Gateway.create('checkout_public_fa6DbZF3H5Sb8uPfceG5v7A3B4956wkW'); // sandbox
// const threeDS = gateway.get3DSecure();
export default {
  props: ["isPaymentModalVisible", "planDetails", "rentDetails", "rentTotal"],
  components: {
    "add-cardList": AddCard,
    "alert-section": alertSection,
  },
  computed: {
    ...mapGetters({
      isUserVerified: "isUserVerified",
    }),
  },
  data() {
    return {
      lang: this.$lang.payment,
      isPaymentVisible: false,
      isVisible: false,
      selectedCard: null,
      cvv:'',
      cardList: [],
    };
  },
  watch: {
    isPaymentModalVisible(val) {
      if (val) {
        this.updatePaymentSidebar(true);
        this.$emit("handleInitializedPaymentModal");
        this.listOfcards();
      }
    },
  },
  mounted() {},
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getImageUrl(imageId) {
      return `https://picsum.photos/600/400/?image=${imageId}`;
    },
    updatePaymentSidebar(val) {
      this.isPaymentVisible = val;
      const body = document.getElementsByTagName("body")[0];
      if (val) {
        body.classList.add("overflow-hidden");
      } else {
        this.selectedCard = null;
        this.cvv='';
        this.$refs["cardDropDownObserver"].reset();
        body.classList.remove("overflow-hidden");
      }
    },
    updateSidebar(val) {
      this.isVisible = val;
      const body = document.getElementsByTagName("body")[0];
      if (val) {
        this.isPaymentVisible = false;
        body.classList.add("overflow-hidden");
      } else {
        this.isPaymentVisible = true;
        body.classList.remove("overflow-hidden");
      }
    },
    /**
     * Handles cardList added event
     * @param {number} cardId Added cardList id
     */
    handleCardAdded(cardId) {
      let self = this;
      self.selectedCard = cardId;
      self.$store.commit("setLoading", true);
      setTimeout(function () {
        self.listOfcards(cardId);
        self.updateSidebar(false);
        self.$store.commit("setLoading", false);
      }, 5500);
    },
    /**
     * Add new card
     */
    addNewCard() {
      if (this.isUserVerified) {
        this.$store.commit("setVerificationAlert", true);
      } else {
        this.updateSidebar(true);
      }
    },
    /**
     * List of added cards
     * @param {number} cardId Added card id
     */
    listOfcards(cardId, recursiveCounter) {
      let self = this;
      let counter = recursiveCounter || 0;
      self.$store.dispatch("listOfCards").then((response) => {
        if (response.status == 200) {
          self.cardList = response.data;
          let selectedCardDetail = self.cardList.filter((v) => v.is_default)[0];
          if (cardId) {
            self.selectedCard = cardId;
          } else {
            self.selectedCard = selectedCardDetail ? selectedCardDetail.id : null;
          }
          self.recursiveListCards(cardId, counter);
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Handles calling list API recursively
     * @param {number} cardId Added card id
     * @param {number} counter Recursive counter number
     */
    recursiveListCards(cardId, counter) {
      let self = this;
      let limit = 5;
      if (cardId && counter < limit) {
        let addedCard = self.cardList.filter((el) => el.id == cardId);
        if (addedCard.length > 0 && !addedCard[0].card_number) {
          setTimeout(() => {
            counter = counter + 1;
            self.listOfcards(cardId, counter);
          }, 1000);
        }
      }
    },
    /**
     * Submit the payment with card
     */
    onPaymentSubmit() {
      let self = this;
      let data = {
        card: self.selectedCard,
        security_code:self.cvv
      };
      let type = "buySubscription";
      if (self.rentDetails) {
        type = "payRent";
        data["property"] = self.rentDetails.property;
      } else {
        data["plan"] = self.planDetails.id;
      }
      self.isPaymentVisible = false;
      self.$store.dispatch(type, data).then((response) => {
        if (response.status == 200) {
          Swal.fire(response.data.message, "", "success").then(() => {
            self.$emit("handlePayment");
            if (self.rentDetails) {
              window.location.reload();
            }
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: "bank-details",
            });
          }
        }
      });
    },
    /**
     * CVV validation
     */
     validateKey(event) {
      let keyCode = event.keyCode || event.which;
      let keyValue = String.fromCharCode(keyCode);

      // Allow only digits
      if (!/^\d+$/.test(keyValue)) {
        event.preventDefault();
      }
    },
     onInputCvvValidate(event){
      let self=this
      self.cvv = event.replace(/\D/g, "");
     },
  },
};
</script>
