<!-- content from properties.js -->
<template>
  <!-- table section -->
  <div class="table-section vertical-top">
    <div class="table-card table-border-none with-pagination-table">
      <b-card no-body class="custom-card reports-table">
        <b-card-body class="p-0">
          <v-client-table :data="tenantDetails" :columns="columns" :options="options">
            <template slot="name" slot-scope="props">
              <p class="mb-0">
                {{ props.row.tenant_first_name + " " + props.row.tenant_last_name }}
              </p>
            </template>

            <template slot="leaseDuration" slot-scope="props">
              <p class="mb-0">
                {{
                  leaseDuration(
                    props.row.lease_period_start_date,
                    props.row.lease_period_end_date
                  )
                }}
              </p>
            </template>

            <template slot="paidDateTime" slot-scope="props">
              <p class="mb-0">{{ getPaidDateTime(props.row.date) }}</p>
            </template>

            <template slot="billingType" slot-scope="props">
              <p class="mb-0">{{ paymentFrequencyOption[props.row.payment_frequency_id] }}</p>
            </template>

            <template slot="amount" slot-scope="props">
              <p class="mb-0">
                <span v-if="props.row.type_id == 1">+</span>
                <span v-if="props.row.type_id == 2">-</span>
                {{ "$" + props.row.amount }}
              </p>
              <div class="mt-1">
                <span
                  class="sm-font mt-1 d-block"
                  :class="
                    props.row.type_name == 'Expense'
                      ? 'text-danger'
                      : props.row.type_name == 'Income'
                      ? 'text-success'
                      : 'text-dark'
                  "
                  >{{ amountTypeOption[props.row.type_id] }}</span
                >
              </div>
            </template>

            <!-- Expense name -->
            <template slot="expenseName" slot-scope="props">
              <p class="mb-0">
                {{ props.row.name && props.row.name != "" ? props.row.name : "NA" }}
              </p>
            </template>

            <template slot="actions" slot-scope="props">
              <template v-if="props.row.type_name == 'Expense'">
                <!-- view expense confirmation message -->
                <el-tooltip :content="lang.label.tooltip.expenseView" placement="top">
                <b-btn
                  variant="link"
                  class="p-1"
                  v-if="props.row.proofs.length > 0"
                >
                  <span class="material-icons" @click="viewExpensePopup(props.row.id)"
                    >visibility</span
                  >
                </b-btn>
              </el-tooltip>

              <el-tooltip :content="lang.label.tooltip.expenseDelete" placement="top">
                <b-btn
                  variant="link"
                  class="p-1"
                >
                  <span class="material-icons" @click="deleteExpensePopup(props.row.id)"
                    >delete</span
                  >
                </b-btn>
              </el-tooltip>
              </template>
            </template>
          </v-client-table>
        </b-card-body>
      </b-card>

      <!-- Hidden in UI,usage(for downloading pdf) -->
      <div id="tenant" class="d-none">
        <b-table striped hover :items="tenantDetails" :fields="fields" id="tenantTable">
          <!-- Name -->
          <template #cell(name)="data">
            <p class="mb-0">
              {{ data.item.tenant_first_name + " " + data.item.tenant_last_name }}
            </p>
          </template>

          <!-- Lease duration -->
          <template #cell(leaseDuration)="data">
            <p class="mb-0">
              {{
                leaseDuration(
                  data.item.lease_period_start_date,
                  data.item.lease_period_end_date
                )
              }}
            </p>
          </template>

          <!-- Payment calculation -->
          <template #cell(billingType)="data">
            <p class="mb-0">{{ paymentFrequencyOption[data.item.payment_frequency_id] }}</p>
          </template>

          <!-- Expense calculation -->
          <template #cell(expenseName)="data">
            <p class="mb-0">
              {{ data.item.name && data.item.name != "" ? data.item.name : "NA" }}
            </p>
          </template>

          <!-- Amount calculation -->
          <template #cell(amount)="data">
            <span v-if="data.item.type_id == 1">+</span>
            <span v-if="data.item.type_id == 2">-</span>
            <i>{{ "$" + data.item.amount }}</i>
            <span
              class="sm-font mt-1 d-block"
              :class="
                data.item.type_name == 'Expense'
                  ? 'text-danger'
                  : data.item.type_name == 'Income'
                  ? 'text-success'
                  : 'text-dark'
              "
              >{{ amountTypeOption[data.item.type_id] }}</span
            >
          </template>
        </b-table>
      </div>

      <b-modal
        ref="viewExpensePopup"
        id="viewExpense"
        hide-footer
        centered
        size="md"
        modal-class=" with-header"
      >
        <template #modal-header="{ close }">
          <h4 class="modal-title">{{ lang.label.expenseDetails }}</h4>
          <b-button variant="link" class="close-btn" @click="close()">
            <img :src="getIconUrl('close.svg')" alt="close" />
          </b-button>
        </template>
        <div>
          <div class="mb-2">
            <div class="">
              <label class="bf700 mb-2 mb-sm-0 d-block d-sm-inline">{{
                lang.label.uploadProof
              }}</label>
              <div class="mt-2">
                <div
                  class="d-inline-block"
                  v-for="(image, i) in expenseImages"
                  v-bind:key="i"
                >
                  <div class="uploaded-proof-img">
                    <img :src="ImageUrl + image" alt="placehoder" />
                    <div class="img-download">
                      <b-btn
                        variant="primary"
                        size="sm"
                        @click="downloadImage(ImageUrl + image)"
                      >
                        <span class="material-icons">file_download</span>
                      </b-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import jsondata from "@/assets/json/data.json";
import { ClientTable } from "vue-tables-2";
import Swal from "sweetalert2";
Vue.use(ClientTable, {}, false, "bootstrap4");
import html2pdf from "html2pdf.js";
import { mapGetters } from "vuex";
import mixin from "@/mixins/mixin";
import property_mixin from "@/mixins/property_mixin";

export default {
  name: "PropertiesReports",
  props: {
    tenantDetails: {
      type: Array,
      default() {
        return [];
      },
    },
    fileFormat: {
      type: String,
      default: "",
    },
  },
  mixins: [mixin, property_mixin],
  data() {
    return {
      lang: this.$lang.properties,
      data: jsondata,
      // table
      columns: [
        "name",
        "leaseDuration",
        "paidDateTime",
        "billingType",
        "amount",
        "expenseName",
        "actions",
      ],
      fields: [
        {
          key: "name",
          label: this.$lang.properties.label.tableReportHeader.name,
        },
        {
          key: "leaseDuration",
          label: this.$lang.properties.label.tableReportHeader.leaseDuration,
        },
        {
          key: "date",
          label: this.$lang.properties.label.tableReportHeader.paidDateTime,
        },
        {
          key: "billingType",
          label: this.$lang.properties.label.tableReportHeader.billingType,
        },
        {
          key: "expenseName",
          label: this.$lang.properties.label.tableReportHeader.expenseName,
        },
        {
          key: "amount",
          label: this.$lang.properties.label.tableReportHeader.amount,
        },
      ],
      options: {
        headings: this.$lang.properties.label.tableReportHeader,
        skin: "table",
        perPageValues: [5, 10, 15],
        perPage: 5,
        sortable: ["paidDateTime"],
        columnsClasses: {
          address: "address-width",
          tenant: "d-none d-md-table-cell ",
          leaseDuration: "d-none d-md-table-cell ",
          paidDateTime: "d-none d-md-table-cell ",
          billingType: "d-none d-md-table-cell ",
          actions: "expense-buttons ",
        },
        pagination: { chunk: 5 },
        texts: {
          noResults: this.$lang.root.label.noRecord,
          filter: this.$lang.root.label.filter,
          filterPlaceholder: this.$lang.root.label.filterPlaceholder,
          limit: this.$lang.root.label.limit,
          count: this.$lang.root.label.count,
        },
        customSorting: {
          paidDateTime: function (ascending) {
            return function (a, b) {
              let dateA = new Date(a.date);
              let dateB = new Date(b.date);
              if (ascending) {
                return dateA >= dateB ? 1 : -1;
              } else {
                return dateA < dateB ? 1 : -1;
              }
            };
          },
        },
      },
      showImageModal: false,
      expenseImages: [],
    };
  },
  computed: {
    ...mapGetters({
      ImageUrl: "ImageUrl",
      language:"language"
    }),
  },
  mounted() {
    this.actionsNone();
  },
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getImgUpload(uploaded) {
      return require("@/assets/styles/img/properties/" + uploaded);
    },
    /**
     * Popup for deletion of expense
     * @param {number} id
     */
    deleteExpensePopup(id) {
      let self = this;
      Swal.fire({
        title: this.lang.label.expenseDelete,
        text: self.lang.label.deleteConfirmMsg,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        focusConfirm: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          /* function for bank deletion */
          self.deleteExpense(id);
        }
      });
    },
    /**
     * Delete the expense
     * @param {number} id
     */
    deleteExpense(id) {
      let self = this;
      self.$store.dispatch("deleteExpense", id).then((response) => {
        if (response.status == 200) {
          this.$bvModal.hide("list");
          // for api call
          self.$emit("handleApiCallTenant", true);
          self.$store.commit("setMessages", {
            type: "success",
            message: response.data.message,
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    viewExpense(id) {
      let self = this;
      self.$bvModal.show("view" + id);
    },
    actionsNone() {
      const index = this.columns.indexOf("actions");
      if (this.$route.name == "reports") {
        if (index > -1) {
          this.columns.splice(index, 1);
        }
      }
    },
    /**
     * Calculation for billing in table column -> Billing Type
     */
    paymentMode(id) {
      if (id == 0) {
        return "NA";
      } else if (id == 1) {
        return "Monthly";
      } else if (id == 2) {
        return "Quarterly";
      } else if (id == 3) {
        return "Half yearly";
      } else if (id == 4) {
        return "Yearly";
      }
    },
    /**
     * Calculation for lease duration calculation -> Lease Duration in table
     */
    leaseDuration(startDate, endDate) {
      if (startDate == 0 && endDate == 0) {
        return "N/A";
      } else if (startDate != 0 && endDate != 0) {
        return this.formatDateToShow(startDate) + " - " + this.formatDateToShow(endDate);
      } else if (startDate != 0 && endDate == 0) {
        return this.formatDateToShow(startDate) + " - ";
      }
    },
    /**
     * download csv/pdf file
     */
    downloadFile(downloadFormat) {
      let self = this;
      if (downloadFormat.format == "pdf") {
        if (self.tenantDetails.length > 0) {
          self.downloadPdf();
        }
      } else if (downloadFormat.format == "csv") {
        if (self.tenantDetails.length > 0) {
          self.convertToCSV(self.tenantDetails, "Income-Expense Report.csv");
        }
      }
    },
    /**
     * Downloads pdf file
     */
    downloadPdf() {
      let tenant = document.getElementById("tenant");
      tenant.classList.remove("d-none");
      const opt = {
        filename: "Income-Expense Report.pdf",
        margin: [5, 5],
        top: 0,
        jsPDF: { unit: "mm", format: "A2", orientation: "P", compress: true },
      };
      html2pdf().from(tenant).set(opt).save();
      setTimeout(() => {
        tenant.classList.add("d-none");
      });
    },
    /**
     * Downloads CSV file
     */
    convertToCSV(jsonData, fileName) {
      let self = this;

      // console.log("JSON data",jsonData)
  
      if (jsonData.length == 0) {
        // static json
        var data={};
        if(self.language=="es"){
            data={
              'id':"",
              "tipo de identificación": "",
              'nombre del gasto':  "",
              'nombre':  "",
              'cantidad': "",
              'fecha':  "",
              'frecuencia de pago id': "",
              'nombre frecuencia pago':  "",
              'nombre del inquilino':  "",
              'apellido del inquilino':  "",
              'número de contacto del inquilino':  "",
              'correo electrónico del inquilino':  "",
              'período de arrendamiento fecha de inicio': "",
              'período de arrendamiento fecha final': "",
              'pruebas': "",
              'created_ts':"",
              'contrato de arrendamiento de inquilino': "",
            };
        } else{
            data = {
              id: "",
              type_id: "",
              type_name: "",
              name: "",
              amount: "",
              date: "",
              payment_frequency_id: "",
              payment_frequency_name: "",
              tenant_first_name: "",
              tenant_last_name: "",
              tenant_contact_number: "",
              tenant_email: "",
              lease_period_start_date: "",
              lease_period_end_date: "",
              proofs: "",
              created_ts: "",
              tenant_lease_agreement: "",
            };
        }
         
        self.generateCSV(data, fileName, true);
      } else {
        self.generateCSV(jsonData, fileName, false);
      }
    },
    /**
     * Generates the csv according to availability of data in the array
     */
    generateCSV(jsonData, fileName, onlyHeader = false) {
      let self = this;
      // Create CSV data array
      var csvData = [];      
      if(self.language=="es"){ 
        jsonData=jsonData.map(({ id,type_id,type_name,name,amount,date,payment_frequency_id,payment_frequency_name,tenant_first_name,tenant_last_name,tenant_contact_number,tenant_email,lease_period_start_date,lease_period_end_date,proofs,created_ts,tenant_lease_agreement}) => ({ "id":id,"tipo de identificación":type_id,"nombre del gasto" :type_name,"nombre":name,"cantidad":amount,"fecha":date,"frecuencia de pago id":payment_frequency_id,"nombre frecuencia pago":payment_frequency_name,"nombre del inquilino":tenant_first_name,"apellido del inquilino":tenant_last_name,"número de contacto del inquilino":tenant_contact_number,"correo electrónico del inquilino":tenant_email,"período de arrendamiento fecha de inicio":lease_period_start_date,"período de arrendamiento fecha final":lease_period_end_date,"pruebas":proofs,"created_ts":created_ts,"contrato de arrendamiento de inquilino":tenant_lease_agreement}));
      }

      var headers = onlyHeader
        ? Object.keys(jsonData)
        : Object.keys(jsonData[0]);
      var values = "";
      csvData.push(headers);
      // Convert CSV data array to string
      var csvString = "";

      if (onlyHeader) {
        csvData.forEach((row) => {
          csvString += row.join(",") + "\n";
        });
      } else {
        // Add rows to CSV data array
        // console.log("JSON",jsonData)

        let temparray = jsonData.map((values) => {
            if(self.language=="en"){
              return {
                id: values.id,
                type_id: values.type_id,
                type_name: values.type_name,
                name: values.name,
                amount: values.amount,
                date: values.date,
                payment_frequency_id: values.payment_frequency_id,
                payment_frequency_name: values.payment_frequency_name,
                tenant_first_name: values.tenant_first_name,
                tenant_last_name: values.tenant_last_name,
                tenant_contact_number: values.tenant_contact_number,
                tenant_email: values.tenant_email,
                lease_period_start_date: values.lease_period_start_date,
                lease_period_end_date: values.lease_period_end_date,
                proofs: values.proofs.map((data) => data.image),
                created_ts: values.created_ts,
                tenant_lease_agreement: values.tenant_lease_agreement,
           }
        }  else{
              return{
                'id': values['id'],
                'tipo de identificación': values['tipo de identificación'],
                'nombre del gasto': values['nombre del gasto'],
                'nombre': values['nombre'],
                'cantidad': values['cantidad'],
                'fecha': values['fecha'],
                'frecuencia de pago id': values['frecuencia de pago id'],
                'nombre frecuencia pago': values['nombre frecuencia pago'],
                'nombre del inquilino': values['nombre del inquilino'],
                'apellido del inquilino': values['apellido del inquilino'],
                'número de contacto del inquilino': values['número de contacto del inquilino'],
                'correo electrónico del inquilino': values['correo electrónico del inquilino'],
                'período de arrendamiento fecha de inicio': values['período de arrendamiento fecha de inicio'],
                'período de arrendamiento fecha final': values['período de arrendamiento fecha final'],
                'pruebas': values['pruebas'].map((data) => data.image),
                'created_ts': values['created_ts'],
                'contrato de arrendamiento de inquilino': values['contrato de arrendamiento de inquilino'],
              }
            }
        });

        let finalArray = temparray.map((values) => {
          if(self.language=="en"){
              return {
                id: values.id,
                type_id: values.type_id,
                type_name: values.type_name,
                name: values.name,
                amount: values.amount,
                date: values.date,
                payment_frequency_id: values.payment_frequency_id,
                payment_frequency_name: values.payment_frequency_name,
                tenant_first_name: values.tenant_first_name,
                tenant_last_name: values.tenant_last_name,
                tenant_contact_number: values.tenant_contact_number,
                tenant_email: values.tenant_email,
                lease_period_start_date: values.lease_period_start_date,
                lease_period_end_date: values.lease_period_end_date,
                proofs: (values.proofs) ? values.proofs.join(" - ") :"",
                created_ts: values.created_ts,
                tenant_lease_agreement: values.tenant_lease_agreement,
            };
        } else {
            return {
                'id': values['id'],
                'tipo de identificación': values['tipo de identificación'],
                'nombre del gasto': values['nombre del gasto'],
                'nombre': values['nombre'],
                'cantidad': values['cantidad'],
                'fecha': values['fecha'],
                'frecuencia de pago id': values['frecuencia de pago id'],
                'nombre frecuencia pago': values['nombre frecuencia pago'],
                'nombre del inquilino': values['nombre del inquilino'],
                'apellido del inquilino': values['apellido del inquilino'],
                'número de contacto del inquilino': values['número de contacto del inquilino'],
                'correo electrónico del inquilino': values['correo electrónico del inquilino'],
                'período de arrendamiento fecha de inicio': values['período de arrendamiento fecha de inicio'],
                'período de arrendamiento fecha final': values['período de arrendamiento fecha final'],
                'pruebas': values['pruebas'] ?values['pruebas'].join(" - "):"",
                'created_ts': values['created_ts'],
                'contrato de arrendamiento de inquilino': values['contrato de arrendamiento de inquilino'],
            };
          }
        });

        finalArray.forEach((row) => {
          values = Object.values(row);
          csvData.push(values);
        });

        // console.log("CSV data:",csvData)
        csvData.forEach((row) => {
          csvString += row.join(",") + "\n";
        });
      }
      // Create Blob object from CSV string
      let blob = new Blob([csvString], { type: "text/csv" });

      // Create URL object from Blob
      let url = URL.createObjectURL(blob);

      // Create link element
      let link = document.createElement("a");
      link.href = url;
      link.download = fileName;

      // Click link to trigger download
      link.click();
    },
    /**
     * View expense details popup
     */
    viewExpensePopup(id) {
      let self = this;
      let find = self.tenantDetails.find((data) => data.id == id);
      self.$refs["viewExpensePopup"].show();
      self.expenseImages = find.proofs.map((data) => data.image);
    },
    /**
     * Image download
     */
    downloadImage(url) {
      fetch(url)
        .then((resp) => resp.blob())
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "proof";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          console.log("Download image error");
        });
    },
    /**
     * returns date in dd-mm-yyyy format in table
     */
    getPaidDateTime(date) {
      return date ? this.formatDateStringToDate2(date) : "N/A";
    },
  },
};
</script>
