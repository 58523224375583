<!-- content from messages.js -->
<template>
  <b-card no-body class="custom-card h-100">
    <template #header>
      <div class="chat-header">
        <div class="page-breadcrumb mb-0">
          <div class="d-md-flex justify-content-between align-items-center">
            <div class="pg-bread">
              <button
                type="button"
                class="btn p-0 mr-3 primary-text btn-link"
                @click="backToUsers()"
              >
                <span class="material-icons align-middle">arrow_back</span>
              </button>
              <b-breadcrumb class="bg-transparent p-0 mb-0">
                <b-breadcrumb-item active>
                  {{ lang.label.title }}
                </b-breadcrumb-item>
                <b-breadcrumb-item active>
                  <span class="align-middle">
                    <img :src="getIconUrl('chat.svg')" alt="" />
                  </span>
                  <span class="align-middle ml-1">5</span>
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </div>
        </div>
      </div>
    </template>

    <b-card-body :id="'scroll-' + id" v-loading="loading">
      <div v-observe-visibility="visibilityChanged" style="height: 5px"></div>

      <b-list-group class="users-message">
        <b-list-group-item
          class="bg-transparent border-0 rounded-0"
          v-for="(messenger, index) in messages"
          :key="index"
        >
          <div class="message-list-section" :id="'messageSection-' + index">
            <b-media no-body>
              <b-media-aside vertical-align="center">
                <img
                  :src="
                    messenger.profile_image != ''
                      ? ImageUrl + messenger.profile_image
                      : getImgUser('user.svg')
                  "
                  class="rounded-circle"
                  :alt="messenger.name"
                />
              </b-media-aside>
              <b-media-body class="align-self-center">
                <h6 class="contact-name mb-0">{{ messenger.name }}</h6>
              </b-media-body>
            </b-media>
            <div class="message-details">
              <div class="message-listing">
                <span class="d-block text-grey-light sm-font mt-2">{{
                  getDateTime(messenger.created_ts)
                }}</span>
                <p class="mt-2">{{ messenger.message }}</p>
                <template
                  v-if="
                    messenger.file.toLowerCase().endsWith('.jpg') ||
                    messenger.file.toLowerCase().endsWith('.png') ||
                    (messenger.file.toLowerCase().endsWith('.jpeg') &&
                      messenger.file != '')
                  "
                >
                  <div class="uploaded-message-image">
                    <div class="attach-image">
                      <img
                        :src="ImageUrl + messenger.file"
                        :alt="messenger.name"
                      />
                    </div>
                  </div>
                </template>

                <template v-else-if="messenger.file != ''">
                  <div class="uploaded-message-image pdf-upload-tile">
                    <a
                      :href="ImageUrl + messenger.file"
                      target="_blank"
                      class="attach-image"
                    >
                      <span class="material-icons">file_download</span>
                      <template
                        v-if="messenger.file.split('/').pop().includes('.')"
                      >
                        <template
                          v-if="
                            messenger.file.split('/').slice(-1).toString()
                              .length > 5
                          "
                        >
                          {{
                            messenger.file
                              .split("/")
                              .slice(-1)
                              .toString()
                              .slice(0, 4) +
                            ".." +
                            messenger.file.split(".").pop().toString()
                          }}
                        </template>
                        <template v-else>
                          {{ messenger.file.split("/").slice(-1).toString() }}
                        </template>
                      </template>
                      <template v-else>
                        {{ messenger.file.split("/").slice(-1).toString() }}
                      </template>
                    </a>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>

      <!-- To check whether user has suscrittion on not-->
      <b-list-group
        class="users-message border-top"
        v-if="(getTrialDays <= 0 && !login.subscription_end_date) && (!isUserOnlyTenant && !isInvitedUser)"
      >
        <b-list-group-item class="bg-transparent border-0 rounded-0">
          <div class="message-list-section placeholder">
            <b-media no-body>
              <b-media-aside vertical-align="center"></b-media-aside>
              <b-media-body class="align-self-center">
                <h6 class="contact-name mb-0"></h6>
              </b-media-body>
            </b-media>
            <div class="message-details">
              <div class="message-listing">
                <span class="d-block text-grey-light sm-font mt-2"></span>
                <div class="desc-content">
                  <p class="mt-2"></p>
                  <p class="mt-2"></p>
                </div>
              </div>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>

      <div class="unread-messages text-center" v-if="unreadCountMessage > 0 && ((getTrialDays <= 0 && !login.subscription_end_date) && !isUserOnlyTenant)">
        <router-link to="/my-account/2" class="d-inline-flex unread-msg-box">
          <span>{{ unreadCountMessage }} {{lang.label.unreadMessages}}</span>
        </router-link>
      </div>
    </b-card-body>
    <template #footer>
      <div class="message-input-box" :class="hideTypingBar ? 'd-none' : ''">
        <b-input-group>
          <!-- <b-input-group-prepend>
                        <b-btn variant="link" disabled>
                            <span class="material-icons">keyboard</span>
                        </b-btn>
                    </b-input-group-prepend> -->
          <textarea
            class="form-control"
            v-model="messageType"
            :placeholder="lang.label.writeHere"
            @input="resize()"
            ref="textarea"
          ></textarea>

          <b-input-group-append>
            <div class="uploaded-file btn btn-link">
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  id="customFile"
                  accept=".pdf,.jpg,.jpeg,.png"
                  @change="sendMessageValidate($event)"
                />
                <label class="custom-file-label" for="customFile"
                  ><span class="material-icons">attachment</span></label
                >
              </div>
            </div>
            <b-btn
              variant="link"
              type="submit"
              @click="sendMessageValidate()"
              :disabled="messageType.trim().length == 0 ? true : false"
            >
              <span class="material-icons">send</span>
            </b-btn>
          </b-input-group-append>
        </b-input-group>
      </div>
    </template>
  </b-card>
</template>
<script>
import jsondata from "@/assets/json/data.json";
import { mapGetters } from "vuex";
import mixin from "@/mixins/mixin";
import Vue from "vue";
import VueObserveVisibility from "vue-observe-visibility";
Vue.use(VueObserveVisibility);
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  components: {},
  mixins: [mixin],
  props: {
    hideTypingBar: {
      type: Boolean,
      default: false,
    },
    defaultId: {
      type: Number,
      default: null,
    },
    clearText: {
      type: Boolean,
      default: false,
    },
    pageNumber: {
      type: Number,
      default: null,
    },
    clearMessages: {
      type: Boolean,
      default: false,
    },
    roomId: {
      type: Number,
      default: null,
    },
    unreadCountMessage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      lang: this.$lang.messages,
      data: jsondata,
      messageType: "",
      bottom: "",
      messages: [],
      id: "",
      page: 1,
      count: "",
      isDataAvailable: true,
      callFlag: true,
      subscription: false,
      size: 10,
      canLoadNow: true,
      unread_messages: "",
    };
  },
  computed: {
    ...mapGetters({
      ImageUrl: "ImageUrl",
      loading: "loading",
      login: "login",
      isUserOnlyTenant: "isUserOnlyTenant",
      isInvitedUser:"isInvitedUser"
    }),
    getTrialDays() {
      if (this.login.subscription_end_date && this.login.is_trial_active) {
        let date_1 = new Date(this.login.subscription_end_date);
        let date_2 = new Date();

        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        if (date_1.setHours(0, 0, 0, 0) == date_2.setHours(0, 0, 0, 0)) {
          TotalDays = 1;
        }
        return TotalDays;
      } else {
        return 0;
      }
    },
  },
  mounted() {
    this.scrollBottom();
  },
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getImgUser(img) {
      return require("@/assets/styles/img/users/" + img);
    },
    resize() {
      let element = this.$refs["textarea"];
      element.style.height = "50px";
      element.style.height = element.scrollHeight + "px";
    },
    backToUsers() {
      // let self = this
      this.$emit("backToUsersEvent");
    },
    /**
     * Scroll bottom to the last message
     */
    scrollBottom() {
      let self = this;
      setTimeout(() => {
        let container = document.querySelector("#scroll-" + self.id);
        container.scrollTo({
          top: container.scrollHeight,
        });
      }, 100);
    },
    /**
     * Scroll upwards to the last message
     */
    scrollToMessageId(id) {
      setTimeout(() => {
        let container = document.getElementById("messageSection-" + id);
        container.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 100);
    },
    goSubscriptionPlan() {
      this.$router.push({ path: "/my-account/2" });
    },
    /**
     * Loads the user chat when scroll upwards
     */
    loadUserChat(id) {
      let self = this;
      self.id = id;
      let data = {
        id: self.roomId,
        size: self.size,
        page: self.page,
      };
      self.$store.dispatch("loadUserChat", data).then((response) => {
        if (response.status == 200) {
          if (self.page == 1 && self.callFlag) {
            self.messages = response.data.results;
            self.messages.reverse();
            self.callFlag = false;
            setTimeout(() => {
              self.scrollBottom();
            }, 50);
            setTimeout(() => {
              self.callFlag = true;
            }, 100);
          } else {
            let reverseData = response.data.results.reverse();
            self.messages = reverseData.concat(self.messages);
            // setTimeout(() => {
            self.scrollToMessageId(
              self.messages.length - 1 - (self.page - 1) * self.size
            );
            // }, 100);
          }
          // setTimeout(() => {
          self.count = response.data.count;
          self.isDataAvailable = response.data.next ? true : false;
          self.page++;
          // }, 200);
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Date time format to AM/PM
     */
    getDateTime(dateTime) {
      let self = this;
      let splitDateAndTime = dateTime.split(" ");
      let date = self.formatDateToSlash(splitDateAndTime[0]);
      let time = self.timeFormat(splitDateAndTime[1]);
      return time + ", " + date;
    },
    /**
     * returns time in AM/PM format
     */
    timeFormat(dateFormat) {
      let time = dateFormat
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (time.length > 1) {
        time = time.slice(1);
        time.pop();
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12;
      }
      return time.join("");
    },
    /**
     * validate messages of the user
     */
    sendMessageValidate(event) {
      let self = this;
      let formdata = new FormData();
      formdata.append("message", self.messageType ? self.messageType : "");
      formdata.append("chat", self.roomId ? self.roomId : "");
      formdata.append("to", self.id);

      if (event && event !== undefined) {
          let fileSizeMB = event.target.files[0].size / 1000 ** 2;
          if (fileSizeMB > 10) {
            self.fireValidation();
          } else {
            formdata.append("type", 2);
            formdata.append("file", event && event.target.files[0]);
            self.sendMessage(formdata);
          }
      } else {
        formdata.append("type", 1);
        formdata.append("file", "");
        self.sendMessage(formdata);
      }
    },
    /**
     * Sends the message to the user
     */
    sendMessage(formdata) {
      let self = this;
      let data = {
        formData: formdata,
      };
      self.$store.dispatch("sendMessage", data).then((response) => {
        if (response.status == 200) {
          self.page = 1;
          self.loadUserChat(self.id);
          self.scrollBottom();
          self.messageType = "";
          self.$refs["textarea"].style.height = "50px";
          self.$refs["textarea"].focus();
          self.$refs["textarea"].select();
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * For scrolling purpose
     */
    visibilityChanged() {
      if (
        this.canLoadNow &&
        !this.loading &&
        this.isDataAvailable &&
        this.callFlag
      ) {
        this.loadUserChat(this.id);
      }
    },
    /**
     * Fires the error if uploaded file size is greater then 10MB or extension is wrong
     */
    fireValidation(validationType = "") {
      let self = this;
      Swal.fire({
        title: "",
        text:
          validationType == "extensionValidation"
            ? self.lang.label.extensionValidation
            : self.lang.label.documentSizeValidation,
        icon: "warning",
        confirmButtonText: self.lang.label.buttons.ok,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
  },
  watch: {
    /**
     * Default api call to load the message list for first user
     */
    defaultId(val) {
      if (val) {
        this.page = 1;
        this.loadUserChat(val);
        this.scrollBottom();
      }
    },
    /**
     * To clear textare if clicked on new user
     */
    clearText(val) {
      if (val) {
        this.messageType = "";
        this.$emit("textChange");
      }
    },
    /**
     * To clear the chats if clicked on new user
     */
    clearMessages(val) {
      if (val) {
        this.messages = [];
        this.$emit("messageData");
      }
    },
    loading(val) {
      if (val) {
        setTimeout(() => {
          this.canLoadNow = true;
        }, 1000);
      } else {
        this.canLoadNow = false;
      }
    },
  },
};
</script>

