<!-- content from notifications.js -->
<template>
  <div class="body-content">
    <div class="top-sticky">
      <div class="top-bar-radius"></div>
    </div>
    <div class="starts-section" v-loading="loading">
      <!-- body content header  -->
      <alert-section></alert-section>
      <div class="page-breadcrumb">
        <div class="d-md-flex justify-content-between align-items-center">
          <div class="pg-bread">
            <b-btn
              variant="link"
              class="p-0 mr-3 primary-text"
              @click="goBack()"
              ><span class="material-icons align-middle"
                >arrow_back</span
              ></b-btn
            >
            <b-breadcrumb class="bg-transparent p-0 mb-0">
              <b-breadcrumb-item active>
                {{ lang.label.title }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </div>
      </div>
      <div class="pb-3">
        <ul class="all-notification list-unstyled">
          <b-dropdown-text
            :class="notification.read == false ? 'read' : ''"
            v-for="(notification, notificationsIndex) in notifications"
            :key="notificationsIndex"
          >
            <h6 class="notification-title">{{ notification.message }}</h6>
            <p class="mb-0 notification-time">
              {{ notification.created_date }}
            </p>
          </b-dropdown-text>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import { mapGetters } from "vuex";
import alertSection from "@/views/shared/AlertSection.vue";
import notification_mixin from "../mixins/notification_mixin";
export default {
  // props: ["editData"],
  name: "supportPage",
  computed: {
    ...mapGetters({
      loading: "loading",
    }),
  },
  components: {
    "alert-section": alertSection,
  },
  mixins: [notification_mixin],
  data() {
    return {
      lang: this.$lang.notifications,
      data: jsondata,
      models: {
        selectReason: "",
        description: "",
      },
      reasonList: [
        // { text: 'Lorem Ipsum is simply dummy text', value: 1 },
        // { text: 'Contrary to popular belief', value: 2 },
        // { text: 'Printing and typesetting industry?', value: 3 },
        // { text: 'It has roots', value: 4 },
      ],
      faqList: [],
    };
  },
  mounted() {
    this.getFAQList();
    this.getReasons();
    this.readNotification();
    this.getNotifications();
  },
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      let self = this;
      let queryData = {
        reason: self.models.selectReason,
        description: self.models.description,
      };
      self.$store.dispatch("addQuery", queryData).then((response) => {
        if (response.status == 200) {
          let message = response.data.message;
          if (message) {
            self.$store.commit("setMessages", {
              type: "success",
              message: message,
            });
          }
          self.clearForm();
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    getFAQList() {
      let self = this;
      self.$store.dispatch("listFAQs").then((response) => {
        if (response.status == 200) {
          self.faqList = response.data;
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    getReasons() {
      let self = this;
      self.$store.dispatch("getReportReason").then((response) => {
        if (response.status == 200) {
          self.reasonList = response.data;
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    clearForm() {
      this.models = {
        selectReason: "",
        description: "",
      };
      this.$refs["observer"].reset();
    },
    getNotifications() {
      let self = this;
      let data = {
        size: 100000,
        page: 1,
      };
      self.getAllNotifications(data, "notification");
    },
    /**
     * To read out all notifications
     */
    readNotification() {
      let self = this;
      let data = {
        notification_id: 0,
        read_all: true,
      };
      self.$store.dispatch("readNotification", data).then((response) => {
        if (response.status == 200) {
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
  },
};
</script>

