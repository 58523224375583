module.exports = {
  label: {
    brokerPaymentConfiguration: 'Configuración De Pago Del Agente Inmobiliario',
    propertyOwner: 'Propietario',
    addOwnerDetails: 'Solicitar Detalles Del Propietario',
    addBrokerDetails: 'Solicitar Datos Del Agente Inmobiliario',
    addBankDetails: 'Agregar Datos Bancarios',
    contactSelectionAlert: 'Seleccione Primero El Contacto',
    bankDetailsRequest: 'Solicitar Datos Bancarios',
    success: 'Éxito',
    bankRequestSuccessMessage: "Se han solicitado los datos bancarios.",
    myAccountBroker: 'Mi Cuenta (Agente)',
    addNewContact: 'Añadir Nuevo Contacto',
    selectContactLabel: 'Seleccionar Contacto',
    confirmEndDateChangeTitle: 'Estas Seguro?',
    confirmEndDateChangeMsg: 'La fecha se cambiará',
    warning : 'Advertencia',
    confirmTitle:'¿Estas Seguro?',
    confirmContactMsg:'Este usuario ya Eesta en el sistema. ¿estás seguro que quieres agregar a este usuario a tu lista de contactos?',
    staffWarningMsg: 'Este usuario ya está agregado al personal',
    info: {
      firstMonthOnly: 'Sólo Primer Pago',
      selectPercentageAmount: 'Seleccione Entre Porcentaje O Importe',
      feeChargedBrokerMonth: 'Comisión Cobrada Por El Agente Sólo El Primer Mes',
      feeChargedBrokerBasis: 'Comisión Cobrada Mensualmente Por El Agente',
      monthlyFees: 'Cuota Mensual'
    },
    form: {      
      label: {
        assignTenant: 'Asignar Inquilino',
        rentAmount: 'Precio Del Alquiler',
        paymentFrequency: 'Frecuencia De Pago De Alquiler',
        startDate: 'Fecha De Inicio',
        endDate: 'Fecha Final',
        depositAmount: 'Depósito De Seguridad',
        closingCostCommission: 'Comisión De Gastos De Cierre',
        brokerRecurringRentalFee: 'Tarifa De Alquiler Recurrentes Del Agente Inmobiliario',
        rentAmountDepositAccount: 'Cuenta De Depósito De La Cantidad De Alquiler',
        commissionDepositAccount: 'Cuenta De Depósito De Comisión'
      },
      validation: {
        assignTenant: 'asignar inquilino',
        rentAmount: 'precio del alquiler',
        paymentFrequency: 'frecuencia de pago de alquiler',
        startDate: 'fecha de inicio',
        endDate: 'fecha final',
        depositAmount: 'depósito de seguridad',
        closingCostCommission: 'comisión de gastos de cierre',
        brokerRecurringRentalFee: 'tarifa de alquiler recurrentes del agente inmobiliario',
        rentAmountDepositAccount: 'cuenta de depósito de la cantidad de alquiler',
        commissionDepositAccount: 'cuenta de depósito de comisión'
      },
      placeholder: {
        enter: 'Ingrese',
        select: 'Seleccionar',
        selectDate: 'Seleccionar Fecha',
      }
    },
    rentDetails: {
      title: 'Resumen De Detalles Del Alquiler',
      rentalSummaryTitle: "Resumen Del alquiler",
      tenant: 'Inquilino',
      leasePeriod: 'Período De Arrendamiento',
      leaseAmount: 'Monto Del Alquiler',
      maintenanceFees: 'Cuota De Mantenimiento',
      depositAmount: 'Monto Del Depósito',
      amountDueFirstMonth: 'Primera Cuota A Pagar Por El Inquilino',
      recurringDueAmount: 'Mensualidad Periódica A Cargo Del Inquilino',
    },
    brokerCommission: {
      title: 'Tarifas De La Comisión De Broker',
      closingCostComFirst: 'Comisión De Gastos De Cierre (sólo primer plazo)',
      closingCostComFirstToBroker: 'Comisión De Gastos De Cierre Al Agente (sólo primer plazo)',
      recurringRentalFee: 'Cuota Recurrente De Administración Del Alquiler',
      recurringRentalFeeBroker: 'Comisión De Administración Del Alquiler Recurrente Para El Agente',
    },
    rentalAmountOwner: {
      title: 'Cantidad De Alquiler Al Propietario',
      firstMonthOwner: 'Primer Plazo Al Propietario',
      recurringRentalAmountDueOwner: 'Monto Del Alquiler Adeudado Al Propietario'
    },
    buttons: {
      save: 'Guardar',
      selectFromContacts: 'Seleccionar Desde Contactos',
      selectContact: 'Seleccionar Contacto',
      addNewContactBtn: 'Añadir Nuevo',
      yes: 'Sí',
      no: 'No',
      ok: 'Ok',
    }
  }
};