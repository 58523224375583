<!-- content from property_details.js -->
<template>
  <div class="body-content">
    <div class="top-sticky">
      <div class="top-bar-radius"></div>
    </div>
    <div class="starts-section" v-loading="loading">
      <!-- alert component -->
      <alert-section></alert-section>

      <!-- body content header  -->
      <div class="page-breadcrumb property-breadcrumbs details-breadcrumbs">
        <div
          class="d-xl-flex justify-content-between align-items-center add-pro-bread"
        >
          <div class="pg-bread">
            <b-btn
              variant="link"
              class="p-0 mr-3 primary-text"
              @click="goBack()"
              ><span class="material-icons align-middle"
                >arrow_back</span
              ></b-btn
            >
            <b-breadcrumb class="bg-transparent p-0 mb-0">
              <b-breadcrumb-item active>
                <span class="sf700">{{ propertyDetails.property_name }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item active class="text-grey-light">
                {{ propertyDetails.type_name }}
              </b-breadcrumb-item>
            </b-breadcrumb>
            <div class="ml-4">
              <b-badge
                :variant="propertyDetails.status_id == 1 ? 'success' : 'danger'"
                pill
                class="p-2 bf-size"
              >
              {{ propertyStatusOption[propertyDetails.status_id] }}
              </b-badge>
            </div>
            <!-- for mobile -->
            <div class="text-nowrap text-grey-light d-md-none mt-3 w-100">
              {{ lang.label.dateAdded }} :
              {{ getAddedDate }}
            </div>
            <!-- this button for mobile -->
            <div class="display-resp-button d-md-none">
              <b-btn variant="primary" size="sm" class="btn-mw" @click="copyToClipboard">+ {{ lang.label.buttons.shareLink }}</b-btn>
              <b-btn variant="primary" size="sm" class="btn-mw" @click="editProperty($route.params.id)"  v-if="(login.user_type_id==3 && staffpermissions.length>0 && staffpermissions.includes('update_property')) || (login.user_type_id==1 && propertyDetails.created_by == login.id)">{{
                lang.label.buttons.editProperty
              }}</b-btn>
              <b-btn
                variant="primary"
                size="sm"
                class="btn-mw"
                @click="deletePropertyConfirmation()"
                v-if="(login.user_type_id==3 && staffpermissions.length>0 && staffpermissions.includes('delete_property')) || (login.user_type_id==1 && propertyDetails.created_by == login.id)"
                >{{ lang.label.buttons.deleteProperty }}</b-btn
              >
            </div>
          </div>

          <div class="pg-right">
            <div class="d-flex align-items-center flex-wrap">
              <!-- for desktop -->
              <span
                class="text-nowrap text-grey-light mr-2 pr-1 d-none d-md-inline"
                >{{ lang.label.dateAdded }} :
                {{ getAddedDate }}
              </span>

              <!-- this button for desktop -->
              <b-btn variant="primary" size="sm" class="btn-mw" @click="copyToClipboard">+ {{ lang.label.buttons.shareLink }}</b-btn>
              <b-btn
                variant="primary"
                size="sm"
                class="text-nowrap btn-mw d-none d-md-inline"
                 @click="editProperty($route.params.id)"
                 v-if="(login.user_type_id==3 && staffpermissions.length>0 && staffpermissions.includes('update_property')) || (login.user_type_id==1 && propertyDetails.created_by == login.id)"
                >{{ lang.label.buttons.editProperty }}</b-btn
              >
              <b-btn
                variant="primary"
                size="sm"
                class="text-nowrap btn-mw d-none d-md-inline"
                @click="deletePropertyConfirmation()"
                v-if="(login.user_type_id==3 && staffpermissions.length>0 && staffpermissions.includes('delete_property')) || (login.user_type_id==1 && propertyDetails.created_by == login.id)"
                >{{ lang.label.buttons.deleteProperty }}</b-btn
              >
            </div>
          </div>
        </div>
      </div>

      <!-- properties property section -->
      <div class="property-detail-page">
        <b-container fluid class="px-0">
          <b-row>
            <!-- property details images and content section -->
            <b-col lg="6" xl="8" class="order-1 order-lg-1">
              <b-card
                no-body
                class="bg-transparent border-0 rounded-0 form-group"
              >
                <b-card-body class="p-0">
                  <property-media
                    :description="propertyDetails.description"
                    :propertyMedia="propertyDetails.property_images"
                  ></property-media>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- property address section -->
            <b-col lg="6" xl="4" class="order-2 order-lg-2">
              <b-card no-body class="custom-card form-group">
                <b-card-body>
                  <div class="property-facilities">
                    <b-row>
                      <b-col
                        cols="6"
                        lg="6"
                        xl="4"
                        v-for="(facility, fIndex) in data.propertyFacilities"
                        :key="fIndex"
                      >
                        <b-media class="facility-media">
                          <template #aside>
                            <img
                              :src="getIconUrl(facility.icon)"
                              :alt="facility.name"
                            />
                          </template>
                          <p class="mb-0">
                            {{
                              (propertyFacilities[facility.id - 1] || 0)
                            }}
                          </p>
                        </b-media>
                      </b-col>
                    </b-row>
                  </div>
                  <p class="text-grey mb-2">
                    {{
                      lang.label.maintenanceFee +
                      " " +
                      getMaintenanceAmount
                    }}
                  </p>
                  <b-row>
                    <b-col >
                      <label class="mb-0 d-block">{{
                        lang.label.leaseAmount
                      }}</label>
                      <p class="property-amount my-2 mb-0">
                        {{ getLeaseAmount
                        }}<span class="bf400"
                          >/<span>{{
                            propertyDetails.tenant_detail &&
                            propertyDetails.tenant_detail.payment_frequency
                            ? paymentFrequencyOption[propertyDetails.tenant_detail.payment_frequency_id]
                              : lang.label.monthly
                          }}</span></span
                        >
                      </p>
                    </b-col>
                    <b-col class="text-nowrap">
                      <label class="mb-0 d-block">{{
                        lang.label.advanceAmount
                      }}</label>
                      <p class="property-amount my-2 mb-0">
                        {{ getDepositAmount }}
                      </p>
                    </b-col>
                  </b-row>
                  <div class="address-map">
                    <div class="address-iframe">
                      <!-- Google Map Section -->
                      <google-map
                        :location="propertyDetails.coordinates"
                        :isViewOnly="true"
                      ></google-map>
                    </div>
                    <div class="mb-0 address-text">
                      <div class="d-flex align-items-center">
                        <span class="material-icons secondary-text mr-1"
                          >place</span
                        >
                        <span>{{ propertyDetails.address || "N/A" }}</span>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- property amenities section -->
            <b-col lg="6" xl="8" class="order-5 order-lg-3">
              <b-card
                no-body
                class="bg-transparent border-0 rounded-0 form-group"
              >
                <b-card-body class="p-0">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h5 class="mb-0 sf-size bf700">
                      {{ lang.label.amenities }}
                    </h5>
                    <b-btn variant="link" class="p-0 text-underline mh-auto" @click="showAllAmenities" v-if="propertyDetails.amenities && propertyDetails.amenities.length > 6">
                      {{(amenitiesLimit != 0 ? lang.label.viewAll : lang.label.viewLess)}}
                    </b-btn>
                  </div>
                  <div class="mt-3">
                    <property-amenities
                      :selectedAmenities="propertyDetails.amenities"
                      :limit="amenitiesLimit"
                    ></property-amenities>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- property broker & tenant details & property manager section -->
            <b-col lg="6" xl="4" class="order-3 order-lg-4">
              <b-card no-body class="custom-card form-group">
                <b-card-body>
                  <div class="property-manager border-0">
                    <div class="manager-section" v-if="!propertyDetails.is_realtor_self">
                      <h5 class="mng-title sf-size bf700">
                        {{ lang.label.brokerDetails }}
                      </h5>
                      <div class="mng-block">
                        <div
                          class="d-flex flex-wrap"
                          v-if="getRealtorName !== 'N/A'"
                        >
                          <div class="mng-box">
                            <label class="mb-0">{{ getRealtorName }}</label>
                            <p class="mb-0 mt-1">
                              {{ getRealtorContact }} | {{ getRealtorEmail }}
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          {{ lang.label.noBrokerAssigned }}
                        </div>
                      </div>
                    </div>
                    <div class="manager-section" v-else>
                      <h5 class="mng-title sf-size bf700">
                        {{ lang.label.ownerDetails }}
                      </h5>
                      <div class="mng-block">
                        <div
                          class="d-flex flex-wrap"
                          v-if="getOwnerName !== 'N/A'"
                        >
                          <div class="mng-box">
                            <label class="mb-0">{{ getOwnerName }}</label>
                            <p class="mb-0 mt-1">
                              {{ getOwnerContact }} | {{ getOwnerEmail }}
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          {{ lang.label.noOwnerAssigned }}
                        </div>
                      </div>
                    </div>
                    <div class="manager-section" v-if="propertyDetails.user_type == 1">
                      <h5 class="mng-title sf-size bf700">
                        {{ lang.label.memberDetails }}
                      </h5>
                      <div class="mng-block">
                        <div
                          class="d-flex flex-wrap"
                          v-if="getMemberName !== 'N/A'"
                        >
                          <div class="mng-box">
                            <label class="mb-0">{{ getMemberName }}</label>
                            <p class="mb-0 mt-1">
                              {{ getMemberContact }} | {{ getMemberEmail }} | {{ getMemberRole }}
                            </p>
                          </div>
                        </div>
                        <div v-else>{{ lang.label.noMemberAssigned }}</div>
                      </div>
                    </div>
                    <div class="manager-section">
                      <h5 class="mng-title sf-size bf700">
                        {{ lang.label.tenantDetails }}
                      </h5>
                      <div v-if="getTenantName == 'N/A'">
                        {{ lang.label.noTenantAssigned }}
                      </div>
                      <div class="mng-block" v-if="getTenantName !== 'N/A'">
                        <div class="d-flex flex-wrap">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">
                              {{ lang.label.leasedTo }}
                            </h6>
                            <label class="mb-0">{{ getTenantName }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="mng-block" v-if="getTenantName !== 'N/A'">
                        <h6 class="bf-size bf600 mb-1">
                          {{ lang.label.leasedPeriod }}
                        </h6>
                        <div class="d-flex flex-wrap">
                          <div class="mng-box">
                            <label class="mb-0">{{
                              lang.label.startDate
                            }}</label>
                            <p class="mb-0 mt-1">{{ getLeaseStartDate }}</p>
                          </div>
                          <div class="mng-box">
                            <label class="mb-0">{{ lang.label.endDate }}</label>
                            <p class="mb-0 mt-1">{{ getLeaseEndDate }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="mng-block" v-if="getTenantName !== 'N/A'">
                        <div class="d-flex flex-wrap">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">
                              {{ lang.label.rentDue }}
                            </h6>
                            <label class="mb-0">{{ getRentDueDate }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="mng-block" v-if="getTenantName !== 'N/A'">
                        <div class="d-flex flex-wrap">
                          <div class="mng-box">
                            <h6 class="bf-size bf600 mb-1">
                              {{ lang.label.idDoc }}
                            </h6>
                            <label class="mb-0" v-if="getTenantIdDoc == 'N/A'">{{ lang.label.notIDDoc }}</label>
                            <a v-else :href="getTenantIdDoc" class="file-format" target="_blank">
                              <span class="format-name">DOC</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- property reports section -->
            <b-col lg="6" xl="8" class="order-6 order-lg-5">
              <b-card
                no-body
                class="bg-transparent border-0 rounded-0 form-group"
              >
                <b-card-body class="p-0">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h5 class="mb-0 sf-size bf700">
                      {{ lang.label.propertyReport }}
                    </h5>
                  </div>
                  <div class="mt-3">
                    <div class="w-100" v-if="graphAvailable">
                      <apexChart
                        width="100%"
                        type="line"
                        :height="height"
                        :options="chartOptions"
                        :series="series"
                        class="line-chart"
                      ></apexChart>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- property lease agreement section -->
            <b-col lg="6" xl="4" class="order-4 order-lg-6">
              <b-card no-body class="custom-card form-group">
                <b-card-body>
                  <div class="property-manager border-0">
                    <div class="manager-section">
                      <h5 class="mng-title sf-size bf700">
                        {{ lang.label.leaseAgreement }}
                      </h5>
                      <div
                        class="d-flex justify-content-between flex-wrap"
                        v-if="getLeaseDoc"
                      >
                        <div
                          v-if="
                            login.user_type_id == 1 ||
                            login.user_type_id == 2 ||
                            (login.user_type_id == 3 &&
                              staffpermissions.length > 0 &&
                              staffpermissions.includes('view_lease_agreement'))"
                        >
                        <el-tooltip :content="lang.label.tooltip.view" placement="top">
                          <a :href="getLeaseDoc" class="file-format" target="_blank">
                            <span class="format-name">PDF</span>
                          </a>
                        </el-tooltip>
                        </div>
                        <div>

                          <div
                            class="upload-file-button d-inline-flex change-btn"
                          >
                            <b-form-file
                              class="only-text"
                              v-model="leaseDocument"
                              accept=".pdf,.png,.jpg,.jpeg"
                              :browse-text="lang.label.buttons.change"
                              @change="uploadLease($event)"
                              :disabled="
                                login.user_type_id == 3 &&
                                staffpermissions.length > 0 &&
                                !staffpermissions.includes(
                                  'update_lease_agreement'
                                )
                                  ? true
                                  : false
                              "
                            ></b-form-file>
                          </div>
                          <b-btn
                            variant="primary"
                            size="sm"
                            @click="deletedLeaseMedia()"
                            :disabled="
                              login.user_type_id == 3 &&
                              staffpermissions.length > 0 &&
                              !staffpermissions.includes(
                                'delete_lease_agreement'
                              )
                                ? true
                                : false
                            "
                            >{{ lang.label.buttons.remove }}</b-btn
                          >
                        </div>
                      </div>

                      <div
                        class="d-flex justify-content-between flex-wrap"
                        v-else
                      >
                        {{ lang.label.noLeaseDocMsg }}
                        <div
                          class="upload-file-button d-inline-flex change-btn"
                        >
                          <b-form-file
                            class="only-text"
                            v-model="leaseDocument"
                            accept=".pdf,.png,.jpg,.jpeg"
                            :browse-text="lang.label.buttons.upload"
                            @change="uploadLease($event)"
                            :disabled="
                              login.user_type_id == 3 &&
                              !staffpermissions.includes(
                                'update_lease_agreement'
                              )
                                ? true
                                : false
                            "
                          ></b-form-file>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>

            <b-col
              lg="12"
              class="order-7 order-lg-7"
              v-if="
                propertyDetails.tenant_detail &&
                propertyDetails.tenant_detail.first_name
              "
            >
              <b-card no-body class="custom-card form-group">
                <b-card-body class="py-lg-0">
                  <div class="summary-details-card">
                    <b-row>
                      <b-col lg="6" xl="4">
                        <!-- rent details summary -->
                        <div class="detail-summary mb-lg-0">
                          <h5 class="summary-heading">
                            {{ lang.label.rentDetails.title }}
                          </h5>
                          <div
                            class="d-sm-flex justify-content-between summary-div"
                          >
                            <label
                              class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                              >{{ lang.label.rentDetails.tenant }}:</label
                            >
                            <span>{{ getTenant }}</span>
                          </div>
                          <div
                            class="d-sm-flex justify-content-between summary-div"
                          >
                            <label
                              class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                              >{{ lang.label.rentDetails.leasePeriod }}:</label
                            >
                            <span>{{ getLeasePeriod }}</span>
                          </div>
                          <div
                            class="d-sm-flex justify-content-between summary-div"
                          >
                            <label
                              class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                              >{{ lang.label.rentDetails.leaseAmount }}:</label
                            >
                            <span>{{ getLeaseAmount }}</span>
                          </div>
                          <div
                            class="d-sm-flex justify-content-between summary-div"
                          >
                            <label
                              class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                              >{{
                                lang.label.rentDetails.maintenanceFees
                              }}:</label
                            >
                            <span>{{ getMaintenanceAmount }}</span>
                          </div>
                          <div
                            class="d-sm-flex justify-content-between summary-div"
                          >
                            <label
                              class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                              >{{
                                lang.label.rentDetails.depositAmount
                              }}:</label
                            >
                            <span>{{ getDepositAmount }}</span>
                          </div>
                          <div
                            class="d-sm-flex justify-content-between summary-div"
                          >
                            <label
                              class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                              >{{
                                lang.label.rentDetails.amountDueFirstMonth
                              }}:</label
                            >
                            <span>{{ getFirstMonthAmountDue }}</span>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="6" xl="4">
                        <!-- broker commission fees details summary -->
                        <div class="detail-summary mb-lg-0">
                          <h5 class="summary-heading">
                            {{ lang.label.brokerCommission.title }}
                          </h5>
                          <div
                            class="d-sm-flex justify-content-between summary-div"
                          >
                            <label
                              class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                              >{{
                                lang.label.brokerCommission.closingCostComFirst
                              }}:</label
                            >
                            <span>{{ getClosingCostCommissionToBroker }}</span>
                          </div>
                          <div
                            class="d-sm-flex justify-content-between summary-div"
                          >
                            <label
                              class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                              >{{
                                lang.label.brokerCommission.recurringRentalFee
                              }}:</label
                            >
                            <span>{{ getRecurringRentalFee }}</span>
                          </div>
                          <div
                            class="d-sm-flex justify-content-between summary-div"
                          >
                            <label
                              class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                              >{{
                                lang.label.brokerCommission
                                  .recurringRentalFeeBroker
                              }}:</label
                            >
                            <span>{{ getRecurringRentalFeeToBroker }}</span>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="6" xl="4">
                        <!-- rental amount to owner details summary -->
                        <div class="detail-summary mb-lg-0">
                          <h5 class="summary-heading">
                            {{ lang.label.rentalAmountOwner.title }}
                          </h5>
                          <div
                            class="d-sm-flex justify-content-between summary-div"
                          >
                            <label
                              class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                              >{{
                                lang.label.rentalAmountOwner.firstMonthOwner
                              }}:</label
                            >
                            <span>{{ getFirstMonthToOwner }}</span>
                          </div>
                          <div
                            class="d-sm-flex justify-content-between summary-div"
                          >
                            <label
                              class="bf700 mb-2 mb-sm-0 d-block d-sm-inline"
                              >{{
                                lang.label.rentalAmountOwner
                                  .recurringRentalAmountDueOwner
                              }}:</label
                            >
                            <span>{{
                              getRecurringRentalAmountDueToOwner
                            }}</span>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col lg="12" class="order-8 order-lg-8">
              <filter-form
                @handleApiCallTenant="handleApiCallTenant"
                @download="download"
              ></filter-form>
              <hr class="mt-0" />
              <report-list
                :tenantDetails="tenantDetails"
                @handleApiCallTenant="handleApiCallTenant"
                ref="reportList"
              ></report-list>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <!-- cancel property confirmation message -->
      <b-modal
        ref="deleteConfirmation"
        hide-footer
        centered
        size="md"
        modal-class="close-btn-right"
      >
        <template #modal-header="{ close }">
          <b-button variant="link" class="close-btn" @click="close()">
            <img :src="getIconUrl('close.svg')" alt="close" />
          </b-button>
        </template>
        <div class="d-block text-center">
          <h3 class="body-modal-heading">{{ lang.label.areYouSureDelete }}</h3>
          <p class="body-modal-content">
            {{ lang.label.noRevertBackProperty }}
          </p>
        </div>
        <div class="modal-buttons text-center modal-btn-responsive">
          <b-button
            class="btn-mw"
            variant="primary"
            @click="confirmDeleteProperty()"
            >{{ lang.label.buttons.yes }}</b-button
          >
          <b-button
            class="btn-mw"
            variant="danger"
            @click="noDeleteProperty()"
            >{{ lang.label.buttons.no }}</b-button
          >
        </div>
      </b-modal>

      <!-- cancel property confirmation message -->
      <b-modal
        ref="deleteLeaseConfirmation"
        hide-footer
        centered
        size="md"
        modal-class="close-btn-right"
      >
        <template #modal-header="{ close }">
          <b-button variant="link" class="close-btn" @click="close()">
            <img :src="getIconUrl('close.svg')" alt="close" />
          </b-button>
        </template>
        <div class="d-block text-center">
          <h3 class="body-modal-heading">
            {{ lang.label.areYouSureDeleteLease }}
          </h3>
        </div>
        <div class="modal-buttons text-center modal-btn-responsive">
          <b-button class="btn-mw" variant="primary" @click="deleteLease()">{{
            lang.label.buttons.yes
          }}</b-button>
          <b-button class="btn-mw" variant="danger" @click="noDeleteLease()">{{
            lang.label.buttons.no
          }}</b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import jsondata from "@/assets/json/data.json";
import PropertyMedia from "./details/PropertyMedia.vue";
import PropertyAmenities from "./details/PropertyAmenities.vue";
import FilterForm from "../Reports/FilterForm.vue";
import ReportList from "../Reports/List.vue";
import { mapGetters } from "vuex";
import alertSection from "@/views/shared/AlertSection.vue";
import mixin from "@/mixins/mixin";
import property_mixin from "@/mixins/property_mixin";
import GoogleMapSection from "@/views/shared/GoogleMapSection.vue";
import Swal from "sweetalert2";
import moment from "moment";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexChart", VueApexCharts);
export default {
  name: "PropertiesDetails",
  components: {
    "property-media": PropertyMedia,
    "property-amenities": PropertyAmenities,
    "filter-form": FilterForm,
    "report-list": ReportList,
    "alert-section": alertSection,
    "google-map": GoogleMapSection,
  },
  mixins: [mixin, property_mixin],
  computed: {
    ...mapGetters({
      loading: "loading",
      ImageUrl: "ImageUrl",
      filterpayloads: "filterpayloads",
      login: "login",
      staffpermissions: "staffpermissions",
      isUserVerified: "isUserVerified",
      language: "language",
    }),
    /**
     * @returns created date of property
     */
    getAddedDate() {
      return this.propertyDetails.created_date
        ? this.formatDateToShow(this.propertyDetails.created_date)
        : "N/A";
    },
    /**
     * @returns Assigned realtor's name
     */
    getRealtorName() {
      if (this.propertyDetails.realtor_details?.first_name) {
        return this.propertyDetails.realtor_details.first_name + " " + this.propertyDetails.realtor_details.last_name;
      } else if (this.propertyDetails.realtor_details?.name) {
        return this.propertyDetails.realtor_details.name;
      } else {
        return "N/A";
      }
    },
    /**
     * @returns Assigned realtor's contact with formated string
     */
     getRealtorContact() {
      let number =
        this.propertyDetails.realtor_details &&
        this.propertyDetails.realtor_details.contact && this.propertyDetails.realtor_details.contact != ""
          ? this.propertyDetails.realtor_details.contact
          : "N/A";
      return number;
    },
    /**
     * @returns Assigned realtor's email
     */
    getRealtorEmail() {
      return this.propertyDetails.realtor_details &&
      this.propertyDetails.realtor_details.email
      ? this.propertyDetails.realtor_details.email
      : "N/A";
    },
    /**
     * @returns Assigned realtor's name
     */
    getOwnerName() {
      if (this.propertyDetails.owner_details?.first_name) {
        return this.propertyDetails.owner_details.first_name + " " + this.propertyDetails.owner_details.last_name;
      } else if (this.propertyDetails.owner_details?.name) {
        return this.propertyDetails.owner_details.name;
      } else {
        return "N/A";
      }
    },
    /**
     * @returns Assigned realtor's contact with formated string
     */
     getOwnerContact() {
      let number =
        this.propertyDetails.owner_details &&
        this.propertyDetails.owner_details.contact && this.propertyDetails.owner_details.contact != ""
          ? this.propertyDetails.owner_details.contact
          : "N/A";
      return number;
    },
    /**
     * @returns Assigned realtor's email
     */
    getOwnerEmail() {
      return this.propertyDetails.owner_details &&
        this.propertyDetails.owner_details.email
        ? this.propertyDetails.owner_details.email
        : "N/A";
    },
    /**
     * @returns Assigned tenant's name
     */
    getTenantName() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.first_name
        ? this.propertyDetails.tenant_detail.first_name +
            " " +
            this.propertyDetails.tenant_detail.last_name
        : "N/A";
    },
    /**
     * @returns Assigned tenant's identity document
     */
     getTenantIdDoc() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.identity_document
        ? this.ImageUrl + this.propertyDetails.tenant_detail.identity_document
        : "N/A";
    },
    /**
     * @returns Assigned member's name
     */
    getMemberName() {
      return this.propertyDetails.staff_details &&
        this.propertyDetails.staff_details.first_name
        ? this.propertyDetails.staff_details.first_name +
            " " +
            this.propertyDetails.staff_details.last_name
        : "N/A";
    },
    /**
     * @returns Assigned member's contact with formated string
     */
     getMemberContact() {
      let number =
        this.propertyDetails.staff_details &&
        this.propertyDetails.staff_details.contact
          ? this.propertyDetails.staff_details.contact
          : null;
      let formatedNumber = "N/A";
      // if (number) {
      //   const match = number.replace(/\D+/g, "").match(/([^\d]*\d[^\d]*){1,10}$/)[0];
      //   const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match;
      //   const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : "";
      //   const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : "";
      //   formatedNumber = `${part1}${part2}${part3}`;
      // }
      return number;
    },
    /**
     * @returns Assigned member's email
     */
    getMemberEmail() {
      return this.propertyDetails.staff_details &&
        this.propertyDetails.staff_details.username
        ? this.propertyDetails.staff_details.username
        : "N/A";
    },
    /**
     * @returns Assigned member's email
     */
    getMemberRole() {
      return this.propertyDetails.staff_details &&
        this.propertyDetails.staff_details.role
        ? this.propertyDetails.staff_details.role.name
        : "N/A";
    },
    /**
     * @returns Assigned tenant's lease start date
     */
    getLeaseStartDate() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.start_date
        ? this.formatDateToSlash(this.propertyDetails.tenant_detail.start_date)
        : "N/A";
    },
    /**
     * @returns Assigned tenant's lease end date
     */
    getLeaseEndDate() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.end_date
        ? this.formatDateToSlash(this.propertyDetails.tenant_detail.end_date)
        : "N/A";
    },
    /**
     * @returns Assigned tenant's rend due date
     */
    getRentDueDate() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.next_due_date
        ? this.formatDateStringToDate2(
            this.propertyDetails.tenant_detail.next_due_date
          )
        : "N/A";
    },
    /**
     * @returns Tenant lease agreement document
     */
    getLeaseDoc() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.lease_agreement
        ? this.ImageUrl + this.propertyDetails.tenant_detail.lease_agreement
        : null;
    },
  },
  data() {
    return {
      lang: this.$lang.property_details,
      data: jsondata,
      encryptID : '',
      // line chart
      height: "150px",
      series: [
        {
          name: "Income",
          data: [],
        },
        {
          name: "Expense",
          data: [],
        },
      ],
      propertyID:'',
      chartOptions: {
        chart: {
          type: "line",
          width: "100%",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 991,
            options: {
              chart: {
                height: 230,
              },
            },
          },
          {
            breakpoint: 1550,
            options: {
              chart: {
                height: 230,
              },
            },
          },
        ],
        dataLabels: {
          enabled: false,
        },
        legend: {
          formatter: function (seriesName, opts) {
            let sum = opts.w.globals.series[opts.seriesIndex].reduce(
              (partialSum, a) => partialSum + a,
              0
            );
            return [opts.seriesIndex == 0 ? "+" : "-", sum];
          },
          onItemClick: {
            toggleDataSeries: false
          },
        },
        stroke: {
          width: [1.5, 1.5],
          curve: "straight",
          dashArray: [0, 0],
        },
        colors: ["#5734D6", "#295E73"],
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
          categories: [
            "Dec 31, 2021",
            "Jan 31, 2022",
            "Feb 28, 2022",
            "Mar 31, 2022",
            "Apr 30, 2022",
            "May 31, 2022",
            "Jun 30, 2022",
            "Jul 31, 2022",
            "Aug 31, 2022",
            "Sep 30, 2022",
            "Oct 31, 2022",
            "Nov 30, 2022",
            "Dec 31, 2022",
          ],
          labels: {
            style: {
              cssClass: "apexcharts-xaxis-label-custom",
            },
            rotateAlways: false,
            formatter: function (val) {
              return (val ? moment(val, "YYYY-MM-DD").format("MMM D, YYYY") : '');
            }
          },
          axisTicks: {
            show: false,
          },

          //       labels: {
          //   formatter: function (val) {
          //               return val + " (mins)"
          //             }
          // }
          //   labels: {
          //     show: false
          //   }
        },
        tooltip: {
          enabled: false,
        },
        yaxis: {
          // labels: {
          //     show: false,
          // }
          tickAmount: 2,
        },
        grid: {
          show: true,
          borderColor: "#DDDDDD",
          strokeDashArray: 1,
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      propertyDetails: {amenities:[]},
      propertyFacilities: [],
      userType: {
        1: "realtor_details",
        2: "owner_details",
      },
      graphAvailable: true,
      selectedContact: {},
      models: {},
      contactModel: {
        firstName : '',
        lastName : '',
        contactNumber : '',
        message : ''
      },
      maintenanceFee: null,
      leaseDocument: [],
      tenantDetails: [],
      fileFormat: {},
      amenitiesLimit: 6,
      isBrokerAvailable: true,
    };
  },
  created() {
    moment.updateLocale(this.language, {});
    this.propertyID = this.$route.params.id
    this.encryptID = window.btoa(this.propertyID)
    this.viewProperty();
    this.listTenantHistory();
  },
  destroyed() {
    this.$store.commit("setFilterPayload", null);
  },
  methods: {
    showAllAmenities() {
      if(this.amenitiesLimit == 0) {
        this.amenitiesLimit = 6;
      } else {
        this.amenitiesLimit = 0;
      }
    },
    getImgUrl(img) {
      return require("@/assets/styles/img/" + img);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getAmenityIcon(amenity) {
      return require("@/assets/styles/img/amenities/" + amenity);
    },
    goBack() {
      this.$router.push({ name: "properties" });
    },
    editProperty(id){
      if ((this.login.user_type_id == 1 && this.login.subscription_end_date == "") || (this.login.user_type_id == 3 && this.login.is_subscription_expired)) {        
        this.$store.commit("setSubscriptionAlert", true);
      } else {
        this.$router.push({ name: "edit-properties",params:{id:id} });
      }
    },
    deletePropertyConfirmation() {
      if (this.isUserVerified) {
        this.$store.commit("setVerificationAlert", true);
      } else {
        this.$refs["deleteConfirmation"].show();
      }
    },
    confirmDeleteProperty() {
      this.deleteProperty();
    },
    noDeleteProperty() {
      this.$refs["deleteConfirmation"].hide();
    },
    noDeleteLease() {
      this.$refs["deleteLeaseConfirmation"].hide();
    },
    /**
     * Handles fetching property details via API
     */
    viewProperty() {
      let self = this;
      let id = self.propertyID;
      self.$store.dispatch("viewProperty", id).then((response) => {
        if (response.status == 200) {
          self.propertyDetails = response.data;
          self.handleProertyFacilities();
          self.handleGraphData();
          self.handleTenantDetails();
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
            self.$router.push({ name: "not-found" });
          }
        }
      });
    },
    /**
     * Handles fetching property graph details via API
     */
     viewPropertyGraph() {
      let self = this;
      let id = self.propertyID;
      self.$store.dispatch("viewPropertyGraph", id).then((response) => {
        if (response.status == 200) {
          self.propertyDetails.income = response.data.income;
          self.propertyDetails.expense = response.data.expense;
          self.propertyDetails.property_report_range = response.data.property_report_range;
          self.handleGraphData();
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
            self.$router.push({ name: "not-found" });
          }
        }
      });
    },
    /**
     * Handles graph date labels
     */
    handleGraphDate() {
      let self = this;
      let incomeLen = self.propertyDetails.income.length;
      let expenseLen = self.propertyDetails.expense.length;
      let dateArr = new Array(Math.max(incomeLen, expenseLen));
      dateArr[0] = self.propertyDetails.property_report_range.start;
      dateArr[incomeLen - 1] = self.propertyDetails.property_report_range.income_end;
      dateArr[expenseLen - 1] = self.propertyDetails.property_report_range.expense_end;
      self.chartOptions.xaxis.categories = dateArr;
    },
    /**
     * Handles fetching tenant history details via API
     */
    listTenantHistory() {
      let self = this;
      let data = "";
      if (self.filterpayloads == null) {
        data = {
          ID: self.propertyID,
          size: 100000000000,
          page: 1,
          payload: {
            search: null,
            start_date: null,
            end_date: null,
            type: null,
            frequency_type: null,
          },
        };
      } else {
        data = {
          ID: self.propertyID,
          size: 100000000000,
          page: 1,
          payload: {
            search: self.search ? self.search : "",
            start_date: self.filterpayloads.start_date,
            end_date: self.filterpayloads.end_date,
            type: self.filterpayloads.type,
            frequency_type: self.filterpayloads.frequency_type,
          },
        };
      }

      self.$store.dispatch("listTenantHistory", data).then((response) => {
        if (response.status == 200) {
          self.tenantDetails = response.data.results;
          self.handleProertyFacilities();
          self.handleTenantDetails();
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Handles property facilities data
     */
    handleProertyFacilities() {
      let self = this;
      self.propertyFacilities = [];
      self.propertyFacilities.push(self.lang.label.propertyFacilities.livableSpace + " " + self.propertyDetails.approx + " mt2");
      self.propertyFacilities.push(self.lang.label.propertyFacilities.area + " " + self.propertyDetails.area + " mt2");
      self.propertyFacilities.push(self.lang.label.propertyFacilities.construction + " " + self.propertyDetails.year);
      self.propertyFacilities.push(self.propertyDetails.bedrooms + " " + self.lang.label.propertyFacilities.bedrooms);
      self.propertyFacilities.push(
        self.propertyDetails.bathrooms + " " + self.lang.label.propertyFacilities.bathrooms
      );
      self.propertyFacilities.push(self.propertyDetails.parking + " " + self.lang.label.propertyFacilities.parkings);
    },
    /**
     * Handles graph values for income and expense
     */
    handleGraphData() {
      let self = this;
      self.graphAvailable = false;
      if(self.propertyDetails.income.length == 1) {
        self.propertyDetails.income.unshift(0);
      }
      self.series[0].data = self.propertyDetails.income;
      if(self.propertyDetails.expense.length == 1) {
        self.propertyDetails.expense.unshift(0);
      }
      self.series[1].data = self.propertyDetails.expense;
      self.handleGraphDate();
      setTimeout(() => {
        self.graphAvailable = true;
      }, 0);
    },
    /**
     * Handles storing tenant data for computing lease details
     */
    handleTenantDetails() {
      let self = this;
      self.maintenanceFee = self.propertyDetails.maintenance_fee;
      self.selectedContact = {
        name:
          self.propertyDetails.tenant_detail &&
          self.propertyDetails.tenant_detail.first_name
            ? self.propertyDetails.tenant_detail.first_name +
              " " +
              self.propertyDetails.tenant_detail.last_name
            : "N/A",
        email:
          self.propertyDetails.tenant_detail &&
          self.propertyDetails.tenant_detail.email
            ? self.propertyDetails.tenant_detail.email
            : "N/A",
        id: self.propertyDetails.tenant_detail && self.propertyDetails.tenant_detail.id,
      };
      if (self.propertyDetails.tenant_detail && Object.keys(self.propertyDetails.tenant_detail).length > 0) {
        self.models = {
          startDate: self.propertyDetails.tenant_detail.start_date,
          endDate: self.propertyDetails.tenant_detail.end_date,
          paymentFrequency:
            self.propertyDetails.tenant_detail.payment_frequency_id,
          rentAmount: self.propertyDetails.tenant_detail.rent_amount,
          depositAmount: self.propertyDetails.tenant_detail.deposit_amount,
          brokerRecurringCurrency:
            self.propertyDetails.tenant_detail.realtor_rental_fee_type_id,
          brokerRecurringRentalFee:
            self.propertyDetails.tenant_detail.realtor_rental_fee,
          closingCostCurrency:
            self.propertyDetails.tenant_detail.realtor_closing_commission_type_id,
          closingCostCommission:
            self.propertyDetails.tenant_detail.realtor_closing_commission,
        };
      } else {
        self.models = {
          paymentFrequency:
            self.propertyDetails.payment_frequency_id,
          rentAmount: self.propertyDetails.rent_amount,
          depositAmount: self.propertyDetails.security_amount
        };
      }
    },
    /**
     * Handles deleting lease agreement document
     */
    deletedLeaseMedia() {
      let self = this;
      let id = self.propertyDetails.tenant_detail.id;
      self.$store.dispatch("deletedLeaseMedia", id).then((response) => {
        if (response.status == 200) {
          self.propertyDetails.tenant_detail.lease_agreement = null;
          self.$store.commit("setMessages", {
            type: "success",
            message: response.data.message,
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Handles deleting property
     */
    deleteProperty() {
      let self = this;
      let id = self.propertyDetails.id;
      self.$store.dispatch("deleteProperty", id).then((response) => {
        self.$refs["deleteConfirmation"].hide();
        if (response.status == 200) {
          setTimeout(() => {
            self.$store.commit("setMessages", {
              type: "success",
              message: response.data.message,
            });
          }, 0);
          self.$router.push({ name: "properties" });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Event for tenant details API ,flow :-
     * 1) api call in AddExpense page - > Event(flag) passed to parent component FilterForm and then here
     * 2) api call from Reports listing page
     */
    handleApiCallTenant(flag) {
      let self = this;
      self.listTenantHistory();
      self.viewPropertyGraph();
    },
    /**
     * Report listing downloadFile function will be called by using refs and file will be downloaded
     */
    download(obj) {
      let self = this;
      self.fileFormat = obj;
      self.$refs.reportList.downloadFile(self.fileFormat);
    },
    /**
     * Upload lease popup
     */
    uploadLease(evt) {
      let self = this;
      let fileSizeMB = evt.target.files[0] / 1024 ** 2;
      if (
        self.propertyDetails.tenant_detail &&
        self.propertyDetails.tenant_detail.id
      ) {
        if (fileSizeMB > 10) {
          self.fireValidation();
        } else {
          self.leaseDocument = evt.target.files[0];
          self.uploadLeaseDocument();
        }
      } else {
        Swal.fire({
          title: "",
          text: self.lang.label.noTenantAssigned_1,
          icon: "warning",
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    },
    /**
     * Fires the error if uploaded file size is greater then 10MB
     */
    fireValidation() {
      Swal.fire({
        title: "",
        text: self.lang.label.documentSizeValidation,
        icon: "warning",
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    /**
     * Uploads lease document
     */
    uploadLeaseDocument() {
      let self = this;

      let formdata = new FormData();
      formdata.append("lease_agreement", self.leaseDocument);

      let data = {
        id:
          self.propertyDetails.tenant_detail &&
          self.propertyDetails.tenant_detail.id,
        formData: formdata,
      };

      self.$store.dispatch("uploadLeaseMedia", data).then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    copyToClipboard() {
      let self = this;
      let routeCopied = this.$router.resolve({ name: "public-properties-details",params:{id:self.encryptID} });
      let route = this.$router.resolve({ name: "public-properties-details",params:{id:self.encryptID}, query: {isCopied: true} });
      navigator.clipboard.writeText(location.host + routeCopied.href)
        .then(() => {
          window.open(route.href);
        })
        .catch(err => {
          console.log('Something went wrong', err);
        });
    },
  },
};
</script>
