module.exports = {
    label: {
      required: '*',
      form: {      
        label: {
          firstName: "Nombre",
          lastName: 'Apellido',
          email: 'Correo Electrónicol',
          contactNumber: 'Número De Teléfono',
          cardNumber: 'Número De Tarjeta',
          nameOnCard: 'Nombre En La Tarjeta',
          expiryDate: 'Fecha De Vencimiento',
          cvv: 'CVV',
          address: 'Dirección',
          city: 'Ciudad',
          state: 'Provincia',
          country: 'País',
          zipCode: 'Código Postal'
        },
        validation: {
          firstName: "nombre",
          lastName: 'apellido',
          email: 'correo electrónico',
          contactNumber: 'número le teléfono',
          cardNumber: 'número de tarjeta',
          nameOnCard: 'nombre en la tarjeta',
          expiryDate: 'fecha de vencimiento',
          cvv: 'CVV',
          address: 'dirección',
          city: 'ciudad',
          state: 'provincia',
          country: 'país',
          zipCode: 'código postal'
        },
        placeholder: {
          firstName: "Ingrese El Primer Nombre",
          lastName: 'Ingrese El Apellido',
          email: 'example@mail.com',
          contactNumber: 'Ingrese El Número',
          cardNumber: 'XXXX XXXX XXXX XXXX ',
          nameOnCard: 'Ingrese',
          expiryDate: 'MM/YY',
          cvv: 'Ingrese',
          address: 'Dirección',
          city: 'Ciudad',
          state: 'Provincia',
          country: 'País',
          zipCode: 'Código Postal'
        }
      },
      buttons: {
        add: 'Añadir Tarjeta De Crédito'
      }
    }
  };