<!-- content from header.js -->
<template>
  <b-sidebar
    v-if="!showSidebar.includes(this.$route.name)"
    id="menuSidebar"
    text-variant="light"
    :visible="sidebarVisible"
    :backdrop="sidebarBackdrop"
    sidebar-class="sidebar-section"
    :no-close-on-route-change="closeOnRoute"
    :no-close-on-esc="true"
  >
    <template #header="{hide}">
      <div class="nav-icon-img d-sm-none cursor-pointer mt-3" @click="hide()">
        <img :src="getIconUrl('close.svg')" alt="image" />
      </div>
    </template>
    <div class="sidebar-inner">
      <b-nav vertical class="sidebar-nav">
        <template v-for="(nav, index) in getSidebarNav()">
          <b-nav-item :to="nav.link" :key="index" v-if="nav.mobileOnly == false">
            <div class="sidebar-nav-item">
              <div class="nav-icon-img">
                <img :src="getSidebarIconUrl(nav.icon)" alt="image" />
              </div>
              <div class="sidebar-item-body">
                <span class="nav-title">{{ language == 'en' ? nav.title : nav.title_es }}</span>
              </div>
            </div>
          </b-nav-item>

          <b-nav-item :to="nav.link" :exact-path="false" :key="index + '1'" v-if="isMobileScreen && nav.mobileOnly">
            <div class="sidebar-nav-item">
              <div class="nav-icon-img">
                <img :src="getSidebarIconUrl(nav.icon)" alt="image" />
              </div>
              <div class="sidebar-item-body">
                <span class="nav-title">{{ language == 'en' ? nav.title : nav.title_es }}</span>
              </div>
            </div>
          </b-nav-item>
        </template>
        <b-nav-item v-if="isMobileScreen">
          <div class="sidebar-nav-item" @click="logout">
            <div class="nav-icon-img">
              <img :src="getSidebarIconUrl('sign-out.svg')" alt="image" />
            </div>
            <div class="sidebar-item-body">
              <span class="nav-title">{{ lang.label.signOut }}</span>
            </div>
          </div>
        </b-nav-item>
      </b-nav>
    </div>

    <!-- Language Selection Section -->
    <language-selection :isBackgroundDark="true" class=" m-3"></language-selection>

    <template #footer v-if="signOut">
      <b-nav vertical class="sidebar-nav">
        <b-nav-item @click="logout">
          <div class="sidebar-nav-item">
            <div class="nav-icon-img">
              <img :src="getSidebarIconUrl('sign-out.svg')" alt="image" />
            </div>
            <div class="sidebar-item-body">
              <span class="nav-title">{{ lang.label.signOut }}</span>
            </div>
          </div>
        </b-nav-item>
      </b-nav>
    </template>
  </b-sidebar>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import languageSelection from "@/views/shared/LanguageSelection.vue";
import { mapGetters } from "vuex";

export default {
  name: "SidebarPage",
  components: {
    'language-selection': languageSelection
  },
  data() {
    return {
      lang: this.$lang.header,
      data: jsondata,
      showSidebar: ["login", "forgot-password", "reset-password", "invite-user", "sign-up", "add-bank-details", 'request-bank-detail', "public-properties-details" , "not-found" ],
      search: "",
      sidebarVisible: true,
      sidebarBackdrop: false,
      signOut: false,
      closeOnRoute: true,
      isMobileScreen: false,
      staffDisabledSidebar: [
        "view_roles_and_permission",
        "view_staff"
      ],
      tenantDisabledSidebar: [
        "dashboard",
        "view_contacts",
        "view_roles_and_permission",
        "view_staff"
      ]
    };
  },
  computed: {
    ...mapGetters({
      login: "login",
      staffpermissions: "staffpermissions",
      language: "language",
      isUserOnlyTenant: "isUserOnlyTenant",
    })
  },
  mounted() {
    this.onResize();
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/styles/img/" + img);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getSidebarIconUrl(icon) {
      return require("@/assets/styles/img/sidebar/" + icon);
    },
    updateSidebar(val){
      const body = document.getElementsByTagName('body')[0];
      if(val){
        body.classList.add("overflow-hidden")
      } else {
        body.classList.remove("overflow-hidden")
      }
    },
    onResize() {
      if (window.innerWidth > 991) {
        (this.sidebarVisible = true);
          (this.sidebarBackdrop = false);
          (this.signOut = true);
          this.closeOnRoute = true;
          this.isMobileScreen = false;
          
      } else {
        (this.sidebarVisible = false);
          (this.sidebarBackdrop = true);
          (this.signOut = false);
          this.closeOnRoute = false;
          this.isMobileScreen = true;
      }
    },
    /**
     * To load sidebar for tenant,realtor and staff
     */
    getSidebarNav() {
      let self = this;
      let sidebarnav = JSON.parse(JSON.stringify(self.data.sidebarNav));
      var dataVal = [];
      if (self.login.user_type_id == 3 && self.staffpermissions.length > 0) {
        sidebarnav = sidebarnav.filter((data, index) => {
          if (
            (data.key && data.key == "view_property") ||
            data.key == "view_payment_report"
          ) {
            if (self.staffpermissions.includes(data.key)) {
              dataVal.push(data);
            }
          } else {
            if (!self.staffDisabledSidebar.includes(data.key)) {
              dataVal.push(data);
            }
          }
        });
      }  else if(self.isUserOnlyTenant) {
         sidebarnav = sidebarnav.filter((data, index) => {
            if(!self.tenantDisabledSidebar.includes(data.key)) {
              dataVal.push(data);
          }
         });
      } else {
          dataVal = sidebarnav;
      } 
      
      return dataVal;
    },
    logout() {
      let self = this;
      Swal.fire({
        title: this.lang.label.confirmTitle,
        text: this.lang.label.confirmMsg,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: this.lang.label.buttons.yes,
        cancelButtonText: this.lang.label.buttons.no,
        focusConfirm: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.$store.dispatch('logout').then(response => {
            if (response.status == 200) {
              if (response.data.message) {
                self.$store.commit('clearLogin');
                self.$router.push({name: "login"});
              }
            }
          })
        }
      });
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  // destroyed(){
  //     window.removeEventListener('resize', this.onResize)
  // }
};
</script>
