<!-- content from signup.js -->
<template>
    <div class="auth" v-loading="loading">
        <b-container fluid class="bv-example-row">
            <b-row>
                <b-col lg="6" xl="3" class="order-2 order-lg-1 form-view">
                    <div class="auth-left-section">
                        <div class="auth-logo d-lg-block d-none">
                            <img :src="getImgUrl('logo.svg')" alt="miradoor" />
                        </div>
                        <div class="form-section other-auth">
                            <div class="form-inner">
                                <!-- user selction type section -->
                                <!-- <template v-if="!signWithShow && showSignup">
                                    <signup-type @signUpView="signUpView"></signup-type>
                                </template> -->
    
                                <!-- signup with section -->
                                <!-- <template v-if="signWithShow">
                                    <signup-with @registerDetails="registerDetails" @signUpGo="signUpGo"></signup-with>
                                </template> -->
    
                                <!-- signup details section -->
                                <template v-if="showSignup">
                                    <signup-details @otpVerification="otpVerification" @registerDetailsGo="registerDetailsGo"></signup-details>
                                </template>
    
                                
    
                                <b-form-group class="pt-0 pt-lg-2 text-center text-lg-left">
                                    <span class="text-grey">{{ lang.label.alreadyAccount }} <router-link to="/" class="bf600">{{ lang.label.signIn }}</router-link></span>
                                </b-form-group>
                            </div>
    
    
                        </div>
                    </div>
                </b-col>
                <b-col lg="6" xl="9" class="order-1 order-lg-2 slogan-view">
                    <auth-banner></auth-banner>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
  
<script>
import jsondata from "@/assets/json/data.json";
import AuthBanner from "@/components/AuthBanner.vue";
import SignUpType from "./signup-steps/SignupType.vue";
import SignUpWith from "./signup-steps/SignupWith.vue";
import SignUpDetails from "./signup-steps/SignupDetails.vue";
import { mapGetters } from "vuex";
export default {
    components: {
        "auth-banner": AuthBanner,
        "signup-type": SignUpType,
        "signup-with": SignUpWith,
        "signup-details": SignUpDetails,
    },
    computed: {
        ...mapGetters({
            loading: "loading",
        }),
    },
    data() {
        return {
            lang: this.$lang.signup,
            data: jsondata,
            selected: '',
            signWithShow: true,
            showSignup: true,
        };
    },
    mounted() {
    },
    methods: {
        getImgUrl(img) {
            return require("@/assets/styles/img/" + img);
        },
        getIconUrl(icon) {
            return require("@/assets/styles/img/icons/" + icon);
        },
        signUpView(id){
            this.signWithShow = true;
        },
        registerDetails(val) {
            this.showSignup = val.showSignup,
            this.signWithShow = val.signWithShow
        },
        otpVerification(e){
            this.signWithShow = !e.signWithShow
        },
        registerAs(as){
            console.log(as);
        },
        registerDetailsGo(e){
            this.signWithShow = !e.signWithShow,
            this.showSignup = !e.showSignup
        },
        signUpGo(goBack){
            this.signWithShow = goBack.signWithShow
        }
    },
}
</script>
  