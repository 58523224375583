<!-- content from property_details.js -->
<template>
  <div class="body-content public-property-details">
    <div class="starts-section mt-0" v-loading="loading">
      <!-- alert component -->
      <alert-section></alert-section>

      <!-- body content header  -->
      <div class="page-breadcrumb">
        <div class="d-lg-flex justify-content-between align-items-center add-pro-bread">
          <div class="pg-bread">
            <b-breadcrumb class="bg-transparent p-0 mb-0">
              <b-breadcrumb-item active>
                <span class="sf700">{{ propertyDetails.property_name }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item active class="text-grey-light">
                {{ propertyDetails.type_name }}
              </b-breadcrumb-item>
            </b-breadcrumb>
            <div class="ml-4">
              <b-badge
                :variant="propertyDetails.status_id == 1 ? 'success' : 'danger'"
                pill
                class="p-2 bf-size"
              >
              {{ propertyStatusOption[propertyDetails.status_id] }}
              </b-badge>
            </div>
          </div>
        </div>
      </div>

      <!-- properties property section -->
      <div class="property-detail-page">
        <b-container fluid class="px-0">
          <b-row>
            <!-- property details images and content section -->
            <b-col lg="6" xl="8" class="order-1 order-lg-1">
              <b-card no-body class="bg-transparent border-0 rounded-0 form-group">
                <b-card-body class="p-0">
                  <property-media
                    :description="propertyDetails.description"
                    :propertyMedia="propertyDetails.property_images"
                  ></property-media>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- property address section -->
            <b-col lg="6" xl="4" class="order-2 order-lg-2">
              <b-card no-body class="custom-card form-group">
                <b-card-body>
                  <div class="property-facilities">
                    <b-row>
                      <b-col
                        cols="6"
                        lg="6"
                        xl="4"
                        v-for="(facility, fIndex) in data.propertyFacilities"
                        :key="fIndex"
                      >
                        <b-media class="facility-media">
                          <template #aside>
                            <img :src="getIconUrl(facility.icon)" :alt="facility.name" />
                          </template>
                          <p class="mb-0">
                            {{
                              (propertyFacilities[facility.id - 1] || 0)
                            }}
                          </p>
                        </b-media>
                      </b-col>
                    </b-row>
                  </div>
                  <p class="text-grey mb-2">
                    {{ lang.label.maintenanceFee + " " + getMaintenanceAmount }}
                  </p>
                  <b-row>
                    <b-col>
                      <label class="mb-0 d-block">{{ lang.label.leaseAmount }}</label>
                      <p class="property-amount my-2 mb-0">
                        {{ getLeaseAmount
                        }}<span class="bf400"
                          >/{{
                            propertyDetails.tenant_detail &&
                            propertyDetails.tenant_detail.payment_frequency
                            ? paymentFrequencyOption[propertyDetails.tenant_detail.payment_frequency_id]
                              : lang.label.monthly
                          }}</span
                        >
                      </p>
                    </b-col>
                    <b-col class="text-nowrap">
                      <label class="mb-0 d-block">{{ lang.label.advanceAmount }}</label>
                      <p class="property-amount my-2 mb-0">
                        {{ getDepositAmount }}
                      </p>
                    </b-col>
                  </b-row>
                  <div class="address-map">
                    <div class="address-iframe">
                      <!-- Google Map Section -->
                      <google-map
                        :location="propertyDetails.coordinates"
                        :isViewOnly="true"
                      ></google-map>
                    </div>
                    <div class="mb-0 address-text">
                      <div class="d-flex align-items-center">
                        <span class="material-icons secondary-text mr-1">place</span>
                        <span>{{ propertyDetails.address || "N/A" }}</span>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- property amenities section -->
            <b-col lg="6" xl="8" class="order-5 order-lg-3">
              <b-card no-body class="bg-transparent border-0 rounded-0 form-group">
                <b-card-body class="p-0">
                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="mb-0 sf-size bf700">
                      {{ lang.label.amenities }}
                    </h5>
                  </div>
                  <div class="mt-3">
                    <property-amenities
                      :selectedAmenities="propertyDetails.amenities"
                      :limit="amenitiesLimit"
                    ></property-amenities>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>

            <!--contact owner section -->
            <b-col lg="6" xl="4" class="order-4 order-lg-6">
              <b-card no-body class="custom-card form-group">
                <b-card-body>
                  <div class="property-manager border-0">
                    <div class="manager-section">
                      <h5 class="mng-title sf-size bf700">
                        {{ lang.label.contactOwner }}
                      </h5>
                      <div class="">
                        <ValidationObserver ref="inquiryFormObserver" v-slot="{ passes }">
                          <b-form
                            @submit.stop.prevent="passes(onSubmitContactOwner)"
                            autocomplete="off"
                          >
                            <b-container fluid class="px-0 pb-fix-btn">
                              <b-row>
                                <b-col cols="6">
                                  <b-form-group>
                                    <label class="form-label">{{
                                      lang.label.form.label.firstName
                                    }}<span class="text-danger">*</span></label>
                                    <ValidationProvider
                                      :name="lang.label.form.validation.firstName"
                                      rules="required|max:50"
                                      v-slot="validationContext"
                                    >
                                      <div class="position-relative">
                                        <b-form-input
                                          v-model="contactModel.firstName"
                                          type="text"
                                          :state="getValidationState(validationContext)"
                                          :placeholder="lang.label.form.placeholder.enter"
                                        ></b-form-input>
                                        <b-form-invalid-feedback
                                          >{{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                      </div>
                                    </ValidationProvider>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="6">
                                  <b-form-group>
                                    <label class="form-label">{{
                                      lang.label.form.label.lastName
                                    }}<span class="text-danger">*</span></label>
                                    <ValidationProvider
                                      :name="lang.label.form.validation.lastName"
                                      rules="required|max:50"
                                      v-slot="validationContext"
                                    >
                                      <div class="position-relative">
                                        <b-form-input
                                          v-model="contactModel.lastName"
                                          type="text"
                                          :state="getValidationState(validationContext)"
                                          :placeholder="lang.label.form.placeholder.enter"
                                        ></b-form-input>
                                        <b-form-invalid-feedback
                                          >{{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                      </div>
                                    </ValidationProvider>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="12">
                                  <b-form-group>
                                    <label class="form-label">{{
                                      lang.label.form.label.email
                                    }}<span class="text-danger">*</span></label>
                                    <ValidationProvider
                                      :name="lang.label.form.validation.email"
                                      rules="required|email"
                                      v-slot="validationContext"
                                    >
                                      <div class="position-relative">
                                        <b-form-input
                                          v-model="contactModel.email"
                                          type="text"
                                          class="text-lowercase"
                                          :state="getValidationState(validationContext)"
                                          :placeholder="lang.label.form.placeholder.email"
                                        ></b-form-input>
                                        <!-- :disabled="isEdit?true:false" -->
                                        <b-form-invalid-feedback
                                          >{{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                      </div>
                                    </ValidationProvider>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="12">
                                  <b-form-group>
                                    <label class="form-label">{{
                                      lang.label.form.label.contactNumber
                                    }}<span class="text-danger">*</span></label>
                                    <ValidationProvider
                                      :name="lang.label.form.validation.contactNumber"
                                      rules="required|numeric|min:6|max:8"
                                      v-slot="validationContext"
                                    >
                                      <div
                                        class="position-relative prepend-icon prepend-text country-code"
                                      >
                                        <b-form-input
                                          v-model="contactModel.contactNumber"
                                          type="text"
                                          :state="getValidationState(validationContext)"
                                          :placeholder="
                                            lang.label.form.placeholder.contactNumber
                                          "
                                        ></b-form-input>
                                        <div class="icon">
                                          <div class="pre-app-text primary-text bf-size">
                                            +507
                                          </div>
                                        </div>
                                        <b-form-invalid-feedback
                                          >{{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                      </div>
                                    </ValidationProvider>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="12">
                                  <b-form-group>
                                    <label class="form-label">{{
                                      lang.label.form.label.message
                                    }}<span class="text-danger">*</span></label>
                                    <ValidationProvider
                                      :name="lang.label.form.validation.message"
                                      rules="required"
                                      v-slot="validationContext"
                                    >
                                      <div class="position-relative">
                                        <b-form-textarea
                                          v-model="contactModel.message"
                                          type="text"
                                          rows="3"
                                          :state="getValidationState(validationContext)"
                                          :placeholder="
                                            lang.label.form.placeholder.message
                                          "
                                        ></b-form-textarea>
                                        <b-form-invalid-feedback
                                          >{{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                      </div>
                                    </ValidationProvider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-container>
                            <div class="g-recaptcha" data-sitekey="your-key"></div>
                            <div class="add-contact-button">
                              <b-btn variant="primary" block type="submit">{{
                                lang.label.buttons.send
                              }}</b-btn>
                            </div>
                          </b-form>
                        </ValidationObserver>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import jsondata from "@/assets/json/data.json";
import PropertyMedia from "./details/PropertyMedia.vue";
import PropertyAmenities from "./details/PropertyAmenities.vue";
import FilterForm from "../Reports/FilterForm.vue";
import ReportList from "../Reports/List.vue";
import { mapGetters } from "vuex";
import alertSection from "@/views/shared/AlertSection.vue";
import mixin from "@/mixins/mixin";
import property_mixin from "@/mixins/property_mixin";
import GoogleMapSection from "@/views/shared/GoogleMapSection.vue";
import Swal from "sweetalert2";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexChart", VueApexCharts);
export default {
  name: "PublicPropertiesDetails",
  components: {
    "property-media": PropertyMedia,
    "property-amenities": PropertyAmenities,
    "filter-form": FilterForm,
    "report-list": ReportList,
    "alert-section": alertSection,
    "google-map": GoogleMapSection,
  },
  mixins: [mixin, property_mixin],
  computed: {
    ...mapGetters({
      loading: "loading",
      ImageUrl: "ImageUrl",
      filterpayloads: "filterpayloads",
      login: "login",
      staffpermissions: "staffpermissions",
    }),
    /**
     * @returns created date of property
     */
    getAddedDate() {
      return this.propertyDetails.created_date
        ? this.formatDateToShow(this.propertyDetails.created_date)
        : "N/A";
    },
    /**
     * @returns Assigned realtor's name
     */
    getRealtorName() {
      if (this.propertyDetails.realtor_details?.first_name) {
        return this.propertyDetails.realtor_details.first_name + " " + this.propertyDetails.realtor_details.last_name;
      } else if (this.propertyDetails.realtor_details?.name) {
        return this.propertyDetails.realtor_details.name;
      } else {
        return "N/A";
      }
    },
    /**
     * @returns Assigned realtor's contact with formated string
     */
    getRealtorContact() {
      let number =
        this.propertyDetails.realtor_details &&
        this.propertyDetails.realtor_details.contact
          ? this.propertyDetails.realtor_details.contact
          : null;
      let formatedNumber = "N/A";
      if (number) {
        let cleaned = ("" + number).replace(/\D/g, "");
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          let intlCode = match[1] ? "+1 " : "";
          formatedNumber = ["+", intlCode, match[2], " ", match[3], "-", match[4]].join(
            ""
          );
        }
      }
      return formatedNumber;
    },
    /**
     * @returns Assigned realtor's email
     */
    getRealtorEmail() {
      return this.propertyDetails.realtor_details &&
        this.propertyDetails.realtor_details.email
        ? this.propertyDetails.realtor_details.email
        : "N/A";
    },
    /**
     * @returns Assigned tenant's name
     */
    getTenantName() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.first_name
        ? this.propertyDetails.tenant_detail.first_name +
            " " +
            this.propertyDetails.tenant_detail.last_name
        : "N/A";
    },
    /**
     * @returns Assigned tenant's lease start date
     */
    getLeaseStartDate() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.start_date
        ? this.formatDateToSlash(this.propertyDetails.tenant_detail.start_date)
        : "N/A";
    },
    /**
     * @returns Assigned tenant's lease end date
     */
    getLeaseEndDate() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.end_date
        ? this.formatDateToSlash(this.propertyDetails.tenant_detail.end_date)
        : "N/A";
    },
    /**
     * @returns Assigned tenant's rend due date
     */
    getRentDueDate() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.next_due_date
        ? this.formatDateStringToDate2(this.propertyDetails.tenant_detail.next_due_date)
        : "N/A";
    },
    /**
     * @returns Tenant lease agreement document
     */
    getLeaseDoc() {
      return this.propertyDetails.tenant_detail &&
        this.propertyDetails.tenant_detail.lease_agreement
        ? this.ImageUrl + this.propertyDetails.tenant_detail.lease_agreement
        : null;
    },
  },
  data() {
    return {
      lang: this.$lang.property_details,
      data: jsondata,
      // line chart
      height: "150px",
      series: [
        {
          name: "Income",
          data: [],
        },
        {
          name: "Expense",
          data: [],
        },
      ],
      propertyID: "",
      chartOptions: {
        chart: {
          type: "line",
          width: "100%",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 991,
            options: {
              chart: {
                height: 230,
              },
            },
          },
          {
            breakpoint: 1550,
            options: {
              chart: {
                height: 230,
              },
            },
          },
        ],
        dataLabels: {
          enabled: false,
        },
        legend: {
          formatter: function (seriesName, opts) {
            let sum = opts.w.globals.series[opts.seriesIndex].reduce(
              (partialSum, a) => partialSum + a,
              0
            );
            return [opts.seriesIndex == 0 ? "+" : "-", sum];
          },
        },
        stroke: {
          width: [1.5, 1.5],
          curve: "straight",
          dashArray: [0, 0],
        },
        colors: ["#5734D6", "#295E73"],
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
          categories: [
            "Dec 31, 2021",
            "Jan 31, 2022",
            "Feb 28, 2022",
            "Mar 31, 2022",
            "Apr 30, 2022",
            "May 31, 2022",
            "Jun 30, 2022",
            "Jul 31, 2022",
            "Aug 31, 2022",
            "Sep 30, 2022",
            "Oct 31, 2022",
            "Nov 30, 2022",
            "Dec 31, 2022",
          ],
          labels: {
            style: {
              cssClass: "apexcharts-xaxis-label-custom",
            },
            rotateAlways: false,
          },
          axisTicks: {
            show: false,
          },

          //       labels: {
          //   formatter: function (val) {
          //               return val + " (mins)"
          //             }
          // }
          //   labels: {
          //     show: false
          //   }
        },
        tooltip: {
          enabled: false,
        },
        yaxis: {
          // labels: {
          //     show: false,
          // }
          tickAmount: 2,
        },
        grid: {
          show: true,
          borderColor: "#DDDDDD",
          strokeDashArray: 1,
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      propertyDetails: { amenities: [] },
      propertyFacilities: [],
      userType: {
        1: "realtor_details",
        2: "owner_details",
      },
      graphAvailable: true,
      selectedContact: {},
      models: {},
      contactModel: {
        firstName: "",
        lastName: "",
        contactNumber: "",
        email: "",
        message: "",
      },
      maintenanceFee: null,
      leaseDocument: [],
      tenantDetails: [],
      fileFormat: {},
      amenitiesLimit: 0,
    };
  },
  created() {
    let self = this;
    self.copyURLToClipboard();
    self.getPropertyID();
    self.viewProperty();
  },
  mounted() {
    document.body.classList.add("public-detail-link");
  },
  destroyed() {
    this.$store.commit("setFilterPayload", null);
    document.body.classList.remove("public-detail-link");
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/styles/img/" + img);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    /**
     * Submit the details of contact owner form
     */
    onSubmitContactOwner() {
      let self = this;
      let id = self.propertyID;
      let data = {
        property: id,
        first_name: self.contactModel.firstName,
        last_name: self.contactModel.lastName,
        email: self.contactModel.email.toLocaleLowerCase(),
        contact_number: self.contactModel.contactNumber,
        message: self.contactModel.message,
      };
      self.$store.dispatch("propertyInquiry", data).then((response) => {
        self.contactModel = {};
        self.$refs["inquiryFormObserver"].reset();
        if (response.status == 200) {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "success",
              message: message,
            });
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Handles fetching property details via API
     */
    viewProperty() {
      let self = this;
      let id = self.propertyID;
      self.$store.dispatch("viewPropertyPublic", id).then((response) => {
        if (response.status == 200) {
          self.propertyDetails = response.data;
          self.handleProertyFacilities();
          self.handleGraphData();
          self.handleRentDetails();
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Handles property facilities data
     */
    handleProertyFacilities() {
      let self = this;
      self.propertyFacilities = [];
      self.propertyFacilities.push(self.lang.label.propertyFacilities.livableSpace + " " + self.propertyDetails.approx + " mt2");
      self.propertyFacilities.push(self.lang.label.propertyFacilities.area + " " + self.propertyDetails.area + " mt2");
      self.propertyFacilities.push(self.lang.label.propertyFacilities.construction + " " + self.propertyDetails.year);
      self.propertyFacilities.push(self.propertyDetails.bedrooms + " " + self.lang.label.propertyFacilities.bedrooms);
      self.propertyFacilities.push(
        self.propertyDetails.bathrooms + " " + self.lang.label.propertyFacilities.bathrooms
      );
      self.propertyFacilities.push(self.propertyDetails.parking + " " + self.lang.label.propertyFacilities.parkings);
    },
    /**
     * Handles graph values for income and expense
     */
    handleGraphData() {
      let self = this;
      self.graphAvailable = false;
      self.series[0].data = self.propertyDetails.income;
      self.series[1].data = self.propertyDetails.expense;
      setTimeout(() => {
        self.graphAvailable = true;
      }, 0);
    },
    handleRentDetails() {
      let self = this;
      self.maintenanceFee = self.propertyDetails.maintenance_fee;
      self.models = {
          paymentFrequency:
            self.propertyDetails.payment_frequency_id,
          rentAmount: self.propertyDetails.rent_amount,
          depositAmount: self.propertyDetails.security_amount
      };
    },
    /**
     * Get the decrypted property id
     */
    getPropertyID() {
      let self = this;
      self.propertyID =
        self.$route.name === "public-properties-details"
          ? self.decryptID(self.$route.params.id)
          : self.$route.params.id;
      if (!self.propertyID) {
        self.$router.push({ name: "not-found" });
      }
    },
    /**
     * Copied the public link in clipboard
     */
    copyURLToClipboard() {
      let self = this;
      if (self.$route.query.isCopied) {
        let message = self.lang.label.copyLinkText;
        if (message) {
          self.$store.commit("setMessages", {
            type: "success",
            message: message,
          });
        }
        setTimeout(() => {
          self.$router.replace({'query': null});
        }, 4000);
      }
    },
  },
};
</script>
