module.exports = {
    label: {
      changeProfilePic: 'Cambiar La Foto Del Perfil',
      companyImage: 'Subir Imágenes De La Empresa',
      identityDocument: 'Documento De Identidad',
      changeDocument: 'Cambiar Documento De Identidad',
      uploadDocument : 'Cambiar Documento De Identidad',
      viewDocument: 'Ver Documento',
      companyProfile: 'Company Profile',
      areYouSure: '¿Está seguro de que desea eliminar esta imagen?',
      confirmTitle: '¿Estas Seguro?',
      confirmMsg: '¿Quieres borrar esto?',
      extensionValidation:'Sólo se permiten las extensiones .jpeg, .jpg, .png .pdf',
      extensionValidationImage:'Sólo se permiten las extensiones .jpeg .jpg y .png',
      companyImageSizeValidation:'Uno de sus archivos tiene un tamaño superior a 10 MB',
      noFileChosen: "Documento no seleccionado",
      form: {      
        label: {
          firstName: 'Nombre',
          lastName: 'Apellido',
          email: 'Correo Electrónico',
          contactNumber: 'Número De Teléfono',
          contactType: 'Contact Type',
          address: 'Dirección',
          city: 'Ciudad',
          state: 'Provincia',
          country: 'País',
          zipCode: 'Código Postal',
          brokerId: 'ID De Agente Inmobiliario',
          companyName:'Nombre De La Empresa',
          companyEmail:'Correo Electrónico De La Empresa'
        },
        validation: {
          firstName: 'nombre',
          lastName: 'apellido',
          email: 'electrónico',
          contactNumber: 'número de teléfono',
          contactType: 'tipo de contacto',
          address: 'dirección',
          city: 'ciudad',
          state: 'provincia',
          country: 'país',
          zipCode: 'código postal',
          brokerId: 'id de agente inmobiliario',
          companyName:'nombre de empresa',
          companyEmail:'correo electronico de la empresa'
        },
        placeholder: {
          enterfname: 'Nombre',
          enterlname:'Apellido',
          email: 'example@mail.com',
          enterNumber: 'Ingrese El Número',
          select: 'Seleccionar',
          city:'Ciudad',
          brokerId: 'ID De Agente Inmobiliario',
          searchContact: 'Buscar Contacto',
          contactNumber: 'Número De Teléfono',
          address: 'Dirección',
          zipCode: 'Código Postal',
          brokerIdNote: '*Primera Parte 2 Letras - Segunda Parte 4 numeros, ej: ab-1234',
          companyName:'Nombre De La Empresa',
          companyEmail:'Correo Electrónico De La Empresa'
        }
      },
      buttons: {
        update: 'Actualizar',
        removeImage: 'Borrar Imagen',
        yes: 'Sí',
        no: 'No',
        ok : 'Aceptar'
      },
      tooltip:{
        document : 'Documento De Identidad'
      }
    }
  };