<!-- content from bank_information.js -->
<template>
  <div class="bank-info-card">
    <p class="text-center" style="color:#707070 !important" v-if="saveCards && saveCards.length == 0">{{ lang.label.noRecord }}</p> 
    <b-row>
      <b-col md="6" lg="4" v-for="(card, index) in saveCards" :key="index">
        <b-card no-body class="card-with-border h-100">
          <b-card-body>
            <div class="info">
              <div class="d-flex justify-content-between align-items-center">
                <span class="mr-3 word-break">{{ card.card_number }}</span>
                  <div>
                    <div class="check-radio">
                      <b-form-radio
                        v-model="card.is_default"
                        name="card"
                        :value="true"
                        @change="defaultCard(card.id)"
                        :disabled="login.user_type_id == 3 && !staffpermissions.includes('update_card')"
                        >{{ lang.label.cardInformation }}</b-form-radio
                      >
                    </div>
                </div>
              </div>
              <div class="bank-details">
                <h6 class="bf-size mt-3 pt-1 mb-0">
                  {{ card.first_name }} {{ card.last_name }}
                </h6>
                <span class="d-block mt-3">{{ lang.label.expiry }}: {{ card.expiry }}</span>
                <div class="d-flex justify-content-between align-items-center ">
                  <div>
                    <address class="bf-size pt-1 mb-0 d-block">
                      {{ card.address }}, {{ card.city }}, {{ card.state }}, {{ card.country }}<span v-if="card.zip_code">, {{ card.zip_code }}</span>
                    </address>
                  </div>
                  <!-- update_card -> key-> For future references -->
                  <el-tooltip :content="lang.label.tooltip.deleteCard" placement="top">
                    <b-btn variant="link" class="p-0"
                      ><span
                        class="material-icons"
                        @click="deleteCard(card.id)"
                        :hidden="
                          login.user_type_id == 3 &&
                          staffpermissions.length > 0 &&
                          !staffpermissions.includes('delete_card')
                        "
                        >delete</span
                      ></b-btn
                    >
                  </el-tooltip>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>

      </b-col>
    </b-row>

    <!-- add bank details -->
    <b-sidebar
      id="addCard"
      footer-class="sidebar-footer"
      sidebar-class="sidebar-view"
      text-variant="none"
      backdrop
      right
      width="462px"
      @change="updateSidebar"
      :visible="isVisible"
    >
      <template #header="{ hide }">
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex align-items-center">
            <b-btn variant="link" size="sm" class="p-0" @click="hide">
              <img :src="getIconUrl('close-sm.svg')" alt="close" />
            </b-btn>
            <h4 class="sidebar-header-title mb-0">
              {{ lang.label.addNewCard }}
            </h4>
          </div>
        </div>
      </template>
      <div class="amenity-body" v-if="isVisible">
        <add-card :isVisible="isVisible" @handleCardAdded="handleCardAdded"></add-card>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import AddCard from "@/components/bank/AddCard.vue";
import { mapGetters } from "vuex";
import Swal from 'sweetalert2';

export default {
  props: ["isAddCard"],
  components: {
    "add-card": AddCard,
  },
  data() {
    return {
      lang: this.$lang.bank_information,
      data: jsondata,
      selected: "",
      isVisible: false,
      saveCards: [],
    };
  },
  computed: {
    ...mapGetters({
      login: "login",
      staffpermissions: "staffpermissions",
    }),
  },
  mounted() {
    this.listOfcards();
    this.updateSidebar();
  },
  watch: {
    isAddCard(val) {
      if (val) {
        this.updateSidebar(true);
        this.$emit("initializedAdd");
      }
    },
  },
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    /**
     * List of payment cards
     * @param {number} cardId Added card id
     */
     listOfcards(cardId, recursiveCounter) {
      let self = this;
      let counter = recursiveCounter || 0;
      self.$store.dispatch("listOfCards").then((response) => {
        if (response.status == 200) {
          self.saveCards = response.data;
          self.recursiveListCards(cardId, counter);
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Handles calling list API recursively
     * @param {number} cardId Added card id
     * @param {number} counter Recursive counter number
     */
    recursiveListCards(cardId, counter) {
      let self = this;
      let limit = 5;
      if (cardId && counter < limit) {
        let addedCard = self.saveCards.filter(el => el.id == cardId);
        if (addedCard.length > 0 && !addedCard[0].card_number) {
          setTimeout(() => {
            counter = counter + 1;
            self.listOfcards(cardId, counter);
          }, 1000);
        }
      }
    },
    /**
     * Delete Payment Cards
     * @param {number} id Card ID
     */
    deleteCard(id) {
      let self = this
      Swal.fire({
          title: self.lang.label.modal.bankDelete,
          text: self.lang.label.modal.cardDelete,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: self.lang.label.buttons.yes,
          cancelButtonText: self.lang.label.buttons.no,
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            self.$store.dispatch('deleteCard', id).then((response) =>{
              if(response.status == 200){
                Swal.fire(this.$lang.root.label.deleted, response.data.message, "success");
                self.listOfcards()
              }
              else{
                let message = response.data.message || response.data.detail;
                if (message) {
                  self.$store.commit("setMessages", {
                  type: "danger",
                  message: message,
                  });
                }
              }
            })
          }
        });
    },
    updateSidebar(val) {
      this.isVisible = val;
      const body = document.getElementsByTagName("body")[0];
      if (val) {
        body.classList.add("overflow-hidden");
      } else {
        body.classList.remove("overflow-hidden");
      }
    },
    /**
     * Handles card added event
     * @param {number} cardId Added card id
     */
    handleCardAdded(cardId) {
      let self = this
      self.$store.commit('setLoading', true);
      setTimeout(function(){
        self.listOfcards(cardId);
        self.updateSidebar(false);
        self.$store.commit('setLoading', false);
      },5500)
    },
    /**
     * Sets default Card
     * @param {number} id
     */
    defaultCard(id) {
      let self = this;
      self.$store.dispatch("defaultCard", id).then((response) => {
        if (response.status == 200) {
          self.$store.commit("setMessages", {
            type: "success",
            message: response.data.message,
          });
          self.listOfcards();
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
  },
};
</script>
