module.exports = {
  label: {
    setPassword: "Set Password",
    alreadyAccount: "Already have a account?",
    signIn: "Sign In",
    slogan: "You’ll never need another property management platform",
    sentLink: "We have sent you the password reset link to Your registered email id.",
    form: {
      email: 'Email',
      validation: {
        email: 'Email',
        password: 'password',
        confirmPassword: 'confirm password',
      },
      placeholder: {
        email: 'Email',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        passwordFormateNote: '*Length 8 or longer, includes at least one capital, small, special character and one number. Ex: Miradoor123*',
      }
    },
    buttons: {
      continue: 'Continue',
    }
  }
};