<!-- content from header.js -->
<template>
  <b-navbar
    toggleable="lg"
    type="dark"
    fixed="top"
    class="header"
    v-if="!showHeader.includes(this.$route.name)"
  >
    <b-btn
      variant="link"
      class="p-0 menu-btn"
      v-b-toggle.menuSidebar
      v-if="$route.name != 'public-properties-details'"
    >
      <img :src="getIconUrl('menu.svg')" alt="image" />
    </b-btn>
    <div class="header-logo-responsive">
      <router-link to="/dashboard">
        <img :src="getImgUrl('logo_white.svg')" alt="miradoor" />
      </router-link>
    </div>
    <!--<div
      class="header-search-box"
      v-if="$route.name != 'public-properties-details'"
    >
      <div class="position-relative prepend-icon">
        <b-form-input
          v-model="search"
          type="text"
          :placeholder="lang.label.search"
        ></b-form-input>
        <div class="icon">
          <div class="pre-app-text cursor-pointer">
            <img :src="getIconUrl('search.svg')" alt="" />
          </div>
        </div>
      </div>
    </div>-->

    <!-- top menu icons actions -->
    <b-navbar-nav
      class="ml-xl-auto align-items-center"
      v-if="$route.name != 'public-properties-details'"
    >
      <template v-for="(nav, index) in data.navbarRightActions">
        <b-nav-item
          class="nav-icon"
          :to="nav.url"
          :key="index"
          v-if="nav.dropdown == false"
        >
        <el-tooltip :content="lang.label.tooltip[nav.text]" placement="bottom">
          <div>
              <img :src="getIconUrl(nav.icon)" alt="" />
              <!-- {{
                nav.text == "Messages" ? login.unread_messages_count || null : null
              }} -->
              <b-badge variant="danger" pill class="notification-counts" v-if="login.unread_messages_count>0 && nav.text=='Messages'">
                {{login.unread_messages_count>100?'99+':login.unread_messages_count}}
              </b-badge>
          </div>
          </el-tooltip>
        </b-nav-item>
        <b-nav-item-dropdown
          right
          no-caret
          class="notification-dropdown"
          :class="notificationsHeader.length < 1 ? 'no-notification' : ''"
          :key="index + '1'"
          v-else
        >
          <template #button-content>
            <el-tooltip :content="lang.label.tooltip.Notifications" placement="bottom">
              <div @click="getNotifications(false)">
                <img :src="getIconUrl(nav.icon)" alt="" />
                <b-badge variant="danger" pill class="notification-counts" v-if="login.unread_notification_count>0">{{
                  login.unread_notification_count>100?'99+':login.unread_notification_count
                }}</b-badge>
            </div>
          </el-tooltip>
          </template>
          <b-dropdown-text
            :class="notification.is_read == false ? 'read' : ''"
            tag="div"
            v-for="(notification, notificationsIndex) in notificationsHeader"
            :key="notificationsIndex"
            @click="
              notification.is_read == false
                ? readNotification(notification.id)
                : ''
            "
            class="cursor-pointer cursor"
          >
            <h6 class="notification-title">{{ notification.message }}</h6>
            <p class="mb-0 notification-time">
              {{ notification.created_date }}
            </p>
          </b-dropdown-text>
          <b-dropdown-item-button
            variant="primary"
            class="all-notifocation-show"
            v-if="notificationDataCount > 5"
            @click="$route.name != 'notifications' ? goNotifications() : ''"
            >{{ lang.label.viewMore }}</b-dropdown-item-button
          >
        </b-nav-item-dropdown>
      </template>

      <!-- user dropdown -->
      <b-nav-item-dropdown right class="user-dropdown d-none d-lg-block">
        <template #button-content>
          <img :src="getIconUrl('person.svg' , login.profile_image)" alt="" />
          <span class="username">{{ login.first_name }}</span>
        </template>
        <template v-for="(user, i) in data.userDropdown">
          <b-dropdown-item
            v-if="
              !user.exclude_user_type ||
              (user.exclude_user_type &&
                user.exclude_user_type != login.user_type_id)
            "
            :to="user.url"
            :key="i"
            >{{ language == 'en' ? user.name : user.name_es }}</b-dropdown-item
          >
        </template>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-navbar-nav class="public-ml-auto align-items-center" v-else>
      <!-- user dropdown -->
      <div right class="nav-link">
        <template v-if="login && !login.token">
          <router-link :to="{ name: 'login' }">
            <img :src="getIconUrl('person.svg')" alt="" />
            <span class="username text-white ml-2">{{ lang.label.login }}</span>
          </router-link>
        </template>
      </div>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import { mapGetters } from "vuex";
import notification_mixin from "../../mixins/notification_mixin";
export default {
  name: "DashboardPage",
  components: {},
  mixins: [notification_mixin],
  data() {
    return {
      lang: this.$lang.header,
      data: jsondata,
      showHeader: [
        "login",
        "forgot-password",
        "reset-password",
        "invite-user",
        "sign-up",
        "add-bank-details",
        "request-bank-detail",
        "not-found",
      ],
      search: "",
      notifications: []
    };
  },
  computed: {
    ...mapGetters({
      login: "login",
      ImageUrl: "ImageUrl",
      language: "language"
    }),
  },
  mounted() {
    this.getNotifications(false);
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/styles/img/" + img);
    },
    getIconUrl(icon, image) {
      return image ? this.ImageUrl + image : require("@/assets/styles/img/icons/" + icon);
    },
    /**
     * To redirect to notifications page
     */
     goNotifications() {
       let self = this;
       self.readNotification(0, true);
       self.$router.push({ name: "notifications" });
    },
    /**
     * To list out only 5 notifications
     */
    getNotifications(flag = false) {
      let self = this;
      let data = {
        size: flag ? 100000 : 5,
        page: 1,
      };
      // to disable api call for notification 
      if(self.$route.name=='public-properties-details'){
        return;
      }
      if (self.login.token) {
        self.getAllNotifications(data, flag ? "notification" : "header");
      }
    },
    /**
     * To read out all notifications or single notifications 
     */
    readNotification(id, flag = false) {
      let self = this;
      let data = {
        notification_id: id,
        read_all: flag,
      };
      if (id == 0 || id != "") {
        self.$store.dispatch("readNotification", data).then((response) => {
          if (response.status == 200) {
            self.getNotifications(flag ? true : false);
          } else {
            let message = response.data.message || response.data.detail;
            if (message) {
              self.$store.commit("setMessages", {
                type: "danger",
                message: message,
              });
            }
          }
        });
      }
    },
  },
};
</script>
