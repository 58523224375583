module.exports = {
  label: {
    reportTitle: "Report",    
    selectProperty: 'Select Property',
    dateFilter: 'Date Filter',
    from: 'From',
    to: 'To',
    selectBillingType: 'Select Billing Type',
    warning: 'Warning',
    propertySelectionAlert: 'Please select property first',
    expense: 'Expense',
    income: 'Income',
    tableHeader: {
      address: "Property Address",
      name: "Tenant Name",
      ownerName: "Owner",
      leaseDuration: "Lease Duration",
      paidDateTime: "Paid Date",
      billingType: "Billing Type",
      amount: "Amount",
      expenseName: "Expense Name",
      miscellaneous: "Miscellaneous",
      type: "Property Type",
      actions: "Actions",
    },
    buttons: {
      addExpense: 'Add Expense',
      filter: 'Apply Filter',
      clearFilter: 'Clear Filter',
      emailReport: 'Email Report',
      download: 'Download',
      ok: 'Ok',
    },
    tabs: {
      all: 'All',
      ownerBroker: 'Owner/Realtor',
      broker: 'Realtor',
      tenant: 'Tenant',
      owner: "Owner",
      other: 'Other'
    },
  }
};