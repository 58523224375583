<!-- content from reports.js -->
<template>
  <div class="body-content">
    <div class="top-sticky">
      <div class="top-bar-radius"></div>
    </div>
    <div class="starts-section" v-loading="loading">
      <!-- alert component -->
      <alert-section></alert-section>
      <!-- body content header  -->
      <div class="page-breadcrumb">
        <div class="d-md-flex justify-content-between align-items-center">
          <div class="pg-bread">
            <b-breadcrumb class="bg-transparent p-0 mb-0">
              <b-breadcrumb-item active>
                {{ lang.label.reportTitle }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </div>
      </div>
      <div class="pb-3">
        <filter-form
          :activeTab="activeName"
          @handleApiCallTenant="handleApiCallTenant"
          @download="download"
        ></filter-form>
        <hr class="mt-0" />
        <div class="pb-3" v-if="login.user_type_id == 3">
          <report-list
            :tableData="tableData"
            :columns="columnNames[activeName]"
            :options="options"
            :optionsPrint="optionsPrint"
            propertyViewRoute="properties-details"
            v-if="activeName == 'ownerBroker'"
            ref="reportList"
          ></report-list>
        </div>
        <div class="pb-3" v-else-if="isUserOnlyTenant">
          <report-list
            :tableData="tableData"
            :columns="columnNames[activeName]"
            :options="options"
            :optionsPrint="optionsPrint"
            propertyViewRoute="rented-properties-details"
            v-if="activeName == 'tenant'"
            ref="reportList"
          ></report-list>
        </div>
        <div class="pb-3" v-else>
          <el-tabs v-model="activeName">
            <el-tab-pane :label="lang.label.tabs.ownerBroker" name="ownerBroker">
              <report-list
                :tableData="tableData"
                :columns="columnNames[activeName]"
                :options="options"
                :optionsPrint="optionsPrint"
                propertyViewRoute="properties-details"
                v-if="activeName == 'ownerBroker'"
                ref="reportList"
              ></report-list>
            </el-tab-pane>
            <el-tab-pane :label="lang.label.tabs.tenant" name="tenant">
              <report-list
                :tableData="tableData"
                :columns="columnNames[activeName]"
                :options="options"
                :optionsPrint="optionsPrint"
                propertyViewRoute="rented-properties-details"
                v-if="activeName == 'tenant'"
                ref="reportList"
              ></report-list>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";

import FilterForm from "./Reports/FilterForm.vue";
import ReportList from "./Reports/ReportList.vue";
import { mapGetters } from "vuex";
import mixin from "@/mixins/mixin";
import alertSection from "@/views/shared/AlertSection.vue";

export default {
  components: {
    "filter-form": FilterForm,
    "report-list": ReportList,
    "alert-section": alertSection,
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      login: "login",
      isUserOnlyTenant: "isUserOnlyTenant",
    }),
  },
  mixins: [mixin],
  data() {
    return {
      lang: this.$lang.reports,
      data: jsondata,
      selected: "",
      PayTracker: [
        { text: "Expense", value: "expense" },
        { text: "Income", value: "income" },
      ],
      statusSelected: "",
      statusOption: [
        { text: "Available", value: "available" },
        { text: "Rented", value: "rented" },
      ],
      dateRangeSelected: "",
      billingStatus: "",
      bilingType: [
        { text: "Monthly", value: "monthly" },
        { text: "Quarterly", value: "quarterly" },
        { text: "Half yearly", value: "hafly" },
        { text: "Yearly", value: "yearly" },
      ],
      activeName: "ownerBroker",
      columnNames: {
        ownerBroker: [
          "address",
          "name",
          "leaseDuration",
          "paidDateTime",
          "billingType",
          "amount",
          "expenseName",
          "miscellaneous",
          "actions",
        ],
        tenant: [
          "address",
          "type",
          "name",
          "leaseDuration",
          "paidDateTime",
          "billingType",
          "amount",
          "miscellaneous",
          "actions",
        ],
      },      
      options: {
        headings: {
          address: this.$lang.reports.label.tableHeader.address,
          name: this.$lang.reports.label.tableHeader.name,
          leaseDuration: this.$lang.reports.label.tableHeader.leaseDuration,
          paidDateTime: this.$lang.reports.label.tableHeader.paidDateTime,
          billingType: this.$lang.reports.label.tableHeader.billingType,
          amount: this.$lang.reports.label.tableHeader.amount,
          expenseName: this.$lang.reports.label.tableHeader.expenseName,
          miscellaneous: this.$lang.reports.label.tableHeader.miscellaneous,
          actions: "",
        },
        skin: "table",
        perPageValues: [8, 15, 25],
        perPage: 8,
        sortable: ["paidDateTime"],
        filterable: ["name", "address", "leaseDuration",'amount'],
        columnsClasses: {
          address: "address-width",
          tenant: "d-none d-md-table-cell ",
          leaseDuration: "d-none d-md-table-cell ",
          paidDateTime: "d-none d-md-table-cell ",
          billingType: "d-none d-md-table-cell ",
          actions: "expense-buttons ",
        },
        pagination: { chunk: 8 },
        texts: {
          noResults: this.$lang.root.label.noRecord,
          filter: this.$lang.root.label.filter,
          filterPlaceholder: this.$lang.root.label.filterPlaceholder,
          limit: this.$lang.root.label.limit,
          count: this.$lang.root.label.count,
        },
        customSorting: {
          paidDateTime: function (ascending) {
            return function (a, b) {
              let dateA = new Date(a.date);
              let dateB = new Date(b.date);
              if (ascending) {
                return dateA >= dateB ? 1 : -1;
              } else {
                return dateA < dateB ? 1 : -1;
              }
            };
          },
        },
      },
      optionsPrint: {
        headings: {
          address: this.$lang.reports.label.tableHeader.address,
          name: this.$lang.reports.label.tableHeader.name,
          leaseDuration: this.$lang.reports.label.tableHeader.leaseDuration,
          paidDateTime: this.$lang.reports.label.tableHeader.paidDateTime,
          billingType: this.$lang.reports.label.tableHeader.billingType,
          amount: this.$lang.reports.label.tableHeader.amount,
          expenseName: this.$lang.reports.label.tableHeader.expenseName,
          miscellaneous: this.$lang.reports.label.tableHeader.miscellaneous,
          actions: "",
        },
        skin: "table",
        perPageValues: [],
        perPage: 8000000000,
        sortable: ["paidDateTime"],
        filterable: false,
        columnsClasses: {
          address: "address-width",
          tenant: "d-none d-md-table-cell ",
          leaseDuration: "d-none d-md-table-cell ",
          paidDateTime: "d-none d-md-table-cell ",
          billingType: "d-none d-md-table-cell ",
          actions: "expense-buttons ",
        },
        pagination: { chunk: 8 },
        texts: {
          noResults: this.$lang.root.label.noRecord,
          filter: this.$lang.root.label.filter,
          filterPlaceholder: this.$lang.root.label.filterPlaceholder,
          limit: this.$lang.root.label.limit,
          count: this.$lang.root.label.count,
        },
        customSorting: {
          paidDateTime: function (ascending) {
            return function (a, b) {
              let dateA = new Date(a.date);
              let dateB = new Date(b.date);
              if (ascending) {
                return dateA >= dateB ? 1 : -1;
              } else {
                return dateA < dateB ? 1 : -1;
              }
            };
          },
        },
      },
      headingNames: {
        ownerBroker: {
          address: this.$lang.reports.label.tableHeader.address,
          name: this.$lang.reports.label.tableHeader.name,
          leaseDuration: this.$lang.reports.label.tableHeader.leaseDuration,
          paidDateTime: this.$lang.reports.label.tableHeader.paidDateTime,
          billingType: this.$lang.reports.label.tableHeader.billingType,
          amount: this.$lang.reports.label.tableHeader.amount,
          expenseName: this.$lang.reports.label.tableHeader.expenseName,
          miscellaneous: this.$lang.reports.label.tableHeader.miscellaneous,
          actions: "",
        },
        tenant: {
          address: this.$lang.reports.label.tableHeader.address,
          name: this.$lang.reports.label.tableHeader.ownerName,
          leaseDuration: this.$lang.reports.label.tableHeader.leaseDuration,
          paidDateTime: this.$lang.reports.label.tableHeader.paidDateTime,
          billingType: this.$lang.reports.label.tableHeader.billingType,
          amount: this.$lang.reports.label.tableHeader.amount,
          expenseName: this.$lang.reports.label.tableHeader.expenseName,
          miscellaneous: this.$lang.reports.label.tableHeader.miscellaneous,
          type: this.$lang.reports.label.tableHeader.type,
          actions: "",
        },
      },
      methodsName: {
        ownerBroker: "ownerRealtorPaymentReport",
        tenant: "tenantPaymentReport",
      },
      tableData: [],
      filterData: {},
    };
  },
  watch: {
    activeName(name) {
      this.options.headings = this.headingNames[name];
    },
  },
  created() {
    this.activeName = this.isUserOnlyTenant ? "tenant" : "ownerBroker";
  },
  mounted() {
    // if(!this.isUserOnlyTenant) {
    this.getPaymentReport();
    // }
  },
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getPaymentReport(type) {
      let self = this;
      let data = {
        property: self.filterData.property,
        start_date: self.filterData.start_date ? self.formatDateReverse(self.filterData.start_date) : null,
        end_date: self.filterData.end_date ? self.formatDateReverse(self.filterData.end_date) : null,
        type: self.filterData.type,
        search: self.filterData.search,
      };
      self.tableData = [];
      self.$store.dispatch(self.methodsName[self.activeName], data).then((response) => {
        if (response.status == 200) {
          self.tableData = [];
          response.data.forEach((el) => {
            self.tableData.push({
              address: el.property_details.address,
              name: self.activeName == 'tenant' ? (el.owner_details.name || "N/A") : (el.tenant_details.name || "N/A"),
              contact: self.activeName == 'tenant' ? (el.owner_details.contact || "N/A") : (el.tenant_details.contact || "N/A"),
              email: self.activeName == 'tenant' ? (el.owner_details.email || "N/A") : (el.tenant_details.email || "N/A"),
              lease_period_start_date: el.tenant_details.start_date,
              lease_period_end_date: el.tenant_details.end_date,
              date: el.date,
              payment_frequency_id: el.payment_frequency,
              amount: el.amount,
              type_id: el.type,
              type_name: el.type_text,
              propertyType: el.property_details.type_text,
              expenseName: el.name || "N/A",
              miscellaneous: el.tenant_details.lease_agreement,
              actions: "",
              property_id: el.property_details.id,
              property_name: el.property_details.property_name
            });
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Event for tenant details API ,flow :-
     * 1) api call in AddExpense page - > Event(flag) passed to parent component FilterForm and then here
     * 2) api call from Reports listing page
     */
    handleApiCallTenant(data) {
      let self = this;
      self.filterData = data;
      this.getPaymentReport();
    },
    /**
     * Report listing downloadFile function will be called by using refs and file will be downloaded
     */
    download(obj) {
      let self = this;
      self.fileFormat = obj;
      self.$refs.reportList.downloadFile(self.fileFormat);
    },
  },
};
</script>
