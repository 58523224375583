module.exports = {
  label: {
    paymentTitle: "Payment Summary",
    paymentRentTitle : 'Rent Summary',
    addNewCard: "Add New Card",
    amount:'Amount',
    month:'month',
    year : 'year',
    duration: "Duration",
    to: "to",
    grandTotal : 'Grand Total',
    startDate : 'Start Date',
    endDate : 'End Date',
    rentAmount :'Rent Amount',
    maintenanceFee : 'Maintenance Fees',
    depositeAmount : 'Deposit Amount',
    lateRentAmount : 'Late Payment Fees',
    total : 'Total',
    summary: 'Summary',
    form: {
      label: {
        selectedCard: "Select Card",
        cvv:'CVV',
      },
      validation: {
        selectedCard: "select card",
        cvv:'CVV',
      },
      placeholder: {
        select: "select",
        enter:'Enter cvv'
      }
    },
    buttons: {
      payNow : 'Pay Now',
    },
  }
}