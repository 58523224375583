<!-- content from contacts.js -->
<template>
  <div>
    <b-form-group class="search-sticky">
      <div class="position-relative">
        <b-form-input
          v-model="searchContact"
          type="text"
          :placeholder="lang.label.form.placeholder.searchStaffMember"
        ></b-form-input>
      </div>
    </b-form-group>
    <b-list-group class="contact-user-list">
      <b-list-group-item
        class="d-flex justify-content-between align-items-center bg-transparent border-0 rounded-0 p-0"
        v-for="(member, index) in memberUsersListing"
        :class="selectedMember && selectedMember.id == member.id ? 'selected-user' : ''"
        :key="index"
      >
        <b-media>
          <template #aside>
            <img :src="getImgUser(member.pic)" :alt="member.name" v-if="member.pic"/>
            <span class="material-icons" v-else>account_circle</span>
          </template>
          <h6 class="contact-name mb-0">{{ member.name }}</h6>
          <p class="contact-email mb-0">{{ member.email }}</p>
        </b-media>
        <b-btn variant="primary" class="ml-2" @click="select(member)">{{
          selectedMember && selectedMember.id == member.id
            ? lang.label.buttons.selected
            : lang.label.buttons.select
        }}</b-btn>
      </b-list-group-item>
    </b-list-group>
    <span v-if="memberUsersListing.length == 0">{{ lang.label.form.label.noRecord }}</span>
  </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import { mapGetters } from "vuex";
export default {
  props: ["type", "selectedMember"],
  data() {
    return {
      lang: this.$lang.staff_management,
      data: jsondata,
      searchContact: "",
      contactType: {
        all: 1,
        owner: 2,
        broker: 3,
        tenant: 4,
        other: 5,
      },
      memberData: [],
    };
  },
  computed: {
    ...mapGetters({
      ImageUrl: "ImageUrl",
    }),
    memberUsersListing() {
      return this.memberData.filter((member) => {
        return member.name.toLowerCase().includes(this.searchContact);
      });
    },
  },
  mounted() {
    this.listMembers();
  },
  methods: {
    getImgUser(img) {
      if (img.length > 0) {
        return this.ImageUrl + img;
      }
      return require("@/assets/styles/img/users/user.jpeg");
    },
    /**
     * Handles loading tabale data based on active tab and applied date filter
     */
    listMembers() {
      let self = this
        self.$store.dispatch('listStaffMembers').then((response)=>{
        if(response.status == 200){
            self.memberData = []
            response.data.forEach((el,i) =>{
                self.memberData.push({
                    id: el.id,
                    user_id:el.user_id,
                    name: el.first_name + " " + el.last_name,
                    first_name: el.first_name,
                    last_name: el.last_name,
                    email: el.username,
                    contact: el.contact,
                    pic: el.profile_image,
                })
            })
        }else{
            let message = response.data.message || response.data.detail;
            if (message) {
                self.$store.commit("setMessages", {
                type: "danger",
                message: message,
                });
            }
        }
    })
    },
    /**
     * Handles member select event and sends member data to parent
     * @param {object} member Selected member
     */
    select(member) {
      this.$emit("handleMemberSelect", member);
    },
  },
};
</script>
