<template>
  <div>
    <div id="map" class="iframe-map form-group"></div>
    <div class="pac-card m-0" id="pac-card" v-show="!isViewOnly">
      <div v-show="autocompleteVisible">
        <ValidationObserver ref="googleSectionObserver" v-slot="{ passes }">
          <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
            <label class="form-label">{{ lang.label.searchProperty }}</label>
            <div id="pac-container">
              <ValidationProvider
                name="map location"
                :rules="{ required: !isLocationAvailable && isMapAvailable }"
                v-slot="validationContext"
              >
                <input
                  id="pac-input"
                  class="form-control"
                  type="text"
                  :placeholder="lang.label.location"
                  :state="getValidationState(validationContext)"
                  :class="{
                    'is-invalid': validationContext.errors[0],
                  }"
                />
              </ValidationProvider>
            </div>
          </b-form>
        </ValidationObserver>
      </div>
    </div>
    <div id="infowindow-content">
      <span id="place-name" class="title"></span><br />
      <span id="place-address"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
    },
    isViewOnly: {
      type: Boolean,
    },
    isMapAvailable: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      lang: this.$lang.googleMap,
      gMap: {
        map: null,
        marker: null,
        geocoder: null,
        responseDiv: null,
        response: null,
        infowindow: null,
        infowindowContent: null,
      },
      autocompleteVisible: false,
      isLocationAvailable: false,
    };
  },
  watch: {
    location: {
      handler() {
        if (this.gMap.geocoder) {
          this.handleDefaultLocation();
        }
      },
      deep: true,
    },
    isLocationAvailable() {
      setTimeout(async () => {
        await this.$refs.googleSectionObserver.validate();
      }, 0);
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {},
    /**
     * Handles initialization of map object and other plugins related to map
     */
    async initMap() {
      let self = this;
      const { Map } = await google.maps.importLibrary("maps");
      self.gMap.map = new Map(document.getElementById("map"), {
        zoom: 8,
        center: { lat: 8.983333, lng: -79.516670 },
        mapTypeControl: false,
      });

      self.initGeocodeSection();
      self.initAutoCompleteSection();
    },
    /**
     * Handles initialization of geocode plugin and mounts it to eventlistener
     */
    initGeocodeSection() {
      let self = this;
      self.gMap.geocoder = new google.maps.Geocoder();

      self.gMap.marker = new google.maps.Marker({
        draggable: !self.isViewOnly,
        map: self.gMap.map,
        animation: google.maps.Animation.DROP
      });
      if (!self.isViewOnly) {
        self.gMap.map.addListener("click", (e) => {
          self.geocode({ location: e.latLng }, true);
        });
        self.gMap.marker.addListener("dragend", (e) => {
          self.geocode({ location: e.latLng }, true);
        });
      }
      self.handleDefaultLocation();
      // self.geocode({ address: inputText.value })
    },
    /**
     * Handles initialization of auto-complete plugin and binds it to input
     */
    async initAutoCompleteSection() {
      let self = this;
      const card = document.getElementById("pac-card");
      const input = document.getElementById("pac-input");
      const options = {
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
        types: ["establishment"],
      };

      // self.gMap.map.controls[google.maps.ControlPosition.TOP_LEFT].push(card);
      setTimeout(() => {
        self.autocompleteVisible = true;
      }, 800);

      const places = await google.maps.importLibrary("places");
      const autocomplete = new places.Autocomplete(input, options);

      // Bind the map's bounds (viewport) property to the autocomplete object,
      // so that the autocomplete requests use the current map bounds for the
      // bounds option in the request.
      autocomplete.bindTo("bounds", self.gMap.map);

      // Set initial restriction to the greater list of countries.
      autocomplete.setComponentRestrictions({
        country: ["pa"],
      });

      self.gMap.infowindow = new google.maps.InfoWindow();
      self.gMap.infowindowContent = document.getElementById("infowindow-content");

      self.gMap.infowindow.setContent(self.gMap.infowindowContent);

      self.gMap.marker = new google.maps.Marker({
        map: self.gMap.map,
        animation: google.maps.Animation.DROP,
        draggable: !self.isViewOnly,
        anchorPoint: new google.maps.Point(0, -29),
      });

      if (!self.isViewOnly) {
        self.gMap.marker.addListener("dragend", (e) => {
          self.geocode({ location: e.latLng }, true);
        });
      }
      autocomplete.addListener("place_changed", () => {
        self.gMap.infowindow.close();
        self.gMap.marker.setVisible(false);

        const place = autocomplete.getPlace();

        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          // window.alert("No details available for input: '" + place.name + "'");
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          self.gMap.map.fitBounds(place.geometry.viewport);
        } else {
          self.gMap.map.setCenter(place.geometry.location);
          self.gMap.map.setZoom(17);
        }

        self.geocode({ location: place.geometry.location });
        self.setMarker(place);
      });
    },
    /**
     * Handles fetching geocodes based on provided location data
     * @param {object} request Requested method to fetch geocodes
     * @param {Boolean} isClicked Used to differentiat whether event origin is click event
     * @param {Boolean} isDefaultLocation Used to differentiat whether event origin is from default location
     */
    geocode(request, isClicked, isDefaultLocation) {
      let self = this;
      self.gMap.geocoder
        .geocode(request)
        .then((result) => {
          const { results } = result;
          if (isClicked) {
            self.setMarker(results[0]);
          }
          if (isDefaultLocation) {
            self.$emit("selectedLocation", { data: results[0], isDefaultLocation: true });
          } else {
            self.$emit("selectedLocation", results[0]);
          }
          return results;
        })
        .catch((e) => {
          // alert("Geocode was not successful for the following reason: " + e);
          self.$emit('handleMapError');
        });
    },
    /**
     * Handles showing marker and info window for selected place
     * @param {object} place Used to set marker and info window
     */
    setMarker(place) {
      let self = this;
      self.clearMarker();
      self.gMap.marker.setVisible(false);
      self.gMap.marker.setPosition(place.geometry.location);
      self.gMap.marker.setVisible(true);
      self.gMap.marker.setMap(self.gMap.map);
      self.gMap.infowindowContent.children["place-name"].textContent = place.name;
      self.gMap.infowindowContent.children["place-address"].textContent =
        place.formatted_address;
      self.gMap.infowindow.open(self.gMap.map, self.gMap.marker);
      self.isLocationAvailable = true;
    },
    /**
     * Handles removal of marker and info window data
     */
    clearMarker() {
      let self = this;
      self.gMap.marker.setMap(null);
      self.gMap.infowindowContent.children["place-name"].textContent = null;
      self.gMap.infowindowContent.children["place-address"].textContent = null;
    },
    /**
     * Handles default location selection when one is provided
     */
    handleDefaultLocation() {
      let self = this;
      if (
        self.location &&
        self.location.latitude !== null &&
        self.location.latitude !== ""
      ) {
        this.geocode(
          { location: { lat: self.location.latitude, lng: self.location.longitude } },
          true,
          true
        );
      }
    },
  },
};
</script>
<style scoped>
#infowindow-content .title {
  font-weight: bold;
}

#infowindow-content {
  display: none;
}

#map #infowindow-content {
  display: inline;
}

.pac-card {
  margin: 10px;
}
</style>
