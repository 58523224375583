<!-- content from my_profile.js -->
<template>
  <b-container fluid class="px-0 my-profile">
    <b-row>
      <b-col lg="3" class="border-right profile-left pr-lg-0">
        <div class="pic-doc">
          <b-row>
            <b-col cols="6" lg="12">
              <div class="box-section">
                <div class="uploaded-file">
                  <div class="file-box profile-img">
                    <img
                      v-if="getProfilePic"
                      :src="getProfilePic"
                      alt="placeholder"
                    />
                    <div class="placeholder-user" v-else>
                      <span class="material-icons">person</span>
                    </div>
                  </div>
                  <b-form-file
                    ref="profilePic"
                    class="only-text mt-2"
                    v-model="profilePic"
                    accept=".jpeg, .png, .jpg"
                    @change="onProfileChange"
                    :browse-text="lang.label.changeProfilePic"
                  ></b-form-file>
                </div>
              </div>
            </b-col>

            <b-col cols="6" lg="12" v-if="login.user_type_id != 3">
              <div class="box-section">
                <h5 class="sf-size sf400 text-center mb-3 d-none d-lg-block">
                  {{ lang.label.identityDocument }}
                </h5>
                <div class="uploaded-file">
                  <div class="file-box mb-2" v-if="urlDoc && isPDF">
                    <img :src="getIconUrl(urlDoc)" alt="placeholder" class="img-fluid h-100 py-2" />
                  </div>
                  <div class="file-box mb-2 upld-doc-img" v-else>
                    <img  :src="getUrlDoc" alt="" class="h-100 img-fluid" />
                  </div>
                  <b-form-file
                    v-if="!docUrl || (docUrl && idStatus == 3)"
                    ref="IDDoc"
                    class="mt-2 with-file-name"
                    v-model="urlDoc1"
                    accept=".pdf,.jpeg,.jpg,.png"
                    :browse-text="!docUrl && idStatus == 1 ? lang.label.uploadDocument : lang.label.changeDocument"
                    :placeholder="lang.label.noFileChosen"
                    @change="onDocumentChange">
                    <template slot="file-name" slot-scope="{ names }">
                      <span style="word-wrap: break-word;">{{ names[0] }}</span>
                    </template>
                  </b-form-file>
                  <!-- if documnet verified -->
                  <div class="text-center" v-if="getUrlDoc" :class="idStatus == 3 ? 'mt-3 pt-4' : ''">
                    <span
                      class="bf600 text-underline secondary-text p-0 bf-size cursor-pointer"
                      v-b-modal.viewDoc
                      >{{ lang.label.viewDocument }}</span
                    >
                  </div>
                  <!-- view Company Imagemodal -->
                  <b-modal
                    ref="companyImageref"
                    id="viewCompanyImage"
                    hide-footer
                    centered
                    size="xl"
                    modal-class="close-btn-right full-height-modal"
                    scrollable
                    v-if="currentImagetoPreview"
                  >
                    <template #modal-header="{ close }">
                      <b-button
                        variant="link"
                        class="close-btn"
                        @click="close()"
                      >
                        <img :src="getIconUrl('close.svg')" alt="close" />
                      </b-button>
                    </template>
                    <div class="d-block text-center h-100">
                      <div class="h-100 text-center image-doc-show">
                        <img :src="currentImagetoPreview" alt=""/>
                      </div>
                    </div>
                  </b-modal>
                  <!-- view document modal -->
                  <b-modal
                    ref="viewDocRef"
                    id="viewDoc"
                    hide-footer
                    centered
                    size="xl"
                    modal-class="close-btn-right full-height-modal"
                    scrollable
                  >
                    <template #modal-header="{ close }">
                      <b-button
                        variant="link"
                        class="close-btn"
                        @click="close()"
                      >
                        <img :src="getIconUrl('close.svg')" alt="close" />
                      </b-button>
                    </template>
                    <div class="d-block text-center h-100">
                      <iframe
                        :src="getUrlDoc"
                        width="100%"
                        height="100%"
                        frameborder="0"
                        :title="lang.label.tooltip.document"
                        v-if="isPDF"
                      ></iframe>
                      <div class="h-100 text-center image-doc-show" v-else>
                        <img :src="getUrlDoc" alt=""/>
                      </div>
                    </div>
                  </b-modal>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col lg="9" class="profile-form mt-3 mt-lg-0">
        <b-container fluid class="px-0">
          <!-- first name last name -->
          <ValidationObserver ref="observerName" v-slot="{ passes }">
            <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
              <b-row>
                <b-col md="12" xl="6">
                  <b-row>
                    <b-col cols="6">
                      <b-form-group>
                        <label class="form-label ">{{
                          lang.label.form.label.firstName
                        }}<span class="text-danger">*</span></label>
                        <ValidationProvider
                          :name="lang.label.form.validation.firstName"
                          rules="required|max:50"
                          v-slot="validationContext"
                        >
                          <div class="position-relative">
                            <b-form-input
                              v-model="models.firstName"
                              type="text"
                              :state="getValidationState(validationContext)"
                              :placeholder="lang.label.form.placeholder.enterfname"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group>
                        <label class="form-label">{{
                          lang.label.form.label.lastName
                        }}<span class="text-danger">*</span></label>
                        <ValidationProvider
                          :name="lang.label.form.validation.lastName"
                          rules="required|max:50"
                          v-slot="validationContext"
                        >
                          <div class="position-relative">
                            <b-form-input
                              v-model="models.lastName"
                              type="text"
                              :state="getValidationState(validationContext)"
                              :placeholder="lang.label.form.placeholder.enterlname"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <!-- email -->
              <b-row>
                <b-col md="12" xl="6">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group>
                        <label class="form-label">{{
                          lang.label.form.label.email
                        }}</label>
                        <ValidationProvider
                          :name="lang.label.form.validation.email"
                          rules="required|email"
                          v-slot="validationContext"
                        >
                          <div class="position-relative">
                            <b-form-input
                              v-model="models.email"
                              type="text"
                              class="text-lowercase"
                              :state="getValidationState(validationContext)"
                              :placeholder="lang.label.form.placeholder.email"
                              disabled
                            ></b-form-input>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!-- contact number -->
              <b-row>
                <b-col md="12" xl="6">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group>
                        <label class="form-label">{{
                          lang.label.form.label.contactNumber
                        }}<span class="text-danger">*</span> </label>
                        <ValidationProvider
                          :name="lang.label.form.validation.contactNumber"
                          rules="required|numeric|min:6|max:8"
                          v-slot="validationContext"
                        >
                          <div
                            class="position-relative prepend-icon prepend-text country-code"
                          >
                            <b-form-input
                              v-model="models.contactNumber"
                              type="text"
                              :state="getValidationState(validationContext)"
                              :placeholder="
                                lang.label.form.placeholder.contactNumber
                              "
                            ></b-form-input>
                            <div class="icon">
                              <div class="pre-app-text primary-text bf-size">
                                +507
                              </div>
                            </div>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!-- address -->
              <b-row v-if="login.user_type_id != 3">
                <!-- address line 1 -->
                <b-col cols="12" md="6">
                  <b-form-group>
                    <label class="form-label">{{
                    lang.label.form.label.address
                    }}<span class="text-danger">*</span></label>
                    <ValidationProvider
                      :name="lang.label.form.validation.address"
                      rules="required|max:100"
                      v-slot="validationContext"
                    >
                      <div class="position-relative">
                        <b-form-input
                          v-model="models.address"
                          type="text"
                          :state="getValidationState(validationContext)"
                          :placeholder="lang.label.form.placeholder.address"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          >{{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <!-- country -->
                <b-col cols="6" md="6" xl="3">
                  <b-form-group>
                    <label class="form-label">{{
                    lang.label.form.label.country
                  }}<span class="text-danger">*</span></label>
                    <ValidationProvider
                      :name="lang.label.form.validation.country"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <div class="position-relative">
                        <el-select
                          v-model="models.country"
                          :state="getValidationState(validationContext)"
                          :class="
                            validationContext.errors[0] ? 'is-invalid' : ''
                          "
                          :placeholder="lang.label.form.placeholder.select"
                        >
                          <el-option
                            v-for="item in country"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                        <b-form-invalid-feedback
                          >{{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <!-- state -->
                <b-col cols="6" md="6" xl="3">
                  <b-form-group>
                    <label class="form-label">{{
                    lang.label.form.label.state
                    }}<span class="text-danger">*</span></label>
                    <ValidationProvider
                      :name="lang.label.form.validation.state"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <div class="position-relative">
                        <el-select
                          v-model="models.state"
                          :state="getValidationState(validationContext)"
                          :class="
                            validationContext.errors[0] ? 'is-invalid' : ''
                          "
                          :placeholder="lang.label.form.placeholder.select"
                        >
                          <el-option
                            v-for="item in state"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                        <b-form-invalid-feedback
                          >{{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <!-- city -->
                <b-col cols="6" md="6" xl="3">
                  <b-form-group>
                    <label class="form-label">{{
                    lang.label.form.label.city
                    }}<span class="text-danger">*</span></label>
                    <ValidationProvider
                      :name="lang.label.form.validation.city"
                      rules="required|max:50"
                      v-slot="validationContext"
                    >
                      <div class="position-relative">
                        <b-form-input
                          v-model="models.city"
                          :state="getValidationState(validationContext)"
                          :class="
                            validationContext.errors[0] ? 'is-invalid' : ''
                          "
                          :placeholder="lang.label.form.placeholder.city"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          >{{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <!-- zip code -->
                <b-col cols="6" md="6" xl="3">
                  <b-form-group>
                    <label class="form-label">{{
                    lang.label.form.label.zipCode
                    }}</label>
                    <ValidationProvider
                      :name="lang.label.form.validation.zipCode"
                      rules="numeric|min:4|max:6"
                      v-slot="validationContext"
                    >
                      <div class="position-relative">
                        <b-form-input
                          v-model="models.zipCode"
                          type="text"
                          :state="getValidationState(validationContext)"
                          :placeholder="lang.label.form.placeholder.zipCode"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          >{{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- company details -->
              <b-row v-if="login.user_type_id != 3 && !isUserOnlyTenant">
                <!-- brokerID -->
                <b-col cols="6" md="6" xl="3">
                  <b-form-group>
                    <label class="form-label">{{
                      lang.label.form.label.brokerId
                    }}</label>
                    <ValidationProvider
                      :name="lang.label.form.validation.brokerId"
                      :rules="{
                        required: false,
                        regex: /^(?=.*?\b([a-zA-Z]{2})[-]([0-9]{4})\b).{7}$/,
                      }"
                      v-slot="validationContext"
                    >
                      <div class="position-relative">
                        <b-form-input
                          v-model="models.brokerId"
                          type="text"
                          :state="getValidationState(validationContext)"
                          :placeholder="lang.label.form.placeholder.brokerId"
                        ></b-form-input>
                        <small class="sm-font text-grey position-absolute mt-1">{{
                          lang.label.form.placeholder.brokerIdNote
                        }}</small>
                        <b-form-invalid-feedback
                          >{{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <!-- company-name -->
                <b-col cols="6" md="6" xl="3">
                  <b-form-group>
                    <label class="form-label">{{
                      lang.label.form.label.companyName
                    }}</label>
                    <ValidationProvider
                      :name="lang.label.form.validation.companyName"
                      rules="max:50"
                      v-slot="validationContext"
                    >
                      <div class="position-relative">
                        <b-form-input
                          v-model="models.companyName"
                          type="text"
                          :state="getValidationState(validationContext)"
                          :placeholder="lang.label.form.placeholder.companyName"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          >{{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <!-- company-email -->
                <b-col cols="6" md="6" xl="3">
                  <b-form-group>
                    <label class="form-label">{{
                      lang.label.form.label.companyEmail
                    }}</label>
                    <ValidationProvider
                      :name="lang.label.form.validation.companyEmail"
                      rules="email"
                      v-slot="validationContext"
                    >
                      <div class="position-relative">
                        <b-form-input
                          v-model="models.companyEmail"
                          type="text"
                          :state="getValidationState(validationContext)"
                          :placeholder="
                            lang.label.form.placeholder.companyEmail
                          "
                        ></b-form-input>
                        <b-form-invalid-feedback
                          >{{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="mt-4">
                  <b-form-group>
                    <b-btn
                      variant="primary"
                      type="submit"
                      class="w-100 w-md-auto"
                      >{{ lang.label.buttons.update }}</b-btn
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>

          <!--Company Images-->
          <!-- <b-row v-if="login.user_type_id != 3">
            <b-col cols="12" xl="9">
              <b-row>
                <b-col cols="12">
                  <div class="d-flex justify-content-between">
                    <label class="form-label">{{
                      lang.label.companyProfile
                    }}</label>
                    <div class="upload-file-button d-inline-flex">
                      <b-form-file
                        ref="companyImageFile"
                        class="only-text h-file-upload"
                        accept=".jpeg, .png, .jpg"
                        @change="onCompanyImageChange"
                        :browse-text="lang.label.companyImage"
                        :multiple="true"
                        :rules="fileRules"
                        v-if="companyImagesArr.length != 3"
                      ></b-form-file>
                    </div>
                  </div>
                </b-col>
                <b-col
                  cols="6"
                  md="4"
                  xl="4"
                  v-for="(img, index) in companyImagesArr"
                  :key="index"
                >
                  <b-card no-body class="company-image-picture">
                    <b-card-body class="uploaded-file" >
                      <div class="file-box profile-img">
                        <img
                          v-if="img"
                          :src="img.image"
                          alt="placeholder"
                          class="img-fluid"
                          v-b-modal.viewCompanyImage
                          @click="onPreviewCompanyImage(img.image)"
                        />
                        <div class="placeholder-user" v-else>
                          <span class="material-icons">person</span>
                        </div>
                        <div class="img-delete">
                          <b-btn
                            variant="danger"
                            size="sm"
                            @click="removeImageAlert(img.id)"
                          >
                            {{ lang.label.buttons.removeImage }}
                          </b-btn>
                        </div>
                      </div>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row> -->
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  components: {},
  computed: {
    ...mapGetters({
      ImageUrl: "ImageUrl",
      login: "login",
      isUserOnlyTenant: "isUserOnlyTenant",
    }),
    getProfilePic() {
      return this.models.profileImage
        ? this.ImageUrl + this.models.profileImage
        : null;
    },
    getUrlDoc() {
      return this.docUrl ? this.ImageUrl + this.docUrl : null;
    },
  },
  data() {
    return {
      lang: this.$lang.my_profile,
      profilePic: [],
      companyImagesArr: [],
      urlDoc1: [],
      docUrl: null,
      urlDoc: null,
      isPDF:false,
      idStatus:'',
      currentImagetoPreview:'',
      models: {
        firstName: "",
        lastName: "",
        email: "",
        contactNumber: "",
        address:'',
        city: "",
        state: null,
        country: null,
        zipCode: null,
        profileImage: null,
        brokerId: "",
        companyName: "",
        companyEmail: "",
      },
      city: [],
      state: [],
      country: [],
      fileRules: [(files) => !files || files.length <= 3],
    };
  },
  watch: {
    "models.country"(v) {
      this.getListOfState(v);
    },
  },
  created() {
    this.retrieveProfileInfo();
  },
  mounted() {
    this.viewProfile();
    this.getListOfCountries();
  },
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    /**
     * calls the api for update the profile image
     * @param {object} e event target
     */
    onProfileChange(e) {
      let self = this;
      let file = e.target.files[0];
      let fileSizeMB = file ? file.size / 1000 ** 2 : "";
      let allowedExtensions = ['jpeg','jpg', 'png'];
      let fileExtension = file.name.split(".").pop();
      if(!allowedExtensions.includes(fileExtension)){
        self.fireValidation('extensionValidationImage');
        self.$refs.IDDoc.reset();
        return;
      }
      if (fileSizeMB > 10) {
        self.fireValidation('documentSizeValidation');
        self.$refs.profilePic.reset();
        return;
      }
      let formData = new FormData();
      formData.append("profile_image", file);
      self.$store.dispatch("updateProfileImage", formData).then((response) => {
        if (response.status == 200) {
          self.models.profileImage = response.data.profile_image;
          let loginData = self.login;
          loginData.profile_image = response.data.profile_image;
          self.$store.commit("setLogin", loginData);
          self.$store.commit("setMessages", {
            type: "success",
            message: response.data.message,
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * call the api for change the identity document
     * @param {object} e event target
     */
    onDocumentChange(e) {
      let self = this;
      let formData = new FormData();
      let file = e.target.files[0];
      let allowedExtensions = ['jpeg','jpg','png','pdf']
      let fileExtension = file.name.split(".").pop();
      let fileSizeMB = file ? file.size / 1000 ** 2 : "";
      if (fileSizeMB > 10) {
        self.fireValidation('documentSizeValidation');
        self.$refs.IDDoc.reset();
        return;
      }
      if(!allowedExtensions.includes(fileExtension)){
        self.fireValidation('extensionValidation');
        self.$refs.IDDoc.reset();
        return;
      }
      formData.append("id_doc", e.target.files[0]);
      self.$store
      .dispatch("uploadIdentificationDocument", formData)
      .then((response) => {
        if (response.status == 200) {
          self.docUrl = response.data.id_doc;
          self.isPDF = self.docUrl && self.docUrl.split('.').pop(-1) == 'pdf';
          self.urlDoc = self.isPDF ? "pdf-file.svg" : "jpeg-file.svg";
          self.$store.commit("setMessages", {
            type: "success",
            message: response.data.message,
          });
          self.retrieveProfileInfo();
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Fires the error if uploaded file size is greater then 10MB
     */
    fireValidation(validationType) {
      let self = this;
      Swal.fire({
        title: "",
        text: self.lang.label[validationType],
        icon: "warning",
        confirmButtonText: self.lang.label.buttons.ok,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    /**
     * Prepare the payload of Profile Details
     */
    onSubmit() {
      let self = this;
      let data = {
        first_name: self.models.firstName,
        last_name: self.models.lastName,
        contact: self.models.contactNumber,
        address: self.models.address,
        country: self.models.country,
        state: self.models.state,
        city: self.models.city,
        zip: self.models.zipCode,
        broker_id: self.models.brokerId,
        company_name: self.models.companyName,
        company_email: self.models.companyEmail,
      };
      self.updateProfile(data);
    },
    /**
     * Provides the list of coutries
     */
    getListOfCountries() {
      let self = this;
      self.$store.dispatch("listOfCountries").then((response) => {
        if (response.status == 200) {
          self.country = response.data;
          if (self.country.length > 0) {
            self.models.country = self.country[0].id;
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Provide the list of state with country id
     */
    getListOfState(id) {
      let self = this;
      if (id) {
        self.$store.dispatch("listOfStates", id).then((response) => {
          if (response.status == 200) {
            self.state = response.data;
            let stateArr = [];
            self.state.forEach((ele) => {
              stateArr.push(ele.id);
            });
            self.models.state = stateArr.includes(self.models.state)
              ? self.models.state
              : null;
          } else {
            let message = response.data.message || response.data.detail;
            if (message) {
              self.$store.commit("setMessages", {
                type: "danger",
                message: message,
              });
            }
          }
        });
      }
    },
    /**
     * call the api for view profile
     */
    viewProfile() {
      let self = this;
      self.$store.dispatch("viewProfile").then((response) => {
        if (response.status == 200) {
          self.companyImagesArr = [];
          self.models = {
            firstName: response.data.first_name,
            lastName: response.data.last_name,
            email: response.data.email,
            contactNumber: response.data.contact,
            address :response.data.address,
            country:response.data.country_id != 0 ? response.data.country_id : null,
            state: response.data.state_id != 0 ? response.data.state_id : null,
            city: response.data.city_name,
            zipCode: response.data.zip || null,
            brokerId: response.data.broker_id,
            companyName: response.data.company_name,
            companyEmail: response.data.company_email,
            profileImage: response.data.profile_image,
          };
          response.data.company_images.forEach((img) => {
            self.companyImagesArr.push({
              image: self.ImageUrl + img.image,
              id: img.id,
            });
          });
          self.idStatus = response.data.id_doc_status_id
          self.docUrl = response.data.id_doc != '' ? response.data.id_doc : null
          self.isPDF = self.docUrl && self.docUrl.split('.').pop(-1) == 'pdf';
          self.urlDoc = self.isPDF ? "pdf-file.svg" : "jpeg-file.svg";
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Update the specific data field of profile
     * @param {object} data Details of Profile Fields
     */
    updateProfile(data) {
      let self = this;
      self.$store.dispatch("updateProfile", data).then((response) => {
        if (response.status == 200) {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "success",
              message: message,
            });
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Update the company Images with file validation
     * @param {object} e event target data
     */
    onCompanyImageChange(e) {
      let self = this;
      let allFiles = e.target.files;
      let formData = new FormData();
      let companyImages = self.companyImagesArr.length;
      for (let index = 0; index < allFiles.length; index++) {
        let file = allFiles[index];
        let fileSizeMB = file ? file.size / 1000 ** 2 : "";
        let allowedExtensions = ['jpeg','jpg', 'png'];
        let fileExtension = file.name.split(".").pop();
        if(!allowedExtensions.includes(fileExtension)){
          self.fireValidation('extensionValidationImage');
          self.$refs.IDDoc.reset();
          break;
        }
        if (fileSizeMB <= 10) {
          formData.append("image" + (index + 1), file);
          companyImages += 1;
          if (companyImages > 3) {
            self.fireValidation("moreThanThreeValidation");
            self.$refs.companyImageFile.reset();
            break;
          }
        } else {
          self.fireValidation("companyImageSizeValidation");
          self.$refs.companyImageFile.reset();
          break;
        }
        if (index == allFiles.length - 1) {
          self.$store.dispatch("companyImages", formData).then((response) => {
            self.$refs.companyImageFile.reset();
            if (response.status == 200) {
              self.$store.commit("setMessages", {
                type: "success",
                message: response.data.message,
              });
              self.viewProfile();
            } else {
              let message = response.data.message || response.data.detail;
              if (message) {
                self.$store.commit("setMessages", {
                  type: "danger",
                  message: message,
                });
              }
            }
          });
        }
      }
    },
    /**
     * Delete the company image with api
     * @param {number} id Company image id
     */
    deleteCompanyImage(id) {
      let self = this;
      self.$store.dispatch("deleteCompanyImage", id).then((response) => {
        if (response.status == 200) {
          self.$store.commit("setMessages", {
            type: "success",
            message: response.data.message,
          });
          self.viewProfile();
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Alert message on delete company image
     * @param {number} id of Company image
     */
    removeImageAlert(id) {
      let self = this;
      Swal.fire({
        title: self.lang.label.confirmTitle,
        text: self.lang.label.confirmMsg,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: self.lang.label.buttons.yes,
        cancelButtonText: self.lang.label.buttons.no,
        focusConfirm: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.deleteCompanyImage(id);
        }
      });
    },
    /**
     * Receives profile information,specifically to retrieve uploaded document status.
     */
    retrieveProfileInfo() {
      let self = this;
      if (
        self.login.id_doc_status_id == 1 ||
        self.login.id_doc_status_id == 3
      ) {
        self.$store.dispatch("retrieveProfile").then((response) => {
          if (response.status == 200) {
            let loginData = self.login;
            self.idStatus = response.data.id_doc_status_id;
            loginData.id_doc_status_id = response.data.id_doc_status_id;
            loginData.id_doc_reject_reason = response.data.id_doc_reject_reason;
            loginData.id_doc = response.data.id_doc;
            loginData.is_verified = response.data.is_verified;
            loginData.unread_messages_count=response.data.unread_messages_count;
            self.$store.commit("setLogin", loginData);
          } else {
            let message = response.data.message || response.data.detail;
            if (message) {
              self.$store.commit("setMessages", {
                type: "danger",
                message: message,
                allow_module: "OTP-Verification",
              });
            }
          }
        });
      }
    },
    onPreviewCompanyImage(image){
      this.currentImagetoPreview = image
    }

  },
};
</script>
