module.exports = {
  label: {
    verifyEmialAlert: "Verify your email to get full access to Miradoor, you may not be able to add a contact or properties.",
    success: 'Success',
    leaseSubmitlert: 'Your lease details have been saved, upload your lease document now. Your next step is',
    leaseSubmitlertEnd: 'Publish Your Property',
    leaseSubmitlertRented: 'Your lease agreement details have been saved.',
    confirmTitleStatusChange: 'Are you sure?',
    confirmMsgStatusChange: 'You want to change the status to',
    editProperties: 'Edit Property',
    propertyStatus: 'Property Status',
    userType: 'User Type',
    mtSquare: 'mt2',
    reviewedProperty: 'Have you reviewed your property details?',
    updateAnytime: 'Don’t worry you can update it anytime.',
    addAmenities: 'Add Amenities',
    search: 'Search',
    addNewContact: 'Add New Contact',
    selectContactLabel: 'Select Contact',
    leaseAgreementConfiguration: 'Lease Agreement Configuration',
    areYouSureCancel: 'Are you sure you want to cancel?',
    allCleared: '*All the unsaved details will be cleared',
    errorMessages: {
      document: 'The Category Thumbnail field is required',
      fileSizeExceeded: 'The file size is exceeded',
      imageSizeExceeded: 'The Image size is exceeded',
    },
    confirmTitle: 'Are you sure?',
    confirmMsg: 'You want to delete this!',
    ownerValidationTitle: 'Warning!',
    ownerValidationMsg: 'Please select owner contact first',
    propertyManagerAlert: "If you do not select any broker then you will become the property manager of this property",
    confirmContactMsg: 'This user is already added to the system. Are you sure you want to add the user to your contact list?',
    assignLeaseAgreement: 'assign lease agreement',
    propertyAddedAlert: 'The property has been updated successfully',
    form:{
      uploadProperty: 'Upload Property Images or Videos',
      uploadLimit: 'Upload at least one or max 20 image or video. Max 10mb for image and 50mb for video',
      asterisk:'*',
      rentAmount: 'Rent Amount',
      securityDepositAmount: 'Security Deposit Amount',
      securityDeposit: 'Security Deposit',
      propertyType: 'Property Type',
      paymentPeriod: 'Payment Period',
      maintenanceFee: 'Maintenance Fee',
      propertyName: 'Property Name',
      aboutthisProperty: 'About this property',
      approxMtSquare: 'Livable Space mt2',
      propertyArea: 'Property Area',
      constructionYear: 'Construction Year',
      numberBedrooms: 'No. Bedrooms',
      numberBathrooms: 'No. Bathrooms',
      parking: 'Parking(s)',
      propertyAmenities: 'Property Amenities',
      address: 'Address',
      propertyManager: 'Property Manager',
      ownerDetails: 'Owner Details',
      leaseAgreement: 'Lease Agreement (PDF, JPEG, JPG, PNG)',
      streetRoadName: 'Street/Road Name',
      blockPropertynumber: 'Block/Property number',
      city: 'City',
      state: 'State',
      country: 'Country',
      zoneCode: 'Zone Code',
      placeholder: {
        zero: '0.00',
        select: 'Select',
        enterName: 'Enter Name',
        writeSomethingProperty: 'Write something about this property',
        enter: 'Enter',
        enterYear: 'Enter Year',
        enterNumber: 'Enter Number',
        blockPropertynumber: 'Block/Property number',
        searchLocation: 'Search location',
        location: 'Map Location',
        streetRoadName: 'Street/Road Name',
        city: 'City',
        state: 'State',
        country: 'Country',
        zoneCode: 'Zone Code',
        uploadDocument: 'Upload Document',
        realtor: 'Realtor',
        owner: 'Owner',
        available: 'Available',
        rented: 'Rented',
        commercial: 'Commercial',
        residential: 'Residential',
      },
      validation: {
        propertyStatus: 'property status',
        userType: 'user type',
        rentAmount: 'rent amount',
        securityDepositAmount: 'security deposit amount',
        propertyType: 'property type',
        paymentPeriod: 'payment period',
        maintenanceFee: 'maintenance fee',
        propertyName: 'property name',
        aboutthisProperty: 'about this property',
        approxMtSquare: 'livable space mt2',
        propertyArea: 'property area',
        constructionYear: 'construction year',
        numberBedrooms: 'no. bedrooms',
        numberBathrooms: 'no. bathrooms',
        parking: 'parking(s)',
        propertyAmenities: 'property amenities',
        propertyManager: 'property manager',
        ownerDetails: 'owner details',
        uploadProperty: 'property images or videos',
        blockPropertynumber: 'block/property number',
        searchLocation: 'search location',
        location: 'map location',
        streetRoadName: 'Street/Road Name',
        city: 'City',
        state: 'State',
        country: 'Country',
        zoneCode: 'Zone Code',
        uploadDocument: 'Upload Document',
        assignLeaseAgreement: 'assign lease agreement',
      }
    },
    buttons: {
      sendCode: 'Send Verification Code',
      add: 'Publish Property',
      cancel: 'Cancel',
      addAmenities: 'Add Amenities',
      selectBrokerContacts: 'Select Property Manager',
      selectOwnerContacts: 'Select From Contacts',
      assignLeaseAgreement: 'Assign Lease Agreement',
      upload: 'Upload',
      yes: 'Yes',
      no: 'No',
      ok: 'Ok',
      addAmenitiesBtn: 'Add',
      selectContact: 'Select Contact',
      addNewContactBtn: 'Add New',
    }
  }
};