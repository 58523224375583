module.exports = {
    label: {
      title: "Mi Cuenta",
      addBankDetails: "Agregar Datos Bancarios",
      tabs: {
        myProfile: 'Mi Perfil',
        subscriptionPlan: 'Plan De Subscripción',
        bankInformation: 'Información De Facturación',
      }
    }
  };