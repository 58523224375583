import Swal from "sweetalert2";
export default {
    methods: {
        checkSubscriptionValidation(payloadData){
            let self = this;
            return new Promise((resolve,reject) => {
              self.$store.dispatch('checkSubscriptionFeature',payloadData).then((response) => {
                if(response.status == 200){
                    if(response.data.flag){
                      resolve(true);
                    }
                    else{
                      let message = response.data.message || response.data.detail;
                      Swal.fire({
                          title: self.lang.label.ValidationTitle,
                          text: message,
                          confirmButtonText: self.lang.label.buttons.ok,
                          customClass: {
                              confirmButton: "btn btn-primary",
                          }
                      });
                    }
                }else{
                  let message = response.data.message || response.data.detail;
                      if (message) {
                          self.$store.commit("setMessages", {
                          type: "danger",
                          message: message,
                      });
                  }
                }
              })
            })        
        }
    }
}
