module.exports = {
  label: {
    brokerPaymentConfiguration: 'Broker Payment Configuration',
    propertyOwner: 'Property Owner',
    addOwnerDetails: 'Request Owner Bank Details',
    addBrokerDetails: 'Request Broker Bank Details',
    addBankDetails: 'Add Bank Details',
    contactSelectionAlert: 'Please select contact first',
    bankDetailsRequest: 'Bank Details Request',
    success: "Success",
    bankRequestSuccessMessage: "Bank details has been requested.",
    addNewContact: 'Add New Contact',
    selectContactLabel: 'Select Contact',
    myAccountBroker: 'My Account (Broker)',
    confirmEndDateChangeTitle: 'Are you sure?',
    confirmEndDateChangeMsg: 'Date will be changed!',
    confirmTitle:'Are you sure?',
    warning : 'Warning',
    confirmContactMsg:'This user is already added to the system. Are you sure you want to add the user to your contact list?',
    staffWarningMsg: 'This user is already added to the staff',
    info: {
      firstMonthOnly: 'First Installment Only',
      selectPercentageAmount: 'Select between percentage or amount',
      feeChargedBrokerMonth: 'Fee charged by broker only on the first month',
      feeChargedBrokerBasis: 'Fee charged by broker on a monthly basis',
      monthlyFees: 'Monthly Fees'
    },
    form: {      
      label: {
        assignTenant: 'Assign Tenant',
        rentAmount: 'Rent Amount',
        paymentFrequency: 'Payment Frequency',
        startDate: 'Start Date',
        endDate: 'End Date',
        depositAmount: 'Deposit Amount',
        closingCostCommission: 'Closing Cost Commission',
        brokerRecurringRentalFee: 'Broker Recurring Rental Fee',
        rentAmountDepositAccount: 'Rent Amount Deposit Account',
        commissionDepositAccount: 'Commission Deposit Account'
      },
      validation: {
        assignTenant: 'assign tenant',
        rentAmount: 'rent amount',
        paymentFrequency: 'payment frequency',
        startDate: 'start date',
        endDate: 'end date',
        depositAmount: 'deposit amount',
        closingCostCommission: 'closing cost commission',
        brokerRecurringRentalFee: 'broker recurring rental fee',
        rentAmountDepositAccount: 'rent amount deposit account',
        commissionDepositAccount: 'commission deposit account'
      },
      placeholder: {
        enter: 'Enter',
        select: 'Select',
        selectDate: 'Select Date',
      }
    },
    rentDetails: {
      title: 'Rent Details Summary',
      rentalSummaryTitle: "Tenant Rental Summary",
      tenant: 'Tenant',
      leasePeriod: 'Lease Period',
      leaseAmount: 'Lease Amount',
      maintenanceFees: 'Maintenance Fee',
      depositAmount: 'Deposit Amount',
      amountDueFirstMonth: 'First Installment Due by Tenant',
      recurringDueAmount: 'Recurring Monthly Amount Due by Tenant',
    },
    brokerCommission: {
      title: 'Broker Commission Fees',
      closingCostComFirst: 'Closing Cost Commission (First Installment Only)',
      closingCostComFirstToBroker: 'Closing Cost Commission to Broker (First Installment Only)',
      recurringRentalFee: 'Recurring Rental Admin Fee',
      recurringRentalFeeBroker: 'Recurring Rental Admin Fee to Broker',
    },
    rentalAmountOwner: {
      title: 'Rental Amount to Owner',
      firstMonthOwner: 'First Installment to Owner',
      recurringRentalAmountDueOwner: 'Recurring Rental Amount Due to Owner'
    },
    buttons: {
      save: 'Save',
      selectFromContacts: 'Select From Contacts',
      selectContact: 'Select Contact',
      addNewContactBtn: 'Add New',
      yes: 'Yes',
      no: 'No',
      ok:'Ok'
    }
  }
};