module.exports = {
  label: {
    forgotPassword: "Forgot Password",
    alreadyAccount: "Already have a account?",
    signIn: "Sign In",
    slogan: "You’ll never need another property management platform",
    sentLink: "We have sent you the password reset link to Your registered email id.",
    form: {
      email: 'Email',
      validation: {
        email: 'Email',
      },
      placeholder: {
        email: 'Email',
      }
    },
    buttons: {
      continue: 'Continue',
    }
  }
};