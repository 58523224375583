module.exports = {
  label: {
    changePassword: "Change Password",
    form: {
      validation: {
        currentPassword: 'current password',
        newPassword: 'new password',
        reEnterPassword: 're-enter new password',
      },
      placeholder: {
        currentPassword: 'Enter Current Password',
        newPassword: 'Enter New Password',
        reEnterPassword: 'Re-enter New Password',
        passwordFormateNote: '*Length 8 or longer, includes at least one capital, small, special character and one number. Ex: Miradoor123*',
      }
    },
    buttons: {
      update: 'Change Password',
    }
  }
};