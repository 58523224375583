<template>
  <div>
    <b-form-group>
      <label class="form-label">{{ lang.label.form.uploadProperty }}<span class="text-danger">{{lang.label.form.asterisk}}</span></label>
      <p class="text-grey">{{ lang.label.form.uploadLimit }}</p>
      <div class="position-relative dropzone-flex">
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          :useCustomSlot="true"
          @vdropzone-thumbnail="thumbnail"
          @vdropzone-max-files-exceeded="filesExceeded"
          @vdropzone-error="fileError"
          @vdropzone-file-added="filesAdded"
          @vdropzone-success="fileSuccess"
          @vdropzone-removed-file="filesRemoved"
        >
          <div class="dropzone-custom-content">
            <div class="uplaod-icon">
              <span class="material-icons">publish</span>
            </div>
            <p class="dropzone-custom-title">{{ lang.label.form.placeholder.mediaUpload }}</p>
          </div>
        </vue-dropzone>
      </div>
      <span
        class="invalid-feedback"
        style="display: block !important"
        v-if="documentSizeError"
        >{{ lang.label.errorMessages.fileSizeExceeded }}</span
      >
      <span
        class="invalid-feedback"
        style="display: block !important"
        v-else-if="documentErrorMessage"
        >{{ documentErrorMessage }}</span
      >
      <ValidationObserver ref="mediaObserver">
        <ValidationProvider
          :name="lang.label.form.validation.uploadProperty"
          :rules="totalFilesAdded.length > 0 ? '' : 'required'"
          v-slot="validationContext"
        >
          <b-form-file
            v-model="models.document"
            accept=".png,.jpg,.mp4,.mkv"
            type="text"
            :class="{ 'is-invalid': validationContext.errors[0] }"
            :state="getValidationState(validationContext)"
            disabled
            multiple
            v-show="false"
          >
            <template slot="file-name" slot-scope="{ names }">{{
              getDocNameShort(names[0])
            }}</template>
          </b-form-file>
          <b-form-invalid-feedback
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </ValidationObserver>
    </b-form-group>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapGetters } from "vuex";
export default {
  name: "mediaUploadSection",
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: ["selectedMedia", 'disabled'],
  computed: {
    ...mapGetters({
      ImageUrl: "ImageUrl",
    }),
  },
  data() {
    return {
      lang: this.$lang.add_properties,
      models: {
        document: [],
      },
      dropzoneOptions: {
        autoProcessQueue: false,
        previewTemplate: this.template(),
        url: " ",
        maxImageFilesize: 10,
        maxVideoFilesize: 50,
        maxFiles: 20,
        addRemoveLinks: true,
        acceptedFiles: ".mkv,.jpg,.png,.mp4",
        paramName: "asdasd",
      },
      documentError: false,
      documentErrorMessage: "",
      documentSizeError: false,
      uploading: false,
      deletedMedia: [],
      totalFilesAdded: [],
    };
  },
  watch: {
    selectedMedia: {
      handler() {
        if (
          this.selectedMedia.length > 0 &&
          this.selectedMedia.length !== this.models.document.length
        ) {
          let extArr = ["jpg", "jpeg", "png"];
          this.selectedMedia.forEach((media) => {
            let isImage = extArr.includes(
              media.file.split(".")[media.file.split(".").length - 1]
            );
            const file = {
              name: media.file,
              type: isImage ? "image/jpeg" : "video/mp4",
              dataURL: media.file,
              id: media.id,
              size: 100,
            };
            this.$refs.myVueDropzone.manuallyAddFile(file, this.ImageUrl + media.file);
          });
        }
      },
      deep: true,
    },
    disabled() {
      if (this.disabled){
        this.$refs.myVueDropzone.disable();
      }
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    /**
     * Handles uploaded thumbnail preview section
     * @returns HTML template for file preview
     */
    template: function () {
      // <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
      return `<div class="dz-preview dz-file-preview">
                            <div class="dz-image">
                                <div class="h-100" data-dz-thumbnail-bg></div>
                            </div>
                            <div class="dz-details">
                                <div class="dz-size"><span data-dz-size></span></div>
                                <div class="dz-filename"><span data-dz-name></span></div>
                            </div>
                            <div class="dz-error-message"><span data-dz-errormessage></span></div>
                            <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                            <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                        </div>
                `;
    },
    /**
     * Handles thumbnail image for uploaded file
     * @param {File} file uploaded file
     * @param {String} dataUrl Thumbnail image as base64
     */
    thumbnail: function (file, dataUrl) {
      let j, len, ref, thumbnailElement;
      if (file.previewElement) {
        file.previewElement.classList.remove("dz-file-preview");
        ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
        for (j = 0, len = ref.length; j < len; j++) {
          thumbnailElement = ref[j];
          thumbnailElement.alt = file.name;
          let img = document.createElement("img");
          img.src = dataUrl;
          thumbnailElement.appendChild(img);
        }
        return setTimeout(
          (function (_this) {
            return function () {
              return file.previewElement.classList.add("dz-image-preview");
            };
          })(this),
          1
        );
      }
    },
    /**
     * Handles error visibility
     */
    checkForErrors() {
      if (!(this.models.document && this.models.document.length)) {
        this.documentError = true;
      }
      if (!this.models.description) {
        $("#description").focus();
        $("#description").blur();
      }
    },
    /**
     * Handles event for success on file upload
     * @param {File} file Uploaded file
     */
    fileSuccess: function (file) {
      this.uploading = false;
    },
    /**
     * Handles event for error on file upload
     * @param {File} file Uploaded file
     * @param {Error} error Error initiated by user
     */
    filesRemoved: function (file, error) {
      if (file.id) {
        this.deletedMedia.push(file.id);
        this.$emit('handleDeletedMedia', this.deletedMedia);
      }
      this.models.document = this.$refs.myVueDropzone.dropzone.files.filter((media) => {
        return media.accepted == true || !media.manuallyAdded;
      });
      this.totalFilesAdded = this.$refs.myVueDropzone.dropzone.files.filter((media) => {
        return media.accepted !== false;
      });
      this.validatedMediaObserver();
      this.$emit("handleFileChange", this.models.document);
      this.uploading = false;
    },
    /**
     * Handles event for file size exceeded error on file upload
     * @param {File} file Uploaded file
     */
    filesExceeded(file) {
      this.$refs.myVueDropzone.removeFile(file);
    },
    /**
     * Handles event for file not acceptable error on file upload
     * @param {File} file Uploaded file
     * @param {String} message Error message
     * @param {object} xhr xhr request
     */
    fileError(file, message, xhr) {
      this.documentErrorMessage = message;
      this.$refs.myVueDropzone.removeFile(file);
      // this.$refs.myVueDropzone.removeAllFiles();
    },
    /**
     * Handles event for file added on file upload
     * @param {File} file Selected file
     */
    filesAdded(file) {
      this.validateSelectedMedia(file);
      if (!file.manuallyAdded) {
        this.validatedMediaObserver();
        this.models.document = this.$refs.myVueDropzone.dropzone.files.filter((media) => {
          return media.accepted == true || !media.manuallyAdded;
        });
        this.$emit("handleFileChange", this.models.document);
      }
      setTimeout(() => {
        this.totalFilesAdded = this.$refs.myVueDropzone.dropzone.files.filter((media) => {
          return media.accepted !== false;
        });      
      }, 200);

    },
    /**
     * Handles validation for selected file
     * @param {File} file Selected file
     */
    validateSelectedMedia(file) {
      this.documentErrorMessage = "";
      let extArr = ["jpg", "jpeg", "png"];
      let isImage = extArr.includes(
        file.name.split(".")[file.name.split(".").length - 1]
      );
      if (
        file.size >
        (isImage
          ? this.dropzoneOptions.maxImageFilesize
          : this.dropzoneOptions.maxVideoFilesize) *
          1000 *
          1000
      ) {
        this.documentSizeError = true;
        this.$refs.myVueDropzone.removeFile(file);
      } else {
        this.documentSizeError = false;
        if (!isImage) {
          let thumbnail = this.getIconUrl("media-file.svg");
          this.$refs.myVueDropzone.dropzone.emit("thumbnail", file, thumbnail);
          this.$refs.myVueDropzone.dropzone.emit("complete", file);
        }
      }
    },
    /**
     * Handles validation for selected media
     */
    validatedMediaObserver() {
      if (this.$refs.mediaObserver.flags.validated) {
        this.$refs.mediaObserver.validate();
      }
    },
  },
};
</script>
