<!-- content from rented_property_details.js -->
<template>
    <div>
        <div class="reports-filter">
            <div class="d-md-flex align-items-center flex-wrap justify-content-xl-end">
                <div class="input-select-box payment-history-box">
                    <div class="d-md-flex ">
                        <b-form-group>
                            <div class="d-flex align-items-center">
                                <label class="mr-2 mb-0 text-nowrap">{{ lang.label.dateFilter }}</label>
                                <div class="date-range">
                                    <el-date-picker v-model="dateRangeSelected" type="daterange" range-separator="-"
                                        :start-placeholder="lang.label.from" :end-placeholder="lang.label.to" size="sm"
                                        :append-to-body="false" align="right">
                                    </el-date-picker>
                                </div>
                            </div>
                        </b-form-group>
                    </div>
                </div>

                <b-form-group>
                    <div class="d-flex flex-wrap align-items-center filter-buttons">
                        <div class="form-buttons">
                            <b-btn variant="primary" size="sm">{{ lang.label.buttons.filter }}</b-btn>
                            <b-dropdown id="dropdown-1" variant="primary" size="sm" :text="lang.label.buttons.download"
                                right no-caret>
                                <b-dropdown-item>As CSV</b-dropdown-item>
                                <b-dropdown-item>As PDF</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                </b-form-group>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {

    },
    data() {
        return {
            lang: this.$lang.rented_property_details,            
            dateRangeSelected: '',
        };
    },
    mounted() {        
    },
    methods: {
    },
}
</script>

