<!-- content from rented_properties.js -->
<template>
  <div class="body-content">
    <div class="top-sticky">
      <div class="top-bar-radius"></div>
    </div>
    <div class="starts-section">
      <!-- body content header  -->
      <div class="page-breadcrumb">
        <div class="d-md-flex justify-content-between align-items-center">
          <div class="pg-bread">
            <b-breadcrumb class="bg-transparent p-0 mb-0" v-if='!isHidden'>
              <b-breadcrumb-item active>
                {{ lang.label.rentedProperties }}
              </b-breadcrumb-item>
              <b-breadcrumb-item active>
                <span class="material-icons align-middle">home</span>
                <span class="align-middle ml-1">8</span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
          <div class="pg-right">
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="search"
                type="text"
                :placeholder="lang.label.search"
                size="sm"
                class="data-search"
              ></b-form-input>
            </div>
          </div>
        </div>
      </div>

      <div class="rented-properties-list">
        <b-row>
          <b-col
            md="6"
            lg="4"
            xl="3"
            v-for="(property, index) in rentedProperties"
            :key="index"
          >
            <b-card no-body class="custom-card h-100">
              <b-card-body>
                <div
                  class="property-detail cursor-pointer"
                  @click="propertyDetails(property.id)"
                >
                  <h6 class="name">{{ property.address }}</h6>
                  <div class="property-picture">
                    <img :src="getPropertiesImg(property.img)" alt="property image" :class="!property.img ? 'placeholder-img' : ''" />
                  </div>
                  <div class="d-flex justify-content-between align-items-center pricing">
                    <h5 class="mb-0 property-price">{{ property.price }}</h5>
                    <b-badge variant="primary" pill>{{ property.type }}</b-badge>
                  </div>
                  <p class="mb-0 property-date">
                    {{ lang.label.nextDueDate }}: {{ property.rentDue }}
                  </p>
                  <p class="mb-0 property-date">
                    {{ lang.label.addedDate }}: {{ property.dateAdded }}
                  </p>
                </div>
              </b-card-body>
              <template #footer v-if="property.canPayRent">
                <b-btn variant="primary" @click="addPayRent(property.id)">{{ lang.label.buttons.payRent }}</b-btn>
              </template>
            </b-card>
          </b-col>
        </b-row>
        <div class="b-pagination-custom">
          <div class="d-flex justify-content-between">
            <p class="showing-record" v-if="rows == 1">{{ lang.label.oneRecord }}</p>
            <!--Need to align center-->
            <p class="showing-record w-100 text-center" v-else-if="rows == 0">{{ lang.label.noRecord }}</p> 
            <p class="showing-record" v-else>{{lang.label.showing + startIndex + 1 + lang.label.to + endIndex + lang.label.of + rows + lang.label.records}}</p>
            <b-pagination
              v-if="rows"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            >
              <template #first-text>
                <span class="go-first">
                  <img :src="getIconUrl('double-arrow.svg')" alt="" />
                </span>
              </template>
              <template #prev-text>
                <span class="go-prev">
                  <img :src="getIconUrl('single-arrow.svg')" alt="" />
                </span>
              </template>
              <template #next-text>
                <span class="go-next">
                  <img :src="getIconUrl('single-arrow.svg')" alt="" />
                </span>
              </template>
              <template #last-text>
                <span class="go-last">
                  <img :src="getIconUrl('double-arrow.svg')" alt="" />
                </span>
              </template>
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <payment-modal :rentTotal='rentTotal' :rentDetails='rentDetails' :isPaymentModalVisible="isPaymentModalVisible" @handleInitializedPaymentModal="handleInitializedPaymentModal" :handlePayment="handlePayment"></payment-modal>  
  </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import paymentModal from "../myaccount/paymentModal.vue"
import { mapGetters } from "vuex";
import mixin from "@/mixins/mixin";

export default {
  components: {
    'payment-modal': paymentModal
  },
  props:['isHidden', 'tableData'],
  mixins: [mixin],
  computed:{
    ...mapGetters({
      ImageUrl: "ImageUrl",
    }),
    rentedProperties(){
      let self = this;
      let currentPageData = []
      let filteredData = self.tableData.filter((el) => {
        return el.address.toLowerCase().includes(this.search.toLocaleLowerCase());
      });
      self.rows = filteredData.length
      self.startIndex = (self.currentPage * self.perPage) - self.perPage ;
      self.endIndex = (self.currentPage * self.perPage);
      self.endIndex = self.endIndex >  self.rows ? self.rows : self.endIndex;
      for(let i = self.startIndex ; i <= self.endIndex -1; i++){
        currentPageData.push(filteredData[i])
      }
      return currentPageData;
    }
  },
  data() {
    return {
      lang: this.$lang.rented_properties,
      data: jsondata.rentedProperties,
      isPaymentModalVisible:false,
      rentDetails:[],
      search: "",
      rows: 0,
      perPage:10,
      currentPage: 1,
      startIndex:1,
      endIndex:10,
      rentTotal: 0
    };
  },
  watch: {
    // search() {
    //   this.$emit("handleSearch", this.search);
    // },
  },
  mounted(){
  },
  methods: {
    getPropertiesImg(img) {
      if (!img) {
        return require("@/assets/styles/img/properties/logo.svg");
      }
      return this.ImageUrl + img;
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    propertyDetails(id) {
      this.$router.push({ name: "rented-properties-details", params: { id: id } });
    },
    /**
     * payment initialize with summary
     * @param {number} id Property id
     */
    addPayRent(id){
      let self = this;
      let data = {
        property : id
      }
      self.rentDetails = []
      self.$store.dispatch('payRentSummary',data).then((response) => {
        if (response.status == 200 ) {
          let rents = response.data.rent_summary;
          self.rentTotal = response.data.total_amount;
          self.rentDetails['summary'] = []
          self.rentDetails['property'] = id;
          rents.forEach(rent => {
              self.rentDetails['summary'].push({
                  startDate : self.formatDateToShow(rent.rent_start_date),
                  endDate : self.formatDateToShow(rent.rent_end_date),
                  rentAmount : rent.rent_amount,
                  maintenanceFee :rent.maintenance_fee,
                  depositeAmount : rent.deposit_amount,
                  lateRentAmount : rent.late_rent_amount,
                  total : rent.total
                });
            });
          self.isPaymentModalVisible = true;
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      })

    },
    handleInitializedPaymentModal(){
      this.isPaymentModalVisible = false;
    },
    /**
     * handle payment in tenant property
     */
    handlePayment(){
      this.isPaymentModalVisible = false;
      this.getPropertiesList()
    },
    /**
    * call the list of added property api
    */
    getPropertiesList() {
      let self = this;
      let data = {
        page: 1,
        size: 100000,
        data: {
          type: 3,
        },
      };
      self.tableData = [];
      self.$store.dispatch("listOfProperties", data).then((response) => {
        if (response.status == 200) {
          self.pushIntotableData(response)
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * update the tableData
     * @param {object} response details of propery list
     */
     pushIntotableData(response) {
      let self = this;
      self.tableData = [];
      response.data.results.forEach((v) => {
        if(v.income.length == 1) {
          v.income.unshift(0);
        }
        if(v.expense.length == 1) {
          v.expense.unshift(0);
        }
        let sumOfIncome = v.income.reduce((a, v) => a + v, 0);
        let sumOfExpense = v.expense.reduce((a, v) => a + v, 0);
        self.tableData.push({
          id: v.id,
          amount: "$" + v.rent_amount,
          address: v.address,
          status: v.status_name,
          status_id: v.status_id,
          img: v.property_image,
          dateAdded: self.formatDateToShow(v.created_date),
          type: v.type_name,
          rentDue: self.formatDateToShow(v.next_due_date),
          created_by: v.created_by,
          series: [
            {
              name: "Income",
              data: v.income,
            },
            {
              name: "Expense",
              data: v.expense,
            },
          ],
          totalExpense: sumOfExpense,
          totalIncome: sumOfIncome,
          canPayRent : v.can_pay_rent
        });
      });
    },
  },
};
</script>
