module.exports = {
    label: {
      register: "Crear Cuenta",
      alreadyAccount: "¿Ya Tienes Cuenta?",
      signIn: "Inicia Sesión",
      slogan: "Tu única Plataforma Para El Manejo De Propiedades",
      selectUserType: "Seleccione Tipo De Usuario",
      onBoard: 'Subscribirse Como',
      enterYourDetails: 'Entra Tus Detalles',
      agreeTerms: "He Leído y Estoy De Acuerdo Con Los",
      termsConditions: 'Términos y Condiciones',
      privacyPolicy: 'Políticas de Privacidad',
      verification: 'Verificación',
      codeSentEmail: 'Un Código De Verificación De 6 Dígitos Fue Enviado A Su Correo Electrónico.',
      didNotReceive: 'No Lo Recibí?',
      resend: 'Reenviar',
      and: 'y',
      emailLabel: 'Correo Electrónico',
      signGoogle: 'Regístrese Con Google',
      signFacebook: 'Regístrese Con Facebook',
      form: {      
        validation: {
          firstName: 'nombre',
          lastName: 'apellido',
          email: 'correo electrónico',
          brokerId: 'id de agente inmobiliario',
          realtorId: 'id de agente inmobiliario',
          brokerRealtorId: 'id de corredor / agente inmobiliario',
          password: 'contraseña',
          confirmPassword: 'confirmar contraseña',
          termsConditionAgree: 'términos y condiciones'
        },
        placeholder: {
          firstName: 'Nombre',
          lastName: 'Apellido',
          email: 'Correo Electrónico',
          brokerId: 'ID De Corredor',
          realtorId: 'ID De Agente Inmobiliario',
          brokerRealtorId: 'ID De Corredor / Agente Inmobiliario',
          password: 'Contraseña',
          confirmPassword: 'Confirmar Contraseña',
          brokerIdNote: '*Primera Parte 2 Letras - Segunda Parte 4 Numeros, ej: ab-1234',
          passwordFormateNote: '*Longitud De 8 O Más, Incluir Al Menos Una Mayúscula, Minúscula, Caracteres Especiales y Un Número. Ej: Miradoor123*',
        }
      },
      buttons: {
        continue: 'Continuar',
        verify: 'Verificar'
      }
    }
  };