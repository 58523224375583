module.exports = {
  label: {
    paymentTitle: "Resumen De Pago",
    paymentRentTitle : 'Resumen De Detalles Del Alquiler',
    addNewCard: "Añadir Nueva Tarjeta",
    amount:'Cantidad',
    month:'Mensual',
    year : 'Anualmente',
    duration: "Duración",
    to: "Hasta",
    grandTotal : 'Gran Total',
    startDate : 'Fecha De Inicio',
    endDate : 'Fecha Final',
    rentAmount :'Precio Del Alquiler',
    maintenanceFee : 'Tarifas De Mantenimiento',
    depositeAmount : 'Cantidad Del Depósito',
    lateRentAmount : 'Cargos Por Pagos Atrasados',
    total : 'Total',
    summary: 'Resumen',
    form: {
      label: {
        selectedCard: "Seleccione la Tarjeta",
        cvv:'CVV',
      },
      validation: {
        selectedCard: "seleccione la tarjeta",
        cvv:'CVV',
      },
      placeholder: {
        select: "Seleccione",
        enter:'Ingrese CVV'
      }
    },
    buttons: {
      payNow : 'Pagar Ahora',
    }
  }
}