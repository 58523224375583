module.exports = {
    label: {
      verifyEmialAlert: "Verifique su correo electrónico para obtener acceso completo a Miradoor, es posible que no pueda añadir contactos o propiedades.",
      propertiesPortfolio: 'Cartera De Propiedades',
      deleteMember: '¿Está seguro de que desea eliminar este gasto?',
      expenseName: 'Nombre Del Gasto',
      expenseAmount: 'Monto Del Gasto',
      expenseDate: 'Fecha De Gasto',
      uploadProof: 'Subir Prueba Del Gasto',
      confirmTitle: '¿Estas Seguro?',
      confirmMsg: '¿Desea cambiar el estado a?',
      deleteConfirmMsg: "¿Quieres borrar esto?",
      confirmAdd:'Añadir inquilino',
      confirmWarning:'¿Confirmar?',
      expenseDelete:'¿Está seguro de que desea eliminar este gasto?',
      expense:'Gasto',
      income:'Ingresos',
      ValidationTitle: 'Advertencia',
      expenseDetails: "Detalles Del Gasto",
      areYouSureDelete: '¿Está Seguro Que Desea Eliminar Esta Propiedad?',
      noRevertBackProperty: '*No podrá revertir después de eliminar esta propiedad.',
      tableHeader: {
        address: "Dirección De La Propiedad",
        amount: "Precio del alquiler",
        rentDue: "Próxima Fecha de Pago",
        type: "Tipo De Propiedad",
        dateAdded: "Fecha Que Se Agrego",
        status: "Estado",
        actions: "Acciones",
      },
      tableReportHeader: {
        name: "Inquilino/Propietario/Agente Inmobiliario",
        tenant: "Inquilino",
        leaseDuration: "Duración De Arrendamiento",
        paidDateTime: "Fecha Pagada",
        billingType: "Tipo De Facturación",
        amount: "Cantidad",
        expenseName: "Nombre Del Ingresos/Gasto",
        actions: "Acciones",
      },
      buttons: {
        sendCode: 'Enviar Código De Verificación',
        add: 'Agregar',
        update: 'Actualizarlos',
        yes: 'Sí',
        no: 'No',
        ok:'Aceptar'
      },
      tabs: {
        all: 'Todo',
        broker: 'Agente Inmobiliario',
        tenant: 'Inquilino',
        owner: "Propietario",
        other: 'Otros'
      },
      tooltip:{
        edit : 'Editar Propiedad',
        view : 'Ver Propiedad',
        delete:'Eliminar Propiedad',
        expenseView:'Ver Gastos',
        expenseDelete:'Eliminar Gastos',
        miscellaneous: 'Ver Documento De Arrendamiento.',
    }
    }
  };