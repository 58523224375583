import Swal from "sweetalert2";
import subscriptionValidation_mixin from '@/mixins/subscriptionValidation_mixin'
export default {
  mixins: [subscriptionValidation_mixin],
  data() {
    return {
      paymentFrequencyVal: [1, 3, 6, 12],
      isAddPropertyPage : false,
      canLeave:true,
      initialValue:{},
      amountTypeOption: {
        1: this.$lang.property_details.label.amountType.income,
        2: this.$lang.property_details.label.amountType.expense,
      },
      propertyStatusOption: {
        1: this.$lang.add_properties.label.form.placeholder.available,
        2: this.$lang.add_properties.label.form.placeholder.rented,
      },
      paymentFrequencyOption: {
        0: 'N/A',
        1: this.$lang.property_details.label.paymentFrequency.monthly,
        2: this.$lang.property_details.label.paymentFrequency.quarterly,
        3: this.$lang.property_details.label.paymentFrequency.haldYearly,
        4: this.$lang.property_details.label.paymentFrequency.yearly,
      }
    }
  },
  watch:{
    models:{
      handler(val){
        if(this.isAddPropertyPage){
          this.canLeave = JSON.stringify(val) === JSON.stringify(this.initialValue) ? true : false
        }
     },
     deep: true
    }
  },
  computed: {
    /**
     * @returns selected tenant
     */
    getTenant() {
      return this.selectedContact.name
        ? this.selectedContact.name + " - " + this.selectedContact.email
        : "-";
    },
    /**
     * @returns selected lease period
     */
    getLeasePeriod() {
      return this.models.startDate && this.models.endDate
        ? this.formatDateToSlash(this.models.startDate) +
        " to " +
        this.formatDateToSlash(this.models.endDate)
        : "-";
    },
    /**
     * @returns lease amount based on inputs
     */
    getLeaseAmount() {
      let period = this.paymentFrequencyVal[this.models.paymentFrequency - 1];
      return this.models.rentAmount && period
        ? "$" +
        parseFloat((parseFloat(this.models.rentAmount)) *
          period).toFixed(2)
        : "-";
    },
    /**
     * @returns deposit amount based on inputs
     */
    getDepositAmount() {
      return this.models.depositAmount
        ? "$" + parseFloat(this.models.depositAmount).toFixed(2)
        : "-";
    },
    /**
     * @returns maintenance amount based on inputs
     */
    getMaintenanceAmount() {
      let period = this.paymentFrequencyVal[this.models.paymentFrequency - 1];
      return this.maintenanceFee &&
        period
        ? "$" + parseFloat((parseFloat(this.maintenanceFee) *
          period)).toFixed(2)
        : "-";
    },
    /**
     * @returns first month amount due based on inputs
     */
    getFirstMonthAmountDue() {
      let period = this.paymentFrequencyVal[this.models.paymentFrequency - 1];
      return this.models.rentAmount &&
        this.maintenanceFee &&
        this.models.depositAmount &&
        period
        ? "$" +
        parseFloat(((parseFloat(this.models.rentAmount) + parseFloat(this.maintenanceFee)) *
          period +
          parseFloat(this.models.depositAmount))).toFixed(2)
        : "-";
    },
    /**
     * @returns recurring amount due by tenant based on inputs
     */
    getRecurringRentalAmountByTenant() {
      let period = this.paymentFrequencyVal[this.models.paymentFrequency - 1];
      return this.models.rentAmount &&
        this.maintenanceFee &&
        period
        ? "$" +
        parseFloat(((parseFloat(this.models.rentAmount) + parseFloat(this.maintenanceFee)) *
          period)).toFixed(2)
        : "-";
    },
    /**
     * @returns conditional required validation for closing cost commission account
     */
    getCommissionDepositAccountValidation() {
      return (this.getClosingCostCommission !== '-' && this.getClosingCostCommission) || (this.getRecurringRentalFee !== '-' && this.getRecurringRentalFee);
    },
    /**
     * @returns first month broker cost commission amount based on inputs
     */
    getClosingCostCommission() {
      let period = this.paymentFrequencyVal[this.models.paymentFrequency - 1];
      if (this.models.closingCostCurrency == 2) {
        return this.models.closingCostCommission
          ? parseFloat(this.models.closingCostCommission) + "%"
          : "-";
      } else {
        return this.models.closingCostCommission && period
          ? "$" + parseFloat(parseFloat(this.models.closingCostCommission) * period).toFixed(2)
          : "-";
      }
    },
    /**
     * @returns recurring rental admin fee amount based on inputs
     */
    getRecurringRentalFee() {
      let period = this.paymentFrequencyVal[this.models.paymentFrequency - 1];
      if (this.models.brokerRecurringCurrency == 2) {
        return this.models.brokerRecurringRentalFee
          ? parseFloat(this.models.brokerRecurringRentalFee) + "%"
          : "-";
      } else {
        return this.models.brokerRecurringRentalFee
          ? "$" + parseFloat(parseFloat(this.models.brokerRecurringRentalFee) * period).toFixed(2)
          : "-";
      }
    },
    /**
     * Recurring Rental Fee = % of Rent Amount, or A Fixed Amount input
     * @returns recurring rental admin fee amount to broker based on inputs
     */
    getRecurringRentalFeeToBroker() {
      let period = this.paymentFrequencyVal[this.models.paymentFrequency - 1];
      let leaseAmount =
        parseFloat(this.models.rentAmount);
      if (this.models.brokerRecurringCurrency == 2) {
        return this.models.brokerRecurringRentalFee && leaseAmount
          ? "$" +
          parseFloat(((leaseAmount * parseFloat(this.models.brokerRecurringRentalFee)) / 100) *
            period).toFixed(2)
          : "-";
      } else {
        return this.models.brokerRecurringRentalFee && period
          ? "$" + parseFloat(parseFloat(this.models.brokerRecurringRentalFee) * period).toFixed(2)
          : "-";
      }
    },
    /**
     * Closing Cost Commission = % of Rent Amount, or A Fixed Amount input
     * @returns first month broker cost commission amount to broker based on inputs
     */
    getClosingCostCommissionToBroker() {
      let period = this.paymentFrequencyVal[this.models.paymentFrequency - 1];
      let amountDueFirstMonth =
        parseFloat(this.models.rentAmount);
      if (this.models.closingCostCurrency == 2) {
        return this.models.closingCostCommission && amountDueFirstMonth
          ? "$" +
          parseFloat(((amountDueFirstMonth * parseFloat(this.models.closingCostCommission)) / 100) *
            period).toFixed(2)
          : "-";
      } else {
        return this.models.closingCostCommission && period
          ? "$" + parseFloat(parseFloat(this.models.closingCostCommission) * period).toFixed(2)
          : "-";
      }
    },
    /**
     * @returns first month to owner amount based on inputs
     */
    getFirstMonthToOwner() {
      let period = this.paymentFrequencyVal[this.models.paymentFrequency - 1];
      let amountDueFirstMonth =
        (parseFloat(this.models.rentAmount) + parseFloat(this.maintenanceFee)) * period +
        parseFloat(this.models.depositAmount);
      let rentAmount = parseFloat(this.models.rentAmount) * period;
      let closingCostCommission = this.isBrokerAvailable ? (this.models.closingCostCommission || 0) : 0;
      if (this.models.closingCostCurrency == 2) {
        return amountDueFirstMonth && period
          ? "$" +
          parseFloat((amountDueFirstMonth -
            (rentAmount * parseFloat(closingCostCommission)) /
            100)).toFixed(2)
          : "-";
      } else {
        return period
          ? "$" +
          parseFloat((amountDueFirstMonth -
            parseFloat(closingCostCommission) * period)).toFixed(2)
          : "-";
      }
    },
    /**
     * @returns recurring rental amount due to owner based on inputs
     */
    getRecurringRentalAmountDueToOwner() {
      let period = this.paymentFrequencyVal[this.models.paymentFrequency - 1];
      let leaseAmount =
        parseFloat(this.models.rentAmount) + parseFloat(this.maintenanceFee);
      let rentAmount = parseFloat(this.models.rentAmount);
      let brokerRecurringRentalFee = this.isBrokerAvailable ? (this.models.brokerRecurringRentalFee || 0) : 0;
      if (this.models.brokerRecurringCurrency == 2) {
        return leaseAmount && period
          ? "$" +
          parseFloat((leaseAmount -
            (rentAmount * parseFloat(brokerRecurringRentalFee)) / 100) *
            period).toFixed(2)
          : "-";
      } else {
        return  period
          ? "$" +
          parseFloat((leaseAmount - parseFloat(brokerRecurringRentalFee)) * period).toFixed(2)
          : "-";
      }
    },
    /**
     * @returns True is user type is realtor and property manager is not selected t
     */
    isBrokerNoteVisible() {
      return this.models.userType == '1' && !this.selectedBrokerContact.id;
    }
  },
  created(){
    let self = this
    self.isAddPropertyPage = self.$route.name == "add-properties" ? true : false
    if(self.isAddPropertyPage){
      self.initialValue = {...self.models}
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.handler)
  },
  beforeRouteLeave(to, from, next) {
    if (!this.canLeave) {
      if (!window.confirm("Leave site?\nChanges that you made may not be saved.")) {
        return;
      }
    }
    window.removeEventListener('beforeunload', this.handler)
    next();
  },
  destroyed(){
    window.removeEventListener('beforeunload', this.handler)
  },
  methods: {
    /**
     * show alert message when reload the page without published the property 
     * @returns alert message
     */
    handler : function(e){
      if(!this.canLeave || this.$route.name == 'edit-properties'){
        var confirmationMessage = 'It looks like you have been editing something. '
        + 'If you leave before saving, your changes will be lost.';
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
      }
    },
    /**
     * Handles restriction of input character length
     * @param {Event} event User event used
     * @param {number} len Length to be used
     */
    restrictCharcterLen(event, len) {
      // let input = event.target;
      // if (input.value.length > len) {
      //   input.value = input.value.slice(0, 500)
      //   let resetEvent = new InputEvent("input");
      //   input.dispatchEvent(resetEvent);
      // }
    },
    addLeaseAgreement() {
      let self = this;
      if (parseInt(self.models.userType) === 1 && !self.selectedOwnerContact.id) {
        console.log("Select Owner First");
        Swal.fire({
          title: self.lang.label.ownerValidationTitle,
          text: self.lang.label.ownerValidationMsg,
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: self.lang.label.buttons.ok,
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        });
      } else if(parseInt(self.statusSelected) == 1){
        let payloadData = {
          type : 2
        }
        self.checkSubscriptionValidation(payloadData).then((response) => {
          if(response){
            self.updateLeaseSidebar(true);
          }
        })
      }else{
        self.updateLeaseSidebar(true);
      }
    },
    /**
     * Handles generating specific address object with given map address component object
     * @param {object} address_components Address object that contains all data retured from map
     * @returns Modelled address object with specific address fields
     */
    getAddressObject(address_components) {
      let ShouldBeComponent = {
        home: ["street_number"],
        postal_code: ["postal_code"],
        street: ["street_address", "route"],
        region: [
          "administrative_area_level_1",
          "administrative_area_level_2",
          "administrative_area_level_3",
          "administrative_area_level_4",
          "administrative_area_level_5"
        ],
        city: [
          "locality",
          "sublocality",
          "sublocality_level_1",
          "sublocality_level_2",
          "sublocality_level_3",
          "sublocality_level_4"
        ],
        country: ["country"]
      };

      let address = {
        home: "",
        postal_code: "",
        street: "",
        region: "",
        city: "",
        country: ""
      };
      address_components.forEach(component => {
        for (let shouldBe in ShouldBeComponent) {
          if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
            if (shouldBe === "country") {
              address[shouldBe] = component.short_name;
            } else {
              address[shouldBe] = component.long_name;
            }
          }
        }
      });
      return address;
    },
    /**
     * Handles prefilling location based on selected location on map
     * @param {object} location Selected location
     */
    handleLocationPrefill(location) {
      let self = this;
      let address = self.getAddressObject(location.address_components);
      self.models.zoneCode = address.postal_code;
      self.models.country = address.country;
      self.models.state = address.region;
      self.models.city = address.city;
      self.models.streetRoadName = address.street;
      // self.models.blockPropertynumber = address.home;
    },
    /**
     * Handles showing lease submit message
     */
    showLeaseSubmitMessage() {
      let self = this;
      Swal.fire({
        icon: 'success',
        title: self.lang.label.success,
        html: self.statusSelected == '1' ?  `${self.lang.label.leaseSubmitlert} <strong>${self.lang.label.leaseSubmitlertEnd}</strong>` : self.lang.label.leaseSubmitlertRented,
        // text: self.statusSelected == '1' ? self.lang.label.leaseSubmitlert : self.lang.label.leaseSubmitlertRented,
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: self.lang.label.buttons.ok,
        focusConfirm: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      })
    },
  },
}