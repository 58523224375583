<!-- content from add_properties.js -->
<template>
  <div class="body-content">
    <div class="top-sticky">
      <div class="top-bar-radius"></div>
    </div>
    <div class="starts-section" v-loading="loading">
      <!-- alert component -->
      <alert-section></alert-section>

      <!-- body content header  -->
      <div class="page-breadcrumb property-breadcrumbs">
        <div class="d-xl-flex justify-content-between align-items-center add-pro-bread">
          <div class="pg-bread">
            <b-btn variant="link" class="p-0 mr-3 primary-text" @click="goBack()"
              ><span class="material-icons align-middle">arrow_back</span></b-btn
            >
            <b-breadcrumb class="bg-transparent p-0 mb-0">
              <b-breadcrumb-item active>
                {{ lang.label.addProperties }}
              </b-breadcrumb-item>
            </b-breadcrumb>

            <!-- this button for mobile -->
            <div class="display-resp-button d-md-none">
              <b-btn
                variant="primary"
                size="sm"
                class="btn-mw"
                @click="addPropertyConfirmation()"
                >+ {{ lang.label.buttons.add }}</b-btn
              >
              <b-btn
                variant="primary"
                size="sm"
                class="btn-mw"
                @click="cancelPropertyConfirmation()"
                >{{ lang.label.buttons.cancel }}</b-btn
              >
            </div>
          </div>

          <div class="pg-right">
            <div class="d-flex align-items-center flex-wrap">
              <div class="d-xl-flex align-items-center mb-3">
                <div class="text-nowrap property-status-label">
                  {{ lang.label.propertyStatus }}
                </div>
                <ValidationProvider
                  :name="lang.label.form.validation.propertyStatus"
                  rules="required"
                  v-slot="validationContext"
                >
                  <el-select
                    v-model="statusSelected"
                    placeholder="Select"
                    size="small"
                    :class="{ 'is-invalid': validationContext.errors[0] }"
                    :state="getValidationState(validationContext)"
                    disabled
                  >
                    <el-option
                      v-for="item in statusOption"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <b-form-invalid-feedback
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </div>
              <!-- this button for desktop -->
              <b-btn
                variant="primary"
                size="sm"
                class="text-nowrap btn-mw d-none d-md-inline"
                @click="addPropertyConfirmation()"
                >+ {{ lang.label.buttons.add }}</b-btn
              >
              <b-btn
                variant="primary"
                size="sm"
                class="text-nowrap btn-mw d-none d-md-inline"
                @click="cancelPropertyConfirmation()"
                >{{ lang.label.buttons.cancel }}</b-btn
              >
            </div>
          </div>
        </div>
      </div>

      <!-- add properties section -->
      <div class="add-properties">
        <ValidationObserver ref="observer" v-slot="{ passes }">
          <b-form @submit.stop.prevent="passes(onSubmit)" autocomplete="off">
            <b-container fluid class="px-0">
              <b-row>
                <b-col lg="7" xl="8" class="border-right">
                  <media-upload-section
                    @handleFileChange="handleFileChange"
                  ></media-upload-section>
                </b-col>
                <b-col
                  lg="5"
                  xl="4"
                  class="property-right-section order-2 order-lg-3"
                >
                  <div class="property-right">
                    <div class="address-section">
                      <b-form-group class="mb-0">
                        <label class="form-label">{{ lang.label.form.address }}</label>
                      </b-form-group>
                      <b-form-group>
                        <!-- Google Map Section -->
                        <google-map 
                          :location="staticLocation"
                          :isMapAvailable="isMapAvailable"
                          @selectedLocation="selectedLocation"
                          @handleMapError="handleMapError"
                        ></google-map>
                        <ValidationProvider
                          :name="lang.label.form.validation.location"
                          :rules="isMapAvailable ? 'required' : ''"
                          v-slot="validationContext"
                        >
                          <div class="position-relative">
                            <b-form-input
                              v-model="mapCoords"
                              type="text"
                              :state="getValidationState(validationContext)"
                              :placeholder="lang.label.form.placeholder.location"
                              disabled
                              v-show="false"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>

                      <b-row>
                        <b-col cols="6" lg="6">
                          <b-form-group>
                            <label class="form-label">{{
                              lang.label.form.blockPropertynumber
                            }}<span class="text-danger">*</span></label>
                            <ValidationProvider
                              :name="
                                lang.label.form.validation.blockPropertynumber
                              "
                              rules="required"
                              v-slot="validationContext"
                            >
                              <div class="position-relative">
                                <b-form-input
                                  v-model="models.blockPropertynumber"
                                  type="text"
                                  :state="getValidationState(validationContext)"
                                  :placeholder="
                                    lang.label.form.placeholder
                                      .blockPropertynumber
                                  "
                                ></b-form-input>
                                <b-form-invalid-feedback
                                  >{{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </div>
                            </ValidationProvider>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6" lg="6">
                          <b-form-group>
                            <label class="form-label">{{
                              lang.label.form.streetRoadName
                            }}<span class="text-danger">*</span></label>
                            <ValidationProvider
                              :name="lang.label.form.validation.streetRoadName"
                              rules="required"
                              v-slot="validationContext"
                            >
                              <div class="position-relative">
                                <b-form-input
                                  v-model="models.streetRoadName"
                                  type="text"
                                  :state="getValidationState(validationContext)"
                                  :placeholder="
                                    lang.label.form.placeholder.streetRoadName
                                  "
                                ></b-form-input>
                                <b-form-invalid-feedback
                                  >{{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </div>
                            </ValidationProvider>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6" lg="6">
                          <b-form-group>
                            <label class="form-label">{{
                              lang.label.form.city
                            }}<span class="text-danger">*</span></label>
                            <ValidationProvider
                              :name="lang.label.form.validation.city"
                              rules="required"
                              v-slot="validationContext"
                            >
                              <div class="position-relative">
                                <b-form-input
                                  v-model="models.city"
                                  type="text"
                                  :state="getValidationState(validationContext)"
                                  :placeholder="
                                    lang.label.form.placeholder.city
                                  "
                                ></b-form-input>
                                <b-form-invalid-feedback
                                  >{{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </div>
                            </ValidationProvider>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6" lg="6">
                          <b-form-group>
                            <label class="form-label">{{
                              lang.label.form.state
                            }}<span class="text-danger">*</span></label>
                            <ValidationProvider
                              :name="lang.label.form.validation.state"
                              rules="required"
                              v-slot="validationContext"
                            >
                              <div class="position-relative">
                                <b-form-input
                                  v-model="models.state"
                                  type="text"
                                  :state="getValidationState(validationContext)"
                                  :placeholder="
                                    lang.label.form.placeholder.state
                                  "
                                ></b-form-input>
                                <b-form-invalid-feedback
                                  >{{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </div>
                            </ValidationProvider>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6" lg="6">
                          <b-form-group>
                            <label class="form-label">{{
                              lang.label.form.country
                            }}<span class="text-danger">*</span></label>
                            <ValidationProvider
                              :name="lang.label.form.validation.country"
                              rules="required"
                              v-slot="validationContext"
                            >
                              <div class="position-relative">
                                <b-form-input
                                  v-model="models.country"
                                  type="text"
                                  :state="getValidationState(validationContext)"
                                  :placeholder="
                                    lang.label.form.placeholder.country
                                  "
                                ></b-form-input>
                                <b-form-invalid-feedback
                                  >{{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </div>
                            </ValidationProvider>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6" lg="6">
                          <b-form-group>
                            <label class="form-label">{{
                              lang.label.form.zoneCode
                            }}</label>
                            <ValidationProvider
                              :name="lang.label.form.validation.zoneCode"
                              rules="numeric|min:4|max:6"
                              v-slot="validationContext"
                            >
                              <div class="position-relative">
                                <b-form-input
                                  v-model="models.zoneCode"
                                  type="text"
                                  :state="getValidationState(validationContext)"
                                  :placeholder="
                                    lang.label.form.placeholder.zoneCode
                                  "
                                ></b-form-input>
                                <b-form-invalid-feedback
                                  >{{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </div>
                            </ValidationProvider>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <div class="form-group">
                        <label class="form-label">{{
                          lang.label.userType
                        }}<span class="text-danger">*</span></label>
                        <ValidationProvider
                          :name="lang.label.form.validation.userType"
                          rules="required"
                          v-slot="validationContext"
                        >
                          <el-select
                            v-model="models.userType"
                            placeholder="Select"
                            
                            :class="{
                              'is-invalid': validationContext.errors[0],
                            }"
                            :state="getValidationState(validationContext)"
                          >
                            <el-option
                              v-for="item in userTypeEnum"
                              :key="item.value"
                              :label="item.text"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                          <b-form-invalid-feedback
                            >{{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </ValidationProvider>
                      </div>
                    </div>
                    <contact-select
                      title="propertyManager"
                      buttonTitle="selectBrokerContacts"
                      type="broker"
                      :userType="models.userType"
                      :isNotRequired="true"
                      :isNoteVisible="isBrokerNoteVisible"
                      :note="lang.label.propertyManagerAlert"
                      :selectedTenant="models.leaseAgreementDetails?.extra?.tenant"
                      @handleSelected="handleSelectedBroker"
                      @handleMemberSelected="handleSelectedStaff"
                    ></contact-select>
                    <contact-select
                      title="ownerDetails"
                      buttonTitle="selectOwnerContacts"
                      type="owner"
                      :selectedTenant="models.leaseAgreementDetails?.extra?.tenant"
                      @handleSelected="handleSelectedOwner"
                      v-if="models.userType == 1"
                    ></contact-select>
                    <b-form-group v-if="isLeaseAdded">
                      <label class="form-label">{{
                        lang.label.form.leaseAgreement
                      }}<span class="text-danger">*</span></label>
                      <ValidationProvider
                        :name="lang.label.form.validation.uploadDocument"
                        :rules="statusSelected == '2' || isLeaseAdded ? 'required' : ''"
                        v-slot="validationContext"
                      >
                        <div class="position-relative">
                          <b-form-file
                            v-model="models.uploadDocument"
                            :placeholder="lang.label.form.placeholder.uploadDocument"
                            :state="getValidationState(validationContext)"
                            :browse-text="lang.label.buttons.upload"
                            accept=".pdf,.jpeg,.jpg,.png"
                          ></b-form-file>
                          <b-form-invalid-feedback
                            >{{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group>
                      <b-btn block variant="primary" @click="addLeaseAgreement">{{
                        lang.label.buttons.assignLeaseAgreement
                      }}</b-btn>
                      <ValidationProvider
                        :name="lang.label.form.validation.assignLeaseAgreement"
                        :rules="{
                          required: statusSelected == '2' ? { allowFalse: false } : false,
                        }"
                        v-slot="validationContext"
                      >
                        <div class="position-relative">
                          <b-form-checkbox
                            v-model="isLeaseAdded"
                            :class="{
                              'is-invalid': validationContext.errors[0],
                            }"
                            :name="lang.label.assignLeaseAgreement"
                            value="selected"
                            :state="getValidationState(validationContext)"
                            v-show="false"
                          ></b-form-checkbox>
                          <b-form-invalid-feedback
                            >{{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="7" xl="8" class="border-right order-3 order-lg-2">
                  <b-row>
                    <b-col cols="6" xl="4">
                      <b-form-group>
                        <label class="form-label">{{ lang.label.form.rentAmount }}<span class="text-danger">*</span></label>
                        <ValidationProvider
                          :name="lang.label.form.validation.rentAmount"
                          rules="required|double:2"
                          v-slot="validationContext"
                        >
                          <div class="position-relative prepend-icon prepend-text">
                            <b-form-input
                              v-model="models.rentAmount"
                              type="text"
                              :state="getValidationState(validationContext)"
                              :placeholder="lang.label.form.placeholder.zero"
                            ></b-form-input>
                            <div class="icon">
                              <div class="pre-app-text primary-text bf-size">$</div>
                            </div>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" xl="4">
                      <b-form-group>
                        <label class="form-label">{{
                          lang.label.form.paymentPeriod
                        }}<span class="text-danger">*</span></label>
                        <ValidationProvider
                          :name="lang.label.form.validation.paymentPeriod"
                          rules="required"
                          v-slot="validationContext"
                        >
                          <div class="position-relative">
                            <el-select
                              v-model="models.paymentPeriod"
                              :placeholder="lang.label.form.placeholder.select"
                              :state="getValidationState(validationContext)"
                              :class="{
                                'is-invalid': validationContext.errors[0],
                              }"
                            >
                              <el-option
                                v-for="item in paymentPeriodList"
                                :key="item.value"
                                :label="item.text"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" xl="4">
                      <b-form-group>
                        <label class="form-label d-none d-md-block">{{
                          lang.label.form.securityDepositAmount
                        }}<span class="text-danger">*</span></label>
                        <label class="form-label d-md-none">{{
                          lang.label.form.securityDeposit
                        }}<span class="text-danger">*</span></label>
                        <ValidationProvider
                          :name="lang.label.form.validation.securityDepositAmount"
                          rules="required|double:2"
                          v-slot="validationContext"
                        >
                          <div class="position-relative prepend-icon prepend-text">
                            <b-form-input
                              v-model="models.securityDepositAmount"
                              type="text"
                              :state="getValidationState(validationContext)"
                              :placeholder="lang.label.form.placeholder.zero"
                            ></b-form-input>
                            <div class="icon">
                              <div class="pre-app-text primary-text bf-size">$</div>
                            </div>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" xl="4">
                      <b-form-group>
                        <label class="form-label">{{
                          lang.label.form.propertyType
                        }}<span class="text-danger">*</span></label>
                        <ValidationProvider
                          :name="lang.label.form.validation.propertyType"
                          rules="required"
                          v-slot="validationContext"
                        >
                          <div class="position-relative">
                            <el-select
                              v-model="models.propertyType"
                              :placeholder="lang.label.form.placeholder.select"
                              :state="getValidationState(validationContext)"
                              :class="{
                                'is-invalid': validationContext.errors[0],
                              }"
                            >
                              <el-option
                                v-for="item in propertyTypeList"
                                :key="item.value"
                                :label="item.text"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" xl="4">
                      <b-form-group>
                        <label class="form-label">{{
                          lang.label.form.maintenanceFee
                        }}<span class="text-danger">*</span></label>
                        <ValidationProvider
                          :name="lang.label.form.validation.maintenanceFee"
                          rules="required|double:2"
                          v-slot="validationContext"
                        >
                          <div class="position-relative prepend-icon prepend-text">
                            <b-form-input
                              v-model="models.maintenanceFee"
                              type="text"
                              :state="getValidationState(validationContext)"
                              :placeholder="lang.label.form.placeholder.zero"
                            ></b-form-input>
                            <div class="icon">
                              <div class="pre-app-text primary-text bf-size">$</div>
                            </div>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" xl="4">
                      <b-form-group>
                        <label class="form-label">{{
                          lang.label.form.propertyName
                        }}<span class="text-danger">*</span></label>
                        <ValidationProvider
                          :name="lang.label.form.validation.propertyName"
                          rules="required|max:25"
                          v-slot="validationContext"
                        >
                          <div class="position-relative">
                            <b-form-input
                              v-model="models.propertyName"
                              type="text"
                              :state="getValidationState(validationContext)"
                              :placeholder="lang.label.form.placeholder.enterName"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12">
                      <b-form-group>
                        <label class="form-label">{{
                          lang.label.form.aboutthisProperty
                        }}<span class="text-danger">*</span></label>
                        <ValidationProvider
                          :name="lang.label.form.validation.aboutthisProperty"
                          rules="required"
                          v-slot="validationContext"
                        >
                          <div class="position-relative">
                            <b-form-textarea
                              v-model="models.aboutthisProperty"
                              type="text"
                              :state="getValidationState(validationContext)"
                              :placeholder="
                                lang.label.form.placeholder.writeSomethingProperty
                              "
                              rows="5"
                              @keypress.native="restrictCharcterLen($event, 500)"
                              @keydown.native="restrictCharcterLen($event, 500)"
                              @keyup.native="restrictCharcterLen($event, 500)"
                            ></b-form-textarea>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" xl="4">
                      <b-form-group>
                        <label class="form-label">{{
                          lang.label.form.approxMtSquare
                        }}<span class="text-danger">*</span></label>
                        <ValidationProvider
                          :name="lang.label.form.validation.approxMtSquare"
                          rules="required|double:2"
                          v-slot="validationContext"
                        >
                          <div class="position-relative append-icon append-text">
                            <b-form-input
                              v-model="models.approxMtSquare"
                              type="text"
                              :state="getValidationState(validationContext)"
                              :placeholder="lang.label.form.placeholder.enter"
                              class="pr-5"
                            ></b-form-input>
                            <div class="icon">
                              <div class="pre-app-text primary-text bf-size bf700">
                                {{ lang.label.mtSquare }}
                              </div>
                            </div>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" xl="4">
                      <b-form-group>
                        <label class="form-label">{{
                          lang.label.form.propertyArea
                        }}<span class="text-danger">*</span></label>
                        <ValidationProvider
                          :name="lang.label.form.validation.propertyArea"
                          rules="required|double:2"
                          v-slot="validationContext"
                        >
                          <div class="position-relative append-icon append-text">
                            <b-form-input
                              v-model="models.propertyArea"
                              type="text"
                              :state="getValidationState(validationContext)"
                              :placeholder="lang.label.form.placeholder.enter"
                              class="pr-5"
                            ></b-form-input>
                            <div class="icon">
                              <div class="pre-app-text primary-text bf-size bf700">
                                {{ lang.label.mtSquare }}
                              </div>
                            </div>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" xl="4">
                      <b-form-group>
                        <label class="form-label">{{
                          lang.label.form.constructionYear
                        }}<span class="text-danger">*</span></label>
                        <ValidationProvider
                          :name="lang.label.form.validation.constructionYear"
                          rules="required"
                          v-slot="validationContext"
                        >
                          <div class="position-relative">
                            <el-date-picker
                              v-model="models.constructionYear"
                              :picker-options="pickerOptions"
                              type="year"
                              :placeholder="lang.label.form.placeholder.enterYear"
                              :state="getValidationState(validationContext)"
                              :class="{
                                'is-invalid': validationContext.errors[0],
                              }"
                              :clearable="false"
                              size="lg"
                            >
                            </el-date-picker>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" xl="4">
                      <b-form-group>
                        <label class="form-label">{{
                          lang.label.form.numberBedrooms
                        }}<span class="text-danger">*</span></label>
                        <ValidationProvider
                          :name="lang.label.form.validation.numberBedrooms"
                          rules="required|numeric|max_value:50"
                          v-slot="validationContext"
                        >
                          <div class="position-relative">
                            <b-form-input
                              v-model="models.numberBedrooms"
                              type="text"
                              :state="getValidationState(validationContext)"
                              :placeholder="lang.label.form.placeholder.enterNumber"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" xl="4">
                      <b-form-group>
                        <label class="form-label">{{
                          lang.label.form.numberBathrooms
                        }}<span class="text-danger">*</span></label>
                        <ValidationProvider
                          :name="lang.label.form.validation.numberBathrooms"
                          rules="required|numeric|max_value:50"
                          v-slot="validationContext"
                        >
                          <div class="position-relative">
                            <b-form-input
                              v-model="models.numberBathrooms"
                              type="text"
                              :state="getValidationState(validationContext)"
                              :placeholder="lang.label.form.placeholder.enterNumber"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" xl="4">
                      <b-form-group>
                        <label class="form-label">{{ lang.label.form.parking }}<span class="text-danger">*</span></label>
                        <ValidationProvider
                          :name="lang.label.form.validation.parking"
                          rules="required|numeric"
                          v-slot="validationContext"
                        >
                          <div class="position-relative">
                            <b-form-input
                              v-model="models.parking"
                              type="text"
                              :state="getValidationState(validationContext)"
                              :placeholder="lang.label.form.placeholder.enterNumber"
                              class="pr-5"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              >{{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group>
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="form-label mb-0">{{
                            lang.label.form.propertyAmenities
                          }}<span class="text-danger">*</span></label>
                          <b-btn
                            variant="primary"
                            size="sm"
                            v-b-toggle.amenitiesSelection
                            >{{ lang.label.buttons.addAmenities }}</b-btn
                          >

                          <!-- amenities sidebar component -->
                          <amenities-selection
                            :selectedAmenities="selectedAmenities"
                            @handleAmenities="handleAmenities"
                          ></amenities-selection>
                        </div>
                        <ValidationProvider
                          :name="lang.label.form.validation.propertyAmenities"
                          rules="required"
                          :custom-messages="{
                            required: lang.label.form.validation.selectAmenities,
                          }"
                          v-slot="validationContext"
                        >
                          <el-select
                            v-model="selectedAmenitiesId"
                            placeholder="Select"
                            size="small"
                            :class="{
                              'is-invalid': validationContext.errors[0],
                            }"
                            :state="getValidationState(validationContext)"
                            multiple
                            disabled
                            v-show="false"
                          >
                            <el-option
                              v-for="item in selectedAmenities"
                              :key="item.id"
                              :label="item.amenity"
                              :value="item.id"
                            >
                            </el-option>
                          </el-select>
                          <b-form-invalid-feedback
                            >{{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                      <div class="chips amenities-chips">
                        <b-badge
                          pill
                          variant="secondary"
                          class="chip-items"
                          v-for="(amenity, index) in selectedAmenities"
                          :key="index"
                        >
                          <div class="chip-item">
                            <div class="chip-inner">
                              <div class="chip-icon">
                                <img
                                  :src="getAmenityIcon(amenity.image)"
                                  :alt="amenity.amenity"
                                />
                              </div>
                              <span>{{ amenity.amenity }}</span>
                            </div>
                            <b-btn
                              variant="link"
                              class="p-0 text-grey"
                              @click="removeChip(index)"
                            >
                              <span class="material-icons">close</span>
                            </b-btn>
                          </div>
                        </b-badge>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </b-form>
        </ValidationObserver>
      </div>

      <!-- add property confirmation message -->
      <b-modal
        ref="refConfirmation"
        hide-footer
        centered
        size="md"
        modal-class="close-btn-right"
      >
        <template #modal-header="{ close }">
          <b-button variant="link" class="close-btn" @click="close()">
            <img :src="getIconUrl('close.svg')" alt="close" />
          </b-button>
        </template>
        <div class="d-block text-center">
          <h3 class="body-modal-heading">{{ lang.label.reviewedProperty }}</h3>
          <p class="body-modal-content">{{ lang.label.updateAnytime }}</p>
        </div>
        <div class="modal-buttons text-center modal-btn-responsive">
          <b-button class="btn-mw" variant="primary" @click="yes()">{{
            lang.label.buttons.yes
          }}</b-button>
          <b-button class="btn-mw" variant="danger" @click="hideModal">{{
            lang.label.buttons.no
          }}</b-button>
        </div>
      </b-modal>

      <!-- cancel property confirmation message -->
      <b-modal
        ref="cancelConfirmation"
        hide-footer
        centered
        size="md"
        modal-class="close-btn-right"
      >
        <template #modal-header="{ close }">
          <b-button variant="link" class="close-btn" @click="close()">
            <img :src="getIconUrl('close.svg')" alt="close" />
          </b-button>
        </template>
        <div class="d-block text-center">
          <h3 class="body-modal-heading">{{ lang.label.areYouSureCancel }}</h3>
          <p class="body-modal-content">{{ lang.label.allCleared }}</p>
        </div>
        <div class="modal-buttons text-center modal-btn-responsive">
          <b-button class="btn-mw" variant="primary" @click="yesClose()">{{
            lang.label.buttons.yes
          }}</b-button>
          <b-button class="btn-mw" variant="danger" @click="no()">{{
            lang.label.buttons.no
          }}</b-button>
        </div>
      </b-modal>

      <!-- lease agreement configuration sidebar -->
      <b-sidebar
        id="leaseAgreementConfiguration"
        footer-class="sidebar-footer"
        sidebar-class="sidebar-view"
        text-variant="none"
        backdrop
        right
        width="462px"
        @change="updateLeaseSidebar"
        :visible="showSidebarLease"
      >
        <template #header="{ hide }">
          <div class="d-flex justify-content-between w-100">
            <div class="d-flex align-items-center">
              <b-btn variant="link" size="sm" class="p-0" @click="hide">
                <img :src="getIconUrl('close-sm.svg')" alt="close" />
              </b-btn>
              <h4 class="sidebar-header-title mb-0">
                {{ lang.label.leaseAgreementConfiguration }}
              </h4>
            </div>
          </div>
        </template>
        <div class="amenity-body">
          <lease-agreement-configuration
            :isRented="statusSelected == 2"
            :rentAmount="models.rentAmount"
            :securityDepositAmount="models.securityDepositAmount"
            :maintenanceFee="models.maintenanceFee"
            :paymentPeriod="models.paymentPeriod"
            :selectedBroker="selectedBrokerContact"
            :selectedOwner="selectedOwnerContact"
            :selectedUserType="models.userType"
            :isAddProperty="true"
            :isSelfBroker="isBrokerNoteVisible"
            :propertyStatus="statusSelected"
            @addBroker="addBroker"
            @handleLeaseDetailSubmit="handleLeaseDetailSubmit"
            @handleInvalidLeaseInfo="handleInvalidLeaseInfo"
          ></lease-agreement-configuration>
        </div>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import jsondata from "@/assets/json/data.json";
import AmenitiesSelection from "@/components/AmenitiesSelection.vue";
import LeaseAgreementConfiguration from "@/components/LeaseAgreementConfiguration.vue";
import { mapGetters } from "vuex";
import alertSection from "@/views/shared/AlertSection.vue";
import mediaUploadSection from "@/views/shared/mediaUploadSection.vue";
import contactSelect from "@//components/contacts/contactSelect.vue";
import GoogleMapSection from "@/views/shared/GoogleMapSection.vue";
import moment from "moment";
import mixin from "@/mixins/mixin";
import property_mixin from "@/mixins/property_mixin";
import Swal from "sweetalert2";
export default {
  name: "addProperties",
  components: {
    "amenities-selection": AmenitiesSelection,
    "lease-agreement-configuration": LeaseAgreementConfiguration,
    "alert-section": alertSection,
    "media-upload-section": mediaUploadSection,
    "google-map": GoogleMapSection,
    "contact-select": contactSelect,
  },
  mixins: [mixin, property_mixin],
  computed: {
    ...mapGetters({
      loading: "loading",
      ImageUrl: "ImageUrl",
      language: "language",
    }),
    /**
     * Returns selected map coordinates object as a sting
     */
    mapCoords() {
      this.canLeave = this.models.location ? false : true
      return this.models.location ? JSON.stringify(this.models.location) : null;
    },
    /**
     * Returns id list of selected amenities
     */
    selectedAmenitiesId() {
      this.canLeave = Object.keys(this.selectedAmenities).length ? false : true
      return this.selectedAmenities.map((a) => a.id);
    },
  },
  data() {
    return {
      lang: this.$lang.add_properties,
      data: jsondata,
      statusSelected: "1",
      isVisible: false,
      contactListVisible: false,
      showSidebarLease: false,
      statusOption: [
        { text: this.$lang.add_properties.label.form.placeholder.available, value: "1" },
        { text: this.$lang.add_properties.label.form.placeholder.rented, value: "2" },
      ],
      models: {
        rentAmount: "",
        securityDepositAmount: "",
        propertyType: "",
        maintenanceFee: "",
        propertyName: "",
        aboutthisProperty: "",
        approxMtSquare: "",
        propertyArea: "",
        constructionYear: "",
        numberBedrooms: "",
        numberBathrooms: "",
        parking: "",
        location: null,
        blockPropertynumber: "",
        streetRoadName: "",
        city: "",
        state: "",
        country: "",
        zoneCode: "",
        uploadDocument: [],
        document: [],
        userType: "1",
        paymentPeriod: "",
        leaseAgreementDetails: {},
      },
      staticLocation: {
        "longitude": 0,
        "latitude": 0
      },
      isMapAvailable: true,
      pickerOptions: {
        disabledDate: this.disabledDate,
        onPick: this.pick,
      },
      propertyTypeList: [
        {
          text: this.$lang.add_properties.label.form.placeholder.commercial,
          value: "1",
        },
        {
          text: this.$lang.add_properties.label.form.placeholder.residential,
          value: "2",
        },
      ],
      numberBathroomsList: [
        {
          text: "1",
          value: "1",
        },
        {
          text: "2",
          value: "2",
        },
        {
          text: "3",
          value: "3",
        },
        {
          text: "4",
          value: "4",
        },
        {
          text: "5",
          value: "5",
        },
        {
          text: "6",
          value: "6",
        },
        {
          text: "7",
          value: "7",
        },
        {
          text: "8",
          value: "8",
        },
        {
          text: "9",
          value: "9",
        },
        {
          text: "10",
          value: "10",
        },
      ],
      numberBedroomsList: [
        {
          text: "1",
          value: "1",
        },
        {
          text: "2",
          value: "2",
        },
        {
          text: "3",
          value: "3",
        },
        {
          text: "4",
          value: "4",
        },
        {
          text: "5",
          value: "5",
        },
        {
          text: "6",
          value: "6",
        },
        {
          text: "7",
          value: "7",
        },
        {
          text: "8",
          value: "8",
        },
        {
          text: "9",
          value: "9",
        },
        {
          text: "10",
          value: "10",
        },
      ],
      userTypeEnum: [
        {
          text: this.$lang.add_properties.label.form.placeholder.realtor,
          value: "1",
        },
        {
          text: this.$lang.add_properties.label.form.placeholder.owner,
          value: "2",
        },
      ],
      paymentPeriodList: [
        { text: this.$lang.property_details.label.paymentFrequency.monthly, value: "1" },
        { text: this.$lang.property_details.label.paymentFrequency.quarterly, value: "2" },
        { text: this.$lang.property_details.label.paymentFrequency.haldYearly, value: "3" },
        { text: this.$lang.property_details.label.paymentFrequency.yearly, value: "4" },
      ],
      selectedAmenities: [],
      selectedBrokerContact: {},
      selectedOwnerContact: {},
      selectedStaffMember:{},
      isLeaseAdded: false,
      addedPropertyId: null,
      addedTenantId: null,
    };
  },
  created() {
    moment.updateLocale(this.language, {});
  },
  watch: {
    "models.userType"(val) {
      if (val == 2) {
        this.selectedOwnerContact = {};
      }
    },
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/styles/img/" + img);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    getAmenityIcon(amenity) {
      return this.ImageUrl + amenity;
    },
    goBack() {
      this.$router.push({ name: "properties" });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      let self = this;
      let addressArr = new Array();
      if(self.models.blockPropertynumber.trim() != "") {
          addressArr.push(self.models.blockPropertynumber);
      }
      if(self.models.streetRoadName.trim() != "") {
          addressArr.push(self.models.streetRoadName);
      }
      if(self.models.city.trim() != "") {
          addressArr.push(self.models.city);
      }
      if(self.models.state.trim() != "") {
          addressArr.push(self.models.state);
      }
      if(self.models.country.trim() != "") {
          addressArr.push(self.models.country);
      }
      if(self.models.zoneCode.trim() != "") {
          addressArr.push(self.models.zoneCode);
      }

      let address = addressArr.join(", ");
      self.canLeave = true;
      let data = {
        user_type: self.models.userType,
        type: self.models.propertyType,
        block: self.models.blockPropertynumber,
        street: self.models.streetRoadName,
        address: address,
        city: self.models.city,
        state: self.models.state,
        country: self.models.country,
        zip: self.models.zoneCode,
        status: self.statusSelected,
        property_name: self.models.propertyName,
        description: self.models.aboutthisProperty,
        approx: self.models.approxMtSquare,
        area: self.models.propertyArea,
        payment_frequency : self.models.paymentPeriod,
        year: moment(self.models.constructionYear).format("YYYY"),
        bedrooms: self.models.numberBedrooms,
        bathrooms: self.models.numberBathrooms,
        parking: self.models.parking,
        latitude: self.mapCoords ? JSON.parse(self.mapCoords).lat : 0,
        longitude: self.mapCoords ? JSON.parse(self.mapCoords).lng : 0,
        rent_amount: self.models.rentAmount,
        security_amount: self.models.securityDepositAmount,
        maintenance_fee: self.models.maintenanceFee,
        amenities: self.selectedAmenitiesId,
        owner: self.selectedOwnerContact.id ? self.selectedOwnerContact.id : null,
        realtor: self.selectedBrokerContact.id ? self.selectedBrokerContact.id : null,
        staff: self.selectedStaffMember.user_id ? self.selectedStaffMember.user_id : null
      };
      let method = "addProperty";
      if (self.addedPropertyId) {
        method = "editProperty";
        data = {
          id: self.addedPropertyId,
          data: data,
        };
      }
      self.$store.dispatch(method, data).then((response) => {
        if (response.status == 200) {
          if (response.data.message) {
            self.$store.commit("setMessages", {
              type: "success",
              message: response.data.message,
            });
          }
          self.addedPropertyId = response.data.id;
          if (self.isLeaseAdded) {
            if (!self.addedTenantId) {
              self.handleTenantSubmit(self.addedPropertyId || response.data.id);
            } else {
              self.handleLeaseDocUpload(self.addedTenantId, self.addedPropertyId);
            }
          } else {
            self.handleMediaUpload(self.addedPropertyId || response.data.id);
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    removeChip(index) {
      this.selectedAmenities.splice(index, 1);
    },
    addPropertyConfirmation() {
      this.$refs["refConfirmation"].show();
    },
    hideModal() {
      this.$refs["refConfirmation"].hide();
    },
    cancelPropertyConfirmation() {
      this.$refs["cancelConfirmation"].show();
    },
    async yes() {
      let self = this;
      self.$refs["refConfirmation"].hide();
      const valid = await self.$refs.observer.validate();
      if (valid) {
        self.onSubmit();
      } else {
        self.scrollToError();
      }
    },
    yesClose() {
      this.canLeave = true
      this.$router.push({ name: "properties" });
    },
    no() {
      this.$refs["cancelConfirmation"].hide();
    },
    contactList() {
      this.contactListVisible = !this.contactListVisible;
    },
    addBroker(val) {
      this.updateLeaseSidebar(val);
    },
    /**
     * Handles submit event for lease details and fetches user inputs
     */
    handleLeaseDetailSubmit(data) {
      this.models.leaseAgreementDetails = data;
      this.handleLeaseDetailChange();
      this.isLeaseAdded = true;
      this.updateLeaseSidebar(false);
      this.showLeaseSubmitMessage();
    },
    /**
     * Handles updating rent values on lease update
     */
    handleLeaseDetailChange() {
      this.models.rentAmount = this.models.leaseAgreementDetails.extra.rentAmount;
      this.models.paymentPeriod = this.models.leaseAgreementDetails.payment_frequency;
      this.models.securityDepositAmount = this.models.leaseAgreementDetails.extra.depositAmount;
    },
    /**
     * Handles invalid info in lease event
     */
    handleInvalidLeaseInfo() {
      this.isLeaseAdded = false;
    },
    /**
     * Handles visibility of lease sidebar form
     * @param {boolean} val Sidebar visibility value
     */
    updateLeaseSidebar(val) {
      this.showSidebarLease = val;
      const body = document.getElementsByTagName("body")[0];
      if (val) {
        body.classList.add("overflow-hidden");
      } else {
        setTimeout(() => {
          body.classList.remove("overflow-hidden");
        }, 100);
      }
    },
    /**
     * Handles tenant detail submit
     * @param {number} id Property id against which tenant will be added
     */
    handleTenantSubmit(id) {
      let self = this;
      if (self.isLeaseAdded) {
        let data = JSON.parse(JSON.stringify(self.models.leaseAgreementDetails));
        delete data.extra;
        data["property"] = id;

        self.$store.dispatch("addtenant", data).then((response) => {
          if (response.status == 200) {
            if (response.data.message) {
              self.$store.commit("setMessages", {
                type: "success",
                message: response.data.message,
              });
            }
            self.addedTenantId = response.data.id;
            self.handleLeaseDocUpload(response.data.id, id);
          } else {
            let message = response.data.message || response.data.detail;
            if (message) {
              self.$store.commit("setMessages", {
                type: "danger",
                message: message,
              });
            }
          }
        });
      }
    },
    /**
     * Handles lease document upload
     * @param {number} id Lease id
     * @param {number} propertyId Property id
     */
    handleLeaseDocUpload(id, propertyId) {
      let self = this;
      let formData = new FormData();
      formData.append("lease_agreement", self.models.uploadDocument);
      let data = {
        id: id,
        formData: formData,
      };
      self.$store.dispatch("uploadLeaseMedia", data).then((response) => {
        if (response.status == 200) {
          self.handleMediaUpload(propertyId);
          if (response.data.message) {
            self.$store.commit("setMessages", {
              type: "success",
              message: response.data.message,
            });
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Fetches selected geo location coords
     * @param {object} locationdata Selected location
     */
    selectedLocation(locationData) {
      if (locationData.isDefaultLocation) {
        this.models.location = locationData.data.geometry.location;
        this.models.address = locationData.data.formatted_address;
      } else {
        this.models.location = locationData.geometry.location;
        this.models.address = locationData.formatted_address;
        this.handleLocationPrefill(locationData);
      }
    },
    /**
     * Handles Map error case to disable map validation
     */
    handleMapError() {
      this.isMapAvailable = false;
    },
    /**
     * Handles selectable and disabled dates
     * @param {Date} date Date to be checked
     */
    disabledDate: function (date) {
      const today = moment();
      let fromDate = moment(today);
      fromDate = new Date(fromDate.format("YYYY-MM-DD HH:mm:ss"));
      if (fromDate) {
        return fromDate < date;
      }
      return false;
    },
    /**
     * Handles selected amenities from sidebar
     * @param {object} amenities Selected amenities
     */
    handleAmenities(amenities) {
      this.selectedAmenities = amenities;
    },
    /**
     * Handles selected broker contact
     * @param {object} contact Selected contact
     */
    handleSelectedBroker(contact) {
      console.log("handleSelectedBroker", contact);
      this.canLeave = Object.keys(contact).length ? false : true
      this.selectedBrokerContact = contact;
    },
    /**
     * Handles selected owner contact
     * @param {object} contact Selected contact
     */
    handleSelectedOwner(contact) {
      this.canLeave = Object.keys(contact).length ? false : true
      this.selectedOwnerContact = contact;
    },
    handleSelectedStaff(member) {
      this.canLeave = Object.keys(member).length ? false : true
      this.selectedStaffMember = member
    },
    /**
     * Handles selected media
     * @param {Array} media Selected media
     */
    async handleFileChange(media) {
      this.models.document = media;
    },
    /**
     * Handles media upload through API calls
     * @param {number} id Property id for selected media
     */
    handleMediaUpload(id) {
      let self = this;
      let formData = new FormData();
      self.models.document.forEach((doc, index) => {
        formData.append("file_" + (index + 1), doc);
      });
      let data = {
        id: id,
        formData: formData,
      };
      self.$store.dispatch("uploadPropertyMedia", data).then((response) => {
        if (response.status == 200) {
          setTimeout(() => {
            self.$store.commit("setMessages", {
              type: "success",
              message: self.lang.label.propertyAddedAlert,
              allow_module: "propertyList",
            });
          }, 0);
          self.$router.push({ name: "properties", query: {activeTab: self.models.userType } });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
  },
};
</script>
