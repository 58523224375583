<!-- content from roles_permissions.js -->
<template>
  <div class="body-content">
    <div class="top-sticky">
      <div class="top-bar-radius"></div>
    </div>
    <div class="starts-section" v-loading="loading">
      <!-- alert component -->
      <alert-section></alert-section>

      <!-- body content header  -->
      <div class="page-breadcrumb">
        <div class="d-md-flex justify-content-between align-items-center">
          <div class="pg-bread">
            <b-breadcrumb class="bg-transparent p-0 mb-0">
              <b-breadcrumb-item active>
                {{ lang.label.rolesPermissions }}
              </b-breadcrumb-item>
              <b-breadcrumb-item active>
                <span class="material-icons align-middle">people</span>
                <span class="align-middle ml-1">{{ tableData.length || '' }}</span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>

          <div class="pg-right">
            <div class="d-flex align-items-center">
              <b-btn
                variant="primary"
                size="sm"
                class="text-nowrap btn-mw w-sm-100"
                @click="addNewRole"
                >+ {{ lang.label.buttons.add }}</b-btn
              >
            </div>
          </div>
        </div>
      </div>
      <div class="table-section vertical-top">
        <div class="table-card table-border-none with-pagination-table">
          <b-card no-body class="custom-card reports-table">
            <b-card-body class="p-0">
              <v-client-table :data="tableData" :columns="columns" :options="options">
                <template slot="permissions" slot-scope="props">
                  <span
                    v-html="
                      getProcessedStr(
                        props.row.permissions.length > 0
                          ? '• ' + props.row.permissions.join('\n• ')
                          : 'N/A'
                      )
                    "
                  ></span>
                </template>
                <template slot="actions" slot-scope="props">
                  <el-tooltip :content="lang.label.tooltip.edit" placement="top">
                  <b-btn variant="link" class="p-0">
                    <span class="material-icons" @click="editRole(props.row.id)"
                      >edit</span
                    >
                  </b-btn>
                </el-tooltip>
                <el-tooltip :content="lang.label.tooltip.delete" placement="top">
                  <b-btn
                    variant="link"
                    class="p-1"
                    @click="deleteRole(props.row.id)"
                    v-if="props.row.is_allow_delete"
                  >
                    <span class="material-icons">delete</span>
                  </b-btn>
                </el-tooltip>
                </template>
              </v-client-table>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>

    <!-- add new role -->
    <b-sidebar
      id="addNewRole"
      footer-class="sidebar-footer"
      sidebar-class="sidebar-view"
      text-variant="none"
      backdrop
      right
      width="462px"
      @change="updateSidebar"
      :visible="isVisible"
    >
      <template #header="{ hide }">
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex align-items-center">
            <b-btn variant="link" size="sm" class="p-0" @click="hide">
              <img :src="getIconUrl('close-sm.svg')" alt="close" />
            </b-btn>
            <h4 class="sidebar-header-title mb-0">{{ isEdit ? lang.label.editRolesPermission : lang.label.addRolesPermission }}</h4>
          </div>
        </div>
      </template>
      <div class="amenity-body">
        <update-role
          v-if="isVisible"
          :isEdit="isEdit"
          :roleId="roleId"
          @handleUpdate="handleUpdate"
        ></update-role>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import Vue from "vue";
import jsondata from "@/assets/json/data.json";
import { ClientTable } from "vue-tables-2";
Vue.use(ClientTable, {}, false, "bootstrap4");
import alertSection from "@/views/shared/AlertSection.vue";
import mixin from "@/mixins/mixin";
import updateRole from "./Update.vue";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import { mapGetters } from "vuex";
export default {
  components: {
    "alert-section": alertSection,
    "update-role": updateRole,
  },
  mixins: [mixin],
  computed: {
    ...mapGetters({
      loading: "loading",
      isUserVerified: "isUserVerified",
    }),
  },
  data() {
    return {
      lang: this.$lang.roles_permissions,
      data: jsondata,
      columns: ["name", "permissions", "actions"],
      tableData: [],
      options: {
        headings: {
          name: this.$lang.roles_permissions.label.tableHeader.roleName,
          permissions: this.$lang.roles_permissions.label.tableHeader.permissions,
          actions: this.$lang.roles_permissions.label.tableHeader.actions,
        },
        filterable: true,
        skin: "table",
        perPage: 10,
        perPageValues: [],
        sortable: ["name"],
        columnsClasses: {
          name: "name-width",
          actions: "action-width",
        },
        pagination: { chunk: 5 },
        texts: {
          noResults: this.$lang.root.label.noRecord,
          filter: this.$lang.root.label.filter,
          filterPlaceholder: this.$lang.root.label.filterPlaceholder,
          limit: this.$lang.root.label.limit,
          count: this.$lang.root.label.countRolesAndPermission,
        },
      },
      isVisible: false,
      isEdit: false,
      roleId: null,
    };
  },
  mounted() {
    this.listRole();
  },
  methods: {
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    /**
     * Get roles list to display on table
     */
    listRole() {
      let self = this;
      self.$store.dispatch("listRole").then((response) => {
        if (response.status == 200) {
          self.tableData = response.data;
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    editRole(id) {
      if (this.isUserVerified) {
        this.$store.commit("setVerificationAlert", true);
      } else {
        this.isEdit = true;
        this.roleId = id;
        this.isVisible  = true;
      }
    },
    deleteRole(id) {
      let self = this;
      if (this.isUserVerified) {
        this.$store.commit("setVerificationAlert", true);
      } else {
        Swal.fire({
          title: self.lang.label.confirmTitle,
          text: self.lang.label.confirmMsg,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: self.lang.label.buttons.yes,
          cancelButtonText: self.lang.label.buttons.no,
          focusConfirm: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            self.$store.dispatch("deleteRole", id).then((response) => {
              if (response.status == 200) {
                Swal.fire(this.$lang.root.label.deleted, response.data.message, "success");
                self.listRole();
              } else {
                let message = response.data.message || response.data.detail;
                if (message) {
                  self.$store.commit("setMessages", {
                    type: "danger",
                    message: message,
                  });
                }
              }
            });
          }
        });
      }
    },
    /**
     * Handles add role event
     */
    addNewRole() {
      if (this.isUserVerified) {
        this.$store.commit("setVerificationAlert", true);
      } else {
        this.updateSidebar(true);
      }
    },
    /**
     * Handles visibility of update sidebar form
     * @param {boolean} val Sidebar visibility value
     */
    updateSidebar(val) {
      this.isVisible = val;
      if (!this.isVisible) {
        this.isEdit = false;
        this.roleId = null;
      }
      const body = document.getElementsByTagName('body')[0];
      if(val){
        body.classList.add("overflow-hidden")
      } else {
        body.classList.remove("overflow-hidden")
      }
    },
    /**
     * Loads new list when role is updated
     */
    handleUpdate() {
      this.updateSidebar(false);
      this.listRole();
    },
  },
};
</script>
