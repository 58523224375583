module.exports = {
    label: {
      form: {      
        label: {
          firstName: 'Nombre',
          lastName: 'Apellido',
          email: 'Electrónico',
          contactNumber: 'Número De Teléfono',
          contactType: 'Tipo De Contacto',
          noRecord: 'Ningún Registro Disponible',
          notes:'Notas'
        },
        contactType: {
          realtor: "Agente Inmobiliario",
          owner: "Propietario",
          tenant: "Inquilino",
          other: "Otros",
        },
        validation: {
          firstName: 'nombre',
          lastName: 'apellido',
          email: 'electrónico',
          contactNumber: 'número de teléfono',
          contactType: 'tipo de contacto',
          notes:'notas'
        },
        placeholder: {
          enter: 'Entrar',
          email: 'example@mail.com',
          enterNumber: 'Ingrese El Número',
          select: 'Seleccionar',
          searchContact: 'Buscar Contacto',
          contactNumber: 'Número De Teléfono'
        }
      },
      buttons: {
        addContact: 'Añadir Contacto',
        select: 'Seleccionar',
        selected: 'Seleccionado',
        saveContact: 'Guardar Contacto'
      }
    }
  };