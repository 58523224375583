module.exports = {
    label: {
      verifyEmialAlert: "Verifique su correo electrónico para obtener acceso completo a Miradoor, es posible que no pueda añadir contactos o propiedades.",
      myContacts: 'Mis Contactos',
      myContactDetail: 'Mis Detalles De Contacto',
      editContact: "Editar Contacto",
      addNewContact: 'Añadir Nuevo Contacto',
      assignHome: 'Propiedades Asignadas De',
      bankDetails:'Detalles Bancarios De',
      notes: 'Notas',
      addNoteLabel: 'Añadir Nota',
      other: 'Otros',
      dateFilter: 'Filtro De Fecha',
      from: 'De',
      to: 'Para',
      warning : 'Advertencia',
      confirmTitle: '¿Estas Seguro?',
      confirmMsg: '¿Quieres borrar esto?',
      confirmContactMsg:'Este usuario ya esta en el sistema. ¿estás seguro que quieres agregar a este usuario a tu lista de contactos?',
      staffWarningMsg: 'Este usuario ya está agregado al personal',
      noRecord: 'Ningún Registro Disponible',
      tableHeader: {
        contactName: "Nombre De Contacto",
        role: "Función",
        emailId: "Correo Electrónico",
        contact: "Teléfono",
        dateAdded: "Fecha Que Se Agrego",
        actions: "Acciones",
      },
      message: {
        delete: '¿Está seguro de que desea eliminar a este contacto?',
        deleteNoteText: '¿está seguro de que desea eliminar a este nota?',
      },
      tabs: {
        all: 'Todo',
        broker: 'Agente Inmobiliario',
        tenant: 'Inquilino',
        owner: "Propietario",
        other: 'Otros'
      },
      buttons: {
        sendCode: 'Enviar Código De Verificación',
        add: 'Añadir Nuevo Contacto',
        update: 'Actualizar',
        assignHome: 'Propiedades Asignadas',
        delete: 'Borrar',
        edit: 'Editar',
        yes: 'Sí',
        no: 'No',
        assign: 'Asignar',
        save: 'Guardar',
        cancel: 'Cancelar',
        addNote: 'Añadir Nota',
        filter: 'Aplicar Filtro',
      },
      validation: {
        note: 'nota'
      },
      tooltip:{
        viewContact : 'Ver Contacto',
        notes : 'Ver Notas',
        assignedProperties : 'Propiedades Asignadas',
        deleteContact : 'Borrar Contacto',
        bankDetails:'Ver Detalles Bancarios',
        editNote: 'Editar Nota',
        deleteNote: 'Borrar Nota'
      }
    }
  };