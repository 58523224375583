module.exports = {
  label: {
    verifyEmialAlert: "Verify your email to get full access to Miradoor, you may not be able to add a contact or properties.",
    emailAlert: "As you have not provided your email this action can not be performed. Please provide your email from My Profile",
    yourTrialPeriod: 'Your trial period will be expired in',
    suscriptionCancelled: 'Your subscription has been cancelled. You can use the system for ',
    days: 'days',
    warning: 'Warning',
    expiredTrial: 'Your trial period is expired',
    uploadDocument:'Verify your identification document to get full access to Mirador,you may not be able to add  a contact or properties.',
    verifyEmailAndIdentityAlert:'Verify your email and identification document to get full access to Mirador,you may not be able to add  a contact or properties.',
    documentSizeValidation:'You cannot upload document greater than 10 MB is size',
    rejectionReason:'Rejection reason ?',
    rejectionText:'Your document has been rejected.',
    form:{
      placeholder:{
        email:'example@mail.com'
      },
      validation:{
        email : 'Email'
      },
      label : {
        email : 'Email'
      }
    },
    extensionValidation:'Only .jpeg, .jpg, .png and .pdf extensions are allowed',
    buttons: {
      sendCode: 'Send Verification Code',
      enrollNow: 'Enroll Now',
      uploadDocument:'Upload Identification Document',
      submit:'Submit',
      ok : 'Ok',
    }
  }
};