<!-- content from dashboard.js -->
<template>
  <div class="body-content">
    <div class="dashboard">
      <div class="top-sticky">
        <div class="top-bar-radius"></div>
      </div>

      <div class="starts-section" v-loading="loading">
        <!-- alert component -->
        <alert-section current-module="Dashboard"></alert-section>

        <!-- statestics section -->
        <div class="stats-section mt-0">
          <b-row>
            <b-col
              cols="6"
              md="6"
              lg="3"
              v-for="(stats, index) in statistics"
              :key="index"
            >
              <b-card no-body class="custom-card">
                <b-card-body>
                  <div class="stats-card">
                    <div class="stats-icon">
                      <img :src="getIconUrl(stats.icon)" alt="" />
                    </div>
                    <h2 class="stats-counter">{{ stats.counts }}</h2>
                    <p class="stats-name">{{ stats.name }}</p>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </div>

        <!-- graph section -->
        <div class="graph-section">
          <b-row>
            <b-col md="12" xl="6" class="chart-card">
              <b-card no-body class="custom-card">
                <template #header>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                      <div class="chart-header-icon">
                        <img :src="getIconUrl('pricing_bag.svg')" alt="" />
                      </div>
                      <h4 class="chart-title">{{ lang.label.monthlyIncome }}</h4>
                    </div>
                    <div class="datepicker-section">
                      <el-date-picker
                        v-model="selectIncomeMonth"
                        format="MM-yyyy"
                        type="month"
                        :placeholder="lang.label.selectMonth"
                      >
                      </el-date-picker>
                    </div>
                  </div>
                </template>
                <b-card-body>
                  <div class="chart-view">
                    <apexChart
                      width="100%"
                      type="bar"
                      :options="chartOptionsIncome"
                      :series="seriesIncome"
                      ref="monthlyIncome"
                    >
                    </apexChart>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="12" xl="6" class="chart-card">
              <b-card no-body class="custom-card">
                <template #header>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                      <div class="chart-header-icon">
                        <img :src="getIconUrl('expense.svg')" alt="" />
                      </div>
                      <h4 class="chart-title">{{ lang.label.monthlyExpenses }}</h4>
                    </div>
                    <div class="datepicker-section">
                      <el-date-picker
                        v-model="selectExpenseMonth"
                        format="MM-yyyy"
                        type="month"
                        :placeholder="lang.label.selectMonth"
                      >
                      </el-date-picker>
                    </div>
                  </div>
                </template>
                <b-card-body>
                  <div class="chart-view">
                    <apexChart
                      width="100%"
                      type="bar"
                      :options="chartOptionsExpense"
                      :series="seriesExpense"
                      ref="monthlyExpenses"
                    >
                    </apexChart>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="12" xl="6" class="chart-card">
              <b-card no-body class="custom-card">
                <template #header>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                      <div class="chart-header-icon">
                        <img :src="getIconUrl('home.svg')" alt="" />
                      </div>
                      <h4 class="chart-title">{{ lang.label.rentedUnits }}</h4>
                    </div>
                    <div class="datepicker-section">
                      <el-date-picker
                        v-model="selectRentedMonth"
                        format="MM-yyyy"
                        type="month"
                        :placeholder="lang.label.selectMonth"
                      >
                      </el-date-picker>
                    </div>
                  </div>
                </template>
                <b-card-body>
                  <div class="chart-view">
                    <apexChart
                      width="100%"
                      type="bar"
                      :options="chartOptionsRented"
                      :series="seriesRented"
                      ref="rentedUnits"
                    >
                    </apexChart>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="12" xl="6" class="chart-card">
              <b-card no-body class="custom-card">
                <template #header>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                      <div class="chart-header-icon">
                        <img :src="getIconUrl('properties.svg')" alt="" />
                      </div>
                      <h4 class="chart-title">{{ lang.label.propertiesAvailable }}</h4>
                    </div>
                    <div class="datepicker-section">
                      <el-date-picker
                        v-model="selectAvailableMonth"
                        format="MM-yyyy"
                        type="month"
                        :placeholder="lang.label.selectMonth"
                      >
                      </el-date-picker>
                    </div>
                  </div>
                </template>
                <b-card-body>
                  <div class="chart-view">
                    <apexChart
                      width="100%"
                      type="bar"
                      :options="chartOptionsAvailable"
                      :series="seriesAvailable"
                      ref="propertiesAvailable"
                    >
                    </apexChart>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </div>

        <!-- table section -->
        <div class="table-section">
          <h4 class="table-heading">{{ lang.label.other }}</h4>
          <div class="table-card table-border-none">
            <b-card no-body class="custom-card">
              <b-card-body class="p-0">
                <v-client-table :data="tableData" :columns="columns" :options="options">
                  <template slot="actions" slot-scope="props">
                    <!-- slot-scope="props" -->
                    <el-tooltip :content="lang.label.tooltip.editContact">
                      <b-btn
                      variant="link"
                      class="p-1"
                      @click="updateSidebar(true, props.row)"
                    >
                      <span class="material-icons">edit</span>
                    </b-btn>
                    </el-tooltip>
                    <el-tooltip :content="lang.label.tooltip.deleteContact">
                      <b-btn
                      variant="link"
                      class="p-1"
                      @click="deleteContact(props.row.id)"
                    >
                      <span class="material-icons">delete</span>
                    </b-btn>
                    </el-tooltip>
                  </template>
                </v-client-table>
              </b-card-body>
            </b-card>
          </div>
        </div>
        <b-sidebar
          id="editNewContact"
          footer-class="sidebar-footer"
          sidebar-class="sidebar-view"
          text-variant="none"
          backdrop
          right
          width="462px"
          @change="updateSidebar"
          :visible="isEdit"
        >
          <template #header="{ hide }">
            <div class="d-flex justify-content-between w-100">
              <div class="d-flex align-items-center">
                <b-btn variant="link" size="sm" class="p-0" @click="hide">
                  <img :src="getIconUrl('close-sm.svg')" alt="close" />
                </b-btn>
                <h4 class="sidebar-header-title mb-0">{{ lang.label.editContact }}</h4>
              </div>
            </div>
          </template>
          <div class="amenity-body" v-if="isEdit">
            <add-contact
              :contactData="contactDetails"
              @updateContact="updateContact"
              :isEdit="true"
            >
            </add-contact>
          </div>
        </b-sidebar>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import jsondata from "@/assets/json/data.json";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexChart", VueApexCharts);
import { ClientTable } from "vue-tables-2";
Vue.use(ClientTable, {}, false, "bootstrap4");
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import { mapGetters } from "vuex";
import alertSection from "@/views/shared/AlertSection.vue";
import moment from "moment";
import mixin from "@/mixins/mixin";
import AddContactForm from "@/components/contacts/AddForm.vue";
// for duplicate api calling 
var date = new Date();
export default {
  name: "DashboardPage",
  components: {
    "alert-section": alertSection,
    "add-contact": AddContactForm,
  },
  mixins: [mixin],
  computed: {
    ...mapGetters({
      messages: "messages",
      loading: "loading",
      login: "login",
      language: "language",
    }),
  },
  data() {
    return {
      lang: this.$lang.dashboard,
      data: jsondata,
      selectMonth: "",
      selectIncomeMonth: date,
      selectExpenseMonth: date,
      selectRentedMonth: date,
      selectAvailableMonth: date,
      seriesIncome: [
        {
          name: this.$lang.dashboard.label.income,
          data: [],
        },
      ],
      seriesExpense: [
        {
          name: this.$lang.dashboard.label.expense,
          data: [],
        },
      ],
      seriesRented: [
        {
          name: this.$lang.dashboard.label.rentedUnits,
          data: [],
        },
      ],
      seriesAvailable: [
        {
          name: this.$lang.dashboard.label.propertiesAvailable,
          data: [],
        },
      ],
      series: [
        {
          name: "Properties Available",
          data: [
            280,
            150,
            100,
            190,
            250,
            80,
            310,
            150,
            240,
            120,
            280,
            150,
            100,
            190,
            250,
            80,
            310,
            150,
            240,
            120,
            280,
            150,
            390,
            190,
            250,
            80,
            310,
            150,
            240,
            120,
            90,
          ],
        },
      ],
      chartOptionsIncome: {
        chart: {
          type: "bar",
          height: 350,
          width: "100%",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "50%",
            borderRadius: 5,
            borderRadiusApplication: 'end'
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
        },
        xaxis: {
          categories: JSON.parse(JSON.stringify(jsondata.statsMonths)),
          title: {
            text: "Days",
            offsetY: 150,
          },
          labels: {
            formatter: function (value) {
              return moment(value, "YYYY-MM-DD").format("D MMM");
            },
          },
        },
        yaxis: {
          title: {
            text: this.$lang.dashboard.label.monthlyIncome,
          },
        },
        fill: {
          opacity: 1,
          colors: ["#5734D6"],
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return '$' + val;
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#EAEAEA",
          strokeDashArray: 8,
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        responsive: [
          {
            breakpoint: 992,
            options: {
              xaxis: {
                tickAmount: 10,
              },
            },
          },
        ],
      },
      chartOptionsExpense: {
        chart: {
          type: "bar",
          height: 350,
          width: "100%",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "50%",
            borderRadius: 5,
            borderRadiusApplication: 'end'
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
        },
        xaxis: {
          categories: JSON.parse(JSON.stringify(jsondata.statsMonths)),
          title: {
            text: "Days",
            offsetY: 150,
          },
          labels: {
            formatter: function (value) {
              return moment(value, "YYYY-MM-DD").format("D MMM");
            },
          },
        },
        yaxis: {
          title: {
            text: this.$lang.dashboard.label.monthlyExpenses,
          },
        },
        fill: {
          opacity: 1,
          colors: ["#5734D6"],
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return '$' + val;
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#EAEAEA",
          strokeDashArray: 8,
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        responsive: [
          {
            breakpoint: 992,
            options: {
              xaxis: {
                tickAmount: 10,
              },
            },
          },
        ],
      },
      chartOptionsRented: {
        chart: {
          type: "bar",
          height: 350,
          width: "100%",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "50%",
            borderRadius: 5,
            borderRadiusApplication: 'end'
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
        },
        xaxis: {
          categories: JSON.parse(JSON.stringify(jsondata.statsMonths)),
          title: {
            text: "Days",
            offsetY: 150,
          },
          labels: {
            formatter: function (value) {
              return moment(value, "YYYY-MM-DD").format("D MMM");
            },
          },
        },
        yaxis: {
          title: {
            text: this.$lang.dashboard.label.rentedUnits,
          },
          labels: {
            formatter: function(val) {
              return isFinite(val) ? val.toFixed(0) : 1;
            }
          }
        },
        fill: {
          opacity: 1,
          colors: ["#5734D6"],
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#EAEAEA",
          strokeDashArray: 8,
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        responsive: [
          {
            breakpoint: 992,
            options: {
              xaxis: {
                tickAmount: 10,
              },
            },
          },
        ],
      },
      chartOptionsAvailable: {
        chart: {
          type: "bar",
          height: 350,
          width: "100%",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "50%",
            borderRadius: 5,
            borderRadiusApplication: 'end'
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
        },
        xaxis: {
          categories: JSON.parse(JSON.stringify(jsondata.statsMonths)),
          title: {
            text: "Days",
            offsetY: 150,
          },
          labels: {
            formatter: function (value) {
              return moment(value, "YYYY-MM-DD").format("D MMM");
            },
          },
        },
        yaxis: {
          title: {
            text: this.$lang.dashboard.label.propertiesAvailable,
          },
          labels: {
            formatter: function(val) {
              return isFinite(val) ? val.toFixed(0) : 1;
            }
          }
        },
        fill: {
          opacity: 1,
          colors: ["#5734D6"],
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#EAEAEA",
          strokeDashArray: 8,
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        responsive: [
          {
            breakpoint: 992,
            options: {
              xaxis: {
                tickAmount: 10,
              },
            },
          },
        ],
      },
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          width: "100%",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "50%",
            borderRadius: 5,
            borderRadiusApplication: 'end'
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
        },
        xaxis: {
          categories: [
            "1 Jan",
            "2 Jan",
            "3 Jan",
            "4 Jan",
            "5 Jan",
            "6 Jan",
            "7 Jan",
            "8 Jan",
            "9 Jan",
            "10 Jan",
            "11 Jan",
            "12 Jan",
            "13 Jan",
            "14 Jan",
            "15 Jan",
            "16 Jan",
            "17 Jan",
            "18 Jan",
            "19 Jan",
            "20 Jan",
            "21 Jan",
            "22 Jan",
            "23 Jan",
            "24 Jan",
            "25 Jan",
            "26 Jan",
            "27 Jan",
            "28 Jan",
            "29 Jan",
            "30 Jan",
            "31 Jan",
          ],
          title: {
            text: "Days",
            offsetY: 150,
          },
        },
        yaxis: {
          title: {
            text: "Properties Available",
          },
        },
        fill: {
          opacity: 1,
          colors: ["#5734D6"],
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#EAEAEA",
          strokeDashArray: 8,
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        responsive: [
          {
            breakpoint: 992,
            options: {
              xaxis: {
                tickAmount: 10,
              },
            },
          },
        ],

        //   responsive: [
        //   {
        //     breakpoint: 1000,
        //     chartOptions: {
        //       xaxis: {
        //     tickAmount: 3,

        //   },
        //     }
        //   }
        // ]
      },
      // table
      columns: ["name", "role", "email", "dateAdded", "actions"],
      tableData: [],
      options: {
        headings: this.$lang.dashboard.label.tableHeader,
        filterable: false,
        skin: "table",
        pagination: {
          show: false,
        },
        texts: {
          noResults: this.$lang.root.label.noRecord,
          filter: this.$lang.root.label.filter,
          filterPlaceholder: this.$lang.root.label.filterPlaceholder,
          limit: this.$lang.root.label.limit,
          count: this.$lang.root.label.count,
        },
        perPageValues: [],
        sortable: ["name", "role", "email", "dateAdded"],
        columnsClasses: {
          name: "name-width",
          actions: "action-width",
          role: "d-none d-md-table-cell ",
          email: "d-none d-md-table-cell ",
          dateAdded: "d-none d-md-table-cell ",
        },
      },
      statistics: [
        {
          id: 1,
          icon: "pricing_bag.svg",
          counts: "",
          name: this.$lang.dashboard.label.monthlyIncome,
        },
        {
          id: 2,
          icon: "expense.svg",
          counts: "",
          name: this.$lang.dashboard.label.monthlyExpenses,
        },
        {
          id: 3,
          icon: "home.svg",
          counts: "",
          name: this.$lang.dashboard.label.rentedUnits,
        },
        {
          id: 4,
          icon: "properties.svg",
          counts: "",
          name: this.$lang.dashboard.label.propertiesAvailable,
        },
      ],
      isEdit: false,
      contactDetails: {},
      rollName: {
        1: this.$lang.my_contacts.label.tabs.broker,
        2: this.$lang.my_contacts.label.tabs.owner,
        3: this.$lang.my_contacts.label.tabs.tenant,
      },
    };
  },
  watch: {
    selectIncomeMonth() {
      this.monthlyGraph(
        "selectIncomeMonth",
        "seriesIncome",
        "chartOptionsIncome",
        "monthlyIncome",
        "monthlyIncomeGraph"
      );
    },
    selectExpenseMonth() {
      this.monthlyGraph(
        "selectExpenseMonth",
        "seriesExpense",
        "chartOptionsExpense",
        "monthlyExpenses",
        "monthlyExpenseGraph"
      );
    },
    selectRentedMonth() {
      this.monthlyGraph(
        "selectRentedMonth",
        "seriesRented",
        "chartOptionsRented",
        "rentedUnits",
        "monthlyRentedGraph"
      );
    },
    selectAvailableMonth() {
      this.monthlyGraph(
        "selectAvailableMonth",
        "seriesAvailable",
        "chartOptionsAvailable",
        "propertiesAvailable",
        "monthlyAvailableGraph"
      );
    },
  },
  filters: {
    date(date) {
      return new Intl.DateTimeFormat("en-US").format(date);
    },
  },
  created() {
    moment.updateLocale(this.language, {});
  },
  mounted() {
    setTimeout(() => {
      this.$refs.monthlyIncome?.refresh();
      this.$refs.monthlyExpenses?.refresh();
      this.$refs.rentedUnits?.refresh();
      this.$refs.propertiesAvailable?.refresh();
    }, 0);
    this.loadInitialData();
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/styles/img/" + img);
    },
    getIconUrl(icon) {
      return require("@/assets/styles/img/icons/" + icon);
    },
    showAlert() {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this!",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        focusConfirm: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(this.$lang.root.label.deleted, "", "success");
        }
      });
    },
    formatCompactNumber(number) {
      const formatter = Intl.NumberFormat("en", { notation: "compact" });
      return formatter.format(number);
    },
    /**
     * Calls all initial APIs
     */
    loadInitialData() {
      this.dashboardStats();
      this.monthlyGraph(
        "selectIncomeMonth",
        "seriesIncome",
        "chartOptionsIncome",
        "monthlyIncome",
        "monthlyIncomeGraph"
      );
      this.monthlyGraph(
        "selectExpenseMonth",
        "seriesExpense",
        "chartOptionsExpense",
        "monthlyExpenses",
        "monthlyExpenseGraph"
      );
      this.monthlyGraph(
        "selectRentedMonth",
        "seriesRented",
        "chartOptionsRented",
        "rentedUnits",
        "monthlyRentedGraph"
      );
      this.monthlyGraph(
        "selectAvailableMonth",
        "seriesAvailable",
        "chartOptionsAvailable",
        "propertiesAvailable",
        "monthlyAvailableGraph"
      );
      this.listContacts();
    },
    /**
     * Fetches dashboard Stats
     */
    dashboardStats() {
      let self = this;
      self.$store.dispatch("dashboardStats").then((response) => {
        if (response.status == 200) {
          self.statistics[0].counts =
            "$" + self.formatCompactNumber(response.data.income);
          self.statistics[1].counts =
            "$" + self.formatCompactNumber(response.data.expense);
          self.statistics[2].counts = response.data.rentedUnits;
          self.statistics[3].counts = response.data.availableUnits;
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Handle formating  date string to yyyy-mm
     * @param {String} date Value that needs to be formated
     * @returns String formated date
     */
    formatDateStringForGraph: function (date) {
      return moment(date).format("YYYY-MM");
    },
    /**
     * Fetches Monthly Graph Data
     * @param {Boolean} selectMonth User selected month filter
     * @param {string} series Series data name
     * @param {string} chartOptions Chart options name
     * @param {string} graphRef Graph reference name
     * @param {string} method Graph method name
     */
    monthlyGraph(selectMonth, series, chartOptions, graphRef, method) {
      let self = this;
      self[selectMonth] = self[selectMonth] ? self[selectMonth] : moment();
      let data = {
        month_year: self.formatDateStringForGraph(self[selectMonth]),
      };
      self.$store.dispatch(method, data).then((response) => {
        if (response.status == 200) {
          self[series][0].data = response.data.data;
          self[chartOptions].xaxis.categories = response.data.label;
          setTimeout(() => {
            self.$refs[graphRef]?.refresh();
          }, 0);
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    /**
     * Handles loading tabale data based on active tab and applied date filter
     */
    listContacts() {
      let self = this;
      let data = {
        page: 1,
        size: 5,
        data: {
          type: 1,
        },
      };
      self.tableData = [];
      self.$store.dispatch("listContacts", data).then((response) => {
        if (response.status == 200) {
          self.tableData = [];
          response.data.results.forEach((el) => {
            self.tableData.push({
              id: el.id,
              name: el.first_name + " " + el.last_name,
              email: el.email,
              dateAdded: self.formatDateToShow(el.created_date),
              first_name: el.first_name,
              last_name: el.last_name,
              contact: el.contact,
              contact_type: el.contact_type,
              role: self.rollName[el.contact_type],
            });
          });
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
            });
          }
        }
      });
    },
    updateSidebar(val, contactDetails) {
      if (contactDetails) {
        this.contactDetails = contactDetails;
      }
      this.isEdit = val;
    },
    /**
     * Handles contact add operation
     * @param {object} data Contact data to be added
     */
    updateContact(data) {
      let self = this;
      let updateContactdata = {
        id: self.contactDetails.id,
        data: data,
      };
      self.$store.dispatch("updateContact", updateContactdata).then((response) => {
        if (response.status == 200) {
          self.isEdit = false;
          self.listContacts();
          if (response.data.message) {
            self.$store.commit("setMessages", {
              type: "success",
              message: response.data.message,
              allow_module: 'Dashboard',
            });
          }
        } else {
          let message = response.data.message || response.data.detail;
          if (message) {
            self.$store.commit("setMessages", {
              type: "danger",
              message: message,
              allow_module: 'Dashboard',
            });
          }
        }
      });
    },
    deleteContact(id) {
      let self = this;
      Swal.fire({
        title: self.lang.label.confirmTitle,
        text: self.lang.label.confirmMsg,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: self.lang.label.buttons.yes,
        cancelButtonText: self.lang.label.buttons.no,
        focusConfirm: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.$store.dispatch("deleteContact", id).then((response) => {
            if (response.status == 200) {
              Swal.fire(this.$lang.root.label.deleted, response.data.message, "success");
              self.listContacts();
            } else {
              let message = response.data.message || response.data.detail;
              if (message) {
                Swal.fire("Alert!", message, "danger");
              }
            }
          });
        }
      });
    },
  },
};
</script>
